<template>
  <div>
    <div class="cmp">
      <div class="col s12">
        <div class="row wrapper">
          <div class="col s12">
            <h1>{{ aggregateHeaderName }} - {{ name(selectedDistribution) }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col s8" />
          <div class="col s4">
            <button
              v-if="!isSelectedDistributionClosed && !waitingForConfirmation"
              class="btn float-right"
              :disabled="
                pendingCalculation ||
                selectedEndMonth == null ||
                selectedRevenues.length === 0 ||
                issuesTotalCount != 0
              "
              @click="onWaitForPayToLedger"
            >
              Transfer to ledger
            </button>
          </div>
        </div>

        <div class="row card">
          <div class="row small-inner">
            <label class="col s3">Payment rule</label>
            <div class="col s9">
              {{ aggregateHeaderAreaDistributionRuleName }}
            </div>
          </div>

          <div class="row small-inner">
            <label class="col s3">Category of Rights</label>
            <div class="col s9">
              <span v-if="aggregateHeaderAreaCategoryOfRights">
                {{ `mandate.right.${aggregateHeaderAreaCategoryOfRights}` | translate }}
              </span>
              <span v-else class="none">None</span>
            </div>
          </div>

          <div class="row small-inner">
            <label class="col s3">Status</label>
            <div class="col s9">
              <span>{{ selectedDistributionState | distributionState }}</span>
            </div>
          </div>

          <div
            v-if="!isSelectedDistributionClosed && !waitingForConfirmation"
            class="row small-inner"
          >
            <label class="col s3">Errors</label>
            <div class="col s9">
              <error-percent :value="aggregateHeaderErrorPct" />
              <span v-show="issuesTotalCount != null">({{ issuesTotalCount }})</span>
            </div>
          </div>

          <div class="row small-inner">
            <label class="col s3">Report Year</label>
            <div class="col s9">
              {{ year }}
            </div>
          </div>

          <div
            v-if="isSelectedDistributionClosed || waitingForConfirmation"
            class="row small-inner"
          >
            <label class="col s3">Period</label>
            <div class="col s9">
              {{ selectedDistributionFromMonth | formatMonthFromNumber }} -
              {{ selectedDistributionToMonth | formatMonthFromNumber }}
            </div>
          </div>

          <div v-if="waitingForConfirmation" class="row small-inner">
            <label class="col s3">
              <span class="text--bold">Points to distribute</span>
            </label>
            <div class="col s9">
              <span>{{ selectedDistributionPoints || 0 | removeDecimal | formatNumber }}</span>
            </div>
          </div>

          <div v-if="waitingForConfirmation" class="row small-inner">
            <label class="col s3">
              <span class="text--bold">Distributable</span>
            </label>
            <div class="col s9">
              <span>{{ sumOfSelectedRevenues | formatAmountThousandSeparator }} SEK</span>
            </div>
          </div>

          <div v-if="isSelectedDistributionClosed" class="row small-inner">
            <label class="col s3">Transferred to ledger</label>
            <div class="col s9">
              {{ selectedDistributionResultDate | formatDate }}
            </div>
          </div>
          <template v-if="isSelectedDistributionClosed">
            <hr />
            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Performers</span>
              </label>
              <div class="col s9">
                <span>{{ selectedDistribution.result.performer_count || 'N/A' }}</span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Points calculated</span>
              </label>
              <div class="col s9">
                <span>{{ totalPoints || 0 | removeDecimal | formatNumber }}</span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Distributable</span>
              </label>
              <div class="col s9">
                <span>{{ sumOfSelectedRevenues | formatAmountThousandSeparator }} SEK</span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Distributed</span>
              </label>
              <div class="col s9">
                <span>
                  {{
                    selectedDistribution.result.distributed_amount | formatAmountThousandSeparator
                  }}
                  SEK
                </span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Remainder</span>
              </label>
              <div class="col s9">
                <span>
                  {{ selectedDistribution.result.remainder | formatAmountThousandSeparator }} SEK
                </span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Reserved</span>
              </label>
              <div class="col s9">
                <span>
                  {{ selectedDistribution.result.reserved_amount | formatAmountThousandSeparator }}
                  SEK
                </span>
              </div>
            </div>

            <div class="row small-inner">
              <label class="col s3">
                <span class="text--bold">Revenues</span>
              </label>
              <div class="col s9">
                <div v-for="rev of selectedRevenues" :key="rev.id">
                  <router-link :to="`/distribution/revenues/${rev.id}/to-check`">
                    {{ rev.name }}
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="isOpenForDistribution && !waitingForConfirmation" class="card">
          <div class="row small-inner">
            <div class="col s2">
              <label>Start period</label>
            </div>
            <div class="col s1" />
            <div class="col s2">
              <label>End period</label>
            </div>
          </div>

          <div class="row small-inner">
            <div class="col s2">
              <span v-if="isSelectedDistributionOpen">
                {{ selectedDistributionFromMonth | formatMonthFromNumber }}
              </span>
              <span v-else>
                <span v-if="aggregateHeaderOpenMonth === null">No music reported</span>
                <span v-else>{{ aggregateHeaderOpenMonth | formatMonthFromNumber }}</span>
              </span>
            </div>
            <div class="col s1">-</div>
            <div class="col s2">
              <select-month
                v-if="
                  isOpenForDistribution &&
                  aggregateHeaderOpenMonth !== null &&
                  aggregateHeaderEndMonth !== null
                "
                v-model="selectedEndMonth"
                label=""
                :disabled="aggregateHeaderAreaDistributionBasis === DistributionBasis.ANALOGY"
                :start-month="aggregateHeaderOpenMonth"
                :end-month="aggregateHeaderEndMonth"
              />
              <span v-else>
                <span v-if="selectedDistributionToMonth !== null">
                  {{ selectedDistributionToMonth | formatMonthFromNumber }}
                </span>
                <span v-else>No reported music</span>
              </span>
            </div>

            <div class="col s3">
              <button
                class="btn secondary"
                :disabled="!isRecalculate || selectedEndMonth === null"
                @click="onCalculate(true)"
              >
                <i v-if="selectedDistribution" class="fas fa-check" />
                {{ isRecalculate ? 'Calculate' : 'Calculated' }}
              </button>
            </div>
          </div>

          <div v-if="isCalculated" class="row small-inner">
            <div class="col s2">Points to distribute</div>
            <div class="col s1" />
            <div class="col s2">
              <span class="text--bold">
                {{ selectedDistributionPoints || 0 | removeDecimal | formatNumber }}
              </span>
            </div>
          </div>

          <div v-if="isCalculated" class="row small-inner">
            <div class="col s2">Distributable</div>
            <div class="col s1" />
            <div class="col s9">
              <div class="row small-inner">
                <span
                  v-if="selectedDistributionRevenueIds.length === 0 && isSelectedDistributionOpen"
                >
                  <a class="modal-trigger" @click="showRevenuesModal = true">
                    <i class="far fa-edit" />
                    Add revenues
                  </a>
                </span>
                <div v-else>
                  <div class="col s2 no-padding">
                    <span class="text--bold">
                      {{ sumOfSelectedRevenues | formatAmountThousandSeparator }} SEK
                    </span>
                  </div>
                  <div v-if="isOpenForDistribution && selectedEndMonth !== null" class="col s4">
                    <a class="modal-trigger" @click="showRevenuesModal = true">
                      <i class="far fa-edit" />
                      Edit
                    </a>
                  </div>
                </div>
              </div>

              <div v-for="revenue in selectedRevenues" :key="revenue.id" class="row">
                <div class="col s2 no-padding">
                  {{ revenue.distributable_amount | formatNumber }} SEK
                </div>
                <div class="col s3">
                  <router-link :to="{ name: 'revenueView', params: { id: revenue.id } }">
                    {{ revenue.name }}
                  </router-link>
                </div>
                <delete-button :is-component="true" @click="onRemoveRevenue(revenue.id)" />
              </div>
            </div>
          </div>

          <select-revenue
            :revenues="revenues"
            :show="showRevenuesModal"
            :selected-revenue-ids="selectedDistributionRevenueIds"
            @cancel="onCancelRevenues"
            @save="onSaveRevenues"
          />

          <div
            v-if="
              selectedDistributionChannelDistributions.length > 0 &&
              selectedDistributionRevenueIds.length > 0
            "
            class="row flush--bottom"
          >
            <select-distribution-allocation-method
              :disabled="!hasRevenues"
              :value="distributionMethod"
              @input="onChangeDistributionMethod($event)"
            />
          </div>
        </div>

        <div v-if="waitingForConfirmation" class="row flush--bottom">
          <div class="col s2 text--bold">Revenues</div>
          <div class="col s10">
            <div v-for="revenue in selectedRevenues" :key="revenue.id" class="row">
              <div class="col s2 no-padding">{{ revenue.amount | formatNumber }} SEK</div>
              <div class="col s3">
                <router-link :to="{ name: 'revenueView', params: { id: revenue.id } }">
                  {{ revenue.name }} SEK
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div v-if="payoutInProgress">
          <span class="text--bold">Please wait, I'm talking to the payment system...</span>
          <div class="col s12">
            <spinner />
          </div>
        </div>
        <div v-else>
          <distribute-year-calculation
            :final="isSelectedDistributionClosed || waitingForConfirmation"
            :loading="loading"
            :revenues="selectedRevenues"
            :distribution-method="distributionMethod"
            :pending-recalculation="isRecalculate"
            :allocate-selected="allocateSelected"
            @channelsBySourceChanged="onChannelsBySourceChanged"
            @edit="onEdit"
            @revertEdit="onRevertEdit"
            @saveManualDistribution="onCalculate"
          />
        </div>

        <div v-if="waitingForConfirmation" class="row">
          <div class="col s12">
            <action-buttons
              class="float-right"
              submit-label="Confirm transfer to ledger"
              abort-label="Cancel"
              @save="onPayToLedger"
              @cancel="waitingForConfirmation = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import { createError, ErrorType } from '../../../domain/starNotification';
import {
  createDistributeCommand,
  DistributionMethod,
  DistributionState,
  RevenueState,
  DistributionBasis,
} from '../../../domain/distributionDomain';
import DeleteButton from '../../ui/button/delete-button';
import DistributeYearCalculation from './distribute-year-calculation';
import DistributionService from '../../../services/distributionService';
import ErrorPercent from '../error-percent';
import { setAggregate } from '../../../store/modules/distribution/utils';
import SelectMonth from '../../ui/select/select-month';
import SelectRevenue from './select-revenue';
import SelectDistributionAllocationMethod from '../../ui/select/select-distribution-allocation-method';
import Spinner from '../../spinner';

export default {
  name: 'DistributeYearCalculate',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ActionButtons,
    DeleteButton,
    DistributeYearCalculation,
    ErrorPercent,
    SelectMonth,
    SelectRevenue,
    SelectDistributionAllocationMethod,
    Spinner,
  },
  filters: {
    distributionState(value) {
      switch (value) {
        case DistributionState.OPEN:
          return 'Open';
        default:
          return 'Closed';
      }
    },
  },
  data() {
    return {
      DistributionMethod,
      DistributionBasis,
      distributionMethod: this.selectedDistributionDistributionMethod
        ? this.selectedDistributionDistributionMethod
        : DistributionMethod.POINTS,
      loading: false,
      error: false,
      payoutInProgress: false,
      channelsBySource: {},
      distributionId: this.$router.currentRoute.params.distributionId,
      issuesTotalCount: null,
      selectedEndMonth: this.selectedDistributionToMonth ? this.selectedDistributionToMonth : null,
      showRevenuesModal: false,
      pendingCalculation: false,
      revenues: [],
      copyOfChannelDistributions: [],
      viewRouteName: 'distributeYear',
      waitingForConfirmation: false,
      year: +this.$router.currentRoute.params.year,
      yearId: +this.$router.currentRoute.params.yearId,
      allocateSelected: false,
    };
  },
  computed: {
    ...mapGetters('distribution', [
      'aggregateHeaderName',
      'aggregateHeaderState',
      'aggregateHeaderErrorPct',
      'aggregateHeaderOpenMonth',
      'aggregateHeaderEndMonth',
      'aggregateHeaderAreaName',
      'aggregateHeaderAreaDistributionRuleId',
      'aggregateHeaderAreaCategoryOfRights',
      'aggregateHeaderAreaDistributionRuleName',
      'aggregateHeaderAreaDistributionBasis',
      'aggregateDistributions',
      'selectedDistribution',
      'selectedDistributionId',
      'selectedDistributionState',
      'selectedDistributionFromMonth',
      'selectedDistributionToMonth',
      'selectedDistributionPoints',
      'selectedDistributionDistributionMethod',
      'selectedDistributionChannelDistributions',
      'selectedDistributionRevenueIds',
      'selectedDistributionClientAttributes',
      'selectedDistributionDistributionGroups',
      'selectedDistributionResultDate',
    ]),
    hasRevenues() {
      return this.selectedDistributionRevenueIds.length > 0;
    },
    selectedRevenues() {
      return this.revenues.filter((rev) => this.selectedDistributionRevenueIds.includes(rev.id));
    },
    sumOfSelectedRevenues() {
      return this.selectedRevenues.reduce(
        (accumulator, revenue) => accumulator + parseFloat(revenue.distributable_amount),
        0.0,
      );
    },
    totalPoints() {
      return Number(
        this.selectedDistributionChannelDistributions.reduce(
          (sum, dist) => Number(sum) + (dist.amount ? Number(dist.points) : 0.0),
          0.0,
        ),
      );
    },
    isOpenForDistribution() {
      return (
        this.selectedDistributionState === null ||
        this.selectedDistributionState === DistributionState.OPEN
      );
    },
    isSelectedDistributionOpen() {
      return this.selectedDistributionState === DistributionState.OPEN;
    },
    isSelectedDistributionClosed() {
      return this.selectedDistributionState === DistributionState.CLOSED;
    },
    isCalculated() {
      return this.selectedDistribution != null && this.selectedDistribution.id != null;
    },
    isRecalculate() {
      return (
        this.pendingCalculation ||
        this.selectedRevenues.length === 0 ||
        this.selectedEndMonth !== this.selectedDistributionToMonth ||
        this.aggregateDistributions.length === 0
      );
    },
  },
  watch: {
    selectedDistributionToMonth(val) {
      this.selectedEndMonth = val;
    },
    selectedDistributionDistributionMethod(val) {
      this.distributionMethod = val;
    },
    async $route() {
      try {
        const selectedDistribution = await this.loadDistribution();
        if (selectedDistribution) {
          this.loadDataFor(selectedDistribution);
        }
      } catch (error) {
        this.$addStarError(error);
      }
    },
  },
  async mounted() {
    try {
      const selectedDistribution = await this.loadDistribution();
      if (selectedDistribution) {
        this.loadDataFor(selectedDistribution);
      }
    } catch (error) {
      this.$addStarError(error);
    }
  },
  activated() {
    this.selectedEndMonth =
      this.selectedDistributionToMonth != null ? this.selectedDistributionToMonth : null;
  },
  deactivated() {
    this.$destroy();
  },
  methods: {
    ...mapMutations('distribution', [
      'updateSelectedDistributionRevenueIds',
      'removeFromSelectedDistributionRevenueIds',
      'updateSelectedDistribution',
      'updateSelectedDistributionClientAttributes',
      'updateSelectedDistributionChannelDistributions',
    ]),
    name(distribution) {
      let name = '';
      if (distribution.name) {
        name = distribution.name;
      } else {
        name = `${moment.months(distribution.from_month)} - ${
          Number.isInteger(distribution.to_month) ? moment.months(distribution.to_month) : ''
        }`;
      }
      if (distribution.state === DistributionState.CLOSED) {
        name = `${name} (closed)`;
      }
      return name;
    },
    isBySourceCalculated(distribution) {
      return !_.isEmpty(distribution.distribution_groups);
    },
    onCancelRevenues() {
      this.showRevenuesModal = false;
    },
    onSaveRevenues(revenues) {
      this.updateSelectedDistributionRevenueIds(revenues);
      this.showRevenuesModal = false;
      this.pendingCalculation = true;
    },
    async onRemoveRevenue(id) {
      const revenueIndex = this.selectedDistributionRevenueIds.indexOf(id);
      const revenueId = this.selectedDistributionRevenueIds[revenueIndex];
      this.removeFromSelectedDistributionRevenueIds(revenueIndex);
      this.pendingCalculation = true;
      if (this.distributionMethod === DistributionMethod.MANUAL) {
        this.distributionMethod = DistributionMethod.POINTS;
      }
      this.onCalculate();
      try {
        const revenueAggregate = await DistributionService.getRevenue(revenueId);
        const revenue = _.cloneDeep(revenueAggregate);
        revenue.state = RevenueState.TO_BE_DISTRIBUTED;
        revenue.financing_rate = revenue.financing_rate.toString();
        await DistributionService.createOrUpdateRevenue(revenue);
        await this.loadRevenues(revenue.id);
      } catch (error) {
        console.log('Could not set removed revenue to inprogress state', error);
      }
    },
    pointsPerMinute(distribution) {
      return (
        Number(distribution.points) / moment.duration(distribution.airtime).asSeconds()
      ).toFixed(2);
    },
    amountPerMinute(distribution) {
      return distribution.amount
        ? (Number(distribution.amount) / moment.duration(distribution.airtime).asSeconds()).toFixed(
            2,
          )
        : '';
    },
    async onChangeDistributionMethod(distributionMethod) {
      this.allocateSelected = true;
      const previousMethod = this.distributionMethod;
      this.distributionMethod = distributionMethod;
      if (this.distributionMethod === DistributionMethod.MANUAL) {
        this.copyOfChannelDistributions = _.cloneDeep(
          this.selectedDistributionChannelDistributions,
        );
      }
      if (
        this.distributionMethod === DistributionMethod.POINTS ||
        this.distributionMethod === DistributionMethod.AIRTIME ||
        (this.distributionMethod === DistributionMethod.BY_SOURCE &&
          previousMethod === DistributionMethod.BY_SOURCE)
      ) {
        this.onCalculate();
      }
    },
    onChannelsBySourceChanged() {
      this.onCalculate();
    },
    onEdit() {
      this.copyOfChannelDistributions = _.cloneDeep(this.selectedDistributionChannelDistributions);
    },
    onRevertEdit() {
      this.updateSelectedDistributionChannelDistributions(this.copyOfChannelDistributions);
    },
    async onCalculate() {
      this.loading = true;
      // The distribution method is ignored when distributing by source, but a value is still required.
      const distributeCommand = createDistributeCommand(
        this.selectedDistributionId,
        this.yearId,
        this.selectedEndMonth,
        this.distributionMethod,
        this.selectedDistributionRevenueIds,
        [],
        [],
        this.selectedDistributionClientAttributes,
      );
      try {
        if (this.distributionMethod === DistributionMethod.MANUAL) {
          distributeCommand.channel_distributions =
            this.selectedDistributionChannelDistributions.map((cd) =>
              _.pick(cd, ['channel_id', 'amount']),
            );
        } else if (this.distributionMethod === DistributionMethod.BY_SOURCE) {
          if (
            !this.selectedDistributionRevenueIds ||
            this.selectedDistributionRevenueIds.length === 0
          ) {
            throw createError(
              ErrorType.ILLEGAL_ARGUMENT,
              'At least one revenue is required for by source distribution.',
            );
          }
          if (new Set(this.selectedRevenues.map((rev) => rev.source_id)).size > 1) {
            throw createError(
              ErrorType.ILLEGAL_ARGUMENT,
              'All revenues must be from same source when distributing by source.',
            );
          }
          const sourceDistributions =
            this.selectedDistributionClientAttributes.source_distributions;
          // Clean out subsources bound to revenues that may have been removed.
          const activeSourceIds = new Set(
            _.flatMap(this.selectedRevenues, 'specification').map((specRow) => specRow.source_id),
          );
          distributeCommand.source_distributions = sourceDistributions.filter((source) =>
            activeSourceIds.has(source.source_id),
          );
        }

        await DistributionService.distribute(distributeCommand);
        await this.loadDistribution();
        this.pendingCalculation = false;
      } catch (error) {
        if (this.distributionMethod === DistributionMethod.MANUAL) {
          this.onRevertEdit();
        }
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async loadDataFor(selectedDistribution) {
      this.loading = true;
      this.waitingForConfirmation = false;
      await this.updateSelectedDistribution(selectedDistribution);
      await this.loadRevenues();
      if (this.isBySourceCalculated(selectedDistribution)) {
        this.distributionMethod = DistributionMethod.BY_SOURCE;
        this.loading = false;
      } else {
        this.distributionMethod = this.selectedDistributionDistributionMethod;
        this.loading = false;
      }
    },
    async loadRevenues(revenueId = undefined) {
      let revenues = [];
      if (revenueId) {
        const revenue = await DistributionService.getRevenue(revenueId);
        revenues = [revenue];
      } else {
        if (this.selectedDistributionState === DistributionState.OPEN) {
          const res = await DistributionService.getRevenues({
            state: RevenueState.TO_BE_DISTRIBUTED,
          });

          revenues = res.items;
        }

        if (this.selectedDistributionRevenueIds.length) {
          const distributionRevenues = await DistributionService.getDistributionRevenues(
            this.selectedDistributionId,
          );
          revenues = [...revenues, ...distributionRevenues];
        }
      }
      const revenuesIds = revenues.map(({ id }) => id);
      this.revenues = _.sortBy(
        [...this.revenues.filter(({ id }) => !revenuesIds.includes(id)), ...revenues],
        'name',
      );
    },
    async loadDistribution() {
      this.loading = true;
      this.error = false;
      const distributionAggregate = await DistributionService.getDistributionYear(this.yearId);
      setAggregate(_.cloneDeep(distributionAggregate));
      const distributionId = this.$router.currentRoute.params.distributionId;
      let selectedDistribution = null;
      if (distributionId) {
        selectedDistribution = this.aggregateDistributions.find(
          (dist) => dist.id.toString() === distributionId.toString(),
        );
      } else {
        const sortedDistributions = _.reverse(_.sortBy(this.aggregateDistributions, 'state'));
        selectedDistribution = sortedDistributions[0];
      }

      if (
        this.$router.currentRoute.name !== 'distributionCalculateIdView' &&
        selectedDistribution
      ) {
        this.$router.replace({
          name: 'distributionCalculateIdView',
          params: { year: this.year, yearId: this.yearId, distributionId: selectedDistribution.id },
        });
        return null;
      }
      this.issuesTotalCount = distributionAggregate.header.errors;
      this.loading = false;
      return selectedDistribution;
    },
    async onWaitForPayToLedger() {
      await this.onCalculate();
      this.waitingForConfirmation = true;
      window.scrollTo(0, document.body.scrollHeight);
    },
    async onPayToLedger() {
      this.waitingForConfirmation = false;
      try {
        this.payoutInProgress = true;
        await DistributionService.payToLedger(this.selectedDistributionId);
        this.$router.replace({
          name: 'distributionYearQueryView',
          params: { year: this.year },
        });
      } catch (error) {
        this.$addStarError(error);
        this.payoutInProgress = false;
      }
    },
  },
};
</script>
