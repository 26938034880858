<template>
  <div class="compare__wide-list">
    <span class="compare__nested-heading">
      <strong>Line-up</strong>
    </span>
    <div class="compare__wide-list-headrow">
      <div>Name</div>
      <div>Pseudo</div>
      <div>Instrument</div>
      <div>Role</div>
      <div>Nationality</div>
      <div>IPN</div>
      <div>Society</div>
    </div>
    <ul v-if="currentCandidate" class="compare__wide-list-list">
      <li
        v-for="(performer, index) in sortedLineup"
        :key="index"
        class="compare__wide-list-list-item"
      >
        <div class="fs-12">
          <router-link :to="'/performers/' + performer.relation.id">
            {{ performer.relation.name }}
          </router-link>
        </div>
        <div>
          <span v-for="(pseudo, index2) in performer.relation.pseudo_names" :key="index2">
            {{ pseudo }}
            <span v-if="index2 + 1 !== performer.relation.pseudo_names.length">
              ,
              <br />
            </span>
          </span>
          <span
            v-if="!performer.relation.pseudo_names || performer.relation.pseudo_names.length === 0"
            class="none"
          >
            None
          </span>
        </div>
        <div>{{ performer.instruments | formatInstrumentList }}</div>
        <div>{{ performer.role | formatRole }}</div>
        <div>{{ performer.relation.nationality | formatCountry }}</div>
        <div>{{ performer.relation.ipn }}</div>
        <div>
          <span
            v-for="(mandate, index2) in activeUniqueMandates(performer.relation.societies)"
            :key="index2"
          >
            <span v-if="!mandate.end_date">
              {{ mandate.society_code | formatSociety }}
              <i
                v-if="mandate.mandate_type"
                style="float: none"
                class="fas"
                :class="mandate.mandate_type | iconByMandateTypeRegionalOrWorldWide"
              />
              <i
                v-if="mandate.mandate_type === 'R+' || mandate.mandate_type === 'WW-'"
                style="float: none"
                class="fas"
                :class="mandate.mandate_type | iconByMandateTypePlusOrMinus"
              />
            </span>
          </span>
        </div>
      </li>
      <ul v-if="lineupExpanded">
        <li
          v-for="performer in sortedLineupRest"
          :key="performer.relation.id"
          class="compare__wide-list-list-item"
        >
          <div class="fs-14">
            <router-link :to="'/performers/' + performer.relation.id">
              {{ performer.relation.name }}
            </router-link>
          </div>
          <div>
            <span v-for="(pseudo, index) in performer.relation.pseudo_names" :key="index">
              {{ pseudo }}
              <span v-if="index + 1 !== performer.relation.pseudo_names.length">
                ,
                <br />
              </span>
            </span>
            <span
              v-if="
                !performer.relation.pseudo_names || performer.relation.pseudo_names.length === 0
              "
              class="none"
            >
              None
            </span>
          </div>
          <div>{{ performer.instruments | formatInstrumentList }}</div>
          <div>{{ performer.role | formatRole }}</div>
          <div>{{ performer.relation.nationality | formatCountry }}</div>
          <div>{{ performer.relation.ipn }}</div>
          <div>
            <span
              v-for="(mandate, index) in activeUniqueMandates(performer.relation.societies)"
              :key="index"
            >
              <span v-if="!mandate.end_date">
                {{ mandate.society_code | formatSociety }}
                <i
                  v-if="mandate.mandate_type"
                  style="float: none"
                  class="fas"
                  :class="mandate.mandate_type | iconByMandateTypeRegionalOrWorldWide"
                />
                <i
                  v-if="mandate.mandate_type === 'R+' || mandate.mandate_type === 'WW-'"
                  style="float: none"
                  class="fas"
                  :class="mandate.mandate_type | iconByMandateTypePlusOrMinus"
                />
              </span>
            </span>
          </div>
        </li>
      </ul>
      <li>
        <div>
          <a
            v-if="sortedLineupRest.length > 0"
            class="read-more"
            @click="lineupExpanded = !lineupExpanded"
          >
            <span v-if="lineupExpanded">
              <i class="fas fa-caret-up"></i>
              Show less
            </span>
            <span v-else>
              <i class="fas fa-caret-down"></i>
              Show all {{ sortedLineupRest.length + 9 }} performers
            </span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import MusicReportHelper from '../matching/musicReportHelper';

export default {
  name: 'CompareLineupMatching',
  data() {
    return {
      sortedLineup: [],
      sortedLineupRest: [],
      lineupExpanded: false,
    };
  },
  computed: {
    ...mapGetters('matching', ['currentCandidate']),
  },
  watch: {
    currentCandidate() {
      this.updateSortedLineup();
    },
  },
  created() {
    this.updateSortedLineup();
  },
  methods: {
    currentLineup() {
      return _.cloneDeep(this.currentCandidate.lineup);
    },
    activeUniqueMandates(mandates) {
      const activeMandates = mandates.filter((mandate) => !mandate.end_date);
      return _.uniqBy(activeMandates, (mandate) => mandate.society_code && mandate.mandate_type);
    },
    updateSortedLineup() {
      const sortedLineupAll = MusicReportHelper.sortByRole(this.currentLineup());
      if (sortedLineupAll.length > 9) {
        this.sortedLineup = sortedLineupAll.splice(0, 9);
        this.sortedLineupRest = sortedLineupAll;
      } else {
        this.sortedLineup = sortedLineupAll;
        this.sortedLineupRest = [];
      }
    },
  },
};
</script>
