<template>
  <div>
    <div class="row small-inner">
      <div class="col s10">
        <h2>Available Reports</h2>
      </div>
      <div class="col s2 float-right report__headers-refresh">
        <RefreshButton :is-loading="loading" @click="fetchReportHeaders" />
      </div>
    </div>

    <div class="row">
      <div class="col s3 float-right">
        <FilterInput
          :filter-query="rangeStart"
          placeholder="E.g. Reklamradion"
          @input="debouncedSearch"
        />
      </div>
    </div>

    <div class="row card">
      <table class="report__headers-table">
        <SortableHead
          :columns="columns"
          :bulk-enabled="false"
          :default-sort-should-override="true"
          @sort="sort"
        />
        <tbody v-if="!loading">
          <tr v-for="report in reports" :key="report.report_id">
            <td>
              <router-link
                v-if="report.report_state !== 'A'"
                :to="{ name: 'musicReport', params: { id: report.report_id } }"
              >
                {{ report.report_name }}
              </router-link>
              <span v-else class="none">
                {{ report.report_name }}
              </span>
            </td>
            <td v-if="config.selectChannel" class="nowrap">
              {{ report.info ? channelMap[report.info.channel] : '' }}
            </td>
            <td class="nowrap">
              {{ report.date_created | formatDate }}
            </td>
            <td v-if="config.reportDate" class="nowrap">
              {{ report.report_date | formatDate }}
            </td>
            <td v-if="config.localCode" class="nowrap">
              {{ `music_report.${report.reporter_id}` | translate }}
            </td>
            <td>
              <span
                class="chip float-left"
                :class="`report__headers-chip--${status[report.report_state].chip || 'remove'}`"
                :title="report.error_message"
              >
                {{ status[report.report_state].name || 'N/A' }}
              </span>
            </td>
            <td v-for="line in config.lines" :key="line.key" :class="`count count--${line.color}`">
              {{ report[matchDataKey][line.key] | formatNumber }}
            </td>
            <td>
              <context-menu
                :options="['Download', config.reportTransferButtonText, 'Archive', 'Delete']"
                :disabled-options="contextMenuDisabledOptions(report)"
                @delete="deleteReport(report.report_id)"
                @archive="archiveReport(report.report_id)"
                @download="downloadReport(report.report_name)"
                @transferMatchedAirtimeToDistribution="sendReportToDistribution(report)"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100">
              <ComponentSpinner />
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :number-of-pages="numberOfPages()"
        :selected-page="page"
        :number-of-hits="itemCount"
        :hits-per-page="pageSize"
        @selectPage="selectPage"
        @updateHitsPerPage="selectPageSize"
      />
    </div>
  </div>
</template>

<script>
import MatchingService from '../../services/matchingService';
import PaginationQueryParamMixin from '../../common/paginationQueryParamMixin';
import SortableHead from '../ui/table/sortable-head';
import ComponentSpinner from '../component-spinner';
import Pagination from '../pagination';
import FilterInput from '../ui/input/filter-input';
import RefreshButton from '../ui/button/refresh-button';
import config from './config';
import DistributionService from '@/services/distributionService';
import ContextMenu from '@/components/ui/context-menu.vue';
import { mutate, query } from '@/services/apolloRequest';
import Delay from '@/common/delay';
import gql from 'graphql-tag';
import DownloadLink from '@/components/ui/file/downloadLink';
import RemoteFileService from '@/services/remoteFileService';
import * as uuid from 'uuid';
import AuthenticationService from '@/services/authenticationService';

export default {
  name: 'ReportsTable',
  components: {
    ContextMenu,
    SortableHead,
    ComponentSpinner,
    Pagination,
    FilterInput,
    RefreshButton,
  },
  mixins: [PaginationQueryParamMixin],
  data() {
    return {
      loading: true,
      reports: [],
      channelMap: {},
      status: {
        I: { name: 'Inserting', chip: 'wait' },
        M: { name: 'Matching', chip: 'wait' },
        MF: { name: 'Matched', chip: 'ok' },
        T: { name: 'Transferring', chip: 'wait' },
        TF: { name: 'Transferred', chip: 'ok' },
        D: { name: 'Deleting', chip: 'remove' },
        E: { name: 'Error', chip: 'fail' },
        A: { name: 'Archived', chip: 'remove' },
        F: { name: 'Finished', chip: 'remove' },
      },
    };
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
    matchDataKey() {
      return `${this.config.reportType.toLowerCase()}_match_data`;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.type !== from.params.type) {
        this.setColumns();
      }
      this.fetchReportHeaders();
    },
  },
  created() {
    this.setColumns();
    this.updateQueryParams(this.$route.query);
    this.fetchReportHeaders();
    if (this.config.selectChannel) {
      this.createChannelMap();
    }
  },
  methods: {
    contextMenuDisabledOptions(report) {
      const disabled = new Set();
      const canTransfer = this.config.transferEnabled(report);
      if (!canTransfer) {
        disabled.add(this.config.reportTransferButtonText);
      }
      switch (report.report_state) {
        // not allowed to delete if transferring, transferring finished, matching, inserting or deleting
        case 'T':
        case 'M':
        case 'I':
        case 'D':
          disabled.add('Delete');
          break;
        case 'TF':
          disabled.add('Delete');
          disabled.add('Archive');
          break;
        case 'A': {
          disabled.add('Archive');
          disabled.add(this.config.reportTransferButtonText);
          break;
        }
        default: {
          break;
        }
      }
      return [...disabled];
    },
    async deleteReport(reportId) {
      await mutate({
        mutation: this.config.gql.delete.mutate,
        variables: {
          reportId,
        },
      });
      await Delay(2000);
      await this.fetchReportHeaders();
    },
    async archiveReport(reportId) {
      try {
        await MatchingService.archiveReportHeader(reportId);
        await Delay(2000);
        await this.fetchReportHeaders();
      } catch (error) {
        this.handleError('Archive failed', error);
      }
    },
    async downloadReport(reportName) {
      try {
        const downloadUrl = await query(
          {
            query: gql`
              query ($source: MusicReportSource!, $filename: String!) {
                getMusicReportDownloadUrl(source: $source, filename: $filename)
              }
            `,
            variables: {
              source: this.config.sources[0].name,
              filename: reportName,
            },
          },
          { pickProp: 'getMusicReportDownloadUrl' },
        );
        const fileData = await RemoteFileService.downloadBinary(downloadUrl);
        DownloadLink.direct(fileData, reportName);
      } catch (err) {
        this.handleError('Download failed', err);
      }
    },
    async sendReportToDistribution(report) {
      const processId = uuid.v4();
      try {
        this.$store.dispatch('process/addProcess', {
          name: `Transferring to ${report.report_name} to distribution`,
          id: processId,
        });
        await mutate({
          mutation: this.config.gql.transfer.mutate,
          variables: {
            command: {
              report_id: report.report_id,
              process_id: processId,
              username: AuthenticationService.getUserName(),
            },
          },
        });
        await Delay(2000);
        await this.fetchReportHeaders();
      } catch (error) {
        error.title = 'Failed to transfer to distribution!';
        this.$addStarError(error);
      }
    },
    async createChannelMap() {
      const result = await DistributionService.getDistributionChannelsCached();
      this.channelMap = Object.fromEntries(result.channels.map((c) => [c.code, c.name]));
    },
    async fetchReportHeaders() {
      try {
        this.loading = true;
        const response = await MatchingService.listReportHeaders({
          process_type: this.config.processType,
          report_type: this.config.reportType,
          limit: this.pageSize,
          offset: this.getOffset(),
          filter: this.rangeStart,
          sort_by: this.sortParam,
          sort_order: this.sortOrder,
        });

        this.reports = response.items;
        this.itemCount = response.total_count;
      } catch (err) {
        this.$addStarError({ title: 'Failed to get report headers', ...err });
      } finally {
        this.loading = false;
      }
    },
    setColumns() {
      this.columns = [
        {
          name: 'Report',
          ascending: true,
          sortParam: 'report_name',
          active: false,
        },
        ...(this.config.selectChannel
          ? [
              {
                name: 'Channel',
                ascending: true,
                sortParam: '',
                active: false,
                thinColumn: true,
              },
            ]
          : []),
        {
          name: 'Uploaded',
          ascending: false,
          sortParam: 'date_created',
          active: false,
          thinColumn: true,
        },
        ...(this.config.reportDate
          ? [
              {
                name: 'Usage Year',
                ascending: true,
                sortParam: 'report_date',
                active: false,
                thinColumn: true,
              },
            ]
          : []),
        ...(this.config.localCode
          ? [
              {
                name: 'Source',
                ascending: true,
                sortParam: 'reporter_id',
                active: false,
                thinColumn: true,
              },
            ]
          : []),
        {
          name: 'Status',
          sortParam: 'report_state',
          ascending: true,
          thinColumn: true,
          active: false,
        },
        ...this.config.lines.map((c) => ({
          name: c.name,
          count: true,
        })),
      ];
    },
  },
};
</script>
