var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row card"},[_c('div',{staticClass:"col s12"},[_c('StarDataTable',{attrs:{"name":"performers","columns":_vm.table.columns,"items":_vm.localPerformers,"sort":_vm.table.sort,"local-sort":""},on:{"update:sort":function($event){return _vm.$set(_vm.table, "sort", $event)}},scopedSlots:_vm._u([{key:"item:name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'performerView', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item:pseudo",fn:function(ref){
var item = ref.item;
return [(item.pseudo_names.length)?_c('span',[_vm._v(" "+_vm._s(item.pseudo_names.join(', '))+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:instruments",fn:function(ref){
var item = ref.item;
return [(item.instruments.length)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatInstrumentList")(item.instruments))+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:roles",fn:function(ref){
var item = ref.item;
return [(item.roles.length)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRoleList")(item.roles))+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:appears-on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tracks.length)+" track"+_vm._s(item.tracks.length > 1 ? 's' : ''))])]}},{key:"item:nationality",fn:function(ref){
var item = ref.item;
return [(item.nationality)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCountry")(item.nationality))+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:ipn",fn:function(ref){
var item = ref.item;
return [(item.ipn)?_c('span',[_vm._v(" "+_vm._s(item.ipn)+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:context",fn:function(ref){
var item = ref.item;
return [_c('context-menu',{attrs:{"options":['Add/Edit appearances']},on:{"addEditAppearances":function($event){return _vm.openEditPerformer(item)}}})]}}])})],1)]),_vm._m(1),_c('div',{staticClass:"edit-link"},[_c('a',{on:{"click":function($event){return _vm.openEditPerformer()}}},[_c('i',{staticClass:"far fa-plus-square"}),_vm._v(" Add performer ")])]),(_vm.showEditPerformer)?_c('EditAddNewPerformer',{attrs:{"performer":_vm.selectedPerformer,"enable-search":!_vm.selectedPerformer},on:{"cancel":function($event){return _vm.closeEditPerformer()},"patch":function($event){return _vm.$emit('patchLineup', $event)}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s6"},[_c('h2',[_vm._v("Performers")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('h2',[_vm._v("Add Existing Performers")])])}]

export { render, staticRenderFns }