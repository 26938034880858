<template>
  <div :name="name">
    <div class="row">
      <div class="col s8">
        <input
          id="bankgiro"
          v-model="companyAccountData.account_type"
          type="radio"
          value="bankgiro"
          name="company_account.account_type"
          :disabled="companyAccountDisabled"
          @change="emit"
        />
        <label for="bankgiro">Bankgiro</label>
        <input
          id="plusgiro"
          v-model="companyAccountData.account_type"
          type="radio"
          value="plusgiro"
          name="company_account.account_type"
          :disabled="companyAccountDisabled"
          @change="emit"
        />
        <label for="plusgiro">Plusgiro</label>
      </div>
    </div>
    <div class="row flush--bottom">
      <div class="col s8">
        <validated-text-input
          v-model="companyAccountData.account_number"
          name="company_account.account_number"
          scope="payment-information"
          label="Account no."
          placeholder="E.g. 123456789012"
          :disabled="!companyAccountData.account_type || companyAccountDisabled"
          :rule="{ gironumber: true }"
          display-mask="account"
          @input="emit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import clone from '../../../common/clone';
import store from '../../../store/index';
import ValidatedTextInput from '../input/validated-text-input';
import { formatAccountNumber } from '../../../filters';

export default {
  name: 'CompanyAccountInput',
  components: { ValidatedTextInput },
  props: {
    name: String,
    label: { type: String, default: '' },
    companyAccount: Object,
    editMode: { type: Boolean },
    companyAccountDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vuexWatchHandles: [],
      companyAccountData: clone(this.companyAccount),
    };
  },
  watch: {
    companyAccount() {
      this.companyAccountData = clone(this.companyAccount);
    },
  },
  mounted() {
    if (this.companyAccountData.account_number) {
      this.companyAccountData.account_number = formatAccountNumber(
        this.companyAccountData.account_number,
      );
    }

    const accountNumberWatch = store.watch(
      () => this.companyAccountData.account_number,
      (newVal) => {
        this.companyAccountData.account_number = formatAccountNumber(newVal);
      },
    );
    this.vuexWatchHandles.push(accountNumberWatch);
  },
  methods: {
    emit() {
      this.$emit('input', this.companyAccountData);
    },
  },
};
</script>
