<template>
  <bulk-table
    ref="contactPersonTable"
    name="Contact Persons"
    :columns="columns"
    :item-ids="itemIds"
    :edit-buttons-enabled="false"
    :edit-mode="editMode"
    :default-sort-column-index="0"
    :enable-row-options="!disabled"
    :disabled="disabled"
    @selectedItems="onSelectedItems"
    @bulkAction="onBulkAction"
    @editItem="onEditItem"
    @editItemSave="onEditItemSave"
    @deleteItem="onDeleteItem"
    @sort="onSort"
  >
    <template v-for="(contactPerson, index) in contactPersonsData">
      <template v-if="isEditRowItem(index)" :slot="index">
        <td :key="`${index}-td1`" colspan="5">
          <contact-person
            :ref="`contactperson-${index}`"
            :name="`contactperson-${index}`"
            :contact-person="editItem"
            :edit-mode="true"
            @save="onEditItemSave(index, $event)"
            @cancel="onEditItemCancel"
          />
        </td>
      </template>
      <template v-else :slot="index">
        <td :key="`${index}-td2`">
          {{ contactPerson.name }}
        </td>

        <td v-if="contactPerson.emails && contactPerson.emails.length > 0" :key="`${index}-td3`">
          <span v-for="(email, index2) in contactPerson.emails" :key="index2">
            <a :href="'mailto:' + email">{{ email }}</a>
            <span v-if="index + 1 !== contactPerson.emails.length">,</span>
          </span>
        </td>
        <td v-else :key="`${index}-td4`">
          <span class="none">None</span>
        </td>

        <td
          v-if="contactPerson.phone_numbers && contactPerson.phone_numbers.length > 0"
          :key="`${index}-td5`"
        >
          <span v-for="(phoneNumber, index2) in contactPerson.phone_numbers" :key="index2">
            {{ phoneNumber.number | formatPhoneNumber }} ({{ phoneNumber.type }})
          </span>
        </td>
        <td v-else :key="`${index}-td6`">
          <span class="none">None</span>
        </td>

        <td :key="`${index}-td7`">
          <span v-if="contactPerson.comment">{{ contactPerson.comment }}</span>
          <span v-else class="none">None</span>
        </td>
      </template>
    </template>
  </bulk-table>
</template>

<script>
import _ from 'lodash';
import BulkTable from '../../ui/table/bulk-table';
import clone from './../../../common/clone';
import ContactPerson from './contact-person';

export default {
  name: 'ContactPersonsEditTable',
  components: { BulkTable, ContactPerson },
  props: {
    contactPersons: { type: Array },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      contactPersonsData: clone(this.contactPersons),
      editItemId: null,
      editItem: null,
      editMode: false,
      selectedItemIds: [],
      columns: [
        {
          name: 'Name',
          ascending: true,
          sortParam: 'name',
          active: true,
          thinColumn: true,
        },
        {
          name: 'Emails',
          thinColumn: true,
        },
        {
          name: 'Phone Numbers',
          thinColumn: true,
        },
        {
          name: 'Comment',
          thinColumn: true,
        },
      ],
    };
  },
  computed: {
    itemIds() {
      return [...Array(this.contactPersonsData.length).keys()].map((n) => n.toString());
    },
    hasContactPersons() {
      return this.contactPersonsData && this.contactPersonsData.length > 0;
    },
  },
  methods: {
    isEditRowItem(id) {
      return id.toString() === this.editItemId;
    },
    onSelectedItems(ids) {
      this.selectedItemIds = ids;
    },
    onEditItem(id) {
      this.editItemId = id;
      this.editItem = this.contactPersonsData[id];
      this.editMode = true;
    },
    onEditItemCancel() {
      if (this.$refs.contactPersonTable) {
        this.$refs.contactPersonTable.cancelEdit();
      }
      this.editItemId = null;
      this.editItem = null;
      this.editMode = false;
      this.contactPersonsData = clone(this.contactPersons);
    },
    onEditItemSave(index, contactPerson) {
      this.contactPersonsData.splice(index, 1, contactPerson);
      this.emit();
    },
    onDeleteItem(id) {
      this.contactPersonsData.splice(id, 1);
      this.emit();
    },
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortParam = column.sortParam;
      const sorted = _.orderBy(
        this.contactPersonsData,
        [
          function sortValue(contactPerson) {
            return contactPerson[sortParam];
          },
        ],
        [sortOrder],
      );
      this.contactPersonsData = sorted;
    },
    onBulkAction() {
      // no-op
    },
    emit() {
      this.$emit('input', this.contactPersonsData);
    },
  },
};
</script>
