<template>
  <td>
    <template v-if="hasValue(column)">
      <span v-if="column.link">
        <router-link :to="replaceTokens(column.link, item)">
          {{ getValue(column) }}
        </router-link>
      </span>

      <span v-else-if="column.component === 'StatusIcon'">
        <StatusIcon :status="getValue(column)" />
      </span>

      <span v-else>{{ getValue(column) }}</span>
    </template>

    <span v-else class="none">None</span>
  </td>
</template>

<script>
import { get } from 'lodash';
import StatusIcon from '../status-icon';

export default {
  name: 'Column',
  components: {
    StatusIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      require: true,
    },
  },
  methods: {
    replaceTokens(value, obj) {
      return value.replace(/\{([a-zA-Z0-9-_.:]*?)\}/g, (_, token) => {
        const [path, defaultValue = ''] = token.split(':');
        return get(obj, path, defaultValue) || defaultValue;
      });
    },
    hasValue(column) {
      return !!get(this.item, column.prop, false);
    },
    getValue(column) {
      let value = get(this.item, column.prop, '');

      if (Array.isArray(value) && column.template) {
        value = value.map((v) => this.replaceTokens(column.template, v)).join(', ');
      } else if (Array.isArray(value)) {
        value = value.join(', ');
      } else if (column.tempate) {
        value = this.replaceTokens(column.template, value);
      }

      if (!column.filter) {
        return value;
      }

      return this.$options.filters[column.filter](value);
    },
  },
};
</script>
