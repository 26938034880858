<template>
  <modal
    v-if="showModal"
    submit-label="Add Channels"
    :show-abort="false"
    :disable-submit="disableSubmit"
    :links="links"
    @save="onSave"
    @cancel="onCancel"
    @close="onCancel"
  >
    <h1 slot="header">Add channels to source</h1>

    <div slot="body">
      <div class="row">
        <div class="col s12">
          <table>
            <thead>
              <th class="thin-column" />
              <th class="thin-column" />
              <th>Channels</th>
            </thead>
            <tbody>
              <template v-for="(channels, group) in channelGroups">
                <tr :key="`group-${group}`" @click="toggleChannels(group)">
                  <td class="thin-column dropdown-button__arrow">
                    <i v-if="expanded(group)" class="fas fa-caret-up" />
                    <i v-else-if="!expanded(group)" class="fas fa-caret-down" />
                  </td>
                  <td>
                    {{ allSelected[group] }}
                    <input
                      :id="group"
                      type="checkbox"
                      :checked="allSelected(channels)"
                      class="filled-in"
                      @click="toggleSelect(channels)"
                    />
                    <label :for="group" />
                  </td>
                  <td>{{ group }}</td>
                </tr>
                <template v-if="expanded(group)" class="expand-info">
                  <template v-for="channel in channels">
                    <tr :key="channel.id">
                      <td />
                      <td>
                        <input
                          :id="channel.id"
                          v-model="checkedIds"
                          class="filled-in"
                          type="checkbox"
                          :value="channel.id.toString()"
                        />
                        <label :for="channel.id" />
                      </td>
                      <td>
                        <span>{{ channel.name }}</span>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
              <tr v-if="baseData.length === 0">
                <td colspan="5" class="text--center">
                  <br />
                  <span class="none">There are no channels to be added.</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import DistributionService from '../../../services/distributionService';
import Modal from '../../ui/dialog/modal';

export default {
  name: 'SelectChannel',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedChannelIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: this.show,
      baseData: null,
      checkedIds: this.selectedChannelIds,
      expandedItemIds: [],
      selectAll: {},
      links: [{ name: 'Administrate channels', view: 'distributionChannelsView', params: {} }],
    };
  },
  computed: {
    ...mapGetters('distribution', []),
    disableSubmit() {
      return this.checkedIds.length < 1;
    },
    channelGroups() {
      return _.groupBy(
        _.filter(this.baseData, (channel) => channel.channel_group),
        'channel_group',
      );
    },
  },
  watch: {
    show(doShow) {
      this.showModal = doShow;
    },
    selectedChannelIds(selectedIds) {
      this.checkedIds = selectedIds;
      // Expand list if channels pre-selected
      new Set(
        this.checkedIds
          .map((id) => this.baseData.find((channel) => channel.id === Number(id)))
          .map((channel) => channel.channel_group),
      ).forEach((group) => this.toggleChannels(group));
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    async reloadBaseData() {
      const result = await DistributionService.getDistributionChannels();
      this.baseData = result.channels;
    },
    expanded(group) {
      return _.includes(this.expandedItemIds, group);
    },
    toggleChannels(group) {
      const index = _.indexOf(this.expandedItemIds, group);
      if (index === -1) {
        this.expandedItemIds.push(group);
      } else {
        this.expandedItemIds.splice(index, 1);
      }
    },
    toggleSelect(channels) {
      if (!this.allSelected(channels)) {
        this.checkedIds = this.checkedIds.concat(channels.map((channel) => channel.id.toString()));
      } else {
        const removeIds = channels.map((channel) => channel.id.toString());
        this.checkedIds = this.checkedIds.filter((id) => !removeIds.includes(id));
      }
    },
    allSelected(channels) {
      return channels.every((channel) => this.checkedIds.includes(channel.id.toString()));
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save', this.checkedIds);
    },
  },
};
</script>
