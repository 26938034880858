<template>
  <div>
    <entity-badge entity="album" />
    <div class="search-result__everything__data">
      <span class="search-result__everything__data__name">
        <router-link :to="'/albums/' + typedResult.value.id">
          {{ typedResult.value.name }}
        </router-link>
      </span>
      <span v-if="typedResult.value.main_artist">
        by
        <router-link :to="'/mainartists/' + typedResult.value.main_artist.id">
          {{ typedResult.value.main_artist.name }}
        </router-link>
      </span>
      <span v-else>
        by
        <i>unknown main artist</i>
      </span>

      <div
        v-if="typedResult.value.profile_description"
        :class="{
          'search-result__everything__data__profile': true,
          'search-result__everything__data__profile--full': showFullProfile,
        }"
        @click="showFullProfile = true"
      >
        {{ typedResult.value.profile_description }}
      </div>

      <div v-if="typedResult.value.release_date">
        <span class="label">Release date:</span>
        <span>{{ typedResult.value.release_date }}.</span>
      </div>
      <div v-if="typedResult.value.produced_in_country">
        <span class="label">Produced in country:</span>
        <span>{{ typedResult.value.produced_in_country | formatCountry }}.</span>
      </div>

      <div v-if="typedResult.value.label">
        <span class="label">Label:</span>
        <span>{{ typedResult.value.label }}.</span>
      </div>
      <div v-if="typedResult.value.tags" class="search-result__tags chips__wrapper">
        <template v-for="(tag, index) in typedResult.value.tags">
          <tag :key="index" :tag="tag" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../tags/tag';
import EntityBadge from '../ui/entity-badge';

export default {
  name: 'EverythingResultAlbum',
  components: { Tag, EntityBadge },

  props: {
    typedResult: Object,
  },
};
</script>
