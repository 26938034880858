/**
 * The Search Service contains search related methods.
 */
import Vue from 'vue';
import { transformApolloError } from '../common/errorTransformers';
import gql from '../domain/searchGql';

const fetchPolicy = 'network-only';

const sortMainArtistLineup = (searchResult) => {
  const data = searchResult.data.advancedSearchMainArtists;
  const mainartists = data.mainartists.map((ma) => ({
    ...ma,
    performers:
      ma.performers && ma.performers.slice().sort((a, b) => a.name.localeCompare(b.name, 'sv')),
  }));
  return {
    ...data,
    mainartists,
  };
};

export default {
  loadPreviousSearches: () => Promise.resolve(JSON.parse(localStorage.getItem('latestVisited'))),
  nameSearch: (query, types) =>
    Vue.$apollo.client
      .query({ query: gql.nameSearch, variables: { query, types }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.nameSearch))
      .catch((error) => Promise.reject(transformApolloError(error))),
  getAllTags: () =>
    Vue.$apollo.client
      .query({ query: gql.getAllTags, fetchPolicy })
      .then((response) => Promise.resolve(response.data.getAllTags))
      .catch((error) => Promise.reject(transformApolloError(error))),
  quickSearch: (query) =>
    Vue.$apollo.client
      .query({ query: gql.quickSearch, variables: { query }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.quickSearch))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchAll: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchAll, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchAll))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchRecordings: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchRecordings, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchRecordings))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchPerformers: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchPerformers, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchPerformers))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchMainArtists: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchMainArtists, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchMainArtists))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchAlbums: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchAlbums, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchAlbums))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchSocieties: (searchRequest) =>
    Vue.$apollo.client
      .query({ query: gql.advancedSearchSocieties, variables: { searchRequest }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.advancedSearchSocieties))
      .catch((error) => Promise.reject(transformApolloError(error))),
  advancedSearchAssociates: (searchRequest) =>
    Vue.$apollo.client
      .query({
        query: gql.advancedSearchAssociates,
        variables: { searchRequest },
        fetchPolicy: 'network-only',
      })
      .then((response) => Promise.resolve(response.data.advancedSearchAssociates))
      .catch((error) => Promise.reject(transformApolloError(error))),
  fieldSearch: (field, value, index) =>
    Vue.$apollo.client
      .query({ query: gql.fieldSearch, variables: { field, value, index }, fetchPolicy })
      .then((response) => Promise.resolve(response.data.fieldSearch))
      .catch((error) => Promise.reject(transformApolloError(error))),
};
