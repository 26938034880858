<template>
  <div>
    <div class="row text--right edit-link">
      <a @click="$emit('edit', $options.name)">
        <i class="far fa-edit" />
        Edit
      </a>
    </div>

    <div class="row card">
      <div class="row">
        <label class="col s3">Protected identity</label>
        <div class="col s9">
          <span v-if="protectedIdentity">🔒</span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row flush--bottom">
        <div v-show="firstName || lastName" class="row">
          <label class="col s3">Real name</label>
          <div class="col s9">
            <span>{{ firstName }} {{ lastName }}</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Profile</label>
          <div class="col s9">
            <span>{{ profile }}</span>
            <span v-if="!profile" class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Pseudo name</label>
          <div class="col s9">
            {{ pseudoNames.join(', ') }}
            <span v-if="pseudoNames.length === 0" class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Name variations</label>
          <div class="col s9">
            {{ nameVariations.join(', ') }}
            <span v-if="nameVariations.length === 0" class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Date of birth</label>
          <div class="col s9">
            <span v-if="dateOfBirth">{{ dateOfBirth | formatDate }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Social security number</label>
          <div class="col s9">
            <span>{{ socialSecurityNumber }}</span>
            <span v-if="!socialSecurityNumber" class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Date of death</label>
          <div class="col s9">
            <span v-if="dateOfDeath">{{ dateOfDeath | formatDate }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Nationality</label>
          <div class="col s9">
            <span>{{ nationality | formatCountry }}</span>
            <span v-if="!nationality" class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Country of residence</label>
          <div class="col s9">
            <span>{{ countryOfResidence | formatCountry }}</span>
            <span v-if="!countryOfResidence" class="none">None</span>
          </div>
        </div>

        <div v-if="!collective" class="row">
          <label class="col s3">Gender</label>
          <div class="col s9">
            <span v-if="sex">{{ sex | formatSex }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>

        <div v-if="collective" class="row">
          <label class="col s3">Collective</label>
          <div class="col s9">
            <span>Yes ({{ collective_size }})</span>
          </div>
        </div>

        <view-local-codes :local-codes="localCodes" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewLocalCodes from '../../../ui/view-local-codes';

export default {
  name: 'GeneralInformation',
  components: { ViewLocalCodes },
  computed: mapGetters('performer', [
    'firstName',
    'lastName',
    'profile',
    'pseudoNames',
    'nameVariations',
    'dateOfBirth',
    'dateOfDeath',
    'protectedIdentity',
    'socialSecurityNumber',
    'nationality',
    'countryOfResidence',
    'sex',
    'localCodes',
    'collective',
    'collective_size',
  ]),
};
</script>
