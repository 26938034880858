<template>
  <div class="cmp">
    <div class="col s12">
      <div class="">
        <div class="row">
          <div class="col s11">
            <h1>Errors in {{ aggregateHeaderName }}</h1>
          </div>
        </div>
      </div>

      <div v-if="loading" class="row">
        <div class="col s12">
          <spinner />
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col s1">
            <span class="text--bold">Errors:</span>
          </div>
          <div class="col s1">
            <error-percent :value="aggregateHeaderErrorPct || 0" />
            ({{ aggregateHeaderErrors }})
          </div>
          <div class="col s10">
            <filter-input
              class="float-right"
              :filter-query="filterQuery"
              placeholder="E.g. 2 Legit 2 Quit"
              :instant="false"
              @input="filterSearch"
            />
          </div>
        </div>

        <div class="row">
          <div class="search-result__result">
            <div class="row">
              <div class="col s12">
                <bulk-table
                  ref="issuesTable"
                  name=""
                  :columns="columns"
                  :bulk-options="['resolve']"
                  :displayed-bulk-action="bulkOptionSelected"
                  :item-ids="bulkIds"
                  :default-sort-column-index="0"
                  :edit-row-enabled="false"
                  :enable-row-options="false"
                  :in-progress="bulkInProgress"
                  :refresh-data-enabled="true"
                  :is-loading-data="loading"
                  @refreshData="reloadBaseData"
                  @selectedItems="onSelectedItems"
                  @bulkAction="onBulkAction"
                  @sort="sorted($event)"
                >
                  <template
                    v-for="(distributionIssue, idx) in searchContext.result.results"
                    :slot="distributionIssue.recording_id"
                  >
                    <td :key="`td1-${idx}`">
                      <span>
                        <router-link
                          :to="{
                            name: 'recordingView',
                            params: { id: distributionIssue.recording_id },
                          }"
                        >
                          {{ distributionIssue.title }}
                        </router-link>
                      </span>
                    </td>
                    <td :key="`td2-${idx}`">
                      {{ distributionIssue.main_artist }}
                    </td>
                    <td :key="`td3-${idx}`">
                      <div v-if="distributionIssue.issues && distributionIssue.issues.length > 0">
                        <span
                          v-for="(issue, idx2) in distributionIssue.issues"
                          :key="`issue-${idx2}`"
                          class="csv-item"
                        >
                          {{ `distribution.errors.${issue.replace('rec:', '')}` | translate }}
                        </span>
                      </div>
                      <div v-else>
                        <span class="none">Pending</span>
                      </div>
                    </td>
                    <td :key="`td4-${idx}`">
                      <span>{{ distributionIssue.airtime | airTime }}</span>
                    </td>
                  </template>
                </bulk-table>
              </div>
            </div>
          </div>

          <div v-if="searchContext.isExecuted">
            <div class="col s12">
              <pagination
                v-show="numberOfPages > 0"
                :number-of-pages="numberOfPages"
                :selected-page="pagination.page"
                :number-of-hits="searchContext.result.totalHits"
                :hits-per-page="pagination.hitsPerPage"
                @selectPage="selectPage"
                @updateHitsPerPage="updateHitsPerPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="showBulkModal"
      submit-label="Ok"
      :show-abort="false"
      :small="true"
      @save="onCloseBulk"
      @close="onCloseBulk"
    >
      <h1 slot="header">Resolving issues</h1>

      <div slot="body">
        <div class="row">
          Resolving issue {{ resolvedBulkIssuesCount }} of {{ bulkIssues.length }} ...
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { UNKNOWN_COUNTRY } from '../../../domain/common';
import BulkTable from '../../ui/table/bulk-table';
import delay from './../../../common/delay';
import DataTableMixin from '../data-table-mixin';
import DistributionService from '../../../services/distributionService';
import ErrorPercent from '../error-percent';
import FilterInput from '../../ui/input/filter-input';
import Modal from '../../ui/dialog/modal';
import Pagination from '../../pagination';
import RecordingService from '../../../services/recordingService';
import Spinner from '../../spinner';

export default {
  name: 'ViewDistributionIssues',
  components: {
    BulkTable,
    ErrorPercent,
    FilterInput,
    Modal,
    Pagination,
    Spinner,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      viewRouteName: 'distributionIssuesView',
      yearId: this.$router.currentRoute.params.yearId,
      year: this.$router.currentRoute.params.year,
      error: false,
      selectedItemIds: [],
      bulkOptionSelected: '',
      bulkInProgress: false,
      showBulkModal: false,
      resolvedBulkIssuesCount: 0,
      loading: false,
      columns: [
        {
          name: 'Recording title',
          filterable: true,
          sortName: 'title',
        },
        {
          name: 'Main artist',
          filterable: true,
          sortName: 'main_artist',
        },
        {
          name: 'Error type',
          filterable: true,
          sortName: 'error_type',
        },
        {
          name: 'Airtime',
          sortName: 'airtime_sort',
          active: true,
          order: 'desc',
          ascending: false,
          sortParam: true,
        },
      ],
      sortTerms: {
        param: 'airtime_sort',
        order: 'desc',
      },
    };
  },
  computed: {
    ...mapGetters('distribution', [
      'aggregateDistributions',
      'aggregateHeaderName',
      'aggregateHeaderErrorPct',
      'aggregateHeaderErrors',
    ]),
    bulkIds() {
      return this.searchContext.result.results.map((issue) => issue.recording_id);
    },
    bulkIssues() {
      return this.searchContext.result.results.filter(
        (issue) =>
          this.selectedItemIds.includes(issue.recording_id) &&
          issue.issues.includes('rec:jurisdiction'),
      );
    },
  },
  activated() {
    this.clearFilter();
  },
  methods: {
    routeParams(base) {
      return Object.assign(base, { year: this.selectedYear });
    },
    updateRouterCustomSearchParams() {
      if (this.$router.currentRoute.params.year) {
        this.selectedYear = this.$router.currentRoute.params.year;
      }
    },
    async onYearChanged() {
      this.pushSearch(true);
    },
    onSelectedItems(ids) {
      this.selectedItemIds = ids;
    },
    onCloseBulk() {
      this.showBulkModal = false;
    },
    async onBulkAction(type) {
      try {
        if (!this.bulkInProgress) {
          this.bulkOptionSelected = type;
          this.resolvedBulkIssuesCount = 0;
          this.bulkInProgress = true;
          this.showBulkModal = true;
          this.resolvedBulkIssuesCount = 0;
          /* eslint-disable */
          for (const issue of this.bulkIssues) {
            this.resolvedBulkIssuesCount += 1;
            const recording = await RecordingService.getRecording(issue.recording_id);
            if (issue.issues.includes('rec:jurisdiction')) {
              recording.basic_info.recorded_in_country = UNKNOWN_COUNTRY;
              recording.basic_info.produced_in_country = UNKNOWN_COUNTRY;
            }
            await RecordingService.updateRecordingBasicInfo(_.cloneDeep(recording));
          }
          /* eslint-enable */
          await delay(1000);
          this.$refs.issuesTable.clearSelected();
          this.pushSearch(true);
        }
      } catch (error) {
        this.baseData = [];
        this.$addStarError(error);
      } finally {
        this.bulkOptionSelected = '';
        this.bulkInProgress = false;
        this.showBulkModal = false;
        this.resolvedBulkIssuesCount = 0;
      }
    },
    async reloadBaseData() {
      try {
        this.loading = true;
        const result = await DistributionService.getDistributionYearIssues(
          Number(this.yearId),
          5000,
          1,
        );
        this.baseData = _.cloneDeep(result.issues);
        this.baseData.forEach((issue) => {
          issue.airtime_sort = moment.duration(issue.airtime)._milliseconds;
        });
      } catch (error) {
        this.baseData = [];
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
