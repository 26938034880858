import DebouncedSearchMixin from './debouncedSearchMixin';

export default {
  mixins: [DebouncedSearchMixin(500)],
  data() {
    return {
      expandedIndex: -1,
      pendingExpandedIndex: -1,
      loading: false,
      columns: [],
      itemCount: 0,
      rangeStart: undefined,
      rangeEnd: undefined,
      page: 1,
      pageSize: 25,
      sortParam: undefined,
      sortOrder: undefined,
      extras: {},
    };
  },
  created() {
    const localStorage = this.loadLocalStorage();
    this.updateQueryParams(localStorage);
  },
  methods: {
    loadLocalStorage() {
      const values = localStorage.getItem(`pagination:${this.$router.currentRoute.name}`);

      if (!values) {
        return {};
      }

      return JSON.parse(values);
    },
    saveLocalStorage() {
      localStorage.setItem(
        `pagination:${this.$router.currentRoute.name}`,
        JSON.stringify(this.getQueryParams()),
      );
    },
    getOffset() {
      return (this.page - 1) * this.pageSize;
    },
    numberOfPages() {
      return Math.ceil(this.itemCount / this.pageSize);
    },
    search(rangeStart, rangeEnd) {
      this.updateQueryParams({ rangeStart, rangeEnd, page: 1 });
      this.replaceRouter();
    },
    sort(options) {
      this.updateQueryParams({ sortOrder: options.order, sortParam: options.sortParam });
      this.replaceRouter();
    },
    selectPage(page) {
      this.updateQueryParams({ page });
      this.replaceRouter();
    },
    selectPageSize(pageSize) {
      this.updateQueryParams({ pageSize, page: 1 });
      this.replaceRouter();
    },
    setExtras(extras) {
      this.updateQueryParams(extras);
      this.replaceRouter();
    },
    setRangeStart(value) {
      this.updateQueryParams({ rangeStart: value });
      if (typeof value !== 'undefined') {
        this.debouncedSearch(value, this.rangeEnd);
      }
    },
    setRangeEnd(value) {
      this.updateQueryParams({ rangeEnd: value });
      if (typeof this.rangeStart !== 'undefined') {
        this.debouncedSearch(this.rangeStart, value);
      }
    },
    resetRange() {
      this.rangeStart = undefined;
      this.rangeEnd = undefined;
      this.updateQueryParams({
        rangeStart: undefined,
        rangeEnd: undefined,
      });
    },
    updateQueryParams(
      { pageSize, page, rangeStart, rangeEnd, sortParam, sortOrder, ...extras },
      push = false,
    ) {
      if (pageSize) {
        this.pageSize = +pageSize;
      }

      if (page) {
        this.page = +page;
      }

      if (rangeStart) {
        this.rangeStart = rangeStart;
      } else if (rangeStart === '') {
        this.rangeStart = undefined;
      }

      if (rangeEnd) {
        this.rangeEnd = rangeEnd;
      } else if (rangeEnd === '') {
        this.rangeEnd = undefined;
      }

      if (sortParam) {
        this.sortParam = sortParam;
        this.columns = this.columns.map((c) => ({ ...c, active: c.sortParam === sortParam }));
      }

      if (sortOrder) {
        this.sortOrder = sortOrder;
        this.columns = this.columns.map((c) =>
          c.sortParam === sortParam ? { ...c, ascending: sortOrder === 'asc' } : c,
        );
      }

      if (extras) {
        this.extras = Object.fromEntries(
          Object.entries({ ...this.extras, ...extras }).filter(([, v]) => v !== null),
        );
      }

      if (push) {
        this.$router.push({ name: this.$router.currentRoute.name, query: this.getQueryParams() });
      }

      this.saveLocalStorage();
    },
    replaceRouter() {
      this.$router.replace({ name: this.$router.currentRoute.name, query: this.getQueryParams() });
    },
    getQueryParams() {
      return {
        rangeStart: this.rangeStart,
        rangeEnd: this.rangeEnd,
        page: this.page > 1 ? this.page : undefined,
        pageSize: this.pageSize !== 25 ? this.pageSize : undefined,
        sortParam: this.sortParam,
        sortOrder: this.sortOrder,
        ...this.extras,
      };
    },
  },
};
