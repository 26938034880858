<template>
  <div>
    <spinner v-if="loading" />

    <div class="row wrapper">
      <div class="col s12 text--right edit-link">
        <a :class="{ disabled: createDisabled }" @click="handleCreateNew()">
          <i class="far fa-plus-square" />
          New distribution closure
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col s2 float-right report__headers-refresh">
        <refresh-button :is-loading="isFetchingHeaders" @click="fetchData" />
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div class="col s12">
          <table>
            <thead>
              <th v-for="column in columns" :key="column.id" :class="[column.styleClass]">
                {{ column.name }}
              </th>
            </thead>
            <tbody>
              <tr v-for="d in distributionClosureHeaders" :key="d.distributionClosureId">
                <td>
                  <router-link
                    v-if="d.state !== hasError(d) || d.state !== isWorking(d)"
                    :to="{
                      path: '/distribution/distribution-closures/wizard',
                      query: { id: d.distributionClosureId },
                    }"
                  >
                    {{ d.year }}
                  </router-link>
                  <span v-else>{{ d.closureYear }}</span>
                </td>
                <td>{{ d.createdDate | formatDate }}</td>
                <td>{{ d.createdBy }}</td>
                <td>
                  <template v-if="d.confirmedDate">
                    {{ d.confirmedDate | formatDate }}
                  </template>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <template v-if="d.confirmedBy">
                    {{ d.confirmedBy }}
                  </template>
                  <span v-else class="none">None</span>
                </td>
                <td
                  :class="{
                    'text--green': isOpen(d),
                    'text--yellow': isWorking(d),
                    'text--red': hasError(d),
                  }"
                >
                  {{ d.state | formatDistributionClosureState }}
                </td>
                <td class="amount-row">
                  {{ d.totalAmount | formatAmountCent('SEK') }}
                </td>
                <td>
                  <options
                    v-if="isOpen(d) || hasError(d)"
                    :options="['Delete']"
                    @delete="handleDelete(d.distributionClosureId)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../../spinner';
import Options from '../../ui/context-menu';
import RefreshButton from '../../ui/button/refresh-button';
import PaymentService from '../../../services/paymentService';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import CommandService from '@/services/commandService';

export default {
  name: 'ViewDistributionClosureHistory',
  components: {
    Spinner,
    Options,
    RefreshButton,
  },
  data() {
    return {
      createDisabled: false,
      loading: true,
      columns: [
        { name: 'Year' },
        { name: 'Date Created' },
        { name: 'Created by' },
        { name: 'Date Confirmed' },
        { name: 'Confirmed by' },
        { name: 'Status' },
        { name: 'Amount', styleClass: 'amount-row' },
        { name: '' },
      ],
      distributionClosureHeaders: [],
      distributionClosureState,
      isFetchingHeaders: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isFetchingHeaders = true;
      this.distributionClosureHeaders = await PaymentService.getDistributionClosureHeaders();
      this.createDisabled = this.distributionClosureHeaders.some(
        (c) => c.state !== distributionClosureState.Confirmed,
      );
      this.loading = false;
      this.isFetchingHeaders = false;
    },
    handleCreateNew() {
      this.$router.push({
        path: '/distribution/distribution-closures/wizard',
      });
    },
    async handleDelete(id) {
      this.loading = true;
      await CommandService.handleCommand('payment', {
        type: 'closure/remove',
        stream_id: id,
      });
      this.fetchData();
    },
    isOpen(distributionClosure) {
      const states = distributionClosureState;
      return [states.Ready, states.Approved].includes(distributionClosure.state);
    },
    isWorking(distributionClosure) {
      return [distributionClosureState.Created].includes(distributionClosure.state);
    },
    hasError(distributionClosure) {
      return distributionClosure.state === distributionClosureState.Error;
    },
  },
};
</script>
<style lang="scss" scoped></style>
