<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>Channels</h1>
      </div>
      <div class="col s6 text--right">
        <a @click="onCreateNew">
          <i class="far fa-edit" />
          Create channel
        </a>
      </div>
    </div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col s3 float-right">
          <filter-input :filter-query="filterQuery" placeholder="E.g. SR" @input="filterSearch" />
        </div>
      </div>

      <div class="row card">
        <table>
          <thead class="cursor-pointer">
            <th
              v-for="(column, index) in columns"
              :key="`column-${index}`"
              :class="{ 'wide-column': true, 'disabled-head': !column.sortName }"
              @click="column.sortName ? sort(column) : ''"
            >
              <span>{{ column.name }}</span>
              <span v-if="column.sortName && !column.thinColumn" class="dropdown-button__arrow">
                <span v-if="column.sortParam">
                  <i
                    v-if="column.order === 'desc'"
                    class="fas fa-caret-down"
                    :class="{ 'text--grey': !column.active }"
                  />
                  <i v-else class="fas fa-caret-up" :class="{ 'text--grey': !column.active }" />
                </span>
              </span>
            </th>
            <th colspan="3">
              {{ editChannelId ? 'Group' : '' }}
            </th>
            <th class="thin-column column" />
          </thead>

          <tbody>
            <template v-for="channel in grouplessChannels">
              <tr v-if="editChannelId !== channel.id" :key="channel.id">
                <td class="thin-column" />
                <td class="width-20">
                  <span class="fs-14">{{ channel.name }}</span>
                </td>
                <td class="width-10">
                  <span v-if="channel.code">{{ channel.code }}</span>
                  <span v-else class="none">None</span>
                </td>
                <td class="width-10">
                  <span :class="{ none: !channel.vrdb_playlist }">
                    {{ channel.vrdb_playlist | yesno }}
                  </span>
                </td>
                <td colspan="3"></td>
                <td>
                  <context-menu :options="['Edit']" @edit="onEditChannel(channel)" />
                </td>
              </tr>
              <template v-else>
                <edit-distribution-channel
                  :key="channel.id"
                  :channel="channel"
                  @changed="onEditChannelComplete"
                  @cancel="onEditChannelComplete"
                />
              </template>
            </template>

            <template v-for="(channels, group) in channelGroups">
              <tr :key="`group-tr1-${group}`" class="cursor-pointer" @click="toggleChannels(group)">
                <td class="thin-column dropdown-button__arrow">
                  <i v-if="expanded(group)" class="fas fa-caret-down" />
                  <i v-else-if="!expanded(group)" class="fas fa-caret-down" />
                </td>
                <td colspan="1000">
                  {{ group }}
                </td>
              </tr>

              <template v-if="expanded(group)" class="expand-info">
                <template v-for="channel in channels">
                  <tr v-if="editChannelId !== channel.id" :key="`channel-${channel.id}`">
                    <td class="thin-column" />
                    <td class="width-20">
                      <span>{{ channel.name }}</span>
                    </td>
                    <td class="width-10">
                      <span v-if="channel.code">{{ channel.code }}</span>
                      <span v-else class="none">None</span>
                    </td>
                    <td class="width-10">
                      <span :class="{ none: !channel.vrdb_playlist }">
                        {{ channel.vrdb_playlist | yesno }}
                      </span>
                    </td>
                    <td colspan="3"></td>
                    <td>
                      <context-menu :options="['Edit']" @edit="onEditChannel(channel)" />
                    </td>
                  </tr>
                  <template v-else>
                    <edit-distribution-channel
                      :key="`channel-${channel.id}`"
                      :channel="channel"
                      @changed="onEditChannelComplete"
                      @cancel="onEditChannelComplete"
                    />
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ContextMenu from '../../ui/context-menu';
import DataTableMixin from '../data-table-mixin';
import DistributionService from '../../../services/distributionService';
import EditDistributionChannel from './edit-distribution-channel';
import FilterInput from '../../ui/input/filter-input';
import Spinner from '../../spinner';

export default {
  name: 'ViewDistributionChannels',
  components: {
    ContextMenu,
    EditDistributionChannel,
    FilterInput,
    Spinner,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      viewRouteName: 'distributionChannelsView',
      editChannelId: null,
      expandedItemIds: [],
      existingGroups: [],
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Name',
          sortName: 'name',
          order: 'asc',
          active: false,
          sortParam: true,
          filterable: true,
        },
        {
          name: 'Code',
        },
        {
          name: 'VRDB',
        },
      ],
    };
  },
  computed: {
    channelGroups() {
      return _.groupBy(
        _.filter(this.searchContext.result.results, (channel) => channel.channel_group),
        'channel_group',
      );
    },
    grouplessChannels() {
      return _.filter(this.searchContext.result.results, (channel) => !channel.channel_group);
    },
  },
  methods: {
    async reloadBaseData() {
      const result = await DistributionService.getDistributionChannels();
      this.showPagination = false;
      this.baseData = result.channels;
    },
    expanded(group) {
      return _.includes(this.expandedItemIds, group);
    },
    toggleChannels(group) {
      const index = _.indexOf(this.expandedItemIds, group);
      if (index === -1) {
        this.expandedItemIds.push(group);
      } else {
        this.expandedItemIds.splice(index, 1);
      }
    },
    onCreateNew() {
      this.$router.push({
        name: 'distributionChannelCreate',
      });
    },
    onEditChannel(result) {
      this.editChannelId = result.id;
    },
    async onEditChannelComplete() {
      this.editChannelId = null;
      await this.reloadBaseData();
      this.filterSearch();
    },
  },
};
</script>
