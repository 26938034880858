<template>
  <div class="cmp">
    <div class="col s12">
      <div class="">
        <div class="row">
          <div class="col s11">
            <h1>Incomplete Recordings</h1>
          </div>
        </div>
      </div>

      <div v-if="loading" class="row">
        <div class="col s12">
          <spinner />
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col s2 float-right">
            <select-input
              name="issue-type-filter"
              label="Error type"
              :items="issueTypes"
              :value="filterTerms.issue_type"
              item-key="key"
              item-value="value"
              :empty-select-option="true"
              @input="onIssueTypeFilter"
            />
          </div>
          <div class="col s2 float-right">
            <select-year
              name="year-filter"
              label="Report year"
              :required="false"
              :years="years"
              :value="filterTerms.year"
              @input="onYearFilter"
            />
          </div>
          <div class="col s2 float-right">
            <select-country
              name="country-filter"
              label="Recorded in country"
              :input-countries="inputCountries"
              :value="filterTerms.country"
              @input="onCountryFilter"
            />
          </div>
          <div class="col s1 float-right">
            <select-input
              name="vrdb_id-filter"
              label="Vrdb Id"
              item-key="key"
              item-value="value"
              :items="inputYesNo"
              :value="filterTerms.vrdb_id"
              @input="onVrdbFilter"
            />
          </div>
          <div class="col s1 float-right">
            <select-input
              name="web-filter"
              label="Web"
              item-key="key"
              item-value="value"
              :items="inputYesNo"
              :value="filterTerms.web"
              @input="onWebFilter"
            />
          </div>
          <div class="col s2 float-right">
            <select-input
              name="channels-filter"
              label="Channels"
              item-key="key"
              item-value="value"
              :items="channels"
              :value="filterTerms.channel"
              @input="onChannelFilter"
            />
          </div>
          <div class="col s2 float_right">
            <div>
              <label class="label">Main artist range</label>
              <div>
                <div class="col s6 custom-select__input-wrapper">
                  <input
                    name="range-start"
                    type="text"
                    autocomplete="off"
                    :value="filterTerms.range_start"
                    placeholder="E.g. A"
                    @input="onRangeStartFilter($event.target.value)"
                  />
                </div>
                <div class="col s6 custom-select__input-wrapper">
                  <input
                    name="range-end"
                    type="text"
                    autocomplete="off"
                    :value="filterTerms.range_end"
                    placeholder="E.g. B"
                    @input="onRangeEndFilter($event.target.value)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button class="btn float-right" @click="applyFilter">Apply filter</button>
          <button class="btn float-right add-margin-right secondary" @click="clearFilter">
            Clear filter
          </button>
        </div>

        <div class="row card">
          <div>
            <div class="row">
              <div class="col s12">
                <table>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="[
                      {
                        'thin-column': column.thinColumn,
                        'disabled-head': !column.sortName,
                        'cursor-pointer': column.sortName,
                      },
                      column.styleClass,
                    ]"
                    @click="column.sortName ? sort(column) : ''"
                  >
                    <span>{{ column.name }}</span>
                    <span v-if="column.sortName && !column.thinColumn">
                      <span v-if="column.order" class="dropdown-button__arrow">
                        <i
                          v-if="column.order === 'desc'"
                          class="fas fa-caret-down"
                          :class="{ 'text--grey': !column.active }"
                        />
                        <i
                          v-else
                          class="fas fa-caret-up"
                          :class="{ 'text--grey': !column.active }"
                        />
                      </span>
                    </span>
                  </th>
                  <tbody>
                    <tr v-for="(issue, index) in baseData" :key="index" class="row">
                      <td>
                        <span
                          v-if="
                            issue.performers.length === 0 ||
                            (issue.issues.length > 1 &&
                              filterTerms.issue_type !== 'perf:country_of_residence')
                          "
                        >
                          <router-link
                            :to="{ name: 'recordingView', params: { id: issue.recording_id } }"
                          >
                            {{ issue.title }}
                          </router-link>
                          <span v-if="issue.performers.length > 0">/</span>
                        </span>
                        <span
                          v-if="
                            issue.performers.length > 0 &&
                            ['', 'perf:country_of_residence'].includes(filterTerms.issue_type)
                          "
                        >
                          <router-link
                            v-for="performer in issue.performers"
                            :key="performer.id"
                            :to="{ name: 'performerView', params: { id: performer.id } }"
                          >
                            {{ performer.name }}
                          </router-link>
                        </span>
                      </td>
                      <td>
                        <span v-if="issue.version_title">{{ issue.version_title }}</span>
                        <span v-else class="none">None</span>
                      </td>
                      <td>
                        {{ issue.main_artist }}
                      </td>
                      <td>
                        <span v-if="issue.recording_year">{{ issue.recording_year }}</span>
                        <span v-else class="none">None</span>
                      </td>
                      <td>
                        <span v-if="issue.recorded_in_country">
                          {{ issue.recorded_in_country | formatCountry }}
                        </span>
                        <span v-else class="none">None</span>
                      </td>
                      <td>
                        <div v-if="issue.issues && issue.issues.length > 0">
                          <span
                            v-for="(subIssue, index2) in issue.issues"
                            :key="index2"
                            class="csv-item"
                          >
                            {{ cleanedIssue(subIssue) | translate }}
                          </span>
                        </div>
                        <div v-else>
                          <span class="none">Pending</span>
                        </div>
                      </td>
                      <td>
                        <span>{{ issue.airtime | airTime }}</span>
                      </td>
                      <td class="text--center">
                        <span
                          v-if="
                            issue.tags &&
                            issue.tags.find((tag) => tag.toLowerCase() === 'investigated')
                          "
                        >
                          <i class="fas fa-check-circle text--green" />
                        </span>
                      </td>
                      <td>
                        <span v-if="issue.vrdb2_id">{{ issue.vrdb2_id }}</span>
                        <span v-else class="none">None</span>
                      </td>
                      <td v-if="filterTerms.worklist_id">
                        <context-menu
                          :options="['Unassign']"
                          @unassign="unassign(issue.recording_id)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-if="searchContext.isExecuted">
            <div class="col s12">
              <pagination
                v-show="numberOfPages > 0"
                :number-of-pages="searchContext.numberOfPages"
                :selected-page="pagination.page"
                :number-of-hits="searchContext.numberOfHits"
                :hits-per-page="pagination.hitsPerPage"
                @selectPage="selectPage"
                @updateHitsPerPage="updateHitsPerPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import DataTableMixin from './data-table-mixin';
import Pagination from '../pagination';
import ContextMenu from '../ui/context-menu';
import SelectYear from '../ui/select/select-year';
import SelectCountry from '../ui/select/select-country';
import SelectInput from '../ui/select/select-input';
import Spinner from '../spinner';
import DistributionHelper from '../distribution/distributionHelper';
import DistributionService from '../../services/distributionService';

export default {
  name: 'ViewIncompleteRecordings',
  components: {
    Pagination,
    ContextMenu,
    SelectYear,
    SelectCountry,
    SelectInput,
    Spinner,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      issues: [],
      expandedItemIds: [],
      selectedItemIds: [],
      searchContext: {
        numberOfHits: 0,
        numberOfPages: 0,
      },
      viewRouteName: 'incompleteRecordingsView',
      loading: false,
      years: [],
      issueTypes: [],
      inputCountries: [],
      inputYesNo: [
        { key: 'true', value: 'Yes' },
        { key: 'false', value: 'No' },
      ],
      channels: [],
      filterTerms: {
        year: '',
        country: '',
        issue_type: '',
        worklist_id: '',
        range_start: '',
        range_end: '',
        channel: '',
        web: '',
      },
      sortTerms: {
        param: '',
        order: '',
      },

      columns: [
        {
          name: 'Recording / Performer',
          sortName: 'title',
          order: 'asc',
          active: false,
        },
        {
          name: 'Version',
          sortName: 'version_title',
          order: 'asc',
          active: false,
        },
        {
          name: 'Main Artist',
          sortName: 'main_artist',
          active: false,
          order: 'asc',
        },
        {
          name: 'Recording Year',
          sortName: 'recording_year',
          order: 'asc',
          active: false,
        },
        {
          name: 'Recorded in Country',
          sortName: 'recording_country',
          order: 'asc',
          active: false,
        },
        {
          name: 'Error Type',
        },
        {
          name: 'Airtime',
          sortName: 'airtime',
          order: 'desc',
          active: true,
        },
        {
          name: 'Investigated',
          styleClass: 'text--center',
        },
        {
          name: 'Vrdb Id',
          sortName: 'vrdb2_id',
          order: 'desc',
          active: false,
        },
      ],
    };
  },
  computed: {
    itemIds() {
      return this.baseData.map((issue) => issue.recording_id);
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  created() {
    if (this.filterTerms.worklist_id) {
      this.columns.push({
        name: '',
        thinColumn: true,
      });
    }
    this.updateColumnNames();
  },
  methods: {
    routeParams(base) {
      return Object.assign(base, {
        year: this.filterTerms.year,
        country: this.filterTerms.country,
        issue_type: this.filterTerms.issue_type,
        worklist_id: this.filterTerms.worklist_id,
        vrdb_id: this.filterTerms.vrdb_id,
        web: this.filterTerms.web,
        range_start: this.filterTerms.range_start,
        range_end: this.filterTerms.range_end,
        channel: this.filterTerms.channel,
      });
    },
    updateRouterCustomSearchParams() {
      this.filterTerms.year = this.$router.currentRoute.query.year
        ? this.$router.currentRoute.query.year
        : '';
      this.filterTerms.country = this.$router.currentRoute.query.country
        ? this.$router.currentRoute.query.country
        : '';
      this.filterTerms.issue_type = this.$router.currentRoute.query.issue_type
        ? this.$router.currentRoute.query.issue_type
        : '';
      this.filterTerms.worklist_id = this.$router.currentRoute.query.worklist_id
        ? this.$router.currentRoute.query.worklist_id
        : '';
      this.filterTerms.vrdb_id = this.$router.currentRoute.query.vrdb_id
        ? this.$router.currentRoute.query.vrdb_id
        : '';
      this.filterTerms.web = this.$router.currentRoute.query.web
        ? this.$router.currentRoute.query.web
        : '';
      this.filterTerms.range_start = this.$router.currentRoute.query.range_start
        ? this.$router.currentRoute.query.range_start
        : '';
      this.filterTerms.range_end = this.$router.currentRoute.query.range_end
        ? this.$router.currentRoute.query.range_end
        : '';
    },
    onYearFilter(year) {
      this.filterTerms.year = year;
    },
    onIssueTypeFilter(issueType) {
      this.filterTerms.issue_type = issueType;
    },
    onCountryFilter(country) {
      this.filterTerms.country = country;
    },
    onVrdbFilter(showVrdb) {
      this.filterTerms.vrdb_id = showVrdb;
    },
    onWebFilter(showWeb) {
      this.filterTerms.web = showWeb;
    },
    onRangeStartFilter(value) {
      this.filterTerms.range_start = value;
    },
    onRangeEndFilter(value) {
      this.filterTerms.range_end = value;
    },
    onChannelFilter(value) {
      this.filterTerms.channel = value;
    },
    applyFilter() {
      this.resetPages();
      this.pushSearch();
    },
    clearFilter() {
      this.filterTerms = {};

      this.resetPages();
      this.pushSearch();
    },
    routeQuery(base) {
      const queryParams = {};
      if (this.filterTerms.year) {
        queryParams.year = this.filterTerms.year;
      }
      if (this.filterTerms.country) {
        queryParams.country = this.filterTerms.country;
      }
      if (this.filterTerms.issue_type) {
        queryParams.issue_type = this.filterTerms.issue_type;
      }
      if (this.filterTerms.worklist_id) {
        queryParams.worklist_id = this.filterTerms.worklist_id;
      }
      if (this.filterTerms.vrdb_id) {
        queryParams.vrdb_id = this.filterTerms.vrdb_id;
      }
      if (this.filterTerms.web) {
        queryParams.web = this.filterTerms.web;
      }
      if (this.filterTerms.range_start) {
        queryParams.range_start = this.filterTerms.range_start;
      }
      if (this.filterTerms.range_end) {
        queryParams.range_end = this.filterTerms.range_end;
      }
      if (this.filterTerms.channel) {
        queryParams.channel = this.filterTerms.channel;
      }
      return Object.assign(base, queryParams);
    },
    updateColumnNames() {
      if (this.filterTerms.issue_type === 'perf:country_of_residence') {
        this.columns[0].name = 'Performers';
        this.columns[0].sort_name = '';
      } else if (this.filterTerms.issue_type === '') {
        this.columns[0].name = 'Recording / Performers';
        this.columns[0].sort_name = 'titel';
      } else {
        this.columns[0].name = 'Recording';
        this.columns[0].sort_name = 'titel';
      }
    },
    async reloadBaseData() {
      try {
        this.loading = true;
        const result = await DistributionHelper.getIncompleteRecordings(
          this.pagination.hitsPerPage,
          this.pagination.page,
          this.sortTerms,
          this.filterTerms,
        );
        this.baseData = _.cloneDeep(result.issues);
        if (result.issues.length === 0) {
          this.loading = false;
        } else {
          this.baseData.forEach((issue) => {
            issue.airtime_sort = moment.duration(issue.airtime)._milliseconds;
          });
          this.searchContext.numberOfHits = result.total_count;
          this.searchContext.numberOfPages = Math.ceil(
            result.total_count / this.pagination.hitsPerPage,
          );
          this.years = result.years;
          this.channels = result.channels.filter(Boolean).map((channel) => ({
            key: channel,
            value: channel.charAt(0).toUpperCase() + channel.substr(1).toLowerCase(),
          }));
          this.inputCountries = this.countries.filter((country) =>
            result.countries.includes(country.iso),
          );
          this.issueTypes = result.issue_types.map((issue) => ({
            key: issue,
            value: Vue.$i18n.t(this.cleanedIssue(issue)),
          }));
          this.updateColumnNames();
          localStorage.setItem('worklistYears', result.years);
          localStorage.setItem('worklistCountries', JSON.stringify(this.inputCountries));
          localStorage.setItem('worklistIssueTypes', JSON.stringify(this.issueTypes));
        }
      } catch (error) {
        this.baseData = [];
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async unassign(recordingId) {
      await DistributionService.unassignIssues(this.filterTerms.worklist_id, [recordingId]);
      this.reloadBaseData();
    },
    cleanedIssue(issue) {
      const cleanedIssue = issue.replace('rec:', '').replace('lineup:', '').replace('perf:', '');
      return `distribution.errors.${cleanedIssue}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cmp {
  .col.s2:first-child {
    padding-left: var(--spacing);
  }
  .add-margin-right {
    margin-right: var(--spacing);
  }
  .cursor-pointer {
    padding-left: var(--spacing-double);
  }
  .disabled-head {
    padding-left: var(--spacing-double);
  }
}
</style>
