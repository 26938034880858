<template>
  <modal
    :hide-overflow="false"
    :wide="true"
    submit-label="Apply"
    abort-label="Cancel"
    :disable-submit="disableControls || !anyChanges || invalidRoleCodes"
    @close="$emit('cancel')"
    @cancel="$emit('cancel')"
    @save="patch"
  >
    <h1 slot="header">
      <span>Add/Edit Appearance</span>
      <span v-if="localPerformer.id">&#32;of {{ localPerformer.name }}</span>
      <!-- &#32; is a regular space -->
    </h1>
    <div slot="body">
      <div v-if="enableSearch" class="row">
        <searchable-input
          label="Search performer"
          name="otherPerformer"
          placeholder="E.g. Joakim Berg"
          use-template="performerNameSearch"
          :clear-after-select="true"
          :css-props="{ whiteSpace: 'normal' }"
          :searcher="searchPerformer"
          @input="addPerformer"
        />
      </div>

      <table class="modal__headers card">
        <sortable-head
          :bulk-enabled="true"
          :columns="columns"
          :marked-for-bulk="allSelected"
          :more-options="true"
          :bulk-suspend="disableControls"
          @markAll="toggleAll($event)"
        />
        <tbody>
          <tr>
            <td colspan="3"></td>
            <td>
              <select-input
                name="recording.lineup.pseudo"
                :items="pseudoNameOptions"
                :disabled="disableControls"
                @input="updateAll({ pseudo_name: $event })"
              />
            </td>
            <td>
              <select-instrument
                v-model="instruments"
                name="recording.lineup.instrument"
                :addable="true"
                :show-label="false"
                :disabled="disableControls"
                :highlight="instrumentsHightlight"
                @input="updateAll({ instruments: instruments })"
              />
            </td>
            <td>
              <select-input
                name="recording.lineup.role"
                :items="roleRefs"
                item-key="code"
                item-value="name"
                :disabled="disableControls"
                @input="updateAll({ role: $event })"
              />
            </td>
            <td></td>
          </tr>
          <tr
            v-for="(track, index) in localTracks.slice(offset, offset + HITS_PER_PAGE)"
            :key="track.recording.id"
          >
            <td class="thin-column">
              <input
                :id="index"
                class="filled-in"
                type="checkbox"
                :checked="!NO_APPEARANCE_STATUS.includes(track.status)"
                :disabled="disableControls || track.recordingAggregate.lineup_locked"
                @change="toggleOne($event.target.checked, track)"
              />
              <label :for="index" />
            </td>
            <td>{{ track.recording.side_no }}.{{ track.recording.track_no }}</td>
            <td>
              {{ track.recordingAggregate.basic_info.name }}
              {{ track.recordingAggregate.lineup_locked ? ' 🔒' : '' }}
            </td>

            <template v-if="editId === track.recording.id">
              <td>
                <select-input
                  v-model="track.appearance.pseudo_name"
                  :name="`recording.lineup.${index}.pseudo`"
                  :items="pseudoNameOptions"
                  @input="updateStatus(track)"
                />
              </td>
              <td>
                <select-instrument
                  v-model="track.appearance.instruments"
                  :name="`recording.lineup.${index}.instrument`"
                  :addable="true"
                  :show-label="false"
                  @input="updateStatus(track)"
                />
              </td>
              <td>
                <select-input
                  v-model="track.appearance.role"
                  :name="`recording.lineup.${index}.role`"
                  :items="roleRefs"
                  item-key="code"
                  rule="required"
                  item-value="name"
                  @input="updateStatus(track)"
                />
              </td>
              <td colspan="2">
                <button
                  class="btn"
                  type="button"
                  :disabled="validationErrors.has(`recording.lineup.${index}.role`)"
                  @click="editId = undefined"
                  @keyup.enter="submit"
                >
                  OK
                </button>
              </td>
            </template>
            <template v-else-if="track.appearance && !NO_APPEARANCE_STATUS.includes(track.status)">
              <td>
                <span v-if="track.appearance.pseudo_name">
                  {{ track.appearance.pseudo_name }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="track.appearance.instruments.filter(Boolean).length">
                  {{ track.appearance.instruments | formatInstrumentList }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="track.appearance.role">{{ track.appearance.role | formatRole }}</span>
                <span v-else class="text--italic text--red">None</span>
              </td>
              <td>
                <span class="chip" :class="getStatusClass(track.status)">
                  {{ getStatusText(track.status) }}
                </span>
              </td>
              <td>
                <context-menu :options="['Edit']" @edit="editId = track.recording.id" />
              </td>
            </template>
            <template v-else>
              <td colspan="3"></td>
              <td>
                <span class="chip" :class="getStatusClass(track.status)">
                  {{ getStatusText(track.status) }}
                </span>
              </td>
              <td></td>
            </template>
          </tr>
        </tbody>
      </table>

      <pagination
        v-show="numberOfPages > 0"
        :number-of-pages="numberOfPages"
        :selected-page="offset / HITS_PER_PAGE + 1"
        :number-of-hits="localTracks.length"
        :hits-per-page="HITS_PER_PAGE"
        :show-hits-per-page="false"
        @selectPage="offset = ($event - 1) * HITS_PER_PAGE"
      />
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import Modal from '../../ui/dialog/modal';
import SelectInstrument from '../../ui/select/select-instrument';
import SelectInput from '../../ui/select/select-input';
import SortableHead from '../../ui/table/sortable-head';
import ContextMenu from '../../ui/context-menu';
import SearchableInput from '../../ui/input/searchable-input';
import SearchHelper from '../../search/searchHelper';
import Pagination from '../../pagination';

const AppearanceStatus = {
  NONE: 'none',
  UNCHANGED: 'unchanged',
  ADDED: 'added',
  UPDATED: 'updated',
  REMOVED: 'removed',
  REMOVED_UPDATED: 'remove_updated',
};

export default {
  name: 'EditAddNewPerformers',
  components: {
    Modal,
    SelectInstrument,
    SelectInput,
    SortableHead,
    SearchableInput,
    ContextMenu,
    Pagination,
  },
  inject: ['$validator'],
  props: {
    enableSearch: {
      type: Boolean,
      default: false,
    },
    performer: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      AppearanceStatus,
      columns: [
        { name: 'No', thinColumn: true },
        { name: 'Track' },
        { name: 'Pseudo Name' },
        { name: 'Instruments' },
        { name: 'Role' },
        { name: 'Status' },
      ],
      offset: 0,
      appearances: [],
      localTracks: [],
      localPerformer: { societies: [], pseudo_names: [] },
      instruments: [null],
      instrumentsHightlight: [],
      editId: undefined,
      NO_APPEARANCE_STATUS: [
        AppearanceStatus.NONE,
        AppearanceStatus.REMOVED,
        AppearanceStatus.REMOVED_UPDATED,
      ],
      UNCHANGED_STATUS: [AppearanceStatus.NONE, AppearanceStatus.UNCHANGED],
      HITS_PER_PAGE: 12,
    };
  },
  computed: {
    ...mapGetters('album', ['tracks']),
    numberOfPages() {
      return Math.ceil(this.localTracks.length / this.HITS_PER_PAGE);
    },
    disableControls() {
      return !!this.editId || !this.localPerformer.id;
    },
    allSelected() {
      return this.localTracks.every((track) => !this.NO_APPEARANCE_STATUS.includes(track.status));
    },
    anyChanges() {
      return this.localTracks.some((track) => !this.UNCHANGED_STATUS.includes(track.status));
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
    roleRefs() {
      const samiWWMandate =
        this.localPerformer.societies.filter(
          (s) => s.society_code === '06' && s.mandate_type === 'WW',
        ).length > 0;
      const { collective } = this.localPerformer;

      if (collective && samiWWMandate) {
        return this.roles.filter((r) => r.code === 'SC');
      } else if (collective) {
        return this.roles.filter((r) => r.code === 'FC');
      } else if (!samiWWMandate) {
        return this.roles.filter((r) => r.code !== 'SC');
      }

      return this.roles.filter((r) => !['SC', 'FC'].includes(r.code));
    },
    pseudoNameOptions() {
      return this.localPerformer.pseudo_names.map((name) => ({ code: name, name }));
    },
    invalidRoleCodes() {
      return this.localTracks.some((track) => track.appearance && track.appearance.role === null);
    },
  },
  watch: {
    tracks() {
      this.updateAppearances();
      this.updateLocalTracks();
    },
  },
  created() {
    this.updateAppearances();
    this.updateLocalTracks();
  },
  methods: {
    updateAppearances() {
      if (this.performer) {
        this.localPerformer = cloneDeep(this.performer);
        this.appearances = this.localPerformer.tracks || [];
      }
    },
    async searchPerformer(query) {
      const res = await SearchHelper.advancedSearchPerformers({ query });
      return res.results.map((perf) => ({
        ...perf,
        name: `${perf.first_name} ${perf.last_name}`,
        main_artists: perf.main_artists.map((ma) => ma.name),
      }));
    },
    updateLocalTracks() {
      this.localTracks = this.tracks
        .map((track) => {
          const appearance = this.appearances.find((t) => t.id === track.recording.id);
          return {
            ...cloneDeep(track),
            appearance,
            status: appearance ? AppearanceStatus.UNCHANGED : AppearanceStatus.NONE,
          };
        })
        .sort((a, b) => {
          if (a.recording.side_no !== b.recording.side_no) {
            return Number(a.recording.side_no) - Number(b.recording.side_no);
          }
          return Number(a.recording.track_no) - Number(b.recording.track_no);
        });
    },
    addPerformer(performer) {
      if (!performer) {
        this.localPerformer = { societies: [], pseudo_names: [] };
        this.appearances = [];
        this.instruments = [null];
      }

      if (this.instrumentsHightlight.length !== 0) {
        this.instrumentsHightlight = [];
      }

      if (performer.main_instrument != null) {
        this.instruments = [performer.main_instrument];
        this.instrumentsHightlight.push(performer.main_instrument);
      }

      (performer.secondary_instruments || []).forEach((instrumentCode) => {
        this.instrumentsHightlight.push(instrumentCode);
      });

      const tracks = this.tracks.reduce((acc, track) => {
        const line = track.recordingAggregate.lineup.find(
          (line) => line.relation.id === performer.id,
        );
        if (line) {
          acc.push({
            id: track.recording.id,
            role: line.role,
            instruments: line.instruments,
            pseudo_name: line.pseudo_name,
          });
        }

        return acc;
      }, []);

      this.localPerformer = {
        id: performer.id,
        name: [performer.first_name, performer.last_name].join(' '),
        nationality: performer.country_of_residence,
        ipn: performer.ipn,
        collective: performer.collective,
        pseudo_names: performer.pseudo_names,
        societies: performer.mandates || [],
        tracks,
        type: 'performer',
      };
      this.appearances = tracks;
      this.updateLocalTracks();
    },
    updateStatus(track) {
      if (track.status === AppearanceStatus.UNCHANGED) {
        track.status = AppearanceStatus.UPDATED;
      }
    },
    updateAll(values) {
      if (values.role === null) {
        return;
      }

      this.localTracks.forEach((track) => {
        if (track.appearance) {
          track.appearance = {
            ...track.appearance,
            ...values,
          };
          this.updateStatus(track);
        }
      });
    },
    toggleAll(toggle) {
      this.localTracks.forEach((track) => this.toggleOne(toggle, track));
    },
    toggleOne(toggle, track) {
      if (toggle) {
        this.addAppearance(track);
      } else {
        this.removeAppearance(track);
      }
    },
    patch() {
      const changes = this.localTracks
        .filter((track) =>
          [
            AppearanceStatus.ADDED,
            AppearanceStatus.UPDATED,
            AppearanceStatus.REMOVED,
            AppearanceStatus.REMOVED_UPDATED,
          ].includes(track.status),
        )
        .map((track) => {
          return {
            id: track.recording.id,
            ...(track.status.startsWith(AppearanceStatus.REMOVED)
              ? { deleteIds: [this.localPerformer.id] }
              : { upsert: [this.mapAppearance(track)] }),
          };
        });

      this.$emit('patch', changes);
    },
    mapAppearance(track) {
      return {
        role: track.appearance.role,
        instruments: (track.appearance.instruments || []).filter(Boolean),
        pseudo_name: track.appearance.pseudo_name,
        relation: {
          id: this.localPerformer.id,
          name: this.localPerformer.name,
          type: this.localPerformer.type,
        },
      };
    },
    addAppearance(track) {
      switch (track.status) {
        case AppearanceStatus.NONE:
          track.appearance = {
            instruments: [...this.instruments],
            role: null,
          };
          track.status = AppearanceStatus.ADDED;
          break;

        case AppearanceStatus.REMOVED:
          track.status = AppearanceStatus.UNCHANGED;
          break;

        case AppearanceStatus.REMOVED_UPDATED:
          track.status = AppearanceStatus.UPDATED;
          break;

        default:
          break;
      }
    },
    removeAppearance(track) {
      switch (track.status) {
        case AppearanceStatus.UNCHANGED:
          track.status = AppearanceStatus.REMOVED;
          break;

        case AppearanceStatus.UPDATED:
          track.status = AppearanceStatus.REMOVED_UPDATED;
          break;

        case AppearanceStatus.ADDED:
          track.appearance = undefined;
          track.status = AppearanceStatus.NONE;
          break;

        default:
          break;
      }
    },
    getStatusClass(status) {
      switch (status) {
        case AppearanceStatus.UPDATED:
          return 'report__headers-chip--ok';
        case AppearanceStatus.REMOVED:
        case AppearanceStatus.REMOVED_UPDATED:
          return 'report__headers-chip--fail';
        case AppearanceStatus.ADDED:
          return 'report__headers-chip--wait';
        default:
          return '';
      }
    },
    getStatusText(status) {
      switch (status) {
        case AppearanceStatus.REMOVED_UPDATED:
          return AppearanceStatus.REMOVED;
        default:
          return status;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  text-transform: capitalize;
}
</style>
