<template>
  <div class="data-table__headers">
    <spinner v-if="loading"></spinner>

    <div class="row wrapper">
      <div class="col s6">
        <h1>VRDB Playlist</h1>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <h2>Transfer New File</h2>
      </div>
    </div>

    <div v-if="!recordings.length" class="card">
      <div class="row">
        <div class="col s12 l2">
          <select-input
            v-model="type"
            :items="reportTypes"
            name="Test"
            label="Playlist Type"
            :mandatory="true"
            translation-prefix="vrdb2.playlist.type"
          />
        </div>
      </div>
      <div class="row">
        <fieldset class="col s12 l2">
          <legend>Include</legend>
          <div>
            <input
              id="playedRecordings"
              v-model="playedRecordings"
              class="filled-in"
              type="checkbox"
              :disabled="!playlist"
            />
            <label for="playedRecordings">Played Recordings (PR)</label>
          </div>

          <div>
            <input
              id="playlist"
              v-model="playlist"
              class="filled-in"
              type="checkbox"
              :disabled="!playedRecordings"
            />
            <label for="playlist">Unmatched Playlist Lines (UPL)</label>
          </div>
        </fieldset>
      </div>

      <div class="row text--right">
        <button class="btn" :disabled="!type" @click="fetchRecordings()">Preview</button>
      </div>
    </div>

    <template v-else>
      <div class="card">
        <div class="row data">
          <DataTable :columns="summaryColumns" :items="summaries" item-key="type">
            <template #item:type="{ item }">
              <span v-if="item.type === 'PR'">Played Recordings (PR)</span>
              <span v-else-if="item.type === 'UPL'">Unmatched Playlist Lines (UPL)</span>
              <span v-else>Total*</span>
            </template>
          </DataTable>
        </div>

        <div class="row">
          <div class="col s9">
            *Actual recordings transferred might be slightly fewer as some Unmatched Playlist
            <br />
            lines will be merged with Played recordings.
          </div>
          <div class="col s3 text--right">
            <action-buttons
              submit-label="Transfer to VRDB"
              @save="transferToVrdb"
              @cancel="recordings = []"
            />
          </div>
        </div>
      </div>

      <div class="row card">
        <DataTable
          :columns="recordingColumns"
          :items="recordings"
          :total-items="totalRecordings"
          @updatePagination="fetchRecordings($event.limit, $event.offset, false)"
        >
          <template #item:title="{ item }">
            <router-link
              :to="{ name: 'recordingView', params: { id: item.star_id } }"
              target="_blank"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template #item:type="{ item }">
            {{ item.vrdb2_id ? 'PR' : 'UPL' }}
          </template>
        </DataTable>
      </div>
    </template>

    <template v-if="!recordings.length">
      <div class="row">
        <div class="col s12">
          <h2>Transferred Files</h2>
        </div>
      </div>

      <div class="row card">
        <div class="col s12">
          <DataTable :columns="transferredColumns" :items="transferred">
            <template #item:type="{ item }">
              {{ `vrdb2.playlist.type.${item.type}` | translate }}
            </template>
            <template #item:created_at="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>
            <template #item:state="{ item }">
              <span class="chip float-left" :class="getStateClass(item.state)" :title="item.error">
                {{ item.state | formatIpdDownloadState }}
              </span>
            </template>
            <template #item:files="{ item }">
              <span v-if="item.files && item.files.length >= 0">
                {{ item.files.length }}
              </span>
              <span v-else class="none">None</span>
            </template>
          </DataTable>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import DistributionService from '../../services/distributionService';
import DataTable from '../ui/table/DataTable';
import Spinner from '../spinner';
import ActionButtons from '../ui/button/action-buttons';
import SelectInput from '../ui/select/select-input.vue';

export default {
  name: 'ViewVrdbDashboard',
  components: {
    DataTable,
    Spinner,
    ActionButtons,
    SelectInput,
  },
  data() {
    return {
      type: null,
      reportTypes: [
        { code: 'default', name: 'default' },
        { code: 'asr', name: 'asr' },
      ],
      summaryColumns: [
        { key: 'type', text: 'Type' },
        { key: 'weight_3', text: 'Weight 3', count: true },
        { key: 'weight_2', text: 'Weight 2', count: true },
        { key: 'weight_1', text: 'Weight 1', count: true },
        { key: 'total', text: 'Total*', count: true },
      ],
      recordingColumns: [
        { key: 'title', text: 'Title ' },
        { key: 'version_title', text: 'Version' },
        { key: 'performance_year', text: 'Performance Year' },
        { key: 'main_artist', text: 'Main Artist' },
        { key: 'vrdb2_id', text: 'VRDB ID' },
        { key: 'type', text: 'Type' },
        { key: 'weight', text: 'Weight', count: true },
      ],
      transferredColumns: [
        { key: 'type', text: 'Report Type' },
        { key: 'created_at', text: 'Transferred' },
        { key: 'state', text: 'Status' },
        { key: 'played_recordings', text: 'Played Recordings', count: true },
        { key: 'playlist', text: 'Playlist', count: true },
        { key: 'files', text: 'Files', count: true },
      ],
      transferred: [],
      recordings: [],
      summaries: [],
      totalRecordings: 0,
      playedRecordings: true,
      playlist: true,
      loading: false,
      timeoutHandle: null,
    };
  },
  created() {
    this.fetchPlaylistFiles();
  },
  destroyed() {
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = null;
  },
  methods: {
    async fetchPlaylistFiles() {
      this.loading = true;

      try {
        this.transferred = await DistributionService.getVrdb2Headers();

        const autoReload = this.transferred.some((file) => ['N', 'P'].includes(file.state));
        if (autoReload && this.timeoutHandle === null) {
          this.timeoutHandle = setTimeout(() => {
            this.timeoutHandle = null;
            this.fetchPlaylistFiles();
          }, 5000);
        }
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchRecordings(limit = 25, offset = 0, summaries = true) {
      this.loading = true;

      try {
        const response = await DistributionService.getVrdb2Recordings(
          {
            played_recordings: this.playedRecordings,
            playlist: this.playlist,
            type: this.type,
            limit,
            offset,
          },
          summaries,
        );

        this.recordings = response.items;
        this.totalRecordings = response.total_count;

        if (summaries) {
          const summaries = response.summaries
            .filter(
              (s) =>
                (s.type === 'PR' && this.playedRecordings) || (s.type === 'UPL' && this.playlist),
            )
            .map((s) => ({ ...s, total: s.weight_1 + s.weight_2 + s.weight_3 }));

          const { weight_1, weight_2, weight_3, total } = summaries.reduce(
            (acc, s) => ({
              weight_1: acc.weight_1 + s.weight_1,
              weight_2: acc.weight_2 + s.weight_2,
              weight_3: acc.weight_3 + s.weight_3,
              total: acc.total + s.weight_1 + s.weight_2 + s.weight_3,
            }),
            { weight_1: 0, weight_2: 0, weight_3: 0, total: 0 },
          );

          this.summaries = [...summaries, { type: 'Total*', weight_1, weight_2, weight_3, total }];
        }
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async transferToVrdb() {
      const processId = uuid.v4();
      this.loading = true;

      try {
        this.recordings = [];

        this.$store.dispatch('process/addProcess', {
          name: `Transfer VRDB playlist`,
          id: processId,
        });

        const header = await DistributionService.uploadVrdb2Files({
          played_recordings: this.playedRecordings,
          playlist: this.playlist,
          type: this.type,
          process_id: processId,
        });

        this.transferred = [header, ...this.transferred];
        this.timeoutHandle = setTimeout(() => {
          this.timeoutHandle = null;
          this.fetchPlaylistFiles();
        }, 5000);
      } catch (error) {
        this.$store.dispatch('process/removeProcess', processId);
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    getStateClass(state) {
      switch (state) {
        case 'N':
        case 'P':
          return 'report__headers-chip--wait';
        case 'F':
          return 'report__headers-chip--ok';
        case 'E':
          return 'report__headers-chip--fail';
        default:
          return 'report__headers-chip--remove';
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
