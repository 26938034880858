<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s6">
        <Spinner />
      </div>
    </div>
    <div v-else-if="id" class="row">
      <div class="col s10">
        <div class="row">
          <h1>Role Codes for {{ basicInfoName }}</h1>
        </div>
        <div class="row">
          <h2>Conversion from SAMI to {{ basicInfoName }}</h2>
        </div>
        <div class="row card">
          <table>
            <thead>
              <tr>
                <th>SAMI Role Codes</th>
                <th>{{ basicInfoName }} Role Codes</th>
                <th />
                <th class="thin-column" />
              </tr>
            </thead>
            <tbody>
              <tr v-for="samiRole in samiRoleCodes" :key="samiRole.code" class="line">
                <td class="width-20">
                  {{ samiRole.name }}
                </td>
                <template v-if="editMode === buildID('incoming', samiRole)">
                  <td class="width-20">
                    <input
                      v-model="roleCodes.incoming[samiRole.code]"
                      v-validate="'required'"
                      :name="buildID('incoming', samiRole)"
                      type="text"
                      autocomplete="off"
                      data-vv-as="input"
                      @keyup.enter="saveChanges"
                      @keyup.esc="abortEdit"
                    />
                    <span
                      v-show="validationErrors.has('incoming', buildID('incoming', samiRole))"
                      class="help is-danger"
                    >
                      Required
                    </span>
                  </td>
                  <td colspan="2" class="float-right">
                    <action-buttons @save="saveChanges" @cancel="abortEdit" />
                  </td>
                </template>
                <template v-else>
                  <td>
                    {{ roleCodes.incoming[samiRole.code] }}
                  </td>
                  <td />
                  <td>
                    <context-menu
                      :options="['Edit', 'Delete']"
                      :disabled-options="!roleCodes.incoming[samiRole.code] ? ['Delete'] : []"
                      @edit="handleEditLinkClick(buildID('incoming', samiRole))"
                      @delete="handleDeleteIncomingClick(samiRole.code)"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="row">
            <h2>Conversion from {{ basicInfoName }} to SAMI</h2>
          </div>
          <div class="row card">
            <table>
              <thead>
                <tr>
                  <th>{{ basicInfoName }} Role Codes</th>
                  <th>Type</th>
                  <th>SAMI Role Codes</th>
                  <th />
                  <th class="thin-column" />
                </tr>
              </thead>
              <tbody>
                <template v-for="societyRole in Object.keys(roleCodes.outgoing)" class="line">
                  <tr
                    v-for="(rc, index) in roleCodes.outgoing[societyRole]"
                    :key="`${index}-${societyRole}`"
                  >
                    <template v-if="editMode === buildID('outgoing', societyRole, index)">
                      <td class="width-20">
                        {{ societyRole }}
                      </td>
                      <td>
                        <select
                          v-model="roleCodes.outgoing[societyRole][index].type"
                          v-validate="
                            editRoleCodeRules(societyRole, roleCodes.outgoing[societyRole][index])
                          "
                          :name="`${buildID('outgoing', societyRole, index)}-type`"
                        >
                          <option value="ALL" />
                          <option value="FA">FA</option>
                          <option value="NF">NF</option>
                        </select>
                        <span
                          v-show="
                            validationErrors.has(`${buildID('outgoing', societyRole, index)}-type`)
                          "
                          class="help is-danger"
                        >
                          <i class="fas fa-times-circle" />
                          {{
                            validationErrors.first(
                              `${buildID('outgoing', societyRole, index)}-type`,
                            )
                          }}
                        </span>
                      </td>
                      <td class="width-20">
                        <select
                          v-model="roleCodes.outgoing[societyRole][index].id"
                          v-validate="'required'"
                          :name="`${buildID('outgoing', societyRole, index)}-id`"
                          data-vv-as="select"
                          @keyup.enter="saveChanges"
                          @keyup.esc="abortEdit"
                        >
                          <option value="">- Select one -</option>
                          <option
                            v-for="samiRole in samiRoleCodes"
                            :key="samiRole.code"
                            :value="samiRole.code"
                          >
                            {{ samiRole.name }}
                          </option>
                        </select>
                        <span
                          v-show="
                            validationErrors.has('outgoing', buildID('outgoing', societyRole))
                          "
                          class="help is-danger"
                        >
                          Required
                        </span>
                      </td>
                      <td colspan="2">
                        <action-buttons @save="saveChanges" @cancel="abortEdit" />
                      </td>
                    </template>
                    <template v-else>
                      <td>{{ societyRole }}</td>
                      <td>
                        <span v-if="rc.type">{{ rc.type }}</span>
                      </td>
                      <td>{{ getNameByCode(rc.id) }}</td>
                      <td />
                      <td>
                        <context-menu
                          :options="['Edit', 'Delete']"
                          @edit="handleEditOutgoingLinkClick(societyRole, index)"
                          @delete="handleDeleteOutgoingClick(societyRole, index)"
                        />
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-if="editMode === addNewMode" class="input-row">
                  <td>
                    <input
                      v-model="newSocietyRole.code"
                      v-validate="addRoleCodeRules('code', newSocietyRole)"
                      name="newSocietyRoleCode"
                      type="text"
                      autocomplete="off"
                      data-vv-as="role code"
                    />
                    <span
                      v-show="
                        validationErrors.has('newSocietyRoleCode') ||
                        validationErrors.has('newSocietyRoleCodeTypeMapping')
                      "
                      class="help is-danger"
                    >
                      <i class="fas fa-times-circle" />
                      <template v-if="validationErrors.first('newSocietyRoleCode')">
                        {{ validationErrors.first('newSocietyRoleCode') }}
                      </template>
                      <template v-else>
                        {{ validationErrors.first('newSocietyRoleCodeTypeMapping') }}
                      </template>
                    </span>
                  </td>
                  <td>
                    <select
                      v-model="newSocietyRole.type"
                      v-validate="addRoleCodeRules('type', newSocietyRole)"
                      name="newSocietyRoleCodeTypeMapping"
                    >
                      <option value="ALL" />
                      <option value="FA">FA</option>
                      <option value="NF">NF</option>
                    </select>
                    <span
                      v-show="
                        validationErrors.has('newSocietyRoleCode') ||
                        validationErrors.has('newSocietyRoleCodeTypeMapping')
                      "
                      class="help is-danger"
                    >
                      <i class="fas fa-times-circle" />
                      <template v-if="validationErrors.first('newSocietyRoleCode')">
                        {{ validationErrors.first('newSocietyRoleCode') }}
                      </template>
                      <template v-else>
                        {{ validationErrors.first('newSocietyRoleCodeTypeMapping') }}
                      </template>
                    </span>
                  </td>
                  <td>
                    <select
                      v-model="newSocietyRole.id"
                      v-validate="'required'"
                      name="newSocietyRoleCodeMapping"
                      data-vv-as="select"
                      @keyup.enter="saveNewOutgoingRoleCode"
                    >
                      <option value="">- Select one -</option>
                      <option
                        v-for="samiRole in samiRoleCodes"
                        :key="samiRole.code"
                        :value="samiRole.code"
                      >
                        {{ samiRole.name }}
                      </option>
                    </select>
                    <span
                      v-show="validationErrors.has('newSocietyRoleCodeMapping')"
                      class="help is-danger"
                    >
                      Required
                    </span>
                  </td>
                  <td>
                    <action-buttons
                      :disable-submit="
                        validationErrors.has('newSocietyRoleCode') &&
                        validationErrors.has('newSocietyRoleCodeMapping')
                      "
                      @save="saveNewOutgoingRoleCode"
                      @cancel="abortEdit"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="editMode !== addNewMode" class="edit-link">
          <a @click="handleAddNewClick">
            <i class="far fa-plus-square" />
            Add role code
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import ContextMenu from '../../ui/context-menu';
import SocietyService from '../../../services/societyService';
import Spinner from '../../spinner';
import ViewSocietyMixin from './view-society-mixin';

export default {
  name: 'SocietyRoleCodes',
  components: {
    ActionButtons,
    ContextMenu,
    Spinner,
  },
  mixins: [ViewSocietyMixin],
  inject: ['$validator'],
  data() {
    return {
      editMode: false,
      addNewMode: Symbol('addNewMode'),
      error: false,
      loading: true,
      newSocietyRole: {
        code: '',
        type: '',
        id: '',
      },
      savedState: {},
      savedStateEditData: {},
      columns: [
        {
          name: 'SAMI Role Codes',
          active: true,
        },
        {
          name: `${this.basicInfoName} Role Codes`,
          active: false,
        },
      ],
      optionsVisibleId: '',
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'aggregate', 'basicInfoName', 'roleCodes']),
    samiRoleCodes() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
  },
  created() {
    if (this.id !== this.$router.currentRoute.params.id) {
      this.fetchData(this.$router.currentRoute.params.id);
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations('society', ['updateRoleCodes']),
    editRoleCodeRules(code, newMapping) {
      const existingMappings = this.savedState.outgoing;
      const mappingsToCompare = _.clone(existingMappings);

      const newMappingToValidate = _.clone(newMapping);
      newMappingToValidate.code = code;

      mappingsToCompare[code] = existingMappings[code].filter(
        (mapping) => mapping.code !== code && mapping.type !== this.savedStateEditData.type,
      );

      return {
        uniqueRoleCode: { existingMappings: mappingsToCompare, newMapping: newMappingToValidate },
      };
    },
    addRoleCodeRules(field, newMapping) {
      return {
        required: field === 'code' ? newMapping.type === '' : newMapping.code === '',
        uniqueRoleCode: { existingMappings: this.roleCodes.outgoing, newMapping },
      };
    },
    buildID(type, o, index = 0) {
      return `${(o.code ? o.code : o).toLowerCase().replace(/\s\//g, '')}_${type}_${index}`;
    },
    getNameByCode(code) {
      const samiRoleCode = this.samiRoleCodes.find((role) => role.code === code);
      return samiRoleCode ? samiRoleCode.name : code;
    },
    handleEditLinkClick(str) {
      this.savedState = _.cloneDeep(this.roleCodes);
      this.editMode = str;
    },
    handleEditOutgoingLinkClick(societyRole, idx) {
      this.savedState = _.cloneDeep(this.roleCodes);
      this.savedStateEditData = {
        code: societyRole,
        type: this.roleCodes.outgoing[societyRole][idx].type,
      };
      this.editMode = this.buildID('outgoing', societyRole, idx);
    },
    handleDeleteIncomingClick(key) {
      if (!this.roleCodes.incoming[key]) {
        return;
      }
      delete this.roleCodes.incoming[key];
      this.trimRoleCodes();
      this.saveChanges();
    },
    handleDeleteOutgoingClick(key, idx) {
      if (!this.roleCodes.outgoing[key]) {
        return;
      }
      if (this.roleCodes.outgoing[key].length === 1) {
        delete this.roleCodes.outgoing[key];
      } else {
        this.roleCodes.outgoing[key].splice(idx, 1);
      }
      this.trimRoleCodes();
      this.saveChanges();
    },
    saveChanges() {
      this.$nextTick(() => {
        this.$validator.validateAll();
      });
      SocietyService.updateRoleCodes(this.aggregate, this.roleCodes)
        .then(() => {
          this.optionsVisibleId = '';
          this.editMode = false;
          this.fetchData(this.id);
        })
        .catch((error) => {
          error.title = 'Could not save';
          this.$addStarError(error);
        });
    },
    abortEdit() {
      this.editMode = false;
      this.updateRoleCodes(this.savedState);
      this.trimRoleCodes();
    },
    handleAddNewClick() {
      this.savedState = _.cloneDeep(this.roleCodes);
      this.newSocietyRole.code = '';
      this.newSocietyRole.type = '';
      this.newSocietyRole.id = '';
      this.editMode = this.addNewMode;
    },
    saveNewOutgoingRoleCode() {
      this.$validator.validate();
      if (this.validationErrors.any()) {
        return;
      }
      const newMapping = {
        id: this.newSocietyRole.id,
        type: this.newSocietyRole.type,
      };
      const code = this.newSocietyRole.code ? this.newSocietyRole.code : this.newSocietyRole.type;
      // snygga till
      if (this.roleCodes.outgoing[code]) {
        this.roleCodes.outgoing[code].push(newMapping);
      } else {
        this.roleCodes.outgoing[code] = [newMapping];
      }

      this.saveChanges();
      this.editMode = false;
    },
    trimRoleCodes() {
      if (!this.roleCodes.incoming) {
        this.roleCodes.incoming = {};
      }
      if (!this.roleCodes.outgoing) {
        this.roleCodes.outgoing = {};
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.input-row {
  td {
    width: 10px;
  }
}

.line {
  border-bottom: solid 1px lightgray;
}
</style>
