<template>
  <div>
    <template v-if="!isActive || single">
      <slot name="view" :activator="onActivate" :editIsActive="isActive"></slot>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    single: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    isActive: false,
  }),

  methods: {
    onActivate() {
      this.$parent.onPairEdit(this.id);
    },
  },
};
</script>
