import { render, staticRenderFns } from "./merge-local-codes.vue?vue&type=template&id=6c49af10&scoped=true&"
import script from "./merge-local-codes.vue?vue&type=script&lang=js&"
export * from "./merge-local-codes.vue?vue&type=script&lang=js&"
import style0 from "./merge-local-codes.vue?vue&type=style&index=0&id=6c49af10&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c49af10",
  null
  
)

export default component.exports