<template>
  <div
    :class="{ 'main-nav': true, 'main-nav--active': navigationActive, 'main-nav--fixed': fixed }"
    @mouseenter="navigationActive = true"
    @mouseleave="navigationActive = false"
  >
    <ul class="main-nav__items">
      <template v-for="navItem in navItems">
        <template v-if="hasAccess(navItem)">
          <template v-if="navItem.subMenuIcon">
            <li
              :key="navItem.id"
              class="main-nav__item main-nav__item--list"
              :class="{ 'is-open': navItem.isOpen }"
              :style="[
                navItem.activeNested ? { overflow: 'visible', color: '#ffdd35' } : '',
                navItem.isOpen ? '' : { overflow: 'hidden' },
                { 'max-height': navItem.isOpen ? `${elHeight}px` : '56px' },
              ]"
              @click="setHeight"
            >
              <div class="main-nav__item-link">
                <span class="main-nav__item-text">
                  <i class="fas" :class="navItem.icon"></i>
                  {{ navItem.title }}
                </span>

                <div class="main-nav__more" @click="open(navItem)">
                  <i class="fas" :class="navItem.subMenuIcon"></i>
                </div>
              </div>

              <ul
                class="main-nav__item-list"
                :class="{ 'main-nav__item-list--hide': !navigationActive && !fixed }"
                :show="navigationActive && navItem.isOpen"
              >
                <template v-for="nestedLink in navItem.links">
                  <li
                    v-if="
                      hasAccess(nestedLink) &&
                      (!nestedLink.developerOnly || isDeveloper) &&
                      (!nestedLink.testingOnly || isTestEnvironment)
                    "
                    :key="nestedLink.id"
                    class="main-nav__item-list-content"
                    @click="toggleOverflow($event)"
                  >
                    <router-link :to="nestedLink.link">
                      {{ nestedLink.title }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </li>
          </template>
          <template v-else>
            <template v-if="navItem.title === 'My Dashboard'">
              <li
                :key="navItem.id"
                class="main-nav__item"
                :style="[navItem.activeNested ? { overflow: 'visible' } : { overflow: 'hidden' }]"
                @click="toggleOverflow($event, closeNestedMenus)"
              >
                <div class="main-nav__item-link">
                  <router-link
                    :to="navItem.link"
                    exact
                    :style="[navItem.activeNested ? { color: '#ffdd35' } : null]"
                  >
                    <span class="main-nav__item-text">
                      <i class="fas" :class="navItem.icon"></i>
                      {{ navItem.title }}
                    </span>
                  </router-link>
                </div>
              </li>
            </template>

            <template v-else>
              <li
                :key="navItem.id"
                class="main-nav__item"
                :style="[
                  route == navItem.link || navItem.activeNested
                    ? { overflow: 'visible' }
                    : { overflow: 'hidden' },
                ]"
                @click="toggleOverflow($event, closeNestedMenus)"
              >
                <div class="main-nav__item-link">
                  <router-link :to="navItem.link">
                    <span class="main-nav__item-text">
                      <i class="fas" :class="navItem.icon"></i>
                      {{ navItem.title }}
                    </span>
                  </router-link>
                </div>
              </li>
            </template>
          </template>
        </template>
      </template>
    </ul>
    <div class="arrow" @click="fixSidebar">
      <i class="far fa-arrow-alt-circle-right"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { userHasAccessToRoute } from '@/router/auth';
import { getConfigValue, PROPS } from '@/services/configService';

export default {
  name: 'SideNav',
  props: {
    route: { type: String },
  },
  data() {
    return {
      selectedMenuItem: 'dashboard',
      navigationActive: false,
      fixed: false,
      elHeight: 0,
      navItems: [
        {
          id: 'dashboard',
          title: 'My Dashboard',
          link: '/',
          icon: 'fa-tachometer-alt',
          activeNested: false,
          links: [
            {
              link: '/assigned-worklists',
            },
            {
              link: '/personal-worklist',
            },
            {
              link: '/all-worklists',
            },
          ],
        },
        {
          id: 'search',
          title: 'Search',
          link: '/search/everything',
          icon: 'fa-search',
          activeNested: false,
          links: [
            {
              link: '/search/everything',
            },
            {
              link: '/search/recordings',
            },
            {
              link: '/search/albums',
            },
            {
              link: '/search/performers',
            },
            {
              link: '/search/mainartists',
            },
            {
              link: '/search/associates',
            },
            {
              link: '/search/societies',
            },
          ],
        },
        {
          id: 'catalogue',
          title: 'Catalogue',
          icon: 'fa-book',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'performer',
              title: 'Create Performer',
              link: '/performers/create',
            },
            {
              id: 'recording',
              title: 'Create Recording',
              link: '/recordings/create',
            },
            {
              id: 'album',
              title: 'Create Album',
              link: '/albums/create',
            },
            {
              id: 'main',
              title: 'Create Main Artist',
              link: '/mainartists/create',
            },
            {
              id: 'associate',
              title: 'Create Associate',
              link: '/associates/create',
            },
            {
              id: 'society',
              title: 'Create Society',
              link: '/societies/create',
            },
            {
              id: 'duplicates',
              title: 'Duplicates',
              link: '/merge/duplicates',
            },
          ],
        },
        {
          id: 'music',
          title: 'Music Reporting',
          icon: 'fa-music',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'music-reports-deprecated',
              title: 'Music Reports',
              link: '/music-reports-deprecated',
            },
            {
              id: 'mediarc',
              title: 'Mediarc',
              link: '/mediarc',
            },
            // temporarily disable supprem in prod
            ...(getConfigValue(PROPS.ENVIRONMENT) === 'prod'
              ? []
              : [
                  {
                    id: 'music-reports-supplementary-remuneration',
                    title: 'Supplementary Remuneration',
                    link: '/music-reports/supplementary-remuneration',
                  },
                ]),
          ],
        },
        {
          id: 'distribution',
          title: 'Distribution',
          icon: 'fa-calculator',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'incomplete-recordings',
              title: 'Incomplete Recordings',
              link: '/distribution/incomplete-recordings',
            },
            {
              id: 'protection-changes',
              title: 'Protection Changes',
              link: '/distribution/protection-changes',
            },
            {
              id: 'revenues',
              title: 'Revenues',
              link: '/distribution/revenues/domestic',
            },
            {
              id: 'distribute',
              title: 'Distributions',
              link: '/distribution/distribute',
            },
            {
              id: 'redistribution',
              title: 'Redistribution',
              link: '/distribution/redistribution',
            },
            {
              id: 'payment',
              title: 'Payments',
              link: '/payments',
              icon: 'fa-dollar-sign',
            },
            {
              id: 'reserves',
              title: 'Reserves',
              link: '/reserves/history',
            },
            {
              id: 'distribution-closure',
              title: 'Distribution Closure',
              link: '/distribution/distribution-closures',
            },
          ],
        },
        {
          id: 'integration',
          title: 'International Exchange',
          link: '/integration',
          icon: 'fa-globe',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'ipd',
              title: 'IPD Performer Update',
              link: '/integrations/ipd',
            },
            {
              id: 'ipd-download',
              title: 'IPD Download',
              link: '/integrations/ipd/download',
            },
            {
              id: 'member',
              title: 'Member Files',
              link: '/integrations/member',
            },
            {
              id: 'vrdb',
              title: 'VRDB Repertoire',
              link: '/integrations/vrdb/repertoire',
            },
            {
              id: 'vrdb-dashboard',
              title: 'VRDB Playlist',
              link: '/integrations/vrdb/dashboard',
            },
            {
              id: 'sdeg',
              title: 'SDEG Collecting and Claiming',
              link: '/integrations/sdeg',
            },
            {
              id: 'played-recordings',
              title: 'SDEG Played Recordings',
              link: '/integrations/sdeg-played-recordings',
            },
          ],
        },
        {
          id: 'member-services',
          title: 'Member Services',
          icon: 'fa-users-cog',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'membership',
              title: 'Membership Agreements',
              link: '/membership/agreement',
            },
            {
              id: 'performer-membership-applications',
              title: 'Reg. Membership Applications',
              link: {
                name: 'performerMembershipApplications',
              },
            },
            {
              id: 'voting-rights',
              title: 'Voting Rights',
              link: '/voting-rights',
            },
            {
              id: 'end-of-year-statements',
              title: 'End of Year Statements',
              link: '/end-of-year-statements',
            },
            {
              id: 'us-tax-forms',
              title: 'US Tax Forms',
              link: '/membership/us-tax-forms',
            },
            {
              id: 'deceased-performer',
              title: 'Deceased Performers',
              link: '/membership/deceased-performers',
            },
          ],
        },
        {
          id: 'administration',
          title: 'Administration',
          icon: 'fa-id-card',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          link: '/administration',
          links: [
            {
              id: 'sources',
              title: 'Sources',
              link: '/distribution/sources',
            },
            {
              id: 'channels',
              title: 'Channels',
              link: '/distribution/distribution-channel',
            },
            {
              id: 'distribution-area',
              title: 'Distribution Areas',
              link: '/distribution/distribution-area',
            },
            {
              id: 'finance',
              title: 'Financial Reports',
              link: '/finance/reports',
              icon: 'fa-file-invoice-dollar',
            },
            {
              id: 'countries',
              title: 'Rome Convention & WPPT',
              link: {
                name: 'countries',
              },
            },
          ],
        },
        {
          id: 'system',
          title: 'System',
          icon: 'fa-cog',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          link: '/system',
          links: [
            {
              id: 'projectors',
              title: 'Projectors Progress',
              link: '/projectors',
            },
            {
              id: 'jobs',
              title: 'Jobs',
              link: '/jobs',
            },
            {
              id: 'processes',
              title: 'Processes',
              link: '/processes',
            },
            {
              title: 'Cronjobs',
              link: '/cronjobs',
            },
          ],
        },
        {
          id: 'testing',
          title: 'Testing',
          icon: 'fa-flask',
          activeNested: false,
          isOpen: false,
          subMenuIcon: 'fa-angle-down',
          links: [
            {
              id: 'testing-payment',
              title: 'Payment testing',
              link: { name: 'testing-payment' },
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
  created() {
    this.setActive();
  },
  updated() {
    this.setActive();
  },
  methods: {
    closeNestedMenus() {
      const items = document.querySelectorAll('.main-nav__item--list');
      this.navItems.map((item) => {
        item.isOpen = false;
        return item;
      });
      for (let i = 0; i < items.length; i++) {
        items[i].classList.remove('is-open');
      }
    },
    toggleOverflow(e, fn) {
      const el = e.currentTarget;
      const links = document.querySelectorAll('.main-nav__item');
      localStorage.setItem('activeRoute', this.$route.path);
      if (fn) {
        fn();
      }
      this.navItems.map((item) => {
        if (el.textContent.trim() === item.title) {
          item.activeNested = true;
        } else {
          item.activeNested = false;
        }
        return item;
      });
      for (let i = 0; i < links.length; i++) {
        links[i].style.overflow = 'hidden';
      }
      el.style.overflow = 'visible';
    },
    setHeight(e) {
      const el = e.currentTarget;
      this.elHeight = el.scrollHeight;
    },
    open(navitem) {
      this.navItems.map((item) => {
        if (item.id !== navitem.id) {
          item.isOpen = false;
        }
        return item;
      });
      navitem.isOpen = !navitem.isOpen;
    },
    fixSidebar() {
      const main = document.getElementById('master-content');
      this.fixed = !this.fixed;
      if (this.fixed) {
        main.style.cssText = 'padding-left: 24px; margin-left: 250px;';
      } else {
        main.style.marginLeft = '56px';
      }
    },
    setActive() {
      const activeRoute = localStorage.getItem('activeRoute');
      this.navItems.map((item) => {
        if (item.links) {
          const checkLink = item.links.map((sub) => sub.link).includes(activeRoute);
          if (checkLink) {
            item.activeNested = true;
          }
          return checkLink;
        }
        return item;
      });
    },
    hasAccess(navItem) {
      if (navItem.link == null) {
        if (navItem.links) {
          return navItem.links.some((nestedNavItem) => this.hasAccess(nestedNavItem));
        }

        return true;
      }

      const user = this.user;
      const { route } = this.$router.resolve(navItem.link);

      return userHasAccessToRoute(user, route);
    },
  },
};
</script>

<style lang="scss">
.main-nav {
  width: 56px;
  height: 100%;
  min-height: calc(100vh - 56px);
  position: absolute;
  top: 56px;
  left: 0;
  background-color: var(--grey--darkest);
  color: var(--white);
  z-index: 9002;
  transition: width 0.4s;
  white-space: nowrap;
  border-top: 2px solid var(--white);

  a {
    color: var(--white);
    display: block;
    height: 100%;
    width: 100%;
  }

  &--active {
    width: 250px;

    .arrow {
      .far {
        animation: rotateArrow 400ms forwards !important;
      }
    }
  }

  &--fixed {
    position: fixed;
    width: 250px !important;
    top: 0;
    padding-top: 56px;

    .arrow {
      .far.fa-arrow-alt-circle-right {
        animation: rotateArrow 400ms forwards !important;
      }
    }
  }

  &__items {
    margin: 0;
  }

  &__item {
    max-height: 56px;
    transition: all 400ms ease-in-out;
    position: relative;
    background-color: var(--grey--darkest);
    overflow: hidden;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);
      position: absolute;
      top: 23px;
      right: -5px;
      background: var(--grey--darkest);
      z-index: 1;
    }

    &--list {
      &.is-open {
        animation: delayed 600ms linear forwards;
      }
    }

    &-link,
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      vertical-align: middle;
      height: 56px;
      overflow: hidden;
      z-index: 9999;

      &.router-link-active,
      &.router-link-exact-active {
        color: var(--yellow);
      }
    }

    a {
      font-size: 12px;

      &:hover {
        color: var(--white);
      }
    }

    &-text {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      font-weight: bold;
      font-size: 12px;

      .fas {
        font-size: 18px;
        min-width: 20px;
        text-align: center;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &-list {
      background-color: var(--white);
      max-height: 1%;
      width: 100%;
      padding: var(--spacing-double) 0 var(--spacing-triple);

      &--hide {
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 200ms ease-in-out, max-height 200ms ease-in-out;
        padding: 0;
      }

      &-content {
        height: 34px;
        line-height: 34px;

        &:hover {
          background-color: rgba(59, 59, 59, 0.2);
        }

        a {
          color: var(--text);
          height: 100%;
          padding: 8px 0px 8px 56px;

          &:hover {
            color: var(--text);
          }

          &.router-link-active {
            background-color: rgba(59, 59, 59, 0.2);
            box-shadow: inset 18px -9px 0px -10px var(--yellow);
            color: var(--text);

            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    cursor: pointer;
    background-color: var(--grey--darkest);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .fas {
      font-size: 20px;
      cursor: pointer;
      margin: 0;
    }
  }

  .fas {
    margin: 0px 18px;
  }

  .arrow {
    position: absolute;
    right: -9px;
    bottom: 24px;

    .far {
      cursor: pointer;
      font-size: 18px;
      background: var(--turquoise);
      border-radius: 50%;
      animation: rotateArrowBack 400ms linear forwards;
    }
  }
}

@keyframes delayed {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}

@keyframes rotateArrow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateArrowBack {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
