<template>
  <div class="spinner-container">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background-color: rgba(255, 255, 255, 0.9);

  .spinner-row td & {
    height: 100px;
  }
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.spinner {
  position: absolute;
  top: 38%;
  left: 50%;
  text-indent: -9999em;
  border-top: 10px solid rgba(76, 175, 80, 0.3);
  border-right: 10px solid rgba(76, 175, 80, 0.3);
  border-bottom: 10px solid rgba(76, 175, 80, 0.3);
  border-left: 10px solid var(--green);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
