<template>
  <table>
    <tbody>
      <tr>
        <td class="text--bold">Performance Year</td>
        <td>
          <span v-if="revenue.year">
            {{ revenue.year }}
          </span>
          <span v-else class="none">None</span>
        </td>
        <td class="text--bold">Total amount</td>
        <td>
          <span v-if="revenue.amount">
            {{ revenue.amount | formatAmountThousandSeparator }} SEK
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Payment Date</td>
        <td>
          <span v-if="revenue.payment_date">
            {{ revenue.payment_date | formatDate }}
          </span>
          <span v-else class="none">None</span>
        </td>
        <td class="text--bold">Distributable</td>
        <td>
          <span v-if="revenue.distributable_amount">
            {{ revenue.distributable_amount | formatAmountThousandSeparator }} SEK
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Source</td>
        <td>
          <span v-if="revenue.source">
            {{ revenue.source.name }}
          </span>
          <span v-else class="none">None</span>
        </td>
        <td class="text--bold">Distributed</td>
        <td>
          <span v-if="revenue.distributed_amount">
            {{ revenue.distributed_amount | formatAmountThousandSeparator }} SEK
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Original Amount</td>
        <td>
          <span v-if="revenue.original_amount">
            {{ revenue.original_amount | formatAmountThousandSeparator(revenue.currency || 'SEK') }}
          </span>
          <span v-else class="none">None</span>
        </td>
        <td class="text--bold">Refunded</td>
        <td>
          <span v-if="revenue.refunded_amount">
            {{ revenue.refunded_amount | formatAmountThousandSeparator }} SEK
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Rate</td>
        <td>
          <span v-if="revenue.conversion_rate">
            {{ revenue.conversion_rate | formatDecimals(4) }}
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">
          <label>{{ `common.administrative_cost` | translate }} %</label>
        </td>
        <td>
          <span v-if="revenue.financing_rate">
            {{ revenue.financing_rate | formatDecimals(2) }}%
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">
          <label>{{ `common.administrative_cost` | translate }}</label>
        </td>
        <td>
          <span v-if="revenue.financing_fee">
            {{ revenue.financing_fee | flipSign | formatAmountThousandSeparator }}
            SEK
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Statement</td>
        <td>
          <span v-if="revenue.statement_url">
            <a @click="onDownloadStatement(revenue.statement_url)">
              <i class="far fa-file-alt"></i>
              {{ getFileName(revenue.statement_url) }}
            </a>
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
      <tr>
        <td class="text--bold">Status</td>
        <td>
          {{ $t(`revenue.state.${revenue.state.toLowerCase()}`) }}
        </td>
      </tr>
      <tr>
        <td class="text--bold">Note</td>
        <td>
          <span v-if="revenue.note">
            {{ revenue.note }}
          </span>
          <span v-else class="none">None</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';
import extractRemoteFileName from '../../../common/extractRemoteFileName';

export default {
  name: 'RevenueRowExpanded',
  props: {
    revenue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    async onDownloadStatement(url) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
        DownloadLink.direct(fileData, url);
      } catch (error) {
        this.$addStarError(error);
      }
    },
  },
};
</script>
