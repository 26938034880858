<template>
  <div class="row">
    <div v-if="loading" class="text--center">
      <simple-spinner />
    </div>
    <div v-else>
      <table v-if="remunerations && remunerations.length > 0" class="table__border">
        <thead>
          <th>Source</th>
          <th>Area</th>
          <th>Performance Year</th>
          <th>Category of Rights</th>
          <th>Original Amount</th>
          <th class="text--right">administrative fee</th>
          <th class="text--right">Gross Amount</th>
          <th class="text--right">VAT</th>
          <th class="text--right">Tax</th>
          <th class="text--right">Net Amount</th>
        </thead>
        <tbody>
          <tr v-for="(row, idx) in remunerations" :key="idx">
            <td>
              {{ row.source && row.source.name }}
            </td>
            <td class="fs-14">
              {{ (row.area && row.area.name) || 'N/A' }}
            </td>
            <td>
              {{ row.year }}
            </td>
            <td>
              {{ `mandate.right.${row.right}` | translate }}
            </td>
            <td class="original-amount amount-row">
              {{ row.net - row.fee - row.tax || 0 | formatAmountCent }}
            </td>
            <td class="amount-row">
              {{ row.fee || 0 | formatAmountCent }}
            </td>
            <td class="amount-row">
              {{ (row.net - row.tax || 0) | formatAmountCent }}
            </td>
            <td class="amount-row">
              {{ 0 | formatAmountCent }}
            </td>
            <td class="amount-row">
              {{ row.tax || 0 | formatAmountCent }}
            </td>
            <td class="amount-row">
              {{ row.net || 0 | formatAmountCent }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="isMigratedPayment">
        There are no remunerations to display. If this payment was transferred, plase check the
        performer's payment history instead.
      </div>
      <div v-else>There are no remunerations to display</div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../../services/apolloRequest';
import SimpleSpinner from '../../ui/simple-spinner.vue';
export default {
  name: 'PaymentDetailsOverview',
  components: {
    SimpleSpinner,
  },
  props: {
    nodeId: String,
    specIds: Array,
    paymentId: String,
    isMigratedPayment: Boolean,
  },
  data() {
    return {
      loading: false,
      tx: null,
      remunerations: [],
    };
  },
  async created() {
    this.loading = true;
    if (this.isMigratedPayment) {
      if (!this.paymentId) {
        throw new Error(`missing payment id property`);
      }
      await this.fetchMigratedSpec();
    } else {
      await this.fetchSummary();
    }
    this.loading = false;
  },
  methods: {
    async fetchMigratedSpec() {
      const { migratedPaymentSpec } = await query({
        query: gql`
          query {
            migratedPaymentSpec(nodeId: "${this.nodeId}", paymentId: "${this.paymentId}") {
              year
              area {
                name
                id
              }
              source {
                name
                id
              }
              right
              net
              fee
              tax
            }
          }
        `,
      });
      this.remunerations = migratedPaymentSpec;
    },
    async fetchSummary() {
      try {
        const { specSummary } = await query({
          query: gql`
            query SpecSummary($specIds: [String]!) {
              specSummary(specIds: $specIds) {
                year
                area {
                  name
                  id
                }
                source {
                  name
                  id
                }
                right
                net
                fee
                tax
              }
            }
          `,
          variables: {
            specIds: this.specIds,
          },
        });
        this.remunerations = specSummary;
      } catch (err) {
        console.error(err);
        this.$addStarError(`Could not fetch remunerations`);
      }
    },
  },
};
</script>
