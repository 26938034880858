<template>
  <div class="report__headers">
    <div class="row wrapper">
      <div class="col s12">
        <h1>Music Reports</h1>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Upload a New Music Report</h2>
      </div>
    </div>

    <div class="card">
      <form class="row">
        <div class="file-upload">
          <div class="col s2">
            <select-input
              v-model="uploadFile.source"
              label="Source"
              name="sources"
              :items="sources"
              item-key="code"
              item-value="name"
              translation-prefix="music_report"
              @input="updateLocalCodeType(uploadFile.source)"
            />
          </div>

          <div class="col s2">
            <select-date
              v-model="uploadFile.reportDate"
              name="uploadFile.reportDate"
              label="Usage year"
            />
          </div>
          <div v-if="uploadFile.source === 'dbf'" class="col s2">
            <select-date
              v-model="uploadFile.playDate"
              name="uploadFile.playDate"
              label="Play date"
            />
          </div>

          <div class="col s2">
            <select-input
              v-model="uploadFile.localCodeType"
              name="uploadFile.localCodeType"
              label="Local code"
              :items="localCodeTypes"
              item-key="code"
              item-value="name"
              :disabled="uploadFile.source !== 'csv'"
            />
          </div>

          <div class="col s3">
            <span>File to upload {{ fileType }}</span>
            <div class="file-input nowrap" action="" method="POST">
              <input id="file" type="file" @change="addFile" />

              <span ref="fileUploadName">
                <i class="far fa-file-alt" />
                Drag your files here or click in this area.
              </span>
            </div>
          </div>
        </div>
      </form>

      <div class="row flush">
        <div class="col offset-s8 s3 margin--top">
          <ProgressBar
            v-if="isUploadingFile && !error"
            label="Uploading"
            :total="100"
            :processed="uploadFile.uploadProgress"
            :show-percent="true"
          />
        </div>

        <div class="col s1 text--right">
          <button
            class="btn secondary abs-top"
            type="submit"
            :disabled="!validForm || isUploadingFile"
            @click.prevent="uploadMusicReport"
          >
            Upload
          </button>
        </div>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s10">
        <h2>Available Reports</h2>
      </div>
      <div class="col s2 float-right report__headers-refresh">
        <refresh-button :is-loading="loading" @click="fetchData" />
      </div>
    </div>

    <div class="row">
      <div class="col s3 float-right">
        <filter-input
          :filter-query="filterQuery"
          placeholder="E.g. Reklamradion"
          @input="debouncedSearch"
        />
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <table class="report__headers-table">
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :default-sort-should-override="true"
            @sort="onSort"
          />
          <tbody>
            <template v-if="loading">
              <tr>
                <td colspan="12">
                  <component-spinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="musicReport in reportHeadersResult.items" :key="musicReport.report_id">
                <td v-if="musicReport.report_state !== 'A'">
                  <router-link
                    :to="
                      '/music-reports-deprecated/' +
                      musicReport.report_id +
                      '/matched' +
                      '?album=' +
                      (musicReport.local_code_id === 'LIBRARY' ? 'true' : 'false')
                    "
                  >
                    {{ musicReport.report_name }}
                  </router-link>
                </td>
                <td v-if="musicReport.report_state === 'A'">
                  {{ musicReport.report_name }}
                </td>
                <td>
                  {{ musicReport.date_created | formatDate }}
                </td>
                <td>
                  {{ musicReport.report_date | formatDate }}
                </td>
                <td class="nowrap">
                  {{ `music_report.${musicReport.reporter_id}` | translate }}
                </td>
                <td style="min-width: 140px">
                  <span
                    class="chip float-left"
                    :class="getMatchStateClass(musicReport.report_state)"
                    :title="musicReport.error_message"
                  >
                    {{ musicReport.report_state | formatMatchState }}
                  </span>
                  <span
                    v-if="
                      isWaitState(musicReport.report_state) &&
                      isWaitingOverdue(musicReport.date_created)
                    "
                    class="alert-container"
                    title="Contact support. Not completed after 30 minutes of processing."
                  >
                    <i class="fas fa-stopwatch error" />
                  </span>
                  <div v-else class="small active alert-container">
                    <div class="spinner-layer spinner-green-only">
                      <div class="circle-clipper left">
                        <div class="circle" />
                      </div>
                      <div class="gap-patch">
                        <div class="circle" />
                      </div>
                      <div class="circle-clipper float-right">
                        <div class="circle" />
                      </div>
                    </div>
                  </div>
                </td>
                <td class="count count--green">
                  <span>
                    {{ musicReport.prr_match_data.matched_lines | formatNumber }}
                  </span>
                </td>
                <td class="count count--gold">
                  <span>
                    {{ musicReport.prr_match_data.candidate_lines | formatNumber }}
                  </span>
                </td>
                <td class="count count--orange">
                  <span>
                    {{ musicReport.prr_match_data.rested_lines | formatNumber }}
                  </span>
                </td>
                <td class="count count--blue">
                  <span>
                    {{ musicReport.prr_match_data.transferred_lines | formatNumber }}
                  </span>
                </td>
                <td class="count count--grey">
                  <span>
                    {{ musicReport.prr_match_data.error_lines | formatNumber }}
                  </span>
                </td>
                <td>
                  <context-menu
                    :options="[
                      'Download',
                      'Transfer matched airtime to distribution',
                      'Archive',
                      'Delete',
                    ]"
                    :disabled-options="contextMenuDisabledOptions(musicReport)"
                    @delete="deleteReport(musicReport.report_id)"
                    @archive="archiveReport(musicReport.report_id)"
                    @download="downloadReport(musicReport)"
                    @transferMatchedAirtimeToDistribution="
                      sendReportToDistribution(musicReport.report_id)
                    "
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div class="report__headers-pagination">
          <pagination
            v-if="numberOfPages() > 0 && !loading"
            class="result__pagination"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="reportHeadersResult.total_count"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUploadFile } from '../../domain/matchingDomain';
import ContextMenu from '../ui/context-menu';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import ReportHeadersMixin from '../../common/reportHeadersMixin';
import Delay from '../../common/delay';
import DistributionService from '../../services/distributionService';
import DownloadLink from '../ui/file/downloadLink';
import FilterInput from '../ui/input/filter-input';
import MatchingService from '../../services/matchingService';
import RemoteFileHelper from '../../common/remoteFileHelper';
import Pagination from '../pagination';
import RefreshButton from '../ui/button/refresh-button';
import SelectDate from '../ui/select/select-date';
import SelectInput from '../ui/select/select-input';
import SortableHead from '../ui/table/sortable-head';
import ComponentSpinner from '../../components/component-spinner';
import ProgressBar from '../ui/progress-bar';

const reportColumnMappings = [
  {
    name: 'Music Report',
    ascending: true,
    sortParam: 'report_name',
    active: false,
  },
  {
    name: 'Uploaded',
    ascending: false,
    sortParam: 'date_created',
    active: false,
    thinColumn: true,
  },
  {
    name: 'Usage Year',
    ascending: true,
    sortParam: 'report_date',
    active: false,
    thinColumn: true,
  },
  {
    name: 'Source',
    ascending: true,
    sortParam: 'reporter_id',
    active: false,
    thinColumn: true,
  },
  {
    name: 'Status',
    sortParam: 'report_state',
    ascending: true,
    thinColumn: true,
    active: false,
  },
  {
    name: 'Matched',
  },
  {
    name: 'Candidates',
  },
  {
    name: 'Unmatched',
  },
  {
    name: 'Transferred',
  },
  {
    name: 'Errors',
  },
];

export default {
  name: 'ViewMusicReports',
  components: {
    ContextMenu,
    FilterInput,
    Pagination,
    RefreshButton,
    SelectDate,
    SelectInput,
    SortableHead,
    ComponentSpinner,
    ProgressBar,
  },
  mixins: [ReportHeadersMixin, DebouncedSearchMixin(250)],
  data() {
    return {
      columns: reportColumnMappings,
      sources: [
        { name: 'UR', code: 'ur' },
        { name: 'SR', code: 'sr' },
        { name: 'COM', code: 'com' },
        { name: 'IFPI', code: 'ifpi' },
        { name: 'CSV', code: 'csv' },
        { name: 'DBF', code: 'dbf' },
      ],
      uploadFile: createUploadFile(null, 'playlists', '', '', null, 'SR'),
    };
  },
  /** Reload page after upload has finished */
  async isUploadingFile(isUploadingFile, wasUploading) {
    if (wasUploading && !isUploadingFile) {
      await this.fetchData();
    }
  },
  computed: {
    validForm() {
      return (
        this.uploadFile.file &&
        this.uploadFile.source &&
        this.uploadFile.reportDate &&
        (this.uploadFile.source === 'dbf' ? this.uploadFile.playDate : true)
      );
    },
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },
    fileType() {
      switch (this.uploadFile.source) {
        case 'ur':
        case 'sr':
        case 'com':
          return '(txt ISO-8859-1)';
        case 'dbf':
          return '(dbf)';
        case 'csv':
          return '(csv UTF-8)';
        default:
          return '';
      }
    },
  },
  async created() {
    this.readQueryParams();
    await this.fetchData();
  },
  methods: {
    processedPercent(header) {
      const round = (value) => Math.abs(Math.round(value));
      const totalCount =
        header.matched_lines +
        header.candidate_lines +
        header.rested_lines +
        header.transferred_lines +
        header.error_lines;
      return round(Number(header.line_count ? (totalCount / header.line_count) * 100 : 0));
    },
    updateLocalCodeType(source) {
      if (source === 'dbf') {
        this.uploadFile.localCodeType = 'LIBRARY';
      } else if (source === 'ifpi') {
        this.uploadFile.localCodeType = 'IFPI';
      } else if (source !== 'csv' && source !== 'dbf' && source !== 'ifpi') {
        this.uploadFile.localCodeType = 'SR';
      }
    },
    addFile(upload) {
      this.$refs.fileUploadName.innerHTML = upload.target.files[0].name;
      this.uploadFile.file = upload.target.files[0];
      this.updateSource();
      this.updateLocalCodeType(this.uploadFile.source);
    },
    updateSource() {
      if (this.uploadFile) {
        const ext = this.uploadFile.file.name.split('.').slice(-1)[0].toLowerCase();
        switch (ext) {
          case 'fut':
            this.uploadFile.source = 'com';
            break;
          case 'srf':
            this.uploadFile.source = 'sr';
            break;
          case 'csv':
            this.uploadFile.source = 'csv';
            break;
          case 'dbf':
            this.uploadFile.source = 'dbf';
            break;
        }
        if (this.uploadFile.file.name.includes('FUT')) {
          this.uploadFile.source = 'com';
        }
      }
    },
    resetFileUpload() {
      this.isUploadingFile = false;
      this.$refs.fileUploadName.innerHTML = 'Drag your files here or click in this area.';
      this.uploadFile = createUploadFile(null, 'playlists', '', '', '');
      this.error = false;
    },
    async deleteReport(reportId) {
      try {
        MatchingService.deletePlayedRecordingReport(reportId);
        await Delay(2000);
        await this.fetchData();
      } catch (error) {
        this.handleError('Delete failed', error);
      }
    },
    async archiveReport(reportId) {
      try {
        await MatchingService.archiveReportHeader(reportId);
        await Delay(2000);
        await this.fetchData();
      } catch (error) {
        this.handleError('Archive failed', error);
      }
    },
    async downloadReport(musicReport) {
      this.error = false;
      try {
        const fileBlob = await RemoteFileHelper.downloadReportFile(
          'playlists',
          musicReport.reporter_id.toLowerCase(),
          musicReport.report_name,
        );
        DownloadLink.direct(fileBlob.fileData, musicReport.report_name);
      } catch (error) {
        this.handleError('Download failed', error);
      }
    },
    async sendReportToDistribution(reportId) {
      await DistributionService.importAirtime([reportId]);
    },
    async fetchData() {
      this.loading = true;
      this.error = false;
      try {
        this.reportHeadersResult = await MatchingService.listReportHeaders({
          process_type: 'PLAYTIME',
          report_type: 'PRR',
          limit: this.pagination.hitsPerPage,
          offset: this.getOffset(),
          filter: this.filterQuery,
          sort_by: this.sortTerms.param,
          sort_order: this.sortTerms.order,
        });
        this.loading = false;

        const autoReload = this.reportHeadersResult.items.some(
          (musicReport) =>
            this.isWaitState(musicReport.report_state) &&
            !this.isWaitingOverdue(musicReport.date_created),
        );
        if (autoReload && this.timeoutHandle == null) {
          this.timeoutHandle = setTimeout(() => {
            this.timeoutHandle = null;
            this.fetchData();
          }, 20000);
        }
      } catch (error) {
        this.handleError('Error fetching music report headers', error);
      }
    },
    uploadMusicReport() {
      this.upload();
      setTimeout(() => {
        this.fetchData();
      }, 3000);
    },
    contextMenuDisabledOptions(musicReport) {
      switch (musicReport.report_state) {
        case 'MF': {
          return ['Download'];
        }
        case 'A': {
          return ['Archive', 'Transfer matched airtime to distribution'];
        }
        default: {
          return [];
        }
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.upload-progress {
  max-height: 40px;
}
</style>
