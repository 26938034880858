<template>
  <tbody>
    <template v-for="(line, index) in reportLines">
      <tr
        ref="line"
        :key="`${index}-tr1`"
        :class="{ 'cursor-pointer': expandEnabled }"
        @click="expandEnabled && toggleExpanded(line.incoming.group_id)"
      >
        <td v-if="expandEnabled" class="compare__arrow">
          <i :class="`fas fa-caret-${isExpanded(line.incoming.group_id) ? 'up' : 'down'}`" />
        </td>

        <td v-if="selectable" class="compare__checkbox">
          <input
            :id="index"
            ref="mark"
            v-model="line.checked"
            class="filled-in"
            type="checkbox"
            name="mark"
            :disabled="line.completed"
            @click.stop
          />
          <label :for="index" @click.stop />
        </td>

        <td
          class="fs-14"
          :class="{
            'text--orange': line.completed && ['I', 'R'].includes(line.incoming.match_state),
            'text--green': line.completed && !['I', 'R'].includes(line.incoming.match_state),
          }"
        >
          <span v-html="line.incoming.title" />
          <i v-if="line.completed" class="fas fa-check-circle" />
        </td>

        <template v-if="!isExpanded(line.incoming.group_id)">
          <td>
            {{ line.incoming.main_artist }}
          </td>
          <td>
            {{ line.incoming.sr_code }}
          </td>
        </template>

        <template v-if="!isExpanded(line.incoming.group_id) && !album">
          <td>
            {{ line.incoming.recording_date }}
          </td>
          <td>
            {{ line.incoming.recorded_in_country | formatCountry }}
          </td>
          <td>
            {{ line.incoming.label }}
          </td>
          <td v-if="line.incoming.duration_sec > 0">
            {{ line.incoming.duration_sec | toMinutes }}
          </td>
        </template>

        <template v-if="!isExpanded(line.incoming.group_id) && album">
          <td>
            {{ line.incoming.label }}
          </td>
          <td>
            {{ line.incoming.release_date }}
          </td>
        </template>

        <td v-else colspan="1000" />
      </tr>

      <template v-if="isExpanded(line.incoming.group_id) && !album">
        <tr :key="`${index}-tr2`" class="prevent-hover-bg no-borders">
          <td colspan="1000">
            <div v-if="loading" class="compare__loading">
              <spinner />
            </div>
            <div v-else class="compare__nested">
              <div class="compare__nested-main compare__main-divide">
                <span class="compare__nested-heading compare__nested-heading--incoming">
                  <strong>Mediarc recording:</strong>
                  <span v-html="line.incoming.title" />
                </span>

                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion:</strong>
                  <a :href="`#/recordings/${getCandidateValue('id')}`">
                    {{ getCandidateValue('basic_info.name') }}
                  </a>
                </span>

                <ul class="compare__nested-list">
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.alternate_titles',
                      value: candidateAllTitles,
                      displayFilter: 'prettyPrintArray',
                    }"
                    :incoming="{
                      key: 'title',
                      value: line.incoming.title,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.version_title',
                      value: getCandidateValue('basic_info.version_title'),
                    }"
                    :incoming="{
                      key: 'basic_info.version_title',
                      value: false,
                    }"
                    :show-left-side="false"
                    forced-status="ok"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.main_artist.name',
                      value: getCandidateValue('basic_info.main_artist.name'),
                    }"
                    :incoming="{
                      key: 'main_artist',
                      value: line.incoming.main_artist,
                    }"
                    :original-value="getCandidateValue('basic_info.main_artist')"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.duration_sec',
                      value: getCandidateValue('basic_info.duration_sec'),
                      displayFilter: 'toMinutes',
                    }"
                    :incoming="{
                      key: 'duration_sec',
                      value: line.incoming.duration_sec,
                      displayFilter: 'toMinutes',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'albums',
                      value: getCandidateValue('albums'),
                      displayFilter: 'prettyPrintAlbumArray',
                    }"
                    :incoming="{
                      key: 'album',
                      value: line.incoming.album,
                      displayFilter: 'prettyPrintAlbumObject',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'album_local_code',
                      value: getCandidateValue('albums')
                        .flatMap((album) => album.local_codes)
                        .filter((lc) => lc.code === 'SR'),
                      displayFilter: 'prettyPrintCodeValueObject',
                    }"
                    :incoming="{
                      key: 'album_local_code',
                      value: line.incoming.album && [
                        `SR:${
                          line.incoming.album.local_codes.find((lc) => lc.code === 'SR').value
                        }`,
                      ],
                      displayFilter: 'prettyPrintArray',
                    }"
                    forced-status="idle"
                    :allow-action="false"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.recording_date',
                      value: getCandidateValue('basic_info.recording_date'),
                      displayFilter: 'formatRecordingDate',
                    }"
                    :incoming="{
                      key: 'recording_date',
                      value: line.incoming.recording_date,
                    }"
                    :comparator="'yearsMatch'"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.recorded_in_country',
                      value: getCandidateValue('basic_info.recorded_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'recording_country',
                      value: line.incoming.recorded_in_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.produced_in_country',
                      value: getCandidateValue('basic_info.produced_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'produced_in_country',
                      value: line.incoming.produced_in_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.label',
                      value: getCandidateValue('basic_info.label'),
                      displayFilter: 'toTitleCase',
                    }"
                    :incoming="{
                      key: 'label',
                      value: line.incoming.label,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.genre',
                      value: getCandidateValue('basic_info.genre'),
                      displayFilter: 'formatGenre',
                    }"
                    :incoming="{
                      key: 'genre',
                      value: line.incoming.genre,
                      displayFilter: 'formatGenre',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.composer',
                      value: getCandidateValue('basic_info.composer'),
                      displayFilter: 'prettyPrintNames',
                    }"
                    :incoming="{
                      key: 'composer',
                      value: line.incoming.composer,
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.writer',
                      value: getCandidateValue('basic_info.writer'),
                    }"
                    :incoming="{
                      key: 'writer',
                      value: line.incoming.writer,
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.arranger',
                      value: getCandidateValue('basic_info.arranger'),
                    }"
                    :incoming="{
                      key: 'arranger',
                      value: line.incoming.arranger,
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.local_codes_label',
                      value: getCandidateValue('basic_info.local_codes'),
                      displayFilter: 'prettyPrintCodeValueObject',
                    }"
                    :incoming="{
                      key: 'basic_info.local_codes_label',
                      value: [`SR:${line.incoming.sr_code}`],
                      displayFilter: 'prettyPrintArray',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />
                </ul>
              </div>

              <CompareButtonsMediarc
                v-if="allowAction && !line.completed"
                :candidate-changed="candidateChanged"
                :handle-new-btn="handleNewBtn"
                :handle-save-btn="handleSaveBtn"
                :handle-unidentify-btn="handleUnidentifyBtn"
                :is-album="album"
                :line="line"
              />
            </div>
          </td>
        </tr>
      </template>

      <template v-if="isExpanded(line.incoming.group_id) && album">
        <tr :key="`${index}-tr2`" class="prevent-hover-bg no-borders">
          <td colspan="1000">
            <div v-if="loading" class="compare__loading">
              <spinner />
            </div>
            <div v-else class="compare__nested">
              <div class="compare__nested-main compare__main-divide">
                <span class="compare__nested-heading compare__nested-heading--incoming">
                  <strong>Mediarc album:</strong>
                  <span v-html="line.incoming.title" />
                </span>

                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion:</strong>
                  <a :href="`#/albums/${getCandidateValue('id')}`">
                    {{ getCandidateValue('basic_info.name') }}
                  </a>
                </span>

                <ul class="compare__nested-list">
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.name',
                      value: getCandidateValue('basic_info.name'),
                    }"
                    :incoming="{
                      key: 'name',
                      value: line.incoming.title,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.main_artist.name',
                      value: getCandidateValue('basic_info.main_artist.name'),
                    }"
                    :incoming="{
                      key: 'main_artist',
                      value: line.incoming.main_artist,
                    }"
                    :original-value="getCandidateValue('basic_info.main_artist')"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.label',
                      value: getCandidateValue('basic_info.label'),
                      displayFilter: 'toTitleCase',
                    }"
                    :incoming="{
                      key: 'label',
                      value: line.incoming.label,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.release_date',
                      value: getCandidateValue('basic_info.release_date'),
                    }"
                    :incoming="{
                      key: 'basic_info.release_date',
                      value: line.incoming.release_date,
                    }"
                    :comparator="'yearsMatch'"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.no_tracks',
                      value: getCandidateValue('tracks').length,
                    }"
                    :incoming="{
                      key: 'no_tracks',
                      value: line.incoming.number_of_tracks,
                    }"
                    :allow-action="false"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.catalog_number',
                      value: getCandidateValue('basic_info.catalog_number'),
                    }"
                    :incoming="{
                      key: 'catalog_number',
                      value: line.incoming.catalog_number,
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.local_codes_label',
                      value: getCandidateValue('basic_info.local_codes'),
                      displayFilter: 'prettyPrintCodeValueObject',
                    }"
                    :incoming="{
                      key: 'basic_info.local_codes_label',
                      value: [`SR:${line.incoming.sr_code}`],
                      displayFilter: 'prettyPrintArray',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :auto-action="true"
                    @valueUpdated="candidateValueUpdated"
                  />
                </ul>
              </div>

              <CompareButtonsMediarc
                v-if="allowAction && !line.completed"
                :candidate-changed="candidateChanged"
                :handle-new-btn="handleNewBtn"
                :handle-save-btn="handleSaveBtn"
                :handle-unidentify-btn="handleUnidentifyBtn"
                :is-album="album"
                :line="line"
              />
            </div>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import Spinner from '../spinner';
import CompareGridRow from './compare-grid-row';
import CompareButtonsMediarc from './compare-buttons-mediarc';
import * as TypeUtils from '../../common/typeUtils';

export default {
  name: 'CompareMediarcMatching',
  components: {
    Spinner,
    CompareGridRow,
    CompareButtonsMediarc,
  },
  props: {
    allowAction: {
      type: Boolean,
      default: true,
    },
    candidateChanged: {
      type: Function,
    },
    handleUnidentifyBtn: {
      type: Function,
    },
    album: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Function,
    },
    toggleExpanded: {
      type: Function,
    },
    handleSaveBtn: {
      type: Function,
    },
    handleNewBtn: {
      type: Function,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    expandEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('matching', ['candidates', 'currentIncoming', 'currentCandidate', 'reportLines']),
    loading() {
      return !(this.currentIncoming && this.currentCandidate && this.candidates);
    },
    candidateAllTitles() {
      if (!this.getCandidateValue('basic_info.name')) {
        return null;
      }
      return [
        this.getCandidateValue('basic_info.name'),
        ...this.getCandidateValue('basic_info.alternate_titles'),
      ];
    },
  },
  methods: {
    ...mapMutations('matching', ['updateCurrentCandidate']),
    getCandidateValue(path) {
      return _.get(this.currentCandidate, path.split('.')) || '';
    },
    candidateValueUpdated(obj) {
      let mutationPath = TypeUtils.camelCase(obj.data.key.replace(/\./g, '_'), true);
      if (obj.data.key === 'basic_info.main_artist.name') {
        mutationPath = mutationPath.replace(/Name$/g, '');
        let value = obj.data.value;
        if (!value.name) {
          value = { name: obj.data.value };
        }
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, value);
        // detta borde kunna tas bort om vi tar bort add-knappen från gränssnittet
      } else if (obj.data.key === 'basic_info.local_codes_label') {
        let updateVal = [];
        if (obj.revert) {
          updateVal = obj.data.value;
        } else {
          const addLc = obj.data.value.map((v) => ({
            code: v.split(':')[0],
            value: v.split(':')[1],
          }));
          const existingLc = this.getCandidateValue('basic_info.local_codes');
          addLc.forEach((lc) => {
            if (!existingLc.find((eLc) => eLc.code === lc.code && eLc.value === lc.value)) {
              updateVal.push(lc);
            }
          });
          updateVal.push(...existingLc);
        }
        this.$store.commit('matching/updateCurrentCandidateBasicInfoLocalCodes', updateVal);
        // hit
      } else {
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, obj.data.value);
      }
      this.$nextTick(() => {
        this.$emit('change', this.currentIncoming.group_id);
      });
    },
  },
};
</script>
