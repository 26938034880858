<template>
  <div>
    <div class="row">
      <div class="col s8">
        <input
          id="domestic"
          v-model="activeCategory"
          type="radio"
          value="domestic"
          name="revenue.domestic"
          @change="setActiveCategory(category.DOMESTIC)"
        />
        <label for="domestic">Domestic Sources</label>
        <input
          id="international"
          v-model="activeCategory"
          type="radio"
          value="international"
          name="revenue.international"
          @change="setActiveCategory(category.INTERNATIONAL)"
        />
        <label for="international">International Sources</label>
      </div>
    </div>

    <div class="row">
      <div class="col s6">
        <validated-text-input
          ref="name"
          name="revenue.name"
          :mandatory="true"
          label="Name"
          placeholder="E.g. Bibliotek 2018"
          :value="name"
          :scope="scope"
          rule="required"
          @input="updateName"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s3">
        <validated-text-input
          name="revenue.year"
          label="Performance year"
          placeholder="YYYY"
          :mandatory="true"
          :value="year"
          :scope="scope"
          :rule="{ year: true, required: true, min_value: 2000 }"
          @input="updateYear"
        />
      </div>
      <div class="col s3">
        <select-date
          name="revenue.payment_date"
          label="Payment date"
          :mandatory="true"
          :scope="scope"
          :rule="{ required: true, after: '2000-01-01', before: beforeDate }"
          :value="formattedPaymentDate"
          @input="updatePaymentDate"
        />
      </div>
      <div class="col s6">
        <template v-if="editMode && statementUrl">
          <label class="label">Statement</label>
          <div class="statement">
            <span v-if="aggregate.statements[0]">
              <a @click="onDownloadFile(statementUrl)">
                <i class="far fa-file-alt" />
                {{ getFileName(statementUrl) }}
              </a>
              <i class="fas fa-times close cursor-pointer" @click="onRemoveStatementUrl" />
            </span>
            <span v-else>In progress...</span>
          </div>
        </template>

        <template v-else-if="editMode">
          <file-upload-simple-field
            label="Statement"
            name="documentRefId"
            accept-file-type=".csv, .tsv, .xml"
            @addFile="onAddStatement"
            @removeFile="onRemoveStatement"
          />
        </template>

        <a href="/StatementSpecial_Template.xlsx" target="_blank">
          Download "StatementSpecial" CSV template for Excel
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <validated-text-input name="revenue.note" label="Note" :value="note" @input="updateNote" />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <label class="label">Attachments</label>

        <template v-if="editMode">
          <div v-for="(url, index) in attachmentUrls" :key="index">
            <a @click="onDownloadFile(url)">
              <i class="far fa-file-alt" />
              {{ getFileName(url) }}
            </a>
            <i class="fas fa-times close cursor-pointer" @click="onRemoveAttachmentUrl(index)" />
          </div>
        </template>

        <div v-for="(attachment, index) in attachments" :key="attachment._localId">
          <file-upload-simple-field
            label=""
            :name="`attachment.name-${index}`"
            @addFile="onAddAttachment(index, $event)"
            @removeFile="onRemoveAttachment(index)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <select-source
          name="revenue.source"
          :mandatory="true"
          rule="required"
          :scope="scope"
          :sources="filteredSources"
          :value="sourceId"
          @input="setSource"
        />
      </div>
      <div v-if="activeCategory === category.DOMESTIC" class="col s4">
        <label>{{ `common.administrative_cost` | translate }}</label>
        <div class="financing-rate text--bold">{{ financingRate | formatDecimals }}%</div>
      </div>
    </div>
    <template v-if="activeCategory === category.INTERNATIONAL">
      <div class="row">
        <div class="col s6">
          <select-society
            label="Society"
            :rule="'required'"
            :value="societyCode"
            :item-key="'code'"
            @input="setSocietyCode"
          />
        </div>
      </div>
    </template>

    <template v-if="sourceId">
      <div class="row">
        <div class="col s2">
          <validated-text-input
            name="revenue.amount"
            label="Total amount to allocate"
            type="number"
            :scope="scope"
            :rule="{ required: true, negative_or_positive_amount: true }"
            :value="amount"
            @input="setAmount"
          />
        </div>
        <div v-if="activeCategory === category.DOMESTIC" class="col s2">
          <validated-text-input
            ref="financingFee"
            name="revenue.financing_fee"
            label="Administrative cost"
            type="number"
            :scope="scope"
            :disabled="fixedFinancingRate !== null"
            :rule="{
              required: fixedFinancingRate === null,
              negative_amount: amount > 0,
              amount: amount <= 0,
              max_value: amount < 0 ? -amount : null,
              min_value: amount > 0 ? -amount : null,
            }"
            :value="financingFee"
            @input="setFinancingFee"
          />
        </div>
        <template v-if="activeCategory === category.INTERNATIONAL">
          <div class="col s3">
            <validated-text-input
              name="revenue.original_amount"
              label="Original amount (If available)"
              rule="amount"
              type="number"
              :value="originalAmount"
              @input="setOriginalAmount"
            />
          </div>

          <div class="col s2">
            <select-currency
              name="revenue.currency"
              :value="currency"
              :rule="{ required: !!originalAmount }"
              @input="updateCurrency"
            />
          </div>

          <div class="col s2">
            <label for="revenue.conversion_rate">Conversion rate</label>
            <div id="revenue.conversion_rate" class="conversion-rate">
              <span v-if="conversionRate">
                {{ conversionRate | formatDecimals(4) }}
              </span>
              <span v-else>-</span>
            </div>
          </div>
        </template>
      </div>

      <div class="row">
        <div v-if="specification && specification.length > 0" class="col s3 revenue-balance">
          <label
            :class="{ 'text--bold': true, 'text--red': balance !== parseFloat(0.0).toFixed(2) }"
            for="revenue.balance"
          >
            Balance
          </label>
          <div
            :class="{
              'revenue-balance__box': true,
              'revenue-balance__box--not-zero': balance !== parseFloat(0.0).toFixed(2),
            }"
          >
            {{ balance }}
          </div>
          <validated-text-input
            id="revenue.balance"
            name="revenue.balance"
            label="balance"
            :show-label="false"
            :value="balance"
            :scope="scope"
            :rule="{ isZero: true }"
          />
        </div>
      </div>

      <div v-if="specification && specification.length > 0" class="row subsources">
        <div class="col s3">
          <table>
            <thead>
              <th>Channels</th>
              <th>Amount to Allocate</th>
            </thead>
            <tbody>
              <tr v-for="(specRow, index) in specification" :key="index">
                <td>{{ specRow.name }}</td>
                <td>
                  <validated-text-input
                    :disabled="!amount"
                    name="subSource.amount"
                    label="Sub source amount"
                    :show-label="false"
                    :scope="scope"
                    :rule="{ amount: true }"
                    :value="specRow.amount"
                    @input="updateSubSourceAmount(index, specRow.source_id, specRow.name, $event)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <div v-if="editMode" class="row float-right">
      <div class="col s12">
        <action-buttons @save="onSave" @cancel="onCancel" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import * as uuid from 'uuid';
import { mapGetters, mapMutations } from 'vuex';
import {
  createDistributionSpecification,
  DistributionCategory,
} from '../../../domain/distributionDomain';
import { statementState } from '../../../domain/statementDomain';
import ActionButtons from '../../ui/button/action-buttons';
import FileUploadSimpleField from '../../ui/file/file-upload-simple-field';
import SelectCurrency from '../../ui/select/select-currency';
import SelectDate from '../../ui/select/select-date';
import SelectSource from '../../ui/select/select-source';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';
import RevenueMixin from '../../../common/revenueMixin';
import SelectSociety from '../../ui/select/select-society';
import extractRemoteFileName from '../../../common/extractRemoteFileName';

export default {
  name: 'CreateBasicInformation',
  components: {
    SelectSociety,
    ActionButtons,
    FileUploadSimpleField,
    SelectCurrency,
    SelectDate,
    SelectSource,
    ValidatedTextInput,
  },
  mixins: [RevenueMixin],
  inject: ['$validator'],
  props: {
    editMode: { type: Boolean, default: false },
    scope: {
      type: String,
      required: true,
    },
    incomingCategory: {
      type: String,
      default: DistributionCategory.DOMESTIC,
    },
  },
  data() {
    return {
      sources: [],
      filteredSources: [],
      attachments: [],
      balance: 0.0,
      activeCategory: null,
      category: DistributionCategory,
      statementState,
      fixedFinancingRate: null,
    };
  },
  computed: {
    ...mapGetters('revenue', [
      'id',
      'aggregate',
      'name',
      'year',
      'paymentDate',
      'currency',
      'note',
      'source',
      'sourceId',
      'amount',
      'originalAmount',
      'conversionRate',
      'financingRate',
      'financingFee',
      'statementUrl',
      'attachmentUrls',
      'attachmentFileHandles',
      'specification',
      'societyCode',
    ]),
    beforeDate() {
      return moment().add(10, 'years').format('YYYY-MM-DD');
    },
    formattedPaymentDate() {
      if (this.paymentDate) {
        return Vue.filter('formatDate')(this.paymentDate);
      }
      return '';
    },
  },
  watch: {
    attachments() {
      this.setLocalIds();
    },
    activeCategory() {
      this.filteredSources = this.filterSources();
    },
    incomingCategory(value) {
      this.activeCategory = this.source && this.source.category ? this.source.category : value;
    },
  },
  async created() {
    this.balance = parseFloat(this.balance).toFixed(2);
    this.addEmptyAttatchment();
    this.setLocalIds();
    this.activeCategory =
      this.source && this.source.category ? this.source.category : this.incomingCategory;

    await this.getRevenueSources();
    const selectedSource = this.sources.find((source) => source.id === this.sourceId);
    if (selectedSource) {
      this.fixedFinancingRate = selectedSource.financing_rate;
    }
    this.flipFeeSign();
  },
  methods: {
    ...mapMutations('revenue', [
      'updateId',
      'updateName',
      'updateYear',
      'updatePaymentDate',
      'updateStatementUrl',
      'updateAttachementUrls',
      'updateNote',
      'updateAmount',
      'updateOriginalAmount',
      'updateSourceId',
      'updateConversionRate',
      'updateFinancingRate',
      'updateFinancingFee',
      'updateCurrency',
      'updateStatementFileHandle',
      'addToAttachmentFileHandles',
      'removeFromAttachmentFileHandles',
      'updateSpecification',
      'replaceInSpecification',
      'updateSocietyCode',
    ]),
    flipFeeSign() {
      if (this.financingFee < 0) {
        this.updateFinancingFee(Math.abs(this.financingFee));
      } else {
        this.updateFinancingFee(-Math.abs(this.financingFee));
      }
    },
    setLocalIds() {
      this.attachments.forEach((attachment) => {
        if (!attachment._localId) {
          attachment._localId = uuid.v4();
        }
      });
    },
    setActiveCategory(category) {
      this.activeCategory = category;
      this.setSource();
      this.setAmount(null);
      this.setOriginalAmount(null);
      this.updateCurrency(null);
    },
    async onDownloadFile(url) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
      DownloadLink.direct(fileData, url);
    },
    setSource(sourceObj) {
      if (!sourceObj || !sourceObj.source) {
        this.updateSpecification([]);
        this.updateSourceId(null);
        this.updateFinancingRate(null);
        return;
      }
      const specification = [];
      if (sourceObj.source.sub_sources) {
        sourceObj.source.sub_sources.forEach((subSource) => {
          specification.push(createDistributionSpecification(subSource.id, subSource.name));
        });
      }
      this.updateSpecification(specification);
      this.updateSourceId(sourceObj.source.id);

      this.fixedFinancingRate = sourceObj.source.financing_rate;
      if (this.fixedFinancingRate !== null) {
        this.updateFinancingRate(sourceObj.source.financing_rate.toFixed(2));
        this.updateFinancingFee(
          (this.amount * (parseFloat(this.fixedFinancingRate) / 100).toFixed(2)).toString(),
        );
      }
      const soc = this.resolveSocietyByName(sourceObj.source.name);
      if (soc) {
        this.updateSocietyCode(soc.code);
      } else {
        this.updateSocietyCode(null);
      }
    },
    setSocietyCode(code) {
      this.updateSocietyCode(code);
    },
    resolveSocietyByName(name) {
      const lname = name.toLowerCase();
      return this.$store.state.appdata.societies.find((sd) => sd.name.toLowerCase() === lname);
    },
    setAmount(val) {
      if (this.fixedFinancingRate !== null) {
        this.setFixedFinancingFee(val);
      } else {
        this.setFinancingRate(this.financingFee, val);
      }
      this.updateAmount(val);
      this.setConversionRate();
      this.balance = this.calculateBalance();
    },
    setFixedFinancingFee(val) {
      if (val > 0) {
        this.updateFinancingFee(
          -Math.abs(val * (parseFloat(this.fixedFinancingRate) / 100).toFixed(2)).toString(),
        );
      } else {
        this.updateFinancingFee(
          Math.abs(val * (parseFloat(this.fixedFinancingRate) / 100).toFixed(2)).toString(),
        );
      }
    },
    setFinancingFee(val) {
      this.updateFinancingFee(val.toString());
      this.setFinancingRate(val, this.amount);
    },
    async setFinancingRate(fee, amount) {
      if (fee && amount) {
        const rate = (Math.abs(parseFloat(fee)) / Math.abs(parseFloat(amount))) * 100;
        this.updateFinancingRate(rate.toFixed(2));
      } else {
        this.updateFinancingRate(Number(0).toFixed(2));
      }
    },
    setOriginalAmount(val) {
      this.updateOriginalAmount(val);
      this.setConversionRate();
    },
    setConversionRate() {
      this.updateConversionRate(this.calculateConversionRate());
    },
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    calculateConversionRate() {
      const rate = this.amount / this.originalAmount;
      return Number.isFinite(rate) && rate > 0 ? rate.toString() : null;
    },
    addEmptyAttatchment() {
      this.attachments.push({});
    },
    onAddStatement(file) {
      this.updateStatementFileHandle(file);
    },
    onRemoveStatement() {
      this.updateStatementFileHandle({});
      this.updateStatementUrl('');
    },
    onAddAttachment(index, file) {
      this.addToAttachmentFileHandles(file);
      this.attachments[index].name = file.name;
      this.addEmptyAttatchment();
    },
    onRemoveAttachment(index) {
      this.removeFromAttachmentFileHandles(this.attachmentFileHandles[index]);
      this.attachments.splice(index, 1);
      if (this.attachments.length === 0) {
        this.attachments.push({});
      }
    },
    onRemoveAttachmentUrl(index) {
      this.attachmentUrls.splice(index, 1);
    },
    onRemoveStatementUrl() {
      this.updateStatementUrl('');
    },
    updateSubSourceAmount(index, sourceId, name, amount) {
      this.replaceInSpecification({
        index,
        value: { source_id: sourceId, name, amount: amount ? amount.toString() : null },
      });
      this.balance = this.calculateBalance();
    },
    calculateBalance() {
      let subSourcesAmount = 0.0;
      if (this.specification) {
        this.specification.forEach((subSource) => {
          if (subSource.amount) {
            subSourcesAmount += parseFloat(subSource.amount);
          }
        });
      }
      let balance = 0.0;
      if (subSourcesAmount !== 0) {
        balance = parseFloat(this.amount) - subSourcesAmount.toFixed(2);
      }
      return balance.toFixed(2);
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.$emit('save', this.$options.name);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.subsources {
  table thead {
    background-color: inherit;
    color: inherit;
  }
}

.revenue-balance {
  i {
    font-size: 12px !important;
  }

  input {
    display: none;
  }
  &__box {
    border: 1px solid var(--grey--vlight);
    height: 50px !important;
    line-height: 50px;
    padding: 0 10px;
    font-size: 14px !important;
    font-weight: bold !important;

    &--not-zero {
      color: var(--red);
      border: 1px solid var(--red);
    }
  }
}
.card {
  padding-bottom: 16px !important;
}

.statement,
.financing-rate,
.conversion-rate {
  line-height: 36px;
}
</style>
