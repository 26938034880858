<template>
  <modal
    v-if="showModal"
    submit-label="Add Revenues"
    :show-abort="false"
    :disable-submit="disableSubmit"
    :links="links"
    :wide="true"
    @save="onSave"
    @cancel="onCancel"
    @close="onCancel"
  >
    <h1 slot="header">Choose revenues</h1>

    <div slot="body">
      <div class="row">
        <div class="col s3 float-right">
          <filter-input
            class=""
            label="Search"
            :filter-query="filterQuery"
            placeholder="E.g. SR"
            @input="filterSearch"
          />
        </div>
      </div>

      <div class="row">
        <div class="search-result__result">
          <div class="row">
            <div class="col s12">
              <table>
                <thead>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="[
                      { 'thin-column': column.thinColumn, 'disabled-head': !column.sortName },
                      column.styleClass,
                    ]"
                    @click="column.sortName ? sort(column) : ''"
                  >
                    <span>{{ column.name }}</span>
                    <span v-if="column.sortName && !column.thinColumn">
                      <span v-if="column.sortParam" class="dropdown-button__arrow">
                        <i
                          v-if="column.order === 'desc'"
                          class="fas fa-caret-up"
                          :class="{ 'text--grey': !column.active }"
                        />
                        <i
                          v-else
                          class="fas fa-caret-down"
                          :class="{ 'text--grey': !column.active }"
                        />
                      </span>
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="revenue in baseData" :key="revenue.id">
                    <td>
                      <input
                        :id="revenue.id"
                        v-model="checkedIds"
                        class="filled-in"
                        type="checkbox"
                        :value="revenue.id"
                      />
                      <label :for="revenue.id" />
                    </td>
                    <td>
                      <span>{{ revenue.payment_date | formatDate }}</span>
                    </td>
                    <td>
                      <span class="fs-14">{{ revenue.name }}</span>
                    </td>
                    <td>
                      <span>{{ revenue.source.name }}</span>
                    </td>
                    <td>
                      <span>{{ parseFloat(revenue.amount) | formatNumber }} SEK</span>
                    </td>
                  </tr>
                  <tr v-if="baseData.length === 0">
                    <td colspan="5" class="text--center">
                      <br />
                      <span class="none">There are no revenues ready to be distributed.</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterInput from './../../ui/input/filter-input';
import Modal from '../../ui/dialog/modal';

export default {
  name: 'SelectRevenue',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    FilterInput,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    revenues: {
      type: Array,
      default: () => [],
    },
    selectedRevenueIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'Choose',
        },
        {
          name: 'Payment date',
          sortName: 'payment_date',
          order: 'asc',
          sortParam: true,
        },
        {
          name: 'Name of revenue',
        },
        {
          name: 'Source',
        },
        {
          name: 'Amount',
          styleClass: 'amount-row',
        },
      ],
      showModal: this.show,
      baseData: this.revenues,
      checkedIds: this.selectedRevenueIds,
      filterQuery: '',
      links: [{ name: 'Administrate revenues', view: 'revenuesViewDomestic', params: {} }],
    };
  },
  computed: {
    ...mapGetters('distribution', []),
    disableSubmit() {
      return this.checkedIds.length < 1;
    },
  },
  watch: {
    show(doShow) {
      this.showModal = doShow;
    },
    revenues(revenues) {
      this.baseData = revenues;
    },
    selectedRevenueIds(selectedIds) {
      this.checkedIds = selectedIds;
    },
  },
  methods: {
    filterSearch(terms) {
      this.filterQuery = terms;
      if (!terms) {
        this.baseData = this.revenues;
      } else {
        const searchTerm = new RegExp(terms, 'i');
        this.baseData = this.revenues.filter((data) => data.name.search(searchTerm) > -1);
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save', this.checkedIds);
    },
  },
};
</script>
