<template>
  <li class="compare__list-item" :class="`compare__list--${status}`">
    <template>
      <div class="compare__incoming">
        <strong>{{ $t('common.' + incoming.key) }}</strong>
        <span
          class="compare__incoming-value"
          :class="{ none: !hasIncomingValue }"
          v-html="leftSideValue || 'None'"
        />
      </div>
      <ActionColumn
        :action="action"
        :dirty="action === 'undo'"
        :visible="actionAllowed"
        :enabled="actionEnabled"
        :status="status"
        @action="handleAction(action)"
      />
    </template>
    <div class="compare__candidate">
      <strong>{{ $t('common.' + candidate.key) }}</strong>
      <div class="compare__candidate-value">
        <a v-if="candidate.link" :href="candidate.link" v-html="updatedAttributeValue" />
        <span v-else :class="{ none: !rightSideValue }" v-html="rightSideValue || 'None'" />
        <span
          v-if="newValue || shouldInherit"
          class="text--line-through"
          :class="{
            none: !hasCandidateValue,
          }"
        >
          {{ candidateValue || 'None' }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import _ from 'lodash';
import CompareFieldsMixin from '../../common/compareFieldsMixin';
import ActionColumn from '@/components/compare/action-column.vue';

export default {
  name: 'CompareGridRow2',
  components: {
    ActionColumn,
  },
  mixins: [CompareFieldsMixin],
  props: {
    incoming: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
    candidate: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
          valueKey: '',
        };
      },
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    comparator: {
      type: String,
      default: 'valuesMatchRelaxed',
    },
    allowAction: {
      type: Boolean,
      default: true,
    },
    inheritWhenEmpty: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    computeNewValue: {
      type: Function,
      default: (old, _new) => _new,
    },
  },
  data() {
    return {
      newValue: null,
    };
  },
  computed: {
    leftSideValue() {
      return this.incomingValue;
    },
    hasIncomingValue() {
      return this.isValue(this.incomingValue);
    },
    hasCandidateValue() {
      return this.isValue(this.candidateValue);
    },
    rightSideValue() {
      if (this.newValue !== null) {
        return this.newValue;
      }
      if (this.shouldInherit) {
        return this.incomingValue;
      }
      return this.candidateValue;
    },
    originalRightSideValue() {
      return this.candidateValue;
    },
    shouldInherit() {
      return (
        this.enabled && this.inheritWhenEmpty && !this.hasCandidateValue && this.hasIncomingValue
      );
    },
    actionEnabled() {
      if (this.shouldInherit) {
        return false;
      }
      return true;
    },
    action() {
      if (this.newValue) {
        return 'undo';
      }
      if (!this.hasIncomingValue) {
        return;
      }
      if (!this.hasCandidateValue) {
        return 'update';
      }
      if (_.isArray(this.candidateValue)) {
        return this.append ? 'addTo' : 'update';
      }
      return 'update';
    },
    actionAllowed() {
      return this.enabled && this.allowAction && this.hasIncomingValue && !this.isEqual;
    },
    status() {
      if (!this.hasIncomingValue) {
        return 'idle';
      }
      if (!this.hasCandidateValue) {
        return 'warning';
      }
      if (this.isEqual) {
        return 'idle';
      }
      return 'error';
    },
    hasCandidate() {
      return this.candidate && this.candidate.value !== '';
    },
    isEqual() {
      if (this.comparator in this) {
        return this[this.comparator](this.incomingValue, this.candidateValue);
      }
      return _.isEqual(this.incomingValue, this.candidateValue);
    },
    instruments() {
      return this.$store.state.appdata.referenceData.instruments;
    },
    incomingValue() {
      return this.getFilteredValue(this.incoming.value, this.incoming.displayFilter);
    },
    candidateValue() {
      return this.getFilteredValue(this.candidate.value, this.candidate.displayFilter);
    },
  },
  methods: {
    getFilteredValue(val, filterName) {
      if (filterName) {
        return this.$options.filters[filterName](val);
      }
      return val;
    },
    isValue(val) {
      if (Array.isArray(val)) {
        return val.length > 0;
      }
      if (typeof val === 'number') {
        return !isNaN(val);
      }
      if (typeof val === 'string') {
        return val.length > 0;
      }
      return false;
    },
    handleAction(action) {
      if (action === 'undo') {
        this.newValue = null;
      } else {
        this.newValue = this.computeNewValue(this.candidateValue, this.incomingValue);
      }
      this.$emit('valueUpdated', {
        action,
        revert: action === 'undo',
        data: {
          key: this.candidate.valueKey,
          value: this.newValue,
        },
      });
    },
  },
};
</script>
