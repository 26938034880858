¨
<template>
  <div>
    <div v-if="loadingHeader" class="result__spinner">
      <spinner />
    </div>
    <div class="result">
      <div class="row wrapper">
        <div class="col s8">
          <h1>VRDB Updates from {{ basicInfoName }}</h1>
        </div>
      </div>
      <div class="row wrapper">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/to_check`">
              To Check ({{ header ? header.to_check : 0 | formatNumber }})
            </router-link>
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/accepted`">
              Accepted ({{ header ? header.accepted : 0 | formatNumber }})
            </router-link>
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/rejected`">
              Rejected ({{ header ? header.rejected : 0 | formatNumber }})
            </router-link>
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/applied`">
              Applied ({{ header ? header.applied : 0 | formatNumber }})
            </router-link>
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/sent_rejections`">
              Sent Rejections ({{ header ? header.rejected_sent : 0 | formatNumber }})
            </router-link>
            <router-link class="nowrap" :to="`/societies/${id}/vrdb/unknown`">
              Not Matched
              <i
                v-if="(header ? header.not_matched : 0 || 0) > 0"
                class="fa fa-exclamation-circle text--red"
              />
              <i
                v-if="(header ? header.not_matched : 0) === 0"
                class="fa fa-check-circle text--green"
              />
            </router-link>
          </div>
        </div>
      </div>
      <template v-if="!loadingHeader">
        <keep-alive>
          <router-view @updated="loadHeader" />
        </keep-alive>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '../../../../spinner';
import ViewSocietyMixin from '../../view-society-mixin';
import MatchingService from '../../../../../services/matchingService';

export default {
  name: 'ViewVrdbPlaylistLines',
  components: {
    Spinner,
  },
  mixins: [ViewSocietyMixin],
  props: {
    processType: String,
  },
  data() {
    return {
      loadingHeader: true,
      rejectionInfo: {},
      header: {},
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'basicInfoName', 'metaInfoVrdbCode']),
  },
  async created() {
    await this.loadSociety();
    await this.loadHeader();
  },
  methods: {
    async loadSociety() {
      this.aggregateId = this.$router.currentRoute.params.id;
      this.version = this.$router.currentRoute.params.version;
      try {
        await this.fetchData(this.aggregateId, this.version);
      } catch (error) {
        this.handleError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.aggregateId },
        });
      }
    },
    async loadHeader() {
      try {
        const result = await MatchingService.listVrdbReportHeaders(this.metaInfoVrdbCode);
        if (result.length) {
          this.header = result[0];
        }
        this.loadingHeader = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
