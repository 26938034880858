<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 xl9 recording-wrapper">
        <h1>Distribution History for {{ basicInfoName }}</h1>
        <h2>
          <span v-if="basicInfoVersionTitle">({{ basicInfoVersionTitle }})</span>
          <span v-if="!basicInfoMainArtist">(main artist missing)</span>
          <span v-else>
            <span v-if="basicInfoMainArtist.id">
              by
              <router-link :to="'/mainartists/' + basicInfoMainArtist.id">
                {{ basicInfoMainArtist.name }}
              </router-link>
            </span>
            <span v-else>by {{ basicInfoMainArtist.name }}</span>
          </span>
        </h2>
      </div>
    </div>

    <div class="row card">
      <table>
        <thead>
          <tr>
            <th class="thin-column" style="width: 40px"></th>
            <th style="width: 25%">Distribution Date</th>
            <th colspan="2">Type</th>
            <th class="amount-row" style="width: 15%">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="items.length === 0">
            <td colspan="5" class="none">No distribution history found for this recording</td>
          </tr>
          <template v-for="(item, idx) in items">
            <tr
              :key="`${item.dist_date}-${item.type}-tr1`"
              @click="
                idx === expandedIndex
                  ? (expandedIndex = undefined)
                  : fetchDetails(idx, item.dist_date, item.type)
              "
            >
              <td class="compare__arrow">
                <i :class="`fas fa-caret-${idx === expandedIndex ? 'up' : 'down'}`" />
              </td>
              <td>{{ item.dist_date | formatDate }}</td>
              <td colspan="2">{{ item.type | formatDistributionType }}</td>
              <td class="amount-row">{{ item.amount | formatAmountThousandSeparator('SEK') }}</td>
            </tr>

            <template v-if="idx === expandedIndex">
              <tr :key="`${item.dist_date}-${item.type}-tr2`" class="text--bold">
                <col />
                <td>Performer</td>
                <td>Role</td>
                <td>Right</td>
              </tr>

              <template v-for="(detail, didx) of details">
                <tr :key="`${idx}-${didx}-detail`" class="details">
                  <col />
                  <td>
                    <router-link :to="`/performers/${detail.performer_id}`">
                      {{ detail.performer_name }}
                    </router-link>
                  </td>
                  <td>{{ detail.role_code | formatRole }}</td>
                  <td>{{ detail.right | formatRight }}</td>
                  <td class="amount-row">
                    {{ detail.amount | formatAmountThousandSeparator('SEK') }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>

      <div class="result__pagination">
        <Pagination
          v-if="numberOfPages() > 0"
          :number-of-pages="numberOfPages()"
          :selected-page="page"
          :number-of-hits="itemCount"
          :hits-per-page="pageSize"
          @selectPage="selectPage"
          @updateHitsPerPage="selectPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import RecordingMixin from './recording-mixin';
import DistributionService from '../../../services/distributionService';
import PaginationQueryParamMixin from '../../../common/paginationQueryParamMixin';
import Pagination from '../../pagination';

export default {
  name: 'ViewDistributionHistory',
  components: { Pagination },
  mixins: [RecordingMixin, PaginationQueryParamMixin],
  data() {
    return {
      items: [],
      details: [],
      expandedIndex: undefined,
    };
  },
  computed: {
    ...mapGetters('recording', [
      'basicInfoName',
      'id',
      'basicInfoVersionTitle',
      'basicInfoMainArtist',
    ]),
  },
  watch: {
    $route() {
      this.expandedIndex = undefined;
      this.fetchDistributionHistory();
    },
  },
  async created() {
    await this.fetchData();
    await this.fetchDistributionHistory();
  },
  activated() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  deactivated() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.togglePrevOrNext('next');
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.togglePrevOrNext('prev');
      }
    },
    async togglePrevOrNext(direction) {
      if (this.expandedIndex === undefined) {
        return;
      }

      const nextIndex = direction === 'prev' ? this.expandedIndex - 1 : this.expandedIndex + 1;

      if (nextIndex < 0 || nextIndex >= this.items.length) {
        this.expandedIndex = undefined;
        const page = direction === 'prev' ? this.page - 1 : this.page + 1;

        if (page < 1 || page > this.numberOfPages()) {
          return;
        }

        this.updateQueryParams({ page }, true);
        await this.fetchDistributionHistory();
        this.expandedIndex = direction === 'prev' ? this.items.length - 1 : 0;
      } else {
        this.expandedIndex = nextIndex;
      }

      const item = this.items[this.expandedIndex];
      await this.fetchDetails(this.expandedIndex, item.dist_date, item.type);
    },
    async fetchDistributionHistory() {
      this.loading = true;
      try {
        const response = await DistributionService.recordingDistributionHistory(
          this.id,
          this.pageSize,
          this.getOffset(),
        );

        this.items = response.items;
        this.itemCount = response.total_count;
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchDetails(index, distDate, type) {
      this.details = [];
      this.expandedIndex = index;
      try {
        this.details = await DistributionService.recordingDistributionHistoryDetails(
          this.id,
          moment(distDate).format('YYYY-MM-DD'),
          type,
        );
      } catch (error) {
        this.expandedIndex = undefined;
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  background: #fafafa;
  & td:before {
    content: none;
  }
}
</style>
