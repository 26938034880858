<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>Reserves</h1>
      </div>
      <div class="col s6 text--right push--top edit-link">
        <a @click="handleCreateNew()">
          <i class="far fa-plus-square" />
          New Reserve Release
        </a>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>

    <div v-else class="row card">
      <div class="row">
        <div class="col s12">
          <table>
            <thead>
              <th v-for="column in columns" :key="column.id" :class="[column.styleClass]">
                {{ column.name }}
              </th>
            </thead>
            <tbody>
              <tr v-if="numberOfReserves === 0">
                <td colspan="5" class="none">No reserve releases</td>
              </tr>
              <tr
                v-for="(r, i) in reserves"
                v-else
                :key="r.id"
                :class="{ 'divide-after': i === indexOfLastOpen }"
              >
                <td>
                  <router-link
                    :to="{
                      path: '/reserves',
                      query: { id: r.id.toString() },
                    }"
                  >
                    {{ r.releaseYear }}
                  </router-link>
                </td>
                <td>{{ r.createdDate | formatDate }}</td>
                <td>{{ r.releaseDate | formatDate }}</td>
                <td>{{ r.createdBy }}</td>
                <td>{{ r.confirmedBy }}</td>
                <td
                  :class="{
                    'text--green': releaseIsOpen(r),
                    'text--yellow': releaseIsWorking(r),
                    'text--red': releaseHasError(r),
                  }"
                >
                  {{ getProcessReadableState(r) }}
                </td>
                <td class="amount-row">
                  {{ r.totalReservedAmount | formatAmountThousandSeparator('SEK') }}
                </td>
                <td>
                  <options
                    v-if="!releaseIsConfirmed(r) && !releaseIsFinished(r)"
                    :options="['Delete']"
                    @delete="handleDelete(r.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination
        v-if="numberOfPages > 0"
        :number-of-pages="numberOfPages"
        :number-of-hits="numberOfReserves"
        :selected-page="Math.floor(paging.offset / paging.limit) + 1"
        :hits-per-page="paging.limit"
        @selectPage="selectPage"
        @updateHitsPerPage="updateHitsPerPage"
      />
    </div>
  </div>
</template>

<script>
import Options from '../ui/context-menu';
import Pagination from '../pagination';
import Spinner from '../spinner';
import { reserveReleaseState } from '../../domain/releaseOfReservesDomain';
import ReserveService from '../../services/reserveService';

export default {
  name: 'ViewReleaseOfReservesHistory',
  components: {
    Options,
    Pagination,
    Spinner,
  },
  async beforeRouteUpdate(to, from, next) {
    await this.onRoute(to.query);
    next();
  },
  data() {
    return {
      columns: [
        { name: 'Performance Year' },
        { name: 'Created Date' },
        { name: 'Release Date' },
        { name: 'Created by' },
        { name: 'Confirmed by' },
        { name: 'Status' },
        { name: 'Amount', styleClass: 'amount-row' },
        { name: '' },
      ],
      reserves: [],
      numberOfReserves: null,
      paging: {
        limit: null,
        offset: null,
      },
      loading: false,
    };
  },
  computed: {
    indexOfLastOpen() {
      return (
        this.reserves.filter((r) => this.releaseIsOpen(r) || this.releaseIsWorking(r)).length - 1
      );
    },
    numberOfPages() {
      return Math.ceil(this.numberOfReserves / this.paging.limit);
    },
  },
  created() {
    this.onRoute(this.$route.query);
  },
  methods: {
    async onRoute(query) {
      this.setParameters(query);
      try {
        this.loading = true;
        const response = await ReserveService.getReserveReleaseHeaders(query);
        this.reserves = response.items;
        this.numberOfReserves = response.total_count;
        this.loading = false;
      } catch (err) {
        this.$addStarError(err);
      }
    },
    fetchWithCurrentPaging() {
      this.$router.replace({
        path: '/reserves/history',
        query: {
          offset: this.paging.offset,
          limit: this.paging.limit,
        },
      });
    },
    setParameters(query = this.$route.query) {
      this.paging.limit = Number.parseInt(query.limit, 10) || 25;
      this.paging.offset = Number.parseInt(query.offset, 10) || 0;
    },
    selectPage(page) {
      this.paging.offset = (page - 1) * this.paging.limit;
      this.fetchWithCurrentPaging();
    },
    updateHitsPerPage(nbr) {
      this.paging.limit = nbr;
      this.fetchWithCurrentPaging();
    },
    async handleDelete(id) {
      this.loading = true;
      await ReserveService.deleteReserveRelease(id);
      this.refresh();
    },
    handleCreateNew() {
      this.$router.push({
        path: '/reserves',
      });
    },
    refresh() {
      this.onRoute(this.$route.query);
    },
    releaseIsOpen(release) {
      return release.state === reserveReleaseState.PENDING_CONFIRMATION;
    },
    releaseIsWorking(release) {
      return release.state === reserveReleaseState.WORKING;
    },
    releaseIsConfirmed(release) {
      return release.state === reserveReleaseState.COMPLETE;
    },
    releaseHasError(release) {
      return release.state === reserveReleaseState.ERROR;
    },
    releaseIsNew(release) {
      return release.state === reserveReleaseState.NEW;
    },
    releaseIsFinished(release) {
      return release.state === reserveReleaseState.FINISHED;
    },
    getProcessReadableState(release) {
      if (this.releaseHasError(release)) {
        return 'Error';
      } else if (this.releaseIsWorking(release)) {
        return 'Working';
      } else if (this.releaseIsOpen(release)) {
        return 'Open';
      } else if (this.releaseIsNew(release)) {
        return 'New';
      } else if (this.releaseIsFinished(release)) {
        return 'Done';
      }
      return '-';
    },
  },
};
</script>

<style lang="scss">
.divide-after {
  border-bottom: 1px solid var(--grey);
}
</style>
