<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s3 float-right">
          <filter-input
            :filter-query="filterQuery"
            label="Recordings"
            placeholder="E.g. Socker"
            @input="filterRecordings"
          />
        </div>
        <div class="col s3 float-right" style="margin-right: 8px">
          <filter-input
            :filter-query="mainArtist"
            label="Main artist"
            placeholder="E.g. Joakim Berg"
            @input="filterMainArtist"
          />
        </div>
      </div>
      <div class="row card">
        <div class="row">
          <table>
            <sortable-head
              :columns="columns"
              :bulk-enabled="false"
              :more-options="false"
              :default-sort-column-index="0"
              @sort="clickSort"
            />
            <tbody>
              <template v-for="recording in recordings">
                <tr :key="`${recording.id}-tr1`">
                  <td
                    class="thin-column dropdown-button__arrow"
                    @click="toggleDetails(recording.id, $event)"
                  >
                    <i
                      :class="`fas fa-caret-${recordingExpanded(recording.id) ? 'up' : 'down'}`"
                    ></i>
                  </td>
                  <td>
                    <router-link :to="'/recordings/' + recording.id">
                      {{ recording.name }}{{ recording.lineup_locked ? ' 🔒' : '' }}
                    </router-link>
                  </td>
                  <td>
                    <span v-if="recording.version_title">{{ recording.version_title }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <template v-if="type === AGGREGATE_TYPES.PERFORMER">
                    <td>{{ recording.role | formatRole }}</td>
                    <td>
                      <span v-if="recording.instruments.length">
                        {{ recording.instruments | formatInstrumentList }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <router-link
                        v-if="recording.main_artist"
                        :to="'/mainartists/' + recording.main_artist.id"
                      >
                        {{ recording.main_artist.name }}
                      </router-link>
                      <span v-else class="none">None</span>
                    </td>
                  </template>
                  <td>
                    <span v-if="recording.isrc">{{ recording.isrc }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="recording.vrdb2_id">{{ recording.vrdb2_id }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <td v-if="type === AGGREGATE_TYPES.MAINARTIST">
                    <span v-if="recording.label">{{ recording.label }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="recording.recording_date">
                      {{ recording.recording_date | formatRecordingDate }}
                    </span>
                    <span v-else class="none">None</span>
                  </td>
                  <td class="status">
                    <span v-if="recording.distribution_state">
                      <status-icon :status="recording.distribution_state.status" />
                    </span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="recording.sami_members">
                      <i class="fas fa-user"></i>
                    </span>
                    <span v-else class="none">None</span>
                  </td>
                </tr>
                <tr
                  v-if="recordingExpanded(recording.id)"
                  :key="`${recording.id}-tr2`"
                  class="expand-info"
                >
                  <template v-if="loadingLineupForRecordingId === recording.id">
                    <td colspan="999">
                      <component-spinner />
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <span
                        v-show="recording.alternate_titles && recording.alternate_titles.length > 0"
                        class="expand-info__header"
                      >
                        Alternate titles
                      </span>
                      <div
                        v-for="(alternate_title, index) in recording.alternate_titles"
                        :key="index"
                      >
                        {{ alternate_title }}
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <span class="expand-info__header">Lineup</span>
                      <div v-if="!recording.lineup || recording.lineup.length === 0" class="none">
                        None
                      </div>
                      <div v-else-if="recording.lineup.length > 10">
                        <div class="expand-info__lineup">
                          <template v-for="(performer, index) in recording.lineup">
                            <div v-if="index < 10" :key="index">
                              <router-link :to="'/performers/' + performer.id">
                                {{ performer.name }}
                              </router-link>
                            </div>
                          </template>
                        </div>
                        <div class="expand-info__lineup__more-exists">
                          {{ recording.lineup.length - 10 }} more
                        </div>
                      </div>
                      <div v-for="(performer, index) in recording.lineup" v-else :key="index">
                        <router-link :to="'/performers/' + performer.id">
                          {{ performer.name }}
                        </router-link>
                      </div>
                    </td>
                    <td v-if="recording.lineup && recording.lineup.length > 0">
                      <span class="expand-info__header">Role</span>

                      <div v-if="recording.lineup.length > 10">
                        <div class="expand-info__lineup">
                          <div
                            v-for="(performer, index) in recording.lineup.slice(0, 10)"
                            :key="index"
                          >
                            <span>
                              {{ performer.role | formatRole }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-for="(performer, index) in recording.lineup" v-else :key="index">
                        <span>
                          {{ performer.role | formatRole }}
                        </span>
                      </div>
                    </td>
                    <td colspan="5" class="expand-info__meta">
                      <span class="expand-info__header">More meta data</span>
                      <ul>
                        <li class="expand-info__meta__title">Duration:</li>
                        <li v-if="recording.duration_sec">
                          {{ recording.duration_sec | toMinutes }}
                        </li>
                        <li v-else>-</li>

                        <li class="expand-info__meta__title">Produced in country:</li>
                        <li v-if="recording.produced_in_country">
                          {{ recording.produced_in_country | formatCountry }}
                        </li>
                        <li v-else>-</li>

                        <li class="expand-info__meta__title">VRDB ID:</li>
                        <li>{{ alternateVrdbIds(recording) || '-' }}</li>

                        <li class="expand-info__meta__title">Composed by:</li>
                        <li>{{ recording.composer || '-' }}</li>
                        <li class="expand-info__meta__title">Written by:</li>
                        <li>{{ recording.writer || '-' }}</li>
                        <li class="expand-info__meta__title">Arranged by:</li>
                        <li>{{ recording.arranger || '-' }}</li>
                      </ul>
                      <div class="expand-info__meta__tags">
                        <div class="expand-info__meta__tags-title expand-info__meta__title">
                          Tags:
                        </div>
                        <div
                          v-if="recording.tags && recording.tags.length > 0"
                          class="chips__wrapper"
                        >
                          <template v-for="(tag, index) in recording.tags">
                            <tag :key="index" :tag="tag" />
                          </template>
                          <div v-if="!recording.tags || recording.tags.length === 0">-</div>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div>
          <pagination
            v-if="numberOfPages > 0"
            topic="discography"
            :number-of-pages="numberOfPages"
            :selected-page="page"
            :number-of-hits="totalRecordings"
            :hits-per-page="pageSize"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
      <template v-if="numberOfPages === 0">
        <div class="no-result">0 recordings found</div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { AGGREGATE_TYPES } from '../../domain/common';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import FilterInput from '../ui/input/filter-input';
import Pagination from '../pagination';
import RecordingService from '../../services/recordingService';
import SortableHead from '../ui/table/sortable-head';
import Spinner from '../spinner';
import StatusIcon from '../ui/status-icon';
import Tag from '../tags/tag';
import ComponentSpinner from '@/components/component-spinner.vue';
export default {
  name: 'ViewDiscographyRecordings',
  components: {
    ComponentSpinner,
    Spinner,
    SortableHead,
    Pagination,
    FilterInput,
    StatusIcon,
    Tag,
  },
  mixins: [DebouncedSearchMixin(400)],
  props: {
    type: { type: String },
  },
  data() {
    return {
      AGGREGATE_TYPES,
      id: this.$router.currentRoute.params.id,
      loading: true,
      loadingLineupForRecordingId: false,
      filterQuery: '',
      mainArtist: '',
      totalRecordings: 0,
      recordings: [],
      page: 1,
      pageSize: 25,
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Recording',
          ascending: true,
          sortParam: 'name.keyword',
          active: true,
        },
        {
          name: 'Version',
          ascending: true,
          sortParam: 'version_title.keyword',
          active: false,
        },
        {
          name: 'ISRC',
          ascending: true,
          sortParam: 'isrc',
          active: false,
        },
        {
          name: 'VRDB ID',
          ascending: true,
          sortParam: 'vrdb2_id',
          active: false,
        },
        {
          name: 'Recording Date',
          ascending: true,
          sortParam: 'recording_date',
          active: false,
        },
        {
          name: 'Status',
          ascending: true,
          sortParam: 'distribution_state.status',
          active: false,
        },
        {
          name: 'Member',
          active: false,
        },
      ],
      expandedRecordingIds: [],
      sortColumn: 'name.keyword',
      sortOrder: 'asc',
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRecordings / this.pageSize);
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  created() {
    if (this.type === AGGREGATE_TYPES.PERFORMER) {
      this.columns.splice(
        3,
        0,
        ...[
          { name: 'Role' },
          { name: 'Instruments' },
          {
            name: 'Main Artist',
            ascending: true,
            sortParam: 'main_artist.name.keyword',
            active: false,
          },
        ],
      );
    }
    if (this.type === AGGREGATE_TYPES.MAINARTIST) {
      const labelColumn = {
        name: 'Label',
        ascending: true,
        sortParam: 'label.keyword',
        active: false,
      };
      this.columns.splice(5, 0, labelColumn);
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let result = null;
      if (this.$router.currentRoute.query) {
        this.page = Number(this.$router.currentRoute.query.page) || 1;
        this.pageSize = Number(this.$router.currentRoute.query.pageSize) || 25;
        this.sortColumn = this.$router.currentRoute.query.sortColumn;
        this.sortOrder = this.$router.currentRoute.query.sortOrder;
        this.filterQuery = this.$router.currentRoute.query.filter;
        this.mainArtist = this.$router.currentRoute.query.mainArtist;
      }
      const pagination = {
        pageFrom: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        sortField: this.sortColumn,
        sortOrder: this.sortOrder,
      };
      try {
        if (this.type === AGGREGATE_TYPES.MAINARTIST) {
          result = await RecordingService.getDiscographyRecordingByMainArtist(
            this.id,
            this.filterQuery,
            pagination,
          );
        } else {
          result = await RecordingService.getDiscographyRecordingByLineupPerformer(
            this.id,
            this.filterQuery,
            this.mainArtist,
            pagination,
          );
        }
        this.handleResult(result);
      } catch (error) {
        console.log('Error fetching discography records', error);
        this.loading = false;
      }
    },
    handleResult(result) {
      this.totalRecordings = result.total;
      this.recordings = result.recordings;
      this.loading = false;
      if (this.$route.query.page) {
        this.page = Number(this.$route.query.page);
      }
      if (this.$route.query.pageSize) {
        this.pageSize = Number(this.$route.query.pageSize);
      }
      this.markSorting();
    },
    markSorting() {
      this.columns.forEach((column) => {
        if (column.sortParam === this.sortColumn) {
          column.active = true;
          column.ascending = this.sortOrder === 'asc';
        } else {
          column.active = false;
          column.ascending = true;
        }
      });
    },
    filterRecordings(query) {
      this.filterQuery = query;
      this.debouncedSearch(query, this.filterQuery);
    },
    filterMainArtist(query) {
      this.mainArtist = query;
      this.debouncedSearch(query, this.mainArtist);
    },
    search() {
      this.selectPage(1);
      this.updateRouterSearchParams();
    },
    clickSort(sort) {
      this.sort(sort.sortParam, sort.ascending ? 'asc' : 'desc');
    },
    sort(sortColumn, sortOrder) {
      this.sortColumn = sortColumn;
      this.sortOrder = sortOrder;
      this.updateRouterSearchParams();
    },
    updateRoute(id, queryParams) {
      this.$router.replace({
        name:
          this.type === AGGREGATE_TYPES.MAINARTIST
            ? 'discographyMainArtistRecordings'
            : 'discographyPerformerRecordings',
        params: { id },
        query: queryParams,
      });
    },
    recordingExpanded(id) {
      return _.includes(this.expandedRecordingIds, id);
    },
    async toggleDetails(id, e) {
      const index = _.indexOf(this.expandedRecordingIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedRecordingIds.push(id);
        row.style.whiteSpace = 'normal';
      } else {
        this.expandedRecordingIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
      const recording = this.recordings.find((r) => r.id === id);
      if (!recording.lineup) {
        this.loadingLineupForRecordingId = id;
        const response = await RecordingService.getRecordingLineup(id);
        recording.lineup = response.lineup.map((l) => ({
          id: l.relation.id,
          name: l.relation.name,
          role: l.role,
          instruments: l.instruments,
        }));
        this.loadingLineupForRecordingId = null;
      }
    },
    selectPage(selectedPage) {
      this.page = selectedPage;
      this.updateRouterSearchParams();
    },
    updateHitsPerPage(hitsPerPage) {
      this.pageSize = Number(hitsPerPage);
      this.selectPage(1);
    },
    updateRouterSearchParams() {
      const queryParams = {};
      const performerId = this.$router.currentRoute.params.id;

      if (this.page > 1) {
        queryParams.page = this.page;
      }

      if (this.pageSize !== 25) {
        queryParams.pageSize = this.pageSize;
      }

      if (this.sortColumn) {
        queryParams.sortColumn = this.sortColumn;
        queryParams.sortOrder = this.sortOrder;
      }

      if (this.filterQuery) {
        if (this.filterQuery !== this.$route.query.filter) {
          this.page = 1;
          queryParams.page = 1;
        }
        queryParams.filter = this.filterQuery;
      }

      if (this.mainArtist) {
        if (this.mainArtist !== this.$route.query.mainArtist) {
          this.page = 1;
          queryParams.page = 1;
        }
        queryParams.mainArtist = this.mainArtist;
      }
      this.updateRoute(performerId, queryParams);
    },
    alternateVrdbIds(recording) {
      if (recording.vrdb2_id) {
        return recording.vrdb2_id;
      } else if (recording.vrdb_type === 'associated') {
        const associated = recording.local_codes.find((lc) => lc.code === 'VRDB2_ASSOCIATED');
        return associated ? associated.value : '';
      } else if (recording.vrdb_type === 'playlist') {
        const playlist = recording.local_codes.find((lc) => lc.code === 'VRDB2_PLAYLIST_ID');
        return playlist ? playlist.value : '';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.status {
  vertical-align: middle;
}
</style>
