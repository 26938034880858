<template>
  <div>
    <div class="row">
      <div class="col s10">
        <h2 class="float-left">
          {{
            relationType === 'childAssociations'
              ? 'Collects/Inherits from'
              : 'Transfers to Associates'
          }}
          <span v-if="numAssociations > 8">({{ numAssociations }})</span>
        </h2>
      </div>
    </div>

    <div class="row card push-half--bottom">
      <div class="col s12">
        <table class="lineup__performers">
          <sortable-head
            :more-options="enableRowOptions"
            :columns="columns"
            :bulk-enabled="false"
            :default-sort-column-index="0"
          />
          <tbody>
            <template v-if="loading">
              <tr>
                <td colspan="7">
                  <component-spinner />
                </td>
              </tr>
            </template>
            <template v-for="(item, index) in sortedItems" v-else>
              <tr
                v-if="
                  inEdit === `${item.id}${item.row_id}` || (inEdit === -1 && item.row_id === -1)
                "
                :key="`edit-${getIndex(item)}${item.row_id}`"
                class="prevent-hover-bg"
              >
                <td colspan="1000">
                  <form :data-vv-scope="scope">
                    <edit-associate
                      :associate="item"
                      :index="getIndex(item)"
                      :relation-type="relationType"
                      :namespace="namespace"
                      :edit-mode="true"
                      @cancel="onCancel(item)"
                      @save="save"
                    />
                  </form>
                </td>
              </tr>
              <tr v-else :key="index">
                <td>
                  <router-link :to="calculateRoute(item)">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.type === 'PERFORMER' ? 'Performer' : item.type | associateTypeName }}
                </td>
                <td class="nowrap">
                  {{ item.start_date }}
                </td>
                <td class="nowrap">
                  {{ item.end_date }}
                </td>
                <td>
                  <i v-if="item.access_policy !== 'NONE'" class="push-half--right far fa-eye" />
                </td>
                <td>
                  <i
                    v-if="item.access_policy === 'WRITE'"
                    class="push-half--right fas fa-pencil-alt"
                  />
                </td>
                <td>
                  {{ item.share }}
                </td>
                <td v-if="enableRowOptions">
                  <context-menu
                    :disabled="inEdit !== ''"
                    :options="contextMenuOptions()"
                    @edit="onEdit(item)"
                    @remove="
                      remove({
                        id: item.id,
                        row_id: item.row_id,
                        type: namespace,
                        index: getIndex(item),
                      })
                    "
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col s12 edit-link">
        <a :class="{ disabled: inEdit !== '' }" @click="onAdd">
          <i class="far fa-plus-square" />
          Add associated
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations } from 'vuex';
import AssociatesMixin from './associates-mixin';
import EditAssociate from './edit-associate';
import ContextMenu from '../../ui/context-menu';
import SortableHead from '../../ui/table/sortable-head';
import AssociateService from '../../../services/associateService';
import ComponentSpinner from '../../component-spinner';

export default {
  name: 'Associates',
  components: {
    ContextMenu,
    EditAssociate,
    SortableHead,
    ComponentSpinner,
  },
  mixins: [AssociatesMixin],
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    namespace: {
      type: String,
      required: true,
    },
    enableRowOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      inEdit: '',
      sortedItems: [],
    };
  },
  computed: {
    numAssociations() {
      return this.relationType === 'childAssociations'
        ? this.numChildAssociations
        : this.sortedItems.length;
    },
  },
  watch: {
    editMode() {
      if (!this.editMode) {
        this.inEdit = '';
        let associate = {};
        if (this.relationType === 'associates') {
          associate = this.associates[this.associates.length - 1];
        } else {
          associate = this.childAssociations[this.childAssociations.length - 1];
        }
        this.removeNewAssociateHolder(associate);
      }
    },
    associates() {
      if (this.relationType === 'associates') {
        this.sortedItems = _.cloneDeep(this.associates);
        this.getSortedItems(this.sortedItems);
      }
    },
    childAssociations() {
      if (this.relationType !== 'associates') {
        this.sortedItems = _.cloneDeep(this.childAssociations);
      }
    },
  },
  async created() {
    if (this.relationType === 'associates') {
      this.sortedItems = this.getSortedItems(this.associates);
    } else {
      this.loading = true;
      const children = await this.fetchChildAssociates();
      this.$nextTick(() => this.updateChildAssociations(children));
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations({
      updateChildAssociations(commit, payload) {
        return commit('associate/updateChildAssociations', payload);
      },
      removeFromChildAssociations(commit, payload) {
        return commit('associate/removeFromChildAssociations', payload);
      },
      addToChildAssociations(commit, payload) {
        return commit('associate/addToChildAssociations', payload);
      },
      replaceInChildAssociations(commit, payload) {
        return commit('associate/replaceInChildAssociations', payload);
      },
    }),
    async fetchChildAssociates() {
      this.loading = true;
      const response = await AssociateService.fetchChildAssociates(
        this.aggregate.id,
        this.numChildAssociations,
      );
      this.loading = false;
      return response.child_associations;
    },
    onAdd() {
      if (this.relationType === 'associates') {
        this.addNewAssociation();
      } else {
        this.addNewChildAssociate();
      }
      this.inEdit = -1;
      this.$emit('edit');
    },
    onEdit(item) {
      this.inEdit = `${item.id}${item.row_id}`;
      this.$emit('edit');
    },
    contextMenuOptions() {
      return ['Edit', 'Remove'];
    },
    getSortedItems(list) {
      return list.sort((a, b) => {
        // push new associates to the end of the array
        if (a._new) {
          return 1;
        } else if (b._new) {
          return -1;
        }
        const aStart = Date.parse(a.start_date);
        const bStart = Date.parse(b.start_date);
        const aEnd = Date.parse(a.end_date);
        const bEnd = Date.parse(b.end_date);
        // if neither has an end date
        if (!aEnd && !bEnd) {
          // if start dates are equal, sort by name
          if (aStart - bStart === 0) {
            return a.name.localeCompare(b.name) > 0 ? 1 : -1;
          }
          // sort by start date
          return bStart - aStart;
        }
        // push missing end dates to the beginning of the array
        if (!bEnd) return 1;
        if (!aEnd) return -1;
        // sort by end date
        return bEnd - aEnd;
      });
    },
    remove(event) {
      this.$emit('remove', event);
    },
    onCancel(associate) {
      this.inEdit = '';
      this.removeNewAssociateHolder(associate);
      this.$emit('cancel');
    },
    removeNewAssociateHolder(associate) {
      if (associate && associate._new) {
        if (this.relationType === 'associates') {
          this.removeFromAssociates(-1);
        } else {
          this.removeFromChildAssociations(-1);
        }
      }
    },
    save(id) {
      this.$emit('save', id);
    },
    // get index in the original associates array for a given associate
    getIndex(ass) {
      if (this.relationType === 'associates') {
        return this.associates.findIndex((a) => a.id === ass.id && a.row_id === ass.row_id);
      }
      return this.childAssociations.findIndex((a) => a.id === ass.id && a.row_id === ass.row_id);
    },
    calculateRoute(item) {
      return `${item.type === 'PERFORMER' ? '/performers' : '/associates'}/${item.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-row {
  height: 160px;
}
</style>
