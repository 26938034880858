import _ from 'lodash';
import { getter as vuexGetter, commit as vuexCommit } from '../store/modules/matching/utils';
import DebouncedSearchMixin from './debouncedSearchMixin';

const tableOptionsMixin = {
  data() {
    return {
      recordedInCountries: [],
      recordedInCountryCode: '',
      range: null,
      customSelect: null,
    };
  },
  mixins: [DebouncedSearchMixin(350, 'reload')],
  activated() {
    if (this.store.recordedInCountryCode) {
      this.recordedInCountryCode = this.store.recordedInCountryCode;
    }

    if (this.store.range) {
      this.range = this.store.range;
    }
  },
  deactivated() {
    this.storeRecordedInCountryCode(this.recordedInCountryCode);
    this.storeRange(this.range);
  },
  computed: {
    store() {
      return vuexGetter(_.camelCase(this.$options.name), 'matching');
    },
  },
  methods: {
    clearFilter(view, clearCountry = true) {
      if (clearCountry) {
        vuexCommit(`update${view}RecordedInCountryCode`, '');
      }
      vuexCommit(`update${view}Range`, '');
    },
    storeRecordedInCountryCode(obj) {
      vuexCommit(
        `update${_.upperFirst(_.camelCase(this.$options.name))}RecordedInCountryCode`,
        obj,
      );
    },
    storeRange(obj) {
      vuexCommit(`update${_.upperFirst(_.camelCase(this.$options.name))}Range`, obj);
    },
    onCountryChanged(countryCode) {
      this.recordedInCountryCode = countryCode;
      this.completed = 0;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.reload();
      }, 0);
    },
    onRangeStartChanged(value) {
      let prevValue = '';
      if (!this.range) {
        this.range = { start: value, end: null };
      } else {
        prevValue = this.range.start;
        this.range.start = value;
      }
      this.pagination.currentPage = 1;
      this.completed = 0;
      this.debouncedSearch(value, prevValue);
    },
    onRangeEndChanged(value) {
      let prevValue = '';
      if (!this.range) {
        this.range = { start: null, end: value };
      } else {
        prevValue = this.range.end;
        this.range.end = value;
      }
      this.pagination.currentPage = 1;
      this.completed = 0;
      if (this.range.start) {
        this.debouncedSearch(value, prevValue);
      }
    },
    onCustomSelectChange(value) {
      this.customSelect = value;
      this.completed = 0;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.reload();
      }, 0);
    },
  },
};

export default tableOptionsMixin;
