import gql from 'graphql-tag';

const getFullAlbum = gql`
  query album($idTerm: String!, $versionTerm: Int) {
    albumAggregate(id: $idTerm, version: $versionTerm) {
      id
      version
      basic_info {
        name
        alternate_titles
        main_artist {
          id
          name
          type
        }
        catalog_number
        release_date
        composer
        label
        local_codes {
          code
          value
        }
        tags
        description
      }
      tracks {
        track_title
        recording {
          id
          side_no
          track_no
        }
        recordingAggregate {
          id
          lineup_locked
          basic_info {
            name
            main_artist {
              id
              name
            }
            local_codes {
              code
              value
            }
            duration_sec
            isrc
            vrdb2_id
            vrdb2_rm
            version_title
            recording_date
            genre
            composer
          }
          distribution_state {
            status
          }
          lineup {
            relation {
              id
              name
              pseudo_names
              type
              nationality
              societies {
                society_code
                mandate_type
              }
              ipn
              collective
            }
            instruments
            role
            pseudo_name
          }
        }
      }
      readonly
      merged_with
    }
  }
`;

const getDiscographyAlbums = gql`
  query albums(
    $id: String
    $type: String
    $filterQuery: String
    $mainArtist: String
    $pagination: Pagination
  ) {
    discographyAlbums(
      id: $id
      type: $type
      filterQuery: $filterQuery
      mainArtist: $mainArtist
      pagination: $pagination
    ) {
      total
      albums {
        id
        name
        catalog_number
        release_date
        label
        main_artist {
          id
          name
          type
        }
      }
    }
  }
`;

const getTracksByAlbum = gql`
  query album($idTerm: String!) {
    albumAggregate(id: $idTerm) {
      tracks {
        recordingAggregate {
          lineup {
            relation {
              id
            }
          }
          lineup_locked
          basic_info {
            name
            duration_sec
            main_artist {
              id
              name
              type
            }
          }
        }
        recording {
          id
        }
      }
    }
  }
`;

export default {
  getFullAlbum,
  getDiscographyAlbums,
  getTracksByAlbum,
};
