<template>
  <div>
    <h2 class="margin--bottom">Summary</h2>
    <div class="card">
      <div class="row">
        <div class="col s12">
          <div class="total-amount text--right push--bottom">
            <span class="margin--right">
              <span class="text--bold">Distribution closure year:</span>
              {{ distributionClosure.header && distributionClosure.header.year }}
            </span>
            <span class="text--bold">Total amount:</span>
            {{ totalAmount | formatAmountCent('SEK') }}
          </div>
          <div class="push--bottom">
            <table>
              <sortable-head
                :columns="summaryColumns"
                :bulk-enabled="false"
                :more-options="false"
                :default-sort-should-override="true"
                :disabled="distributionClosure.rows.length === 0"
              />
              <tbody>
                <template v-if="!ready">
                  <tr class="spinner-row">
                    <td colspan="4">
                      <component-spinner />
                    </td>
                  </tr>
                </template>
                <template v-else-if="distributionClosure.rows.length > 0">
                  <tr v-for="row in distributionClosure.rows" :key="row.ledgerId">
                    <td>{{ row.performanceYear }}</td>
                    <td>{{ row.type | toTitleCase }}</td>
                    <td class="amount-row">
                      {{ row.totalNetAmount | formatAmountCent('SEK') }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="none" colspan="3">Nothing to close</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <h2 class="margin--bottom">Details</h2>
    <div class="card">
      <div class="row">
        <div class="tabs">
          <div class="tabs__wrapper">
            <a
              v-for="tab in tabs"
              :key="tab.name"
              :class="{ active: activeTab === tab.name, disabled: closureDetailsLoading }"
              @click="selectTab(tab.name)"
            >
              {{ tab.label }}
              <span v-if="!closureDetailsLoading">
                ({{ closureDetails.total[tab.name] | formatNumber }})
              </span>
            </a>
          </div>
        </div>
        <div class="total-amount text--right push--bottom">
          <template v-if="!closureDetailsLoading">
            <span class="text--bold">Total amount:</span>
            {{ closureDetails.amount[activeTab] | formatAmountCent('SEK') }}
          </template>
        </div>
        <div class="push--bottom">
          <table>
            <sortable-head
              :columns="detailsColumns"
              :bulk-enabled="false"
              :more-options="false"
              :default-sort-should-override="true"
              :disabled="closureDetailsLoading"
              @sort="clickSort"
            />
            <tbody>
              <template v-if="closureDetailsLoading">
                <tr class="spinner-row">
                  <td colspan="4">
                    <component-spinner />
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="row in closureDetails.rows"
                  :key="`${row.streamId}-${row.performanceYear}`"
                >
                  <td>{{ row.performanceYear }}</td>
                  <td>
                    <router-link
                      :to="{
                        name:
                          row.type === AGGREGATE_TYPES.PERFORMER
                            ? 'performerView'
                            : 'associateView',
                        params: { id: row.streamId },
                      }"
                    >
                      {{ row.name }}
                    </router-link>
                  </td>
                  <td>{{ row.type | toTitleCase }}</td>
                  <td class="amount-row">{{ row.amount | formatAmountCent('SEK') }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination
          v-if="closureDetails.rows.length > 0"
          :loading="closureDetailsLoading"
          :number-of-pages="paging.numberOfPages"
          :number-of-hits="closureDetails.total[activeTab]"
          :selected-page="Math.floor(paging.offset / paging.limit) + 1"
          :hits-per-page="paging.limit"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SortableHead from '../../ui/table/sortable-head';
import ComponentSpinner from '../../component-spinner';
import Pagination from '@/components/pagination';
import { AGGREGATE_TYPES } from '@/domain/common';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import PaymentService from '@/services/paymentService';

export default {
  name: 'DistributionClosureInformation',
  components: {
    ComponentSpinner,
    Pagination,
    SortableHead,
  },
  props: {
    distributionClosure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      AGGREGATE_TYPES,
      working: false,
      ready: false,
      totalAmount: 0,
      closureDetailsLoading: true,
      closureDetailsPaging: {
        limit: null,
        offset: null,
      },
      closureDetails: {
        total: {
          calculated: 0,
          exception: 0,
        },
        amount: {
          calculated: 0,
          exception: 0,
        },
        rows: [],
      },
      paging: {
        limit: 10,
        offset: 0,
        numberOfPages: 0,
      },
      sorting: {
        orderBy: 'performanceYear',
        dir: 'desc',
      },
      approved: this.distributionClosure.header?.state === distributionClosureState.Approved,
      activeTab: 'calculated',
      tabs: [
        {
          name: 'calculated',
          label: 'Calculated',
          className: 'fas fa-times-circle text--red',
        },
        {
          name: 'exception',
          label: 'Exception',
          className: 'fas fa-times-circle',
        },
      ],
      summaryColumns: [
        { name: 'Performance Year', active: false },
        { name: 'Type', active: false },
        {
          name: 'Amount',
          ascending: false,
          active: true,
          styleClass: 'amount-row',
        },
      ],
      detailsColumns: [
        { name: 'Performance Year', ascending: false, active: false, sortParam: 'performanceYear' },
        { name: 'Name', active: false, sortParam: 'name' },
        { name: 'Type', sortParam: 'type' },
        {
          name: 'Amount',
          ascending: false,
          active: true,
          styleClass: 'amount-row',
          sortParam: 'amount',
        },
      ],
      verifyWithPasswordMessage:
        ' I hereby confirm that I want to perform\n' + '          a distribution closure.\n',
    };
  },
  created() {
    if (
      this.distributionClosure?.header?.state &&
      [
        distributionClosureState.Ready,
        distributionClosureState.Approved,
        distributionClosureState.Confirmed,
      ].includes(this.distributionClosure.header.state)
    ) {
      this.ready = true;
      this.totalAmount = this.distributionClosure.header.totalAmount;
      this.fetchClosureDetails();
    }
  },
  methods: {
    selectTab(tabName) {
      if (this.activeTab !== tabName) {
        this.activeTab = tabName;
        this.paging.offset = 0;
        this.fetchClosureDetails();
      }
    },
    selectPage(page) {
      this.paging.offset = (page - 1) * this.paging.limit;
      this.fetchClosureDetails();
    },
    updateHitsPerPage(nbr) {
      this.paging.limit = Number(nbr);
      this.fetchClosureDetails();
    },
    clickSort(column) {
      this.sorting.orderBy = column.sortParam;
      this.sorting.dir = column.order;
      this.paging.offset = 0;
      this.fetchClosureDetails();
    },
    async fetchClosureDetails() {
      this.closureDetailsLoading = true;
      const distributionClosureId = this.distributionClosure.header.distributionClosureId;
      const request = {
        distributionClosureId,
        dcException: this.activeTab === 'exception',
        limit: this.paging.limit,
        offset: this.paging.offset,
        orderBy: this.sorting.orderBy,
        dir: this.sorting.dir,
      };
      this.closureDetails = await PaymentService.getDistributionClosureDetails(request);
      this.paging.numberOfPages = Math.ceil(
        this.closureDetails.total[this.activeTab] / this.paging.limit,
      );
      this.closureDetailsLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.calculation-header {
  min-height: 20px;
}

.total-amount {
  min-height: 50px;
}

.tabs {
  margin-bottom: var(--spacing-double);
}
</style>
