<template>
  <div class="compare__action">
    <template v-if="action && visible">
      <i class="fas" :class="actionCssClass" />
      <button
        :disabled="!enabled"
        class="btn__compare"
        :class="btnClasses.btnClass"
        @click="handleClick()"
      >
        <i :class="btnClasses.btnIcon" />
        <span>{{ getDisplayedAction | uppercase }}</span>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ActionColumn',
  props: {
    action: {
      type: String,
      default: undefined,
    },
    actionName: {
      type: String,
      default: undefined,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      btnIcon: '',
      btnClass: 'btn__compare--ok btn__compare--right',
    };
  },
  computed: {
    getDisplayedAction() {
      if (this.actionName) {
        return this.actionName;
      }
      if (!this.enabled) {
        return 'Auto';
      }
      if (this.dirty) {
        return 'Undo';
      }
      if (this.action === 'update') {
        return 'Replace';
      }
      return 'Add';
    },
    btnClasses() {
      switch (this.getDisplayedAction) {
        case 'Undo':
          return {
            btnClass: 'btn__compare--idle btn__compare--left',
            btnIcon: 'fas fa-undo-alt',
          };
        case 'Add':
          return {
            btnClass: 'btn__compare--ok btn__compare--right',
            btnIcon: 'far fa-plus-square',
          };
        case 'Replace':
          return {
            btnClass: 'btn__compare--ok btn__compare--right',
            btnIcon: 'fas fa-sync-alt',
          };
        case 'Auto':
          return {
            btnClass: 'btn__compare--auto btn__compare--right',
            btnIcon: 'fas fa-arrow-alt-circle-right',
          };
        default:
          return {
            btnClass: 'btn__compare--ok btn__compare--right',
            btnIcon: '',
          };
      }
    },
    actionCssClass() {
      switch (this.status) {
        case 'idle':
          return 'fa-exclamation-circle text--orange';
        case 'ok':
          return 'fa-check-circle text--green';
        case 'warning':
          return 'fa-exclamation-triangle text--yellow';
        case 'error':
          return 'fa-times-circle text--red';
        default:
          return '';
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit('action', this.action);
    },
  },
};
</script>

<style lang="scss">
.compare {
  &__action {
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > i {
      font-size: 16px;
    }

    button {
      & > i {
        font-size: 12px;
      }
    }
  }
}
</style>
