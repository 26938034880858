<template>
  <div>
    <div v-if="reportLines.length > 0" class="push--bottom text--right">
      <button class="btn" :disabled="processId" @click="applyChanges">Apply changes</button>
    </div>

    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :worklists="worklists"
          :worklist-id="selectedWorklist"
          :bulk-actions="bulkEditActions"
          :bulk-action-enabled="anySelected()"
          @worklistChanged="onWorklistChanged"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @bulkAction="onBulkAction"
        />
      </div>
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="true"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareRecordingPlaylist
            ref="comparelist"
            :process-type="processType"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :get-candidate-value="getCandidateValue"
            :get-match-state="getMatchState"
            :lineup-state-mask="lineupStateMask"
            :action-enabled="true"
            @change="updateDirtyState"
            @rejectAll="completeAndContinue"
            @acceptAll="completeAndContinue"
          />
        </table>

        <pagination
          v-if="numberOfPages > 0"
          class="result__pagination"
          :number-of-pages="numberOfPages"
          :selected-page="pagination.currentPage"
          :number-of-hits="totalCount"
          :hits-per-page="pagination.hitsPerPage"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>

    <modal
      v-if="showApplyModal"
      submit-label="Ok"
      :show-abort="false"
      @save="showApplyModal = false"
      @close="showApplyModal = false"
    >
      <h1 slot="header">Apply changes</h1>

      <div slot="body">
        <div class="row">
          Changes are being applied, you will be notified when the process is completed, then you
          can reload this page.
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import CompareRecordingPlaylist from '../../../compare/compare-recording-playlist';
import CompareSort from '../../../compare/compare-sort';
import ViewPlaylistMixin from './view-playlist-mixin';
import Pagination from '../../../pagination';
import PaginationAndSortMixin from '../../../../common/paginationAndSortMixin';
import { playlistLineupStates } from '../../../../domain/matchingDomain';
import MatchingService from '../../../../services/matchingService';
import TableOptions from '../../../ui/table/table-options';
import TableOptionsMixin from '../../../../common/tableOptionsMixin';
import Modal from '../../../ui/dialog/modal';

export default {
  name: 'ViewPlaylistAccepted',
  components: {
    CompareRecordingPlaylist,
    CompareSort,
    Pagination,
    TableOptions,
    Modal,
  },
  mixins: [PaginationAndSortMixin, ViewPlaylistMixin, TableOptionsMixin],
  data() {
    return {
      bulkEditActions: [{ key: 'rematch', value: this.$t('matching.bulk_action.move_to_check') }],
      lineupStateMask: playlistLineupStates.ACCEPTED,
      applying: false,
      processId: undefined,
      showApplyModal: false,
    };
  },
  activated() {
    const expandedId = this.viewPlaylistAccepted.expandedId;
    this.loadPaginated().then(() => {
      if (expandedId) {
        this.toggleExpanded(expandedId);
      }
    });
  },
  deactivated() {
    this.updateViewPlaylistAcceptedExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    async applyChanges() {
      this.processId = uuid.v4();
      this.loading = true;

      try {
        this.$store.dispatch('process/addProcess', {
          name: `Apply recordings`,
          id: this.processId,
        });

        await MatchingService.applyAllPlaylistChanges({
          society_id: this.getCodeForSociety(),
          process_type: this.processType,
          report_id: this.reportId,
          process_id: this.processId,
        });
        this.showApplyModal = true;
      } catch (error) {
        this.$store.dispatch('process/removeProcess', this.processId);
        this.processId = undefined;
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async onBulkAction() {
      this.loading = true;
      const internalIds = this.getSelected().map((selected) => selected.incoming.internal_id);
      try {
        await MatchingService.rematchPlaylistRecording({ internal_recording_ids: internalIds });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      await this.fetchData();
      this.$emit('updated');
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  &__options {
    justify-content: space-between;
  }
  &__item {
    flex: 1;
    align-items: center;
  }
  &__text {
    margin-right: var(--spacing);
  }
  &__modifier {
    & + & {
      margin-left: var(--spacing);
    }
    div {
      width: 180px !important;
    }
  }
}
</style>
