<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Transaction</h1>
      </div>
    </div>
    <Spinner v-if="loading" />
    <div v-else class="row card">
      <div class="row">
        <div class="col s12">
          <h3>Transaction Entries</h3>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <table>
            <thead>
              <th>Type</th>
              <th>Account</th>
              <th class="text--right">Amount</th>
            </thead>
            <tbody>
              <tr v-for="entry of entriesToDisplay" :key="entry.id">
                <td>{{ entryType(entry.entryType) }}</td>
                <td v-if="entry.node">
                  <router-link :to="getLink(entry.node)">
                    {{ entry.node.name }}
                  </router-link>
                  ({{ entry.node.type }})
                </td>
                <td v-else>{{ `payment.system_account.${entry.accountId}` | translate }}</td>
                <td
                  class="text--right"
                  :class="{ negative: getAmount(entry) < 0, positive: getAmount(entry) > 0 }"
                >
                  {{ getAmount(entry) | formatAmountCent('SEK') }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="totEntries > displaySize">
            <a @click.prevent="showAllEntries = !showAllEntries">
              <p v-if="!showAllEntries">Show {{ totEntries - entriesToDisplay.length }} more</p>
              <p v-else>Show less</p>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h3>Transaction Source</h3>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <Transfer v-if="isTransfer" :id="transaction.sourceId" />
          <Payment v-else-if="isPayment" :id="transaction.sourceId" />
          <Refund v-else-if="isRefund" :id="transaction.sourceId" />
          <div v-else>Source type: {{ sourceType }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../services/apolloRequest';
import Spinner from '@/components/spinner';
import Transfer from './sources/transfer';
import Payment from './sources/payment';
import Refund from './sources/refund';
import { txSourceType, EntryType } from '@/domain/paymentDomain';

export default {
  components: {
    Spinner,
    Transfer,
    Payment,
    Refund,
  },
  props: {
    txId: String,
  },
  data() {
    return {
      transaction: null,
      loading: false,
      displaySize: 10,
      showAllEntries: false,
    };
  },
  computed: {
    totEntries() {
      return this.transaction.entries.length;
    },
    entriesToDisplay() {
      if (this.showAllEntries) {
        return this.transaction.entries;
      }
      return this.transaction.entries.slice(0, this.displaySize);
    },
    isTransfer() {
      return this.transaction.sourceType === txSourceType.Transfer;
    },
    isPayment() {
      return this.transaction.sourceType === txSourceType.Payment;
    },
    isRefund() {
      return this.transaction.sourceType === txSourceType.Refund;
    },
    sourceType() {
      return txSourceType[this.transaction.sourceType];
    },
  },
  async created() {
    this.loading = true;
    try {
      const { transaction } = await query({
        query: gql`
          query {
            transaction(txId: "${this.txId}") {
              streamId
              version
              sourceType
              sourceId
              entries {
                entryId
                entryType
                specIds
                accountId
                node {
                  name
                  id
                  type
                }
              }
              specs {
                id
                amount
                vatBase
                detail
              }
            }
          }
        `,
      });
      this.transaction = transaction;
    } catch (error) {
      this.$addStarError({
        title: 'Could not load transaction',
        type: `${error.statusCode} - ${error.code}`,
        message: error.message,
      });
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    entryType(type) {
      return EntryType[type];
    },
    getAmount(entry) {
      const amount = this.transaction.specs
        .filter((s) => entry.specIds.includes(s.id))
        .reduce((sum, spec) => sum + spec.amount, 0);
      if (entry.entryType === EntryType.Debit) {
        return -amount;
      }
      return amount;
    },
    getLink(node) {
      if (node.type === 'performer') {
        return `/performers/${node.id}/information`;
      }
      return `/associates/${node.id}/information`;
    },
  },
};
</script>

<style scoped>
dl {
  line-height: 1.8em;
}
dt {
  flex-basis: 20%;
}
dd {
  flex-basis: 80%;
}
</style>
