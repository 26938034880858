var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrapper"},[_c('table',{class:{ loading: _vm.loading, sticky: _vm.stickyHeader }},[_c('thead',[_c('tr',[(_vm.expandKey)?_c('th',{staticClass:"thin-column"}):_vm._e(),_vm._l((_vm.columns),function(column){return [_c('th',{key:column.key,class:{ sortable: column.sortable, 'text--right': column.count },on:{"click":function($event){return _vm.onColumnClick(column)}}},[_vm._v(" "+_vm._s(column.text)+" "),(column.sortable)?[_c('i',{staticClass:"fas",class:{
                  'fa-caret-up':
                    (column.key === _vm.sort.order_by && _vm.sort.direction === 'asc') ||
                    column.ascending,
                  'fa-caret-down':
                    (column.key === _vm.sort.order_by && _vm.sort.direction === 'desc') ||
                    !column.ascending,
                  'text--grey': column.key !== _vm.sort.order_by,
                }})]:_vm._e()],2)]})],2)]),_c('tbody',[(_vm.loading)?[_c('Spinner',{staticClass:"table-spinner"})]:_vm._l((_vm.localSort ? _vm.sortedItems : _vm.items),function(item,index){return [_vm._t("item",function(){return [_c('tr',{key:item[_vm.itemKey]},[(_vm.expandKey)?_c('td',{staticClass:"thin-column dropdown-button__arrow",on:{"click":function($event){return _vm.toggleExpanded(item[_vm.itemKey])}}},[_c('i',{class:("fas fa-caret-" + (_vm.expanded === item[_vm.itemKey] ? 'up' : 'down'))})]):_vm._e(),_vm._l((_vm.columns),function(column){return [_c('td',{key:column.key,class:{ 'text--right': column.count }},[_vm._t(("item:" + (column.key)),function(){return [_vm._v(" "+_vm._s(item[column.key])+" ")]},{"item":item,"index":index})],2)]})],2),(_vm.expanded === item[_vm.itemKey])?_c('tr',{key:((item[_vm.itemKey]) + "-exp")},[_c('td',{attrs:{"colspan":"100"}},[_vm._t(("item:" + _vm.expandKey),function(){return [_vm._v(" "+_vm._s(item[_vm.expandKey])+" ")]},{"item":item,"index":index})],2)]):_vm._e()]},{"item":item})]})],2)]),(_vm.totalItems > 0)?_c('div',{staticClass:"result__pagination"},[_c('Pagination',{attrs:{"topic":_vm.topic,"number-of-pages":_vm.pageCount,"selected-page":_vm.selectedPage,"number-of-hits":_vm.totalItems,"hits-per-page":_vm.pageSize},on:{"selectPage":_vm.selectPage,"updateHitsPerPage":_vm.selectPageSize}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }