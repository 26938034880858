<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>Distribution Areas</h1>
      </div>
      <div class="col s6 text--right">
        <a @click="onCreateNew">
          <i class="far fa-edit" />
          Create distribution area
        </a>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s3 float-left">
          <filter-input
            :filter-query="filterQuery"
            placeholder="E.g. Sveriges radio"
            @input="filterSearch"
          />
        </div>
      </div>

      <div class="row card">
        <div>
          <div class="row">
            <div class="col s12">
              <table>
                <thead>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                    @click="column.sortName ? sort(column) : ''"
                  >
                    <span>{{ column.name }}</span>
                    <span v-if="column.sortName && !column.thinColumn">
                      <span v-if="column.sortParam" class="dropdown-button__arrow">
                        <i
                          v-if="column.order === 'desc'"
                          class="fas fa-caret-down"
                          :class="{ 'text--grey': !column.active }"
                        />
                        <i
                          v-else
                          class="fas fa-caret-up"
                          :class="{ 'text--grey': !column.active }"
                        />
                      </span>
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="area in searchContext.result.results" :key="area.id">
                    <td>
                      <span class="fs-14">{{ area.name }}</span>
                    </td>
                    <td>{{ area.distribution_rule.name }}</td>
                    <td>
                      <span v-if="area.category_of_rights">
                        {{ area.category_of_rights | formatRight }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <context-menu :options="['Edit']" @edit="onEdit(area.id)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="searchContext.isExecuted" class="col s12">
          <pagination
            v-show="numberOfPages > 0"
            :number-of-pages="numberOfPages"
            :selected-page="pagination.page"
            :number-of-hits="searchContext.result.totalHits"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createAggregate } from '../../../store/modules/distributionarea/utils';
import ContextMenu from './../../ui/context-menu';
import DataTableMixin from '../data-table-mixin';
import DistributionService from '../../../services/distributionService';
import FilterInput from './../../ui/input/filter-input';
import Pagination from './../../pagination';
import Spinner from '../../spinner';

export default {
  name: 'ViewDistributionAreas',
  components: {
    ContextMenu,
    FilterInput,
    Pagination,
    Spinner,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      viewRouteName: 'distributionAreasView',
      columns: [
        {
          name: 'Name',
          sortName: 'name',
          order: 'asc',
          sortParam: true,
          filterable: true,
        },
        {
          name: 'Payment Rule',
          sortName: 'distribution_rule_id',
        },
        {
          name: 'Category of Rights',
          sortName: 'category_of_rights',
        },
        {
          name: '',
          thinColumn: true,
        },
      ],
    };
  },
  async created() {
    createAggregate();
  },
  methods: {
    async reloadBaseData() {
      const result = await DistributionService.getDistributionAreas();
      this.baseData = result.areas;
    },
    onEdit(id) {
      this.$router.push({
        name: 'distributionAreaEdit',
        params: { id },
      });
    },
    onCreateNew() {
      this.$router.push({
        name: 'distributionAreaCreate',
      });
    },
  },
};
</script>
