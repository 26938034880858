<template>
  <div>
    <div class="row small-inner">
      <div class="col s6">
        <h2>External Information</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a :class="{ disabled: routeVersion() !== null }" @click="$emit('edit', $options.name)">
          <template v-if="otherExternalSources.length > 0">
            <i class="fas fa-pencil-alt" />
            Edit
          </template>
          <template v-else>
            <i class="far fa-plus-square" />
            Add
          </template>
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <ul>
          <li v-for="(source, index) in otherExternalSources" :key="index">
            <a :href="source.url | toHref" target="_blank" :title="source.source">
              {{ source.source | toTitleCase }}
            </a>
          </li>
        </ul>
        <span v-if="otherExternalSources.length === 0" class="none">None</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AggregateMixin from '../../../../common/aggregateMixin';

export default {
  name: 'ExternalSources',
  components: {},
  mixins: [AggregateMixin],
  data() {
    return {};
  },
  computed: mapGetters('performer', ['otherExternalSources']),
};
</script>
