var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s6"},[_c('spinner')],1)]):_c('div',{staticClass:"row card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('table',[_c('thead',_vm._l((_vm.columns),function(column){return _c('th',{key:column.id,class:[column.styleClass]},[_vm._v(" "+_vm._s(column.name)+" ")])}),0),_c('tbody',_vm._l((_vm.recordings),function(recording){return _c('tr',{key:recording.id},[_c('td',[(recording.title)?_c('span',[_c('router-link',{attrs:{"to":'/recordings/' + recording.id}},[_vm._v(" "+_vm._s(recording.title)+" ")])],1):_c('span',{staticClass:"none"},[_vm._v("None")])]),_c('td',[(recording.version_title)?_c('span',[_vm._v(" "+_vm._s(recording.version_title)+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]),_c('td',[(recording.main_artist)?_c('span',[_vm._v(" "+_vm._s(recording.main_artist)+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]),_c('td',[(recording.vrdb2_id)?_c('span',[_vm._v(" "+_vm._s(recording.vrdb2_id)+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]),_c('td',[(recording.playing_time_sec)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toMinutes")(recording.playing_time_sec))+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]),_c('td',[(recording.prev_produced_in_country !== recording.produced_in_country)?_c('span',{staticClass:"margin--right",class:{
                    none: !recording.prev_produced_in_country,
                    'text--line-through':
                      recording.prev_produced_in_country !== recording.produced_in_country,
                  }},[_vm._v(" "+_vm._s(_vm._f("formatCountry")(recording.prev_produced_in_country || 'None'))+" ")]):_vm._e(),_c('span',{class:{
                    none: !recording.produced_in_country,
                  }},[_vm._v(" "+_vm._s(_vm._f("formatCountry")(recording.produced_in_country || 'None'))+" ")])]),_c('td',[(recording.prev_recording_country !== recording.recording_country)?_c('span',{staticClass:"margin--right",class:{
                    none: !recording.prev_recording_country,
                    'text--line-through':
                      recording.prev_recording_country !== recording.recording_country,
                  }},[_vm._v(" "+_vm._s(_vm._f("formatCountry")(recording.prev_recording_country || 'None'))+" ")]):_vm._e(),_c('span',{class:{
                    none: !recording.recording_country,
                  }},[_vm._v(" "+_vm._s(_vm._f("formatCountry")(recording.recording_country || 'None'))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(recording.date_updated))+" ")]),_c('td',[_c('context-menu',{attrs:{"options":['Remove from list']},on:{"removeFromList":function($event){return _vm.deleteRecording(recording.recording_id)}}})],1)])}),0)])])]),_c('pagination',{attrs:{"loading":_vm.loading,"number-of-pages":_vm.searchContext.numberOfPages,"selected-page":_vm.pagination.page,"number-of-hits":_vm.searchContext.numberOfHits,"hits-per-page":_vm.pagination.hitsPerPage},on:{"selectPage":_vm.selectPage,"updateHitsPerPage":_vm.updateHitsPerPage}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row wrapper"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("Protection Changes")])])])}]

export { render, staticRenderFns }