<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>
    <div class="row">
      <div class="col s12">
        <TableOptions
          :worklists="worklists"
          :worklist-id="selectedWorklist"
          :bulk-actions="bulkEditActions"
          :bulk-action-enabled="anySelected()"
          :custom-select="samiLineupSelect"
          @worklistChanged="onWorklistChanged"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @bulkAction="onBulkAction"
          @customSelectChanged="onCustomSelectChange"
        />
      </div>
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <modal
          v-if="showRematchMergeModal"
          submit-label="Ok"
          :show-abort="false"
          :small="true"
          @save="closeRematchMergeModal"
          @close="closeRematchMergeModal"
        >
          <h1 slot="header">Rematching...</h1>

          <div slot="body">
            <div class="row">
              The accepted lineup contained merges.
              <p>
                Star is now rematching other recordings associated with the merged performer(s).
              </p>
            </div>
          </div>
        </modal>

        <modal
          v-if="showRejectAllModal"
          :disable-footer="true"
          :hide-overflow="false"
          @close="showRejectAllModal = false"
        >
          <h1 slot="header">Reject all</h1>
          <rejection-dialog slot="body" @cancel="showRejectAllModal = false" @reject="rejectAll" />
        </modal>

        <table>
          <CompareSort
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareRecordingPlaylist
            ref="comparelist"
            :process-type="processType"
            :get-candidate-value="getCandidateValue"
            :get-match-state="getMatchState"
            :is-expanded="isExpanded"
            :lineup-state-mask="lineupStateMask"
            :toggle-expanded="toggleExpanded"
            @acceptAll="accept"
            @change="updateDirtyState"
            @refreshRecording="reloadCompared"
            @rejectAll="completeAndContinue"
          />
        </table>

        <pagination
          v-if="numberOfPages > 0"
          class="result__pagination"
          :number-of-pages="numberOfPages"
          :selected-page="pagination.currentPage"
          :number-of-hits="totalCount"
          :hits-per-page="pagination.hitsPerPage"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CompareRecordingPlaylist from '../../../compare/compare-recording-playlist';
import CompareSort from '../../../compare/compare-sort';
import Modal from '../../../ui/dialog/modal';
import ViewPlaylistMixin from './view-playlist-mixin';
import Pagination from '../../../pagination';
import PaginationAndSortMixin from '../../../../common/paginationAndSortMixin';
import { playlistLineupStates } from '../../../../domain/matchingDomain';
import TableOptions from '../../../ui/table/table-options';
import TableOptionsMixin from '../../../../common/tableOptionsMixin';
import MatchingService from '../../../../services/matchingService';
import RejectionDialog from '../../../ui/dialog/rejection-dialog';

export default {
  name: 'ViewPlaylistToCheck',
  components: {
    CompareRecordingPlaylist,
    CompareSort,
    Modal,
    Pagination,
    TableOptions,
    RejectionDialog,
  },
  mixins: [PaginationAndSortMixin, ViewPlaylistMixin, TableOptionsMixin],
  data() {
    return {
      bulkEditActions: [
        { key: 'accept_all', value: this.$t('matching.bulk_action.accept_all') },
        { key: 'reject_all', value: this.$t('matching.bulk_action.reject_all') },
      ],
      lineupStateMask: playlistLineupStates.CONFLICT,
      showRematchMergeModal: false,
      showRejectAllModal: false,
      samiLineupSelect: {
        name: 'SAMI lineup',
        selected: null,
        items: [
          { key: 'empty', name: 'Empty' },
          { key: 'not_empty', name: 'Not empty' },
        ],
        itemKey: 'key',
        itemValue: 'name',
      },
    };
  },
  async activated() {
    const expandedId = this.viewPlaylistToCheck.expandedId;
    await this.loadPaginated();
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewPlaylistToCheckExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    async closeRematchMergeModal() {
      await this.fetchData();
      this.showRematchMergeModal = false;
    },
    async accept(e) {
      this.completeAndContinue(e);
      if (e.rematchedRecordingIds && e.rematchedRecordingIds.length > 0) {
        this.showRematchMergeModal = true;
      }
    },
    async onBulkAction(action) {
      switch (action) {
        case 'accept_all':
          await this.acceptAll();
          break;
        case 'reject_all':
          this.showRejectAllModal = true;
          break;
        default:
          throw new Error(`Unknown action: ${action}`);
      }
    },
    async acceptAll() {
      this.loading = true;
      const selected = this.getSelected().filter((line) => !line.incoming.lineup_locked);
      await Promise.all(
        selected.map(async (line) => {
          if (!line.incoming.lineup) {
            const playlistRecording = await MatchingService.getPlaylistRecording({
              recording_id: line.incoming.id,
            });
            line.incoming.lineup = playlistRecording.lineup;
          }
        }),
      );
      const recordings = selected.map((line) => ({
        recording_id: line.incoming.id,
        new_performers: this.findNewPerformers(line),
      }));

      try {
        const result = await MatchingService.acceptPlaylistRecordingsLineup(recordings);
        if (result.recording_ids.length) {
          this.showRematchMergeModal = true;
        }
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
      this.loading = false;
      await this.fetchData();
      this.$emit('updated');
    },
    async rejectAll(rejection) {
      this.showRejectAllModal = false;
      this.loading = true;
      const recordings = this.getSelected().map((line) => ({
        recording_id: line.incoming.id,
        society_code: line.incoming.society_code,
        reason: rejection.reason,
        note: rejection.note,
      }));

      try {
        await MatchingService.rejectPlaylistRecordingsLineup(recordings);
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
      this.loading = false;
      await this.fetchData();
      this.$emit('updated');
    },
    findNewPerformers(line) {
      return line.incoming.lineup
        .filter((o) => !o.artist)
        .map((o) => {
          const obj = {
            id: o.id,
          };
          if (o.incoming_artist.role_code_old) {
            obj.role_code = o.incoming_artist.role_code;
          }
          return obj;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  &__options {
    justify-content: space-between;
  }
  &__item {
    flex: 1;
    align-items: center;
  }
  &__text {
    margin-right: var(--spacing);
  }
  &__modifier {
    & + & {
      margin-left: var(--spacing);
    }
    div {
      width: 180px !important;
    }
  }
}
</style>
