var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row card"},[_c('DataTable',{attrs:{"columns":_vm.currentColumns,"items":_vm.currentData},scopedSlots:_vm._u([{key:"item:sequence_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.sequence_no))+" ")]}}])})],1),_vm._m(2),_c('div',{staticClass:"row card report__headers"},[_c('DataTable',{attrs:{"columns":_vm.progressColumns,"items":_vm.progressData},scopedSlots:_vm._u([{key:"item:status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"chip",class:'report__headers-chip--' + (item.unprocessed > 0 ? 'wait' : 'ok')},[_vm._v(" "+_vm._s(item.unprocessed > 0 ? 'Processing' : 'Idle')+" ")])]}},{key:"item:unprocessed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.unprocessed))+" ")]}},{key:"item:sequence_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.sequence_no))+" ")]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row wrapper"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("Projectors Progress")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row small-inner"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v("Current Sequence No")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row small-inner"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v("Projectors Progress")])])])}]

export { render, staticRenderFns }