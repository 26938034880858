<template>
  <div class="col s12">
    <div class="tabs">
      <div class="tabs__wrapper">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="{ query: getTabQueryParams(tab) }"
          active-class="none"
          :class="{ active: activeTab.name === tab.name }"
          replace
        >
          <i :class="tab.className"></i>
          <span>{{ tab.label }} ({{ sumStateStats(tab.filterStates).totalCount }})</span>
          <div class="tab-amount">
            {{ getTotalPayable(tab.filterStates) | formatAmountCent('SEK') }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="result-filter">
      <div v-if="activeTab.name === 'errors'">
        <button
          class="btn"
          style="display: flex; flex-direction: row; align-items: center"
          type="button"
          :disabled="!mailingListButtonActive"
          @click.prevent="createMailingList"
        >
          Create/Refill mailing list&nbsp;
          <process-progress-circle
            v-if="!mailingListButtonActive"
            :id="mailingListProcessId"
            @finished="mailingListButtonActive = true"
          />
        </button>
      </div>
      <div v-if="queryParams.graphId" class="payment__node-filter">
        <span v-if="filterByNode">
          Filtering rows related to
          <router-link :to="`performers/${filterByNode.nodeId}`">
            {{ filterByNode.name }} ({{ filterByNode.type }})
          </router-link>
        </span>
        <span v-else>Remove node filter</span>
        <a class="remove-node-filter" @click="resetFilterByNode()">
          <span class="fa fa-times"></span>
        </a>
        <div>
          <input
            id="filter-sources"
            v-model="filterTargetNodeId"
            type="checkbox"
            class="filled-in"
            @click="toggleFilterTargetNodeId"
          />
          <label for="filter-sources">Direct sources only</label>
        </div>
      </div>
      <div v-if="activeTab.filterStates.length > 1" class="payment__error-types">
        <select-generic
          name="errorType"
          label="State"
          :value="selectedState"
          :data="errorSelectValues"
          @input="selectFilter"
        />
      </div>
      <div class="label-inline">
        <select-input
          name="position"
          label="Position"
          :show-label="true"
          :items="nodePositions"
          :value="selectedPosition"
          @input="setPositionFilter"
        />
      </div>
      <div class="label-inline">
        <select-input
          name="node-type"
          label="Node Type"
          :show-label="true"
          :items="nodeTypes"
          :value="nodeTypeFilter"
          @input="setNodeTypeFilter"
        />
      </div>
      <div class="label-inline">
        <filter-input
          name="node-name"
          label="Name"
          placeholder="E.g. Joakim Berg"
          :filter-query="nodeNameFilter"
          @input="setNameFilter"
        />
      </div>
      <div class="label-inline">
        <filter-input
          name="account-no"
          label="Account No"
          placeholder="E.g. 5308123"
          :filter-query="accountNoFilter"
          @input="setAccountNoFilter"
        />
      </div>
      <div class="label-inline">
        <filter-input
          name="total-amount"
          label="Total"
          placeholder="E.g. 1000"
          :filter-query="totalAmountFilter"
          @input="setTotalAmountFilter"
        />
      </div>
      <div>
        <button class="btn" type="button" @click.prevent="resetFilter">Reset Filter</button>
      </div>
    </div>
    <ComponentSpinner v-if="loading" />
    <bulk-table
      v-else
      ref="rowsBulkTable"
      name="Payment nodes"
      :columns="columnsToDisplay"
      :item-ids="paymentRowIds"
      :enable-row-options="false"
      @sort="onSort"
    >
      <template v-for="(row, idx) in rows">
        <template :slot="idx">
          <td :key="`${idx}_position`">
            {{ getRowPosition(row) }}
          </td>
          <td :key="`${idx}_link`">
            <router-link :to="buildNodeLink(row)">
              <person-status :payment-receiver="row">
                {{ row.name }}
              </person-status>
            </router-link>
          </td>
          <td :key="`${idx}_type`" :class="{ 'text--red': rowIsBlocked(row) }">
            {{ row.type }}
          </td>
          <td :key="`${idx}_validation-err`" :class="{ 'text--red': rowIsBlocked(row) }">
            <span v-if="row.state === paymentNodeState.InvalidPaymentInfo">
              Invalid Payment Info:
              <i>{{ `payment.payment_info.${row.paymentInfoState}` | translate }}</i>
            </span>
            <span v-else-if="row.state === paymentNodeState.InvalidTaxInfo">
              Invalid Tax Info:
              <i>{{ `payment.tax_info.${row.taxInfoState}` | translate }}</i>
            </span>
            <span v-else>{{ getRowState(row) }}</span>
          </td>
          <td
            :key="`${row.nodeId}-td10`"
            class="amount-row"
            :class="{ 'text--red': rowIsBlocked(row) }"
          >
            {{ row.appliedNet | formatAmountCent('SEK') }}
          </td>
          <td
            :key="`${row.nodeId}-td5`"
            class="amount-row"
            :class="{ 'text--red': rowIsBlocked(row) }"
          >
            {{ row.appliedVat | formatAmountCent('SEK') }}
          </td>
          <td
            :key="`${row.nodeId}-td4`"
            class="amount-row"
            :class="{ 'text--red': rowIsBlocked(row) }"
          >
            {{ row.total | formatAmountCent('SEK') }}
          </td>
          <td
            v-if="shouldDisplayColumn(getColumnByName('Refunded'))"
            :key="`${row.nodeId}-td7`"
            class="amount-row"
            :class="{ 'text--red': rowIsBlocked(row) }"
          >
            {{ row.refundedTotal | formatAmountCent('SEK') }}
          </td>
          <td
            :key="`${row.nodeId}-td6`"
            class="amount-row"
            :class="{ 'text--red': rowIsBlocked(row) }"
          >
            {{ row.paidTotal | formatAmountCent('SEK') }}
          </td>
          <td :key="`${row.nodeId}-td8`">
            <a v-if="row.graphId" @click="setFilterByNode(row)">
              <span class="far fa-dot-circle"></span>
            </a>
          </td>
        </template>
      </template>
      <template slot="tfoot">
        <tr class="tfoot">
          <td colspan="4"></td>
          <td class="text--right">{{ totals.fundsTot | formatAmountCent('SEK') }}</td>
          <td class="text--right">{{ totals.vat | formatAmountCent('SEK') }}</td>
          <td class="text--right">{{ totals.tot | formatAmountCent('SEK') }}</td>
          <td v-if="shouldDisplayColumn(getColumnByName('Refunded'))" class="text--right">
            {{ totals.refundedTot | formatAmountCent('SEK') }}
          </td>
          <td class="text--right">{{ totals.paidTot | formatAmountCent('SEK') }}</td>
          <td></td>
        </tr>
      </template>
    </bulk-table>
    <pagination
      v-if="numberOfRows > 0"
      :number-of-pages="numberOfPages"
      :number-of-hits="numberOfRows"
      :selected-page="Math.floor(queryParams.offset / queryParams.limit) + 1"
      :hits-per-page="queryParams.limit"
      @selectPage="selectPage"
      @updateHitsPerPage="updateHitsPerPage"
    />
    <div v-if="activeTab.name === 'errors' && rows.length === 0">
      <div class="row">
        <p class="payment__no-errors">
          Hooray! No errors!
          <i class="fas fa-sun spin"></i>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { nodePositions, nodeTypes, paymentNodeState, paymentState } from '@/domain/paymentDomain';
import SelectInput from '../ui/select/select-input';
import SelectGeneric from '../ui/select/select-generic';
import FilterInput from '../ui/input/filter-input';
import BulkTable from '../ui/table/bulk-table';
import Pagination from '../pagination';
import PersonStatus from './person-status';
import RoutesMixin from '../../common/routesMixin';
import { mutate, query } from '@/services/apolloRequest';
import ComponentSpinner from '../component-spinner.vue';
import ProcessProgressCircle from '@/components/process/process-progress-circle.vue';
import * as uuid from 'uuid';
import { STATE } from '@/store/modules/process/store';

export default {
  name: 'PaymentNodeList',
  components: {
    ProcessProgressCircle,
    SelectInput,
    BulkTable,
    Pagination,
    PersonStatus,
    SelectGeneric,
    FilterInput,
    ComponentSpinner,
  },
  mixins: [RoutesMixin],
  props: {
    payment: Object,
    defaultTab: {
      type: String,
      default: 'errors',
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          name: 'Pos.',
        },
        {
          name: 'Name',
          sortParam: 'name',
          ascending: true,
        },
        {
          name: 'Type',
        },
        {
          name: 'State',
        },
        {
          name: 'Funds',
          styleClass: 'amount-row',
          sortParam: 'appliedNet',
          ascending: true,
        },
        {
          name: 'Vat',
          styleClass: 'amount-row',
          sortParam: 'vat',
          ascending: true,
        },
        {
          name: 'Total',
          sortParam: 'total',
          styleClass: 'amount-row',
          ascending: true,
        },
        {
          name: 'Refunded',
          styleClass: 'amount-row',
          sortParam: 'refundedTotal',
          displayState: paymentState.Confirmed,
          ascending: true,
        },
        {
          name: 'Disburse',
          tabSpecificNames: {
            errors: 'Skipped',
            accepted: 'Disburse',
            skipped: 'Skipped',
            blocked: 'Blocked',
          },
          styleClass: 'amount-row',
          sortParam: 'paidTotal',
          ascending: true,
        },
        {
          name: 'Filter',
        },
      ],
      stats: [],
      tabs: [
        {
          name: 'errors',
          label: 'Errors',
          className: 'fas fa-times-circle text--red',
          filterStates: [
            paymentNodeState.InvalidPaymentInfo,
            paymentNodeState.InvalidTaxInfo,
            paymentNodeState.InvalidShare,
          ],
        },
        {
          name: 'accepted',
          label: 'Accepted',
          className: 'fas fa-check-circle text--green',
          filterStates: [paymentNodeState.Approved],
        },
        {
          name: 'skipped',
          label: 'Skipped',
          className: 'fas fa-check-circle',
          filterStates: [
            paymentNodeState.InsufficientFunds,
            paymentNodeState.InvalidMandate,
            paymentNodeState.NoValidPayee,
          ],
        },
        {
          name: 'blocked',
          label: 'Blocked',
          className: 'fas fa-times-circle',
          filterStates: [paymentNodeState.Blocked],
        },
      ],
      orderBy: 'total',
      sortAscending: false,
      nameFilterTimeout: null,
      rows: [],
      filterByNode: null,
      nodeNameFilter: null,
      accountNoFilter: null,
      accountNoFilterTimeout: null,
      totalAmountFilter: null,
      totalAmountFilterTimeout: null,
      filterTargetNodeId: false,
      mailingListButtonActive: true,
      mailingListProcessId: null,
    };
  },
  computed: {
    selectedPosition() {
      return Number(this.queryParams.position);
    },
    selectedState() {
      return String(this.queryParams.stateFilter);
    },
    positionFilter() {
      return this.queryParams.position;
    },
    nodeTypeFilter() {
      return this.queryParams.nodeType;
    },
    paymentNodeState() {
      return paymentNodeState;
    },
    nodeTypes() {
      return nodeTypes.map((v) => ({
        code: v,
        name: this.$options.filters.translate(`common.${v}`),
      }));
    },
    nodePositions() {
      return Object.entries(nodePositions)
        .filter(([k, _]) => isNaN(k))
        .map(([key, val]) => {
          return {
            code: val,
            name: key,
          };
        });
    },
    errorSelectValues() {
      const states = this.activeTab.filterStates;
      return (
        states &&
        states.reduce(
          (acc, s) => ({
            ...acc,
            [s]: this.$options.filters.translate(`payment.node_state.${s}`),
          }),
          {},
        )
      );
    },
    queryParams() {
      const query = this.$route.query;
      const q = {
        tab: query.tab,
        stateFilter: query.stateFilter && Number(query.stateFilter),
        position: query.position && Number(query.position),
        limit: (query.limit && Number(query.limit)) || 25,
        offset: (query.offset && Number(query.offset)) || 0,
        orderBy: query.orderBy,
        ascending: query.ascending === 'true',
        graphId: query.graphId,
        nodeType: query.nodeType,
        nodeName: query.nodeName,
        accountNo: query.accountNo,
        totalAmount: query.totalAmount,
        targetNodeId: query.targetNodeId,
      };
      return Object.fromEntries(Object.entries(q).filter(([_, val]) => val != null && val !== ''));
    },
    paymentRowQuery() {
      const { tab, stateFilter, totalAmount, ...params } = this.queryParams;
      let states = this.activeTab.filterStates;
      if (stateFilter != null && states.includes(Number(stateFilter))) {
        states = [Number(stateFilter)];
      }
      return {
        ...params,
        state: states,
        totalAmount: this.convertAmount(totalAmount),
        issue: params.issue && parseInt(params.issue),
        paymentId: this.payment.id,
      };
    },
    paymentStatsQuery() {
      const {
        position,
        graphId,
        nodeType,
        nodeName,
        accountNo,
        targetNodeId,
        stateFilter,
        totalAmount,
      } = this.queryParams;
      return {
        paymentId: this.payment.id,
        position,
        graphId,
        nodeType,
        nodeName,
        accountNo,
        targetNodeId,
        totalAmount: this.convertAmount(totalAmount),
        state: stateFilter,
      };
    },
    activeTab() {
      return (
        this.tabs.find((t) => t.name === (this.$route.query.tab || this.defaultTab)) || this.tabs[0]
      );
    },
    paymentRowIds() {
      return this.rows.map((r, idx) => String(idx));
    },
    numberOfRows() {
      const res = {
        errors: this.sumStateStats(this.getTabConfig('errors').filterStates),
        accepted: this.sumStateStats(this.getTabConfig('accepted').filterStates),
        skipped: this.sumStateStats(this.getTabConfig('skipped').filterStates),
        blocked: this.sumStateStats(this.getTabConfig('blocked').filterStates),
      }[this.activeTab.name];
      return this.payeesOnly ? res.payeeCount : res.totalCount;
    },
    numberOfPages() {
      return Math.ceil(this.numberOfRows / this.queryParams.limit);
    },
    totals() {
      return this.rows.reduce(
        (acc, row) => {
          acc.vat += row.vat;
          acc.tot += row.total;
          acc.fundsTot += row.appliedNet;
          acc.refundedTot += row.refundedTotal;
          acc.paidTot += row.paidTotal;
          return acc;
        },
        { vat: 0, tot: 0, refundedTot: 0, paidTot: 0, fundsTot: 0 },
      );
    },
    columnsToDisplay() {
      return this.columns
        .filter((col) => this.shouldDisplayColumn(col))
        .map((col) => ({
          ...col,
          name: col.tabSpecificNames
            ? col.tabSpecificNames[this.activeTab?.name] || col.name
            : col.name,
        }));
    },
  },
  watch: {
    async '$route.query'() {
      await Promise.all([this.fetchNodes(), this.fetchStats()]);
      this.nodeNameFilter = this.queryParams.nodeName;
      this.accountNoFilter = this.queryParams.accountNo;
      this.totalAmountFilter = this.queryParams.totalAmount;
      this.filterTargetNodeId = !!this.queryParams.targetNodeId;
    },
  },
  async created() {
    await Promise.all([this.fetchStats(), this.fetchNodes()]);
    if (this.queryParams.graphId) {
      this.filterByNode = this.rows.find((r) => r.graphId === this.queryParams.graphId);
    }
    this.nodeNameFilter = this.queryParams.nodeName;
    this.accountNoFilter = this.queryParams.accountNo;
    this.totalAmountFilter = this.queryParams.totalAmount;
    this.filterTargetNodeId = !!this.queryParams.targetNodeId;
  },
  methods: {
    shouldDisplayColumn(col) {
      return !('displayState' in col) || col.displayState === this.payment.state;
    },
    getColumnByName(name) {
      return this.columns.find((col) => col.name === name);
    },
    toggleFilterTargetNodeId() {
      if (this.filterByNode && !this.queryParams.targetNodeId) {
        this.updateRoute({ targetNodeId: this.filterByNode.nodeId });
      } else {
        this.updateRoute({ targetNodeId: null });
      }
    },

    setNameFilter(val) {
      clearTimeout(this.nameFilterTimeout);
      if (val !== this.queryParams.nodeName) {
        this.nameFilterTimeout = setTimeout(() => {
          this.updateRoute({ nodeName: val });
        }, 1000);
      }
    },

    setAccountNoFilter(val) {
      clearTimeout(this.accountNoFilterTimeout);
      if (val !== this.queryParams.accountNo) {
        this.accountNoFilterTimeout = setTimeout(() => {
          this.updateRoute({ accountNo: val });
        }, 1000);
      }
    },

    setTotalAmountFilter(val) {
      clearTimeout(this.totalAmountFilterTimeout);
      if (val !== this.queryParams.total) {
        this.totalAmountFilterTimeout = setTimeout(() => {
          this.updateRoute({ totalAmount: val });
        }, 1000);
      }
    },

    convertAmount(amount) {
      if (
        amount === undefined ||
        typeof amount !== 'string' ||
        isNaN(amount.replace(/[,.\s]/g, ''))
      ) {
        return undefined;
      }

      if (/[,.]/.test(amount)) {
        return Number(amount.replace(/[,.\s]/g, ''));
      }

      return Number(`${amount.replace(/\s/g, '')}00`);
    },

    updateRoute(query) {
      const newParams = {
        ...this.queryParams,
        ...query,
      };
      const allKeys = Object.keys({ ...this.queryParams, ...newParams });
      const paramsChanged = allKeys.some((key) => {
        return this.queryParams[key] != newParams[key];
      });
      const filteredQuery = Object.fromEntries(
        Object.entries(newParams).filter(([_, v]) => v != null),
      );
      if (paramsChanged) {
        this.$router.replace({ path: this.$route.path, query: filteredQuery });
      }
    },
    selectPage(page) {
      this.updateRoute({ offset: (page - 1) * this.queryParams.limit });
    },
    updateHitsPerPage(nbr) {
      this.updateRoute({ offset: 0, limit: Number.parseInt(nbr, 10) });
    },
    setFilterByNode(row) {
      this.filterByNode = row;
      this.updateRoute({
        graphId: row.graphId,
        position: null,
        nodeType: null,
        nodeName: null,
        accountNo: null,
        totalAmount: null,
        targetNodeId: null,
        stateFilter: null,
        offset: 0,
      });
    },
    resetFilter() {
      this.filterByNode = null;
      this.updateRoute({
        graphId: null,
        position: null,
        nodeType: null,
        nodeName: null,
        accountNo: null,
        totalAmount: null,
        targetNodeId: null,
        stateFilter: null,
      });
    },
    resetFilterByNode() {
      if (this.queryParams.graphId) {
        this.filterByNode = null;
        this.updateRoute({ graphId: null, targetNodeId: null });
      }
    },
    getRowPosition(row) {
      return Object.keys(nodePositions)
        .filter((k) => nodePositions[k] === row.position)
        .pop()
        .slice(0, 1);
    },
    getRowState(row) {
      return paymentNodeState[row.state];
    },
    async fetchStats() {
      this.loading = true;
      try {
        this.stats = await query(
          {
            query: gql`
              query paymentStats($request: PaymentStatsRequest!) {
                paymentStats(request: $request) {
                  state
                  totalCount
                  totalAmount
                  totalVat
                  payeeCount
                  payeeNet
                  payeeVat
                }
              }
            `,
            variables: {
              request: this.paymentStatsQuery,
            },
          },
          { pickProp: 'paymentStats' },
        );
      } catch (err) {
        console.error(err);
        this.$addStarError({
          title: 'Could not fetch payment stats',
          type: `${err.statusCode} - ${err.code}`,
          message: err.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchNodes() {
      this.loading = true;
      try {
        this.rows = await query(
          {
            query: gql`
              query paymentNodes($request: PaymentNodeRequest!) {
                paymentNodes(request: $request) {
                  nodeId
                  name
                  position
                  net
                  appliedNet
                  vat
                  appliedVat
                  total
                  refundedTotal
                  paidTotal
                  type
                  state
                  graphId
                  paymentInfoState
                  taxInfoState
                }
              }
            `,
            variables: {
              request: this.paymentRowQuery,
            },
          },
          { pickProp: 'paymentNodes' },
        );
      } catch (err) {
        console.error(err);
        this.$addStarError({
          title: 'Could not fetch payment nodes',
          type: `${err.statusCode} - ${err.code}`,
          message: err.message,
        });
      } finally {
        this.loading = false;
      }
    },
    getTabQueryParams(tab) {
      return {
        ...this.queryParams,
        stateFilter: null,
        offset: 0,
        tab: tab.name,
      };
    },
    selectFilter(stateFilter) {
      this.updateRoute({ stateFilter });
    },
    onSort(e) {
      this.orderBy = e.sortParam;
      this.sortAscending = e.ascending;
      this.updateRoute({ orderBy: e.sortParam, ascending: e.ascending, offset: 0 });
    },
    getTabConfig(tabName) {
      return this.tabs.find((t) => t.name === tabName);
    },
    buildNodeLink(row) {
      const rootRoutePart = this.getRouteBaseForEntity(row.type);
      return `/${rootRoutePart}/${row.nodeId}/information`;
    },
    rowIsBlocked(row) {
      return row.state === paymentNodeState.Blocked;
    },
    sumStateStats(states) {
      const stats = this.stats.filter((s) => states.includes(s.state));
      return stats.reduce(
        (acc, s) => ({
          totalCount: acc.totalCount + s.totalCount,
          payeeCount: acc.payeeCount + s.payeeCount,
          totalAmount: acc.totalAmount + s.totalAmount,
          payeeNet: acc.payeeNet + s.payeeNet,
          payeeVat: acc.payeeVat + s.payeeVat,
        }),
        { totalCount: 0, payeeCount: 0, totalAmount: 0, payeeNet: 0, payeeVat: 0 },
      );
    },
    getTotalPayable(states) {
      const stats = this.sumStateStats(states);
      return stats.payeeNet + stats.payeeVat;
    },
    getTotalFunds(states) {
      const stats = this.sumStateStats(states);
      return stats.totalAmount + stats.payeeVat;
    },
    shouldDisplayValidationError(row) {
      return [
        paymentNodeState.InvalidPaymentInfo,
        paymentNodeState.InvalidTaxInfo,
        paymentNodeState.InvalidShare,
      ].includes(row.state);
    },
    setPositionFilter(val) {
      this.updateRoute({ position: val });
    },
    setNodeTypeFilter(val) {
      this.updateRoute({ nodeType: val });
    },
    async createMailingList() {
      this.mailingListProcessId = uuid.v4();
      this.mailingListButtonActive = false;
      await this.$store.dispatch('process/addProcess', {
        name: `Mailing list for performers with payment issues - ${this.payment.name}`,
        id: this.mailingListProcessId,
      });
      await mutate({
        mutation: gql`
          mutation createMailingListForErroredPerformers(
            $listName: String!
            $paymentId: String!
            $processId: String!
          ) {
            createMailingListForErroredPerformers(
              listName: $listName
              paymentId: $paymentId
              processId: $processId
            )
          }
        `,
        variables: {
          listName: this.payment.name,
          paymentId: this.payment.id,
          processId: this.mailingListProcessId,
        },
      });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.payment__no-errors {
  text-align: center;
  font-size: 20px;
  margin: 0;
  i {
    margin-top: -4px;
  }
}

.result-filter {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.result-filter div + div {
  margin-left: 2rem;
}
.payment__error-types {
  flex: 0 1 auto;
}

.payment__position-filter {
  flex: 0 1 auto;
  label {
    padding: 0 1rem 0 2rem;
  }
}

.payment__node-filter {
  flex: 1 1 auto;
}

.tabs {
  margin-bottom: var(--spacing-double);
}

ul {
  a:last-of-type {
    li {
      margin-top: 19px;
    }
  }
}
.remove-node-filter {
  margin-left: 0.5rem;
}
.tfoot {
  font-size: 13px;
  font-weight: bold;
}
.tab-amount {
  font-size: 0.8rem;
  font-weight: normal;
}
</style>
