<template>
  <li v-if="showMe" class="compare__list-item" :class="{ [`compare__list--${status}`]: colorMe }">
    <template v-if="showLeftSide">
      <div class="compare__incoming">
        <strong>{{ $t('common.' + incoming.key) }}</strong>
        <span
          class="compare__incoming-value"
          :class="{ none: incomingValue === 'None' }"
          v-html="incomingValue"
        />
      </div>
      <ActionColumn
        :action="action"
        :dirty="dirty"
        :visible="allowAction && hasCandidate && !candidate.isEmpty"
        :status="status"
        @action="handleAction(action)"
      />
    </template>
    <template v-else>
      <div
        v-if="!incoming.value && !forcedStatus"
        class="compare__incoming compare__candidate--empty none"
      />
    </template>
    <div v-if="candidate.isEmpty" class="compare__candidate compare__candidate--empty none">
      {{ candidate.valueWhenEmpty || 'None' }}
    </div>
    <div v-else class="compare__candidate">
      <strong>{{ $t('common.' + candidate.key) }}</strong>
      <div class="compare__candidate-value">
        <a v-if="candidate.link" :href="candidate.link" v-html="updatedAttributeValue" />
        <span
          v-else
          :class="{ none: updatedAttributeValue === 'None' }"
          v-html="updatedAttributeValue"
        />
        <span
          v-if="dirty && showOldValue"
          class="text--line-through"
          :class="{
            none: candidateValue === 'None',
            'original-value': !!originalCandidateValue,
          }"
        >
          {{ candidateValue }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import CompareFieldsMixin from '../../common/compareFieldsMixin';
import ActionColumn from './action-column';

export default {
  name: 'CompareGridRow',
  components: {
    ActionColumn,
  },
  mixins: [CompareFieldsMixin],
  props: {
    incoming: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
    candidate: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
    comparator: {
      type: String,
      default: 'valuesMatchRelaxed',
    },
    allowAction: {
      type: Boolean,
      default: true,
    },
    forcedStatus: {
      type: String,
      default: undefined,
    },
    forcedAction: {
      type: String,
      default: undefined,
    },
    originalValue: {
      default: undefined,
    },
    visible: {
      type: [Object, Boolean],
      default: true,
    },
    showLeftSide: {
      type: Boolean,
      default: true,
    },
    autoAction: {
      type: Boolean,
      default: false,
    },
    updatedAttribute: {
      type: Boolean,
      default: false,
    },
    computeNewValue: {
      type: Function,
      default: (old, _new) => _new,
    },
  },
  data() {
    return {
      originalCandidateValue: '',
      dirty: false,
    };
  },
  computed: {
    ...mapGetters('matching', ['currentCandidate']),
    action() {
      if (this.forcedAction) {
        return this.forcedAction;
      }
      if (this.incomingDataMatch(this.candidate.key) && !this.dirty) {
        return undefined;
      }
      if (_.isArray(this.candidate.value)) {
        if (this.candidate.key.indexOf('local_codes') > -1) {
          return this.dirty ? 'update' : 'addTo';
        } else if (this.candidate.key.indexOf('alternate_titles') > -1) {
          return 'update';
        }
        return this.dirty ? 'removeFrom' : 'addTo';
      }
      return 'update';
    },
    status() {
      if (this.forcedStatus) {
        return this.forcedStatus;
      }
      if (!this.hasValue(this.candidate.key) || !this.hasValue(this.incoming.value)) {
        return 'idle';
      }
      if (this.incomingHasAddableData(this.candidate.key)) {
        return 'warning';
      }
      if (this.incomingDataMatch(this.candidate.key)) {
        return 'ok';
      }
      return 'error';
    },
    hasCandidate() {
      return this.currentCandidate && !!this.currentCandidate.id;
    },
    showOldValue() {
      return _.isString(this.originalCandidateValue) || _.isArray(this.originalCandidateValue);
    },
    showMe() {
      if (this.dirty) {
        return true;
      }
      if (
        typeof this.visible === 'object' &&
        Object.prototype.hasOwnProperty.call(this.visible, 'byStatus')
      ) {
        return this.visible.byStatus.includes(this.status);
      }
      return (
        this.visible &&
        (this.incoming.value ||
          this.incoming.value === false ||
          this.candidate.value ||
          this.incoming.value === false)
      );
    },
    colorMe() {
      return this.forcedStatus || !this.candidate.isEmpty;
    },
    instruments() {
      return this.$store.state.appdata.referenceData.instruments;
    },
    incomingValue() {
      return this.getFilteredValue(this.incoming);
    },
    candidateValue() {
      return this.getFilteredValue(this.candidate, true);
    },
    updatedAttributeValue() {
      if (!this.updatedAttribute) {
        return this.getFilteredValue(this.candidate);
      }

      return this.getFilteredValue({
        ...this.candidate,
        value: _.isArray(this.candidate.value)
          ? [...this.candidate.value, ...this.incoming.value]
          : this.incoming.value,
      });
    },
  },
  watch: {
    currentCandidate(newCandidate, oldCandidate) {
      if (newCandidate.id !== oldCandidate.id) {
        this.dirty = this.updatedAttribute;
        this.setOriginalCandidateValue();
      }
    },
  },
  created() {
    this.setOriginalCandidateValue();
    this.dirty = this.updatedAttribute;
  },
  methods: {
    getFilteredValue(obj, original = false) {
      const value = original ? this.originalCandidateValue : obj.value;
      if ((!value && value !== false) || value.length === 0) {
        return 'None';
      }
      if (!obj.displayFilter) {
        return value;
      }
      return this.$options.filters[obj.displayFilter](value);
    },
    handleAction(action) {
      let newValue = this.computeNewValue(this.getValue(this.candidate), this.incoming.value);
      if (this.dirty && action === 'update') {
        newValue = this.originalCandidateValue;
      }
      this.$emit('valueUpdated', {
        action,
        revert: this.dirty,
        data: {
          key: this.candidate.key,
          value: newValue,
        },
      });
      this.dirty = !this.dirty;
    },
    incomingHasAddableData(key) {
      if (_.isArray(this.candidate.value) && _.isArray(this.incoming.value)) {
        if (key && key.indexOf('local_codes') > -1) {
          const candidateNormalized = this.candidate.value.map((v) => `${v.code}:${v.value}`);
          const incomingNormalized = this.incoming.value.map((v) => v);
          return (
            _.intersection(incomingNormalized, candidateNormalized).length !==
            incomingNormalized.length
          );
        }
        if (key && key.indexOf('instrument') > -1) {
          const candidateNormalized = this.candidate.value.map((v) =>
            isNaN(v) ? this.instruments.find((i) => i.code === v).family : v,
          );
          return !this.incoming.value.every((v) => candidateNormalized.indexOf(v) > -1);
        }
        if (key && key.indexOf('alternate_titles') > -1) {
          return !this.arrayIsSubset(this.getValue(this.incoming), this.getValue(this.candidate));
        }
        return !this.arraysSame(this.getValue(this.incoming), this.getValue(this.candidate));
      }
      if (_.isArray(this.candidate.value)) {
        return !this.arrayIncludesValueRelaxed(
          this.getValue(this.candidate),
          this.getValue(this.incoming),
        );
      }
      return (
        !this.hasValue(this.getValue(this.candidate)) && this.hasValue(this.getValue(this.incoming))
      );
    },
    incomingDataMatch(key) {
      if (!this.hasValue(this.incoming.value)) {
        return true;
      }
      if (_.isArray(this.candidate.value) && _.isArray(this.incoming.value)) {
        if (key && key.indexOf('local_codes') > -1) {
          const candidateNormalized = this.candidate.value.map((v) => `${v.code}:${v.value}`);
          const incomingNormalized = this.incoming.value.map((v) => v);
          return this.arrayIsSubset(incomingNormalized, candidateNormalized);
        }
        if (key && key.indexOf('instrument') > -1) {
          const candidateNormalized = this.candidate.value.map((v) =>
            isNaN(v) ? this.instruments.find((i) => i.code === v).family : v,
          );
          return this.incoming.value.every((v) => candidateNormalized.indexOf(v) > -1);
        }
        if (key && key.indexOf('alternate_titles') > -1) {
          return this.arrayIsSubset(this.getValue(this.incoming), this.getValue(this.candidate));
        }
        return this.arraysSame(this.getValue(this.candidate), this.getValue(this.incoming));
      }
      if (
        _.isArray(this.candidate.value) &&
        this.arrayIncludesValueRelaxed(this.candidate.value, this.incoming.value)
      ) {
        return true;
      } else if (this[this.comparator](this.incoming.value, this.candidate.value)) {
        return true;
      }
      return false;
    },
    getValue(obj) {
      return obj.valueConverter && obj.value ? obj.valueConverter(obj.value) : obj.value;
    },
    setOriginalCandidateValue() {
      this.$nextTick(() => {
        this.originalCandidateValue = _.clone(this.originalValue || this.candidate.value);

        if (
          this.autoAction &&
          this.status === 'warning' &&
          this.allowAction &&
          this.currentCandidate.id
        ) {
          this.handleAction(this.action);
        }
      });
    },
  },
};
</script>
