var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('spinner'):_vm._e(),_c('div',{staticClass:"card row"},[_c('DataTable',{attrs:{"columns":_vm.columns,"items":_vm.recordings,"total-items":_vm.totalRecordings},on:{"updatePagination":function($event){return _vm.fetchData($event.limit, $event.offset)}},scopedSlots:_vm._u([{key:"item:title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'recordingView', params: { id: item.match_id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.recording.title)+" ")])]}},{key:"item:version_title",fn:function(ref){
var item = ref.item;
return [(
            item.recording.version_title &&
            _vm.matchVersionTitle(item.recording.version_title, item.recording_comment)
          )?_c('span',{staticClass:"text--green"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v(" "+_vm._s(item.recording.version_title)+" ")]):(item.recording.version_title)?_c('span',[_vm._v(" "+_vm._s(item.recording.version_title)+" ")]):_c('span',{staticClass:"none"},[_vm._v("None")])]}},{key:"item:context-menu",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('context-menu',{attrs:{"options":['Add to recording']},on:{"addToRecording":function($event){return _vm.toggleAddModal(item, index)}}})]}}])})],1),(_vm.showAddModal)?_c('Modal',{attrs:{"submit-label":"Add","disable-submit":_vm.validationErrors.has('version_title')},on:{"close":function($event){_vm.showAddModal = false},"cancel":function($event){_vm.showAddModal = false},"save":function($event){return _vm.addVersionTitle()}}},[_c('h2',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Add / Replace version title to "+_vm._s(_vm.selectedItem.title))]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('validated-text-input',{attrs:{"name":"version_title","label":"Version title","rule":{ required: true }},model:{value:(_vm.selectedItem.version_title),callback:function ($$v) {_vm.$set(_vm.selectedItem, "version_title", $$v)},expression:"selectedItem.version_title"}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }