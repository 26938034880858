<template>
  <div>
    <div class="row">
      <div>
        <div class="row">
          <h1>
            Ledger for {{ associateName }} ({{
              `common.${associateType.toLowerCase()}` | translate
            }})
          </h1>
        </div>

        <ledger-overview
          :id="associateId"
          :balance="ledgerBalance"
          :latest-payments="latestPayments"
          details-view="viewAssociatePaymentDetails"
          node-type="associate"
        />

        <div class="tabs row wrapper">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'viewAssociatePaymentHistory', params: { associateId } }">
              Payment History
            </router-link>
            <router-link :to="{ name: 'viewAssociateTransactionHistory', params: { associateId } }">
              Transaction History
            </router-link>
          </div>
        </div>
      </div>

      <router-view type="associate" />
    </div>
  </div>
</template>

<script>
import PaymentService from '../../../services/paymentService';
import AssociateService from '../../../services/associateService';
import LedgerOverview from '../../ui/ledger/ledger-overview';
import LedgerHelper from '../../ui/ledger/ledgerHelper';

export default {
  name: 'ViewLedger',
  components: {
    LedgerOverview,
  },
  data() {
    return {
      associateId: this.$router.currentRoute.params.id,
      loading: false,
      associateName: '',
      associateType: 'unknown',
      ledgerBalance: 0,
      latestPayments: [],
    };
  },
  async created() {
    this.fetchPaymentHistory();
    const associate = await AssociateService.getAssociate(this.associateId);
    if (associate.person_info && associate.person_info.first_name) {
      this.associateName = `${associate.person_info.first_name} ${associate.person_info.last_name}`;
    } else {
      this.associateName = associate.organisation_info.name;
    }
    this.associateType = associate.type;

    this.$nextTick(() => this.fetchLedgerBalance());
  },
  methods: {
    async fetchPaymentHistory() {
      this.loading = true;
      try {
        const ledgerId = this.$router.currentRoute.params.id;
        const { items } = await LedgerHelper.getLatestPayments(ledgerId, 4);
        this.latestPayments = items;
      } catch (error) {
        error.title = 'Error fetching payment history';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    async fetchLedgerBalance() {
      this.loading = true;
      try {
        const { ledgerBalance } = await PaymentService.getLedgerBalance(this.associateId);
        this.ledgerBalance = ledgerBalance;
      } catch (error) {
        error.title = 'Error fetching payment remuneration records';
        this.$addStarError(error);
      }
      this.loading = false;
    },
  },
};
</script>
