<template>
  <div>
    <div class="row card">
      <div class="class col s12">
        <table>
          <thead>
            <th v-for="column in columns" :key="column.key" :class="column.styleClass">
              {{ column.label }}
            </th>
          </thead>
          <tbody>
            <template v-if="reserveRelease.rows.length > 0">
              <template v-for="row in reserveRelease.rows">
                <tr :key="row.id">
                  <td v-for="col of columns" :key="col.key" :class="col.styleClass">
                    {{ col.value(row) }}
                  </td>
                </tr>
              </template>
            </template>
            <tr v-else>
              <td colspan="2" class="none">None</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="reservedAmountColIdx" />
              <td class="amount-row">
                <strong>
                  {{ reserveRelease.header.totalReservedAmount | formatAmountThousandSeparator }}
                </strong>
              </td>
              <td class="amount-row">
                <strong>{{ totalReleasedAmount | formatAmountThousandSeparator }}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col s4 offset-s8">
        <verify-with-password
          v-if="verificationStatus !== 'success'"
          :message="verifyWithPasswordMessage"
          :verification-status="verificationStatus"
          :number-of-attempts="numberOfAttempts"
          :disabled="reserveRelease.rows.length === 0"
          submit-label="Confirm release"
          @onSubmitPassword="onSubmitPassword"
          @onSubmitMfaCode="onSubmitMfaCode"
          @onBypassAuth="onBypassAuth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VerifyWithPassword from '../ui/input/verify-with-password';
import AuthenicationMixin from '../../common/authenictaionMixin';

export default {
  name: 'ProgressVerifyInformation',
  components: {
    VerifyWithPassword,
  },
  mixins: [AuthenicationMixin],
  props: {
    reserveRelease: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'performance_year',
          label: 'Performance Year',
          active: true,
          value: (row) => row.performanceYear,
        },
        {
          key: 'source_id',
          label: 'Source',
          dynamic: true,
          active: false,
          value: (row) => row.sourceName,
        },
        {
          key: 'area_id',
          label: 'Area',
          dynamic: true,
          active: false,
          value: (row) => row.areaName,
        },
        {
          key: 'right',
          label: 'Right',
          dynamic: true,
          active: false,
          value: (row) => row.right,
        },
        {
          key: 'reserved_amount',
          label: 'Reserved Amount',
          active: false,
          styleClass: 'amount-row',
          value: (row) => this.$options.filters.formatAmountThousandSeparator(row.reservedAmount),
        },
        {
          key: 'released_amount',
          label: 'Released Amount',
          active: false,
          styleClass: 'amount-row',
          value: (row) => this.$options.filters.formatAmountThousandSeparator(row.releasedAmount),
        },
      ],
      verifyWithPasswordMessage:
        ' I hereby confirm that I want to release\n' + '          the reserves.\n',
    };
  },
  computed: {
    reservedAmountColIdx() {
      return this.columns.findIndex((col) => col.key === 'reserved_amount');
    },
    totalReleasedAmount() {
      return this.reserveRelease.rows.reduce((sum, row) => sum + Number(row.releasedAmount), 0);
    },
  },
  methods: {
    async doWork() {
      this.$emit('onSubmit');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
