import { render, staticRenderFns } from "./searchable-tag-with-columns-input.vue?vue&type=template&id=01a9511e&scoped=true&"
import script from "./searchable-tag-with-columns-input.vue?vue&type=script&lang=js&"
export * from "./searchable-tag-with-columns-input.vue?vue&type=script&lang=js&"
import style0 from "./searchable-tag-with-columns-input.vue?vue&type=style&index=0&id=01a9511e&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a9511e",
  null
  
)

export default component.exports