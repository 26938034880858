<template>
  <div>
    <div class="row wrapper">
      <div class="col s11">
        <h1>
          Accounting Files for {{ selectedMonth | formatMonthFromNumber1 }} {{ selectedYear }}
        </h1>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row card">
        <div class="search-result__result">
          <div class="row">
            <div class="col s12">
              <table>
                <thead>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  >
                    <span>{{ column.name }}</span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="report in sortedReports" :key="report.id">
                    <td>
                      <span>
                        {{ report.dateCreated | formatDate }}
                      </span>
                    </td>
                    <td>
                      <router-link :to="report.filename" append>
                        {{ `finance.report_type.${report.type}` | translate }} ({{ report.format }})
                      </router-link>
                    </td>
                    <td>
                      <span>
                        <a @click="onDownloadReport(report)">
                          <i class="fas fa-download" />
                          ({{ reportSize(report) }} kb)
                        </a>
                      </span>
                    </td>
                    <td>
                      <span v-if="report.metadata.download === 'true'">
                        <i class="fa fa-check text--green" aria-hidden="true" />
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ report.metadata.download_date | formatDateTime }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ report.metadata.download_by }}
                      </span>
                    </td>
                    <td>{{ report.metadata.booked === 'true' ? 'Yes' : 'No' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import AuthenticationService from '../../services/authenticationService';
import Spinner from '../spinner';

export default {
  name: 'ViewFinanceReportMonth',
  components: {
    Spinner,
  },
  data() {
    return {
      viewRouteName: 'financeReportMonthView',
      selectedYear: this.$router.currentRoute.params.year,
      selectedMonth: this.$router.currentRoute.params.monthId,
      error: false,
      loading: false,
      reports: [],
      columns: [
        {
          name: 'Date Created',
        },
        {
          name: 'Name',
        },
        {
          name: 'Download (Size)',
        },
        {
          name: 'Downloaded',
        },
        {
          name: 'Download Date',
        },
        {
          name: 'Downloaded by',
        },
        {
          name: 'Booked',
        },
      ],
    };
  },
  computed: {
    sortedReports() {
      return this.reports.slice().sort((a, b) => {
        if (a.type === b.type) {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        }
        return a.type.localeCompare(b.type);
      });
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    reportSize(report) {
      return report.size / 1000 < 1
        ? (report.size / 1000).toFixed(2)
        : (report.size / 1000).toFixed(0);
    },
    enhanceReports(reports) {
      let id = 0;
      return reports.map((report) => {
        const match = report.filename.match(/(\d{12})_star.si/);
        return Object.assign(_.clone(report), {
          id: ++id,
          dateCreated: match && moment(match[1], 'YYMMDDHHmmss'),
          type: report.filename.substr(0, report.filename.indexOf('_')),
          format: 'SIE',
        });
      });
    },
    async onDownloadReport(report) {
      const username = AuthenticationService.getUserName();
      const metadata = { download: 'true', download_by: username, download_date: new Date() };
      const fileData = await RemoteFileService.downloadFromDocumentRepositoryAndUpdateMetadata(
        `${report.path}/${report.filename}`,
        metadata,
      );
      DownloadLink.direct(fileData, report.filename);
      this.reloadBaseData();
    },
    async reloadBaseData() {
      try {
        this.loading = true;
        const reports = await RemoteFileService.listDocumentRepository(
          `accounting/${this.selectedYear}/${this.selectedMonth}`,
        );
        console.log(reports);
        this.reports = this.enhanceReports(reports);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
