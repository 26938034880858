<template>
  <div class="compare__search">
    <div class="compare__nested-heading">
      <strong>Possible candidates</strong>
    </div>
    <ul>
      <li
        v-for="candidate in candidates"
        :key="candidate.id"
        class="row"
        :class="{ selected: isSelected(candidate) }"
        @click="selectedChanged(candidate)"
      >
        <div class="col s4">
          <span v-if="candidate.name">{{ candidate.name }}</span>
          <span v-else>{{ candidate.first_name }} {{ candidate.last_name }}</span>
        </div>
        <div v-if="candidate.date_of_birth" class="col s4">
          <span>{{ candidate.date_of_birth }}</span>
        </div>
        <div v-if="candidate.nationality" class="col s4">
          <span>{{ candidate.nationality | formatCountry }}</span>
        </div>
      </li>
    </ul>
    <div class="row compare__search__id">
      <div class="col s12">
        <span for="">Other performer:</span>
        <searchable-input
          :show-label="false"
          name="otherPerformer"
          placeholder="Name or social security number or IPN"
          rule=""
          :clear-after-select="true"
          :css-props="{ whiteSpace: 'normal' }"
          :searcher="searchPerformer"
          use-template="performerNameSearch"
          @clear="clearPerformer"
          @input="addPerformer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchableInput from '../../ui/input/searchable-input';
import SearchHelper from '../../search/searchHelper';

export default {
  name: 'PerformerCandidateSelection',
  components: { SearchableInput },
  props: {
    candidates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  created() {
    this.selectedChanged(this.candidates[0]);
  },
  methods: {
    selectedChanged(candidate) {
      this.selected = candidate;
      this.$emit('candidateChanged', candidate);
    },
    isSelected(candidate) {
      return this.selected.id === candidate.id;
    },
    clearPerformer() {
      // No-op.
    },
    async searchPerformer(query) {
      const res = await SearchHelper.advancedSearchPerformers({ query });
      return res.results.map((perf) => ({
        ...perf,
        name: `${perf.first_name} ${perf.last_name}`,
        main_artists: perf.main_artists.map((ma) => ma.name),
      }));
    },
    addPerformer(performer) {
      if (!performer) {
        return;
      }
      this.$emit('newCandidate', performer);
      this.selected = performer;
    },
  },
};
</script>

<style lang="scss" scoped>
.compare__search {
  font-size: 13px;
  ul {
    border: 1px solid #e8e8e8;
    max-height: 510px;
    margin-bottom: var(--spacing-triple);
    overflow-y: auto;
  }
  li {
    padding: var(--spacing);
    margin-bottom: 0;
    &:first-child {
      margin-top: -1px;
    }
    &:last-child {
      margin-bottom: -1px;
    }
    &.selected {
      position: relative;
      font-weight: bold;
      border-left: 3px solid var(--blue--water);
      margin-left: -1px;
    }
    &:hover:not(.selected) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .candidate-artist-separator {
    color: var(--grey--vlight);
  }
}
</style>
