import store from '../../store/index';

const localCodesReferenceData = store.getters['appdata/localCodeTypes'] || [];

const societies = localCodesReferenceData.reduce((obj, current) => {
  if (current.country) {
    obj[current.code] = current.name;
  }
  return obj;
}, {});

const localCodes = localCodesReferenceData.reduce((obj, current) => {
  if (/[åÅäÄöÖ]/g.test(current.code)) {
    obj[current.code.replace(/[åÅäÄöÖ]/g, 'O').toLowerCase()] = current.name;
  }
  obj[current.code.toLowerCase()] = current.name;
  return obj;
}, {});

const basicInfo = {
  active_period: 'Active period',
  alternate_titles: 'Alternative titles',
  arranger: 'Arranged by',
  catalog_number: 'Catalogue no',
  composer: 'Composed by',
  date_of_birth: 'Date of birth',
  duration_sec: 'Duration',
  genre: 'Genre',
  isrc: 'ISRC',
  vrdb2_id: 'VRDB ID',
  vrdb2_attested: 'Attested',
  vrdb2_associated: 'Associated',
  vrdb2_playlist: 'Playlist',
  isrc_history: 'ISRC',
  label: 'Label',
  labels: 'Labels',
  local_codes: localCodes,
  local_codes_label: 'Local codes',
  main_artist: { name: 'Main artist' },
  name: 'Title',
  no_tracks: 'No. Tracks',
  origin_city: 'Origin city',
  origin_country: 'Origin country',
  produced_in_country: 'Produced in country',
  profile_description: 'Profile',
  recorded_in_country: 'Recorded in country',
  recording_date: 'Recording date',
  recording_year: 'Recording year',
  source_info: { source: 'Source' },
  source_info_label: 'Source info',
  version: 'Version',
  version_title: 'Version title',
  vrd_title: 'VRDB title',
  writer: 'Written by',
  release_date: 'Release date',
};

const generalInfo = {
  country_of_birth: '@:general_info.country_of_birth',
  country_of_residence: 'Country of residence',
  date_of_birth: 'Date of birth',
  date_of_death: 'Date of deceased',
  first_name: 'First name',
  label: 'General info',
  last_name: 'Last name',
  local_codes: localCodes,
  local_codes_label: 'Local codes',
  middle_name: 'Middle name',
  name_variations: 'Name variations',
  nationality: 'Nationality',
  profile: 'Profile',
  pseudo_names: 'Pseudo name',
  sex: 'Sex',
  social_security_number: 'Social security number',
  collective: 'Collective',
};

export default {
  en: {
    suppRem: {
      issueCodes: {
        GDNNFA: 'Green distribution state but no non-featured artists on the lineup!',
      },
    },
    common: {
      accounting_firm: 'Accounting firm',
      administrative_cost: 'Administrative cost',
      album: 'Album',
      albums: 'Albums',
      alternate_titles: 'Alternative titles',
      any: 'Any',
      arranger: basicInfo.arranger,
      associate: 'Associate',
      associates: 'Associates',
      basic_info: basicInfo,
      cancel: 'Cancel',
      catalog_number: 'Catalogue no',
      choose_one: 'Choose one...',
      comment: 'Comment',
      company: 'Company',
      composer: basicInfo.composer,
      date_of_birth: basicInfo.date_of_birth,
      duration_sec: basicInfo.duration_sec,
      distributable_amount: 'Distributable amount',
      distributed_amount: 'Distributed amount',
      refunded_amount: 'Refunded amount',
      genre: basicInfo.genre,
      heir: 'Heir',
      intermediary: 'Intermediary',
      instrument: 'Instrument',
      instruments: 'Instruments',
      ipn: 'IPN',
      isrc: basicInfo.isrc,
      label: '@:album.basic_info.label',
      legal_guardian: 'Legal guardian',
      local_codes: 'Local codes',
      local_code: 'Local code',
      album_local_code: 'Album local code',
      main_artist: 'Main Artist',
      main_instrument: 'Main instrument',
      mainartist: 'Main Artist',
      manager: 'Manager',
      name: 'Name',
      no: 'No',
      no_tracks: 'No. Tracks',
      other: 'Other',
      age_when_recorded: 'Age (when recorded)',
      performer: 'Performer',
      produced_in_country: basicInfo.produced_in_country,
      recorded_in_country: basicInfo.recorded_in_country,
      recording: 'Recording',
      recording_country: 'Recorded in country',
      recording_date: basicInfo.recording_date,
      recording_year: 'Recording year',
      role_code: 'Role code',
      save: 'Save',
      society: 'Society',
      society_code: 'Society',
      title: 'Title',
      type: 'Type',
      unknown: 'Unknown',
      upload_file: 'Upload file',
      version: basicInfo.version,
      version_title: 'Version title',
      vrdb2_id: basicInfo.vrdb2_id,
      vrdb2_id_attested: 'VRDB ID (Attested)',
      vrdb2_id_associated: 'VRDB ID (Associated)',
      vrdb2_id_playlist: 'VRDB ID (Playlist)',
      attested: basicInfo.vrdb2_attested,
      associated: basicInfo.vrdb2_associated,
      playlist: basicInfo.vrdb2_playlist,
      writer: basicInfo.writer,
      pseudo_names: generalInfo.pseudo_names,
      sex: generalInfo.sex,
      collective: generalInfo.collective,
      yes: 'Yes',
      child_associate: {
        associate: {
          accounting_firm: 'Accounting firm (associate)',
          company: 'Company (associate)',
          heir: 'Heir (associate)',
          intermediary: 'Intermediary (associate)',
          legal_guardian: 'Legal guardian (associate)',
          manager: 'Manager (associate)',
          other: 'Other (associate)',
        },
        performer: 'Performer',
      },
      error: {
        fileExistError: 'Upload error',
        graphQLError: 'Application error (GQL)',
        illegalArgumentError: 'Inappropriate arguments',
        networkError: 'Network error',
        serverError: 'Application error',
      },
      phone: {
        cell: 'Mobile',
        fax: 'Fax',
        home: 'Home',
        mobile: 'Mobile',
        null: 'Phone',
        work: 'Work',
        private: 'Privat',
      },
    },
    activity: {
      membership: 'Membership confirmed',
      membership_agreement_sent: 'Membership agreement sent',
      membership_agreement_received: 'Membership completed',
      ipd_create_performer: 'IPD-mandate',
      agreements: 'Agreements',
      album: '@:common.album',
      associate: '@:common.associate',
      associates: '@:common.associates',
      basic_info: 'Basic Information',
      child: 'Association',
      contact_info: 'Contact Information',
      distribution_state: 'Distribution state',
      documents: 'Documents',
      emails: '@:contact_info.emails',
      general_info: 'General Information',
      lineup: 'Lineup',
      mainartist: '@:common.mainartist',
      mandate: 'Mandate',
      mandate_cancelled: 'Mandates',
      mandate_created: 'Mandates',
      membership_info: 'Membership Information',
      membership_ipn_received: 'Membership Information',
      meta_info: 'Extra information and meta data',
      metadata_info: 'Tags and extra information',
      organisation_info: 'Organisation Information',
      payment_info: 'Payment Information',
      performer: '@:common.performer',
      performers: 'Performers',
      person_info: 'Person info',
      phone_numbers: '@:contact_info.phone_numbers',
      recording: '@:common.recording',
      role_codes: 'Role codes',
      society: '@:common.society',
      tax_info: 'Tax Information',
      tracks: 'Tracks',
      vrdb_2_info: 'VRDB Information',
      vrdb_2_state: 'VRDB state',
      local_codes: '@:common.local_codes',
    },
    album: {
      basic_info_label: 'Basic info',
      basic_info: basicInfo,
    },
    contact_person: {
      name: '@:common.name',
      emails: '@:contact_info.emails',
      phone_numbers_label: '@:phone_numbers.label',
      phone_numbers: {
        type: '@:phone_numbers.type',
        number: '@:phone_numbers.number',
      },
      comment: '@:common.comment',
    },
    contact_info: {
      address_label: 'Address',
      address: {
        street: 'Street',
        city: 'City',
        province: 'Province',
        postal_code: 'Postal code',
        country: 'Country',
        care_of: 'c/o',
      },
      emails: 'Email',
      emails_label: 'Email',
      phone_numbers_label: '@:phone_numbers.label',
      phone_numbers: {
        type: '@:phone_numbers.type',
        number: '@:phone_numbers.number',
      },
      contact_persons_label: 'Contact person',
      contact_persons: {
        name: '@:contact_person.name',
        emails: '@:contact_person.emails',
        phone_numbers_label: '@:phone_numbers.label',
        phone_numbers: {
          type: '@:phone_numbers.type',
          number: '@:phone_numbers.number',
        },
        comment: '@:contact_person.comment',
      },
      website_label: 'Website',
      website: 'Website',
    },
    mainartist: {
      basic_info_label: '@:basic_info.label',
      basic_info: basicInfo,
    },
    matching: {
      basic_info: basicInfo,
      album: '@:common.album',
      albums: '@:common.albums',
      local_codes: '@:common.local_codes',
      bulk_action: {
        move_to_check: "Move back to 'To check'",
        accept_all: 'Accept all',
        reject_all: 'Reject all',
      },
      rejection_reason: {
        choose_one: '@:common.choose_one',
        evidence_required: 'Evidence required - please send proof',
        recording_mismatch: 'Recording does not match',
        not_on_recording: 'Did not perform on this recording',
        other: 'Other',
      },
    },
    general_info: generalInfo,
    performer: {
      general_info_label: '@:general_info.label',
      general_info: generalInfo,
      membership_info_label: 'Membership info',
      membership_info: {
        main_instrument: '@:common.main_instrument',
        secondary_instruments: 'Secondary instruments',
        voting_right: 'Voting right',
        ipn: 'IPN',
      },
      mandates: {
        society: societies,
        performance_activity: {
          musician: 'Musician',
          actor: 'Actor',
          singer: 'Singer',
        },
        conditional_aspect: {
          featured_artist: 'Featured artist',
          non_featured_artist: 'Non-Featured Artist',
        },
      },
      tax_info_label: '@:tax_info.label',
      tax_info: {
        tax_domicile_label: '@:tax_info.tax_domicile',
        tax_domicile: '@:tax_info.tax_domicile',
        tin_label: '@:tax_info.tin',
        tin: '@:tax_info.tin',
        vat_number: '@:tax_info.vat_number',
      },
    },
    payment_info: {
      private_account_label: 'Private account',
      private_account: {
        bank: 'Bank',
        clearing_number: 'Clearing number',
        account_number: 'Account number',
      },
      company_account_label: 'Company account',
      company_account: {
        name: 'Company account',
        account_type: 'Account type',
        account_number: '@:payment_info.private_account.account_number',
      },
      foreign_account_label: 'Foreign account',
      foreign_account: {
        account_holder: 'Account holder',
        iban_number: 'IBAN',
        account_number: 'Account number',
        bic_swift_number: 'BIC Swift',
        routing_number: 'Routing number',
      },
      banks: {
        amfa: 'Amfa Bank',
        fortis: '​BNP Paribas Fortis',
        citi_bank: 'Citibank International Plc',
        danske_bank: 'Danske Bank',
        dnb_bank: 'DNB Bank',
        ekobanken: 'Ekobanken',
        forex: 'Forex Bank',
        handelsbanken: 'Handelsbanken',
        ica: 'ICA Banken',
        ikano: 'IKANO Banken',
        jak_medlemsbank: 'JAK Medlemsbank',
        lf_bank: 'Länsförsäkringar Bank',
        marginalen: '​Marginalen Bank',
        nordax: '​Nordax Bank',
        nordea: 'Nordea',
        nordea_plusgirot: 'Nordea/Plusgirot',
        nordnet: 'Nordnet Bank',
        resurs: 'Resurs Bank',
        riksgalden: 'Riksgälden',
        sbab: 'SBAB',
        seb: 'SEB',
        skandia: 'Skandiabanken',
        sparbanken_syd: 'Sparbanken Syd',
        sparbanken_ore: 'Sparbanken Öresund',
        swedbank: 'Swedbank',
        alandsbanken: 'Ålandsbanken',
        null: 'Okänd',
      },
    },
    phone_numbers: {
      label: 'Phone numbers',
      type: '@:common.type',
      number: 'Number',
    },
    recording: {
      basic_info_label: 'Basic info',
      basic_info: basicInfo,
      distribution_state: {
        status: 'Status',
      },
      lineup_label: 'Lineup',
      lineup: {
        instruments: 'Instruments',
        role: 'Role',
        relation: {
          name: 'Name',
        },
      },
    },
    society: {
      basic_info: basicInfo,
    },
    tax_info: {
      label: 'Tax information',
      tax_domicile: 'Domicile',
      tin: 'Tin',
      vat_number: 'Vat No.',
    },
    revenue: {
      state: {
        finished: 'Finished',
        in_progress: 'In progress',
        new: 'New',
        on_hold: 'On hold',
        to_be_distributed: 'To be distributed',
      },
      statement: {
        match_state: {
          D: 'Disburse',
          C: 'To Check',
          R: 'Refund',
          T: 'Done',
        },
      },
    },
    ledger: {
      transaction_type: {
        0: 'Distribution',
        1: 'Redistribution',
        2: 'ReserveRelease',
        3: 'Statement',
        4: 'Payment',
        5: 'Refund',
      },
      source_type: {
        0: 'Payment',
        1: 'Deposit',
        2: 'Refund',
        3: 'Distribution Closure',
        4: 'Payment',
        5: 'Refund',
        6: 'Distribution Closure',
        7: 'Manual Transfer',
        8: 'Deposit',
        9: 'Transfer',
      },
      distribution_type: {
        d: 'Distribution',
        c: 'Correction',
        r: 'Reserve Release',
        s: 'Statement',
      },
    },
    finance: {
      report_type: {
        distribution: 'Distribution report',
        refund: 'Refund report',
        payment: 'Payment',
        revenues: 'Revenues',
        reserves: 'Reserves',
        statements: 'Statements',
        dist: 'Distribution closure',
        reserve: 'Reserve release',
        manual: 'Manual Transfer',
      },
    },
    distribution: {
      distribution_method: {
        airtime: 'Airtime',
        by_source: 'By source',
        manual: 'Manual',
        points: 'Points',
      },
      errors: {
        jurisdiction: 'Missing recorded in country',
        release_year: 'Recording date',
        incomplete: 'Incomplete lineup',
        missing: 'Missing information',
        country_of_residence: 'Missing country of residence',
      },
    },
    mandate: {
      right: {
        as: 'Annual Supplementary Remuneration',
        br: 'Broadcasting',
        cp: 'Communication to the public',
        eq: 'Equitable remuneration',
        le: 'Lending',
        pc: 'Private copy remuneration',
        pp: 'Public Performance',
        re: 'Rental',
        rp: 'Reproduction',
        rr: 'Retransmission',
        wc: 'Webcasting',
        wo: 'Web on-demand',
      },
    },
    music_report: {
      com: 'Kommersiell radio',
      ifpi: 'Kommersiell radio (IFPI)',
      csv: 'Generell, CSV-format',
      sr: 'Sveriges Radio',
      ur: 'Utbildningsradion',
      dbf: 'Bibliotek',
    },
    payment: {
      system_account: {
        '7e21a369-cc39-4033-b0fe-7b5ac4768983': 'Deposit',
        'dd937fd7-16b3-488d-9ffa-da2aa43f7dfc': 'Distribution Closure',
        '04ddc4b1-94d0-4d90-b366-51cf40d2f4fb': 'Paid Remunerations',
        '8befce4c-e907-4b5f-a5fe-a3b8235cfea5': 'VAT',
        '346f4bfb-7308-4614-a2a8-3be583061020': 'Remainder',
      },
      node_state: {
        0: 'Pending',
        1: 'Approved',
        2: 'InvalidPaymentInfo',
        3: 'InvalidTaxInfo',
        4: 'NoValidPayee',
        5: 'InvalidShare',
        6: 'InsufficientFunds',
        7: 'InvalidMandate',
        8: 'Blocked',
      },
      payment_info: {
        0: 'Valid',
        1: 'AccountHolderMissing',
        2: 'AccountNoMissing',
        3: 'ClearingNoMissing',
        4: 'AccountTypeMissing',
        5: 'BicMissing',
        6: 'IbanMissing',
        7: 'RoutingNoMissing',
        8: 'IbanConflict',
        9: 'PaymentInfoMissing',
        10: 'InvalidAccountNo',
        11: 'InvalidBic',
        12: 'InvalidIban',
        13: 'InvalidRoutingCode',
        14: 'InvalidRoutingNo',
      },
      tax_info: {
        0: 'Valid',
        1: 'FirstNameMissing',
        2: 'LastNameMissing',
        3: 'SsnMissing',
        4: 'SsnInvalid',
        5: 'CountryMissing',
        6: 'StreetMissing',
        7: 'CityMissing',
        8: 'PostalCodeMissing',
        9: 'OrgNoMissing',
        10: 'OrgNameMissing',
        11: 'TinMissing',
        12: 'TaxInfoMissing',
        13: 'DomicileMissing',
        14: 'DobMissing',
        15: 'InvalidCompanyDomicile',
      },
    },
    vrdb2: {
      playlist: {
        type: {
          default: 'Default',
          asr: 'ASR',
        },
      },
    },
  },
};
