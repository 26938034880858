var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authentication"},[_c('div',{staticClass:"authentication__background"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s6 offset-s3 l4 offset-l4"},[_c('div',{staticClass:"authentication__container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"authentication__container-loader"},[_c('spinner')],1),_vm._m(0),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.authenticationState === 'login'),expression:"authenticationState === 'login'"}],attrs:{"data-vv-scope":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("Username")]),_c('clearable-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:2'),expression:"'required|min:2'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags('$login-form', 'username', [
                    'dirty',
                    'invalid' ]),
                },attrs:{"name":"username","type":"text","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkValidationFlags('$login-form', 'username', ['dirty', 'invalid'])),expression:"checkValidationFlags('$login-form', 'username', ['dirty', 'invalid'])"}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('login-form.username'))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("Password")]),_c('clearable-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:10'),expression:"'required|min:10'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags('$login-form', 'password', [
                    'dirty',
                    'invalid' ]),
                },attrs:{"name":"password","type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkValidationFlags('$login-form', 'password', ['dirty', 'invalid'])),expression:"checkValidationFlags('$login-form', 'password', ['dirty', 'invalid'])"}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('login-form.password'))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{ref:"loginButton",staticClass:"btn",attrs:{"type":"submit","disabled":_vm.validationErrors.any('login-form')},on:{"click":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._v(" Login ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('a',{class:{
                  'authentication__reset-password': true,
                  'float-right': true,
                  disabled: _vm.validationErrors.first('login-form.username'),
                },attrs:{"href":"#"},on:{"click":_vm.resetPassword}},[_vm._v(" Reset password ")])])])]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.authenticationState === 'verificationCodeRequired'),expression:"authenticationState === 'verificationCodeRequired'"}],attrs:{"data-vv-scope":"verification-form"},on:{"submit":function($event){$event.preventDefault();return _vm.verifyAndSetNewPassword.apply(null, arguments)}}},[_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("Verification code")]),_c('clearable-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags('$verification-form', 'verificationCode', [
                    'dirty',
                    'invalid' ]),
                },attrs:{"name":"verificationCode","type":"text","placeholder":"Verification code","autocomplete":"off"},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.checkValidationFlags('$verification-form', 'verificationCode', [
                    'dirty',
                    'invalid' ])
                ),expression:"\n                  checkValidationFlags('$verification-form', 'verificationCode', [\n                    'dirty',\n                    'invalid',\n                  ])\n                "}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('verification-form.verificationCode'))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("New password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.verificationNewPassword),expression:"verificationNewPassword"},{name:"validate",rawName:"v-validate",value:('required|min:10'),expression:"'required|min:10'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags(
                    '$verification-form',
                    'verificationNewPassword',
                    ['dirty', 'invalid']
                  ),
                },attrs:{"name":"verificationNewPassword","type":"password","placeholder":"New password"},domProps:{"value":(_vm.verificationNewPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.verificationNewPassword=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.checkValidationFlags('$verification-form', 'verificationNewPassword', [
                    'dirty',
                    'invalid' ])
                ),expression:"\n                  checkValidationFlags('$verification-form', 'verificationNewPassword', [\n                    'dirty',\n                    'invalid',\n                  ])\n                "}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('verification-form.verificationNewPassword'))+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn",attrs:{"type":"submit","disabled":_vm.validationErrors.any('verification-form')},on:{"click":function($event){$event.preventDefault();return _vm.verifyAndSetNewPassword.apply(null, arguments)}}},[_vm._v(" Set new password ")])])])]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.authenticationState === 'newPasswordRequired'),expression:"authenticationState === 'newPasswordRequired'"}],attrs:{"data-vv-scope":"newPassword-form"},on:{"submit":function($event){$event.preventDefault();return _vm.setNewPassword.apply(null, arguments)}}},[_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("New password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"},{name:"validate",rawName:"v-validate",value:('required|min:10'),expression:"'required|min:10'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags('$newPassword-form', 'newPassword', [
                    'dirty',
                    'invalid' ]),
                },attrs:{"name":"newPassword","type":"password","placeholder":"New password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.checkValidationFlags('$newPassword-form', 'newPassword', ['dirty', 'invalid'])
                ),expression:"\n                  checkValidationFlags('$newPassword-form', 'newPassword', ['dirty', 'invalid'])\n                "}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('newPassword-form.newPassword'))+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn",attrs:{"type":"submit","disabled":_vm.validationErrors.any('newPassword-form')},on:{"click":function($event){$event.preventDefault();return _vm.setNewPassword.apply(null, arguments)}}},[_vm._v(" Set new password ")])])])]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.authenticationState === 'mfaCodeRequired'),expression:"authenticationState === 'mfaCodeRequired'"}],attrs:{"data-vv-scope":"mfaCode-form"},on:{"submit":function($event){$event.preventDefault();return _vm.verifyMfaCode.apply(null, arguments)}}},[_vm._m(4),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('label',{staticClass:"label"},[_vm._v("MFA Code")]),_c('clearable-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  input: true,
                  'is-danger': _vm.checkValidationFlags('$mfaCode-form', 'mfaCode', [
                    'dirty',
                    'invalid' ]),
                },attrs:{"name":"mfaCode","type":"text","placeholder":"MFA Code","autocomplete":"off"},model:{value:(_vm.mfaCode),callback:function ($$v) {_vm.mfaCode=$$v},expression:"mfaCode"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkValidationFlags('$mfaCode-form', 'mfaCode', ['dirty', 'invalid'])),expression:"checkValidationFlags('$mfaCode-form', 'mfaCode', ['dirty', 'invalid'])"}],staticClass:"help is-danger"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.validationErrors.first('mfaCode-form.mfaCode'))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn",attrs:{"type":"submit","disabled":_vm.validationErrors.any('mfaCode-form')},on:{"click":function($event){$event.preventDefault();return _vm.verifyMfaCode.apply(null, arguments)}}},[_vm._v(" Verify ")])])])])])])]),_c('div',{staticClass:"col s5 m6"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authentication__logo"},[_c('h1',[_vm._v("Welcome to Star")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('p',[_vm._v("Login with username and password.")]),_c('p',[_vm._v(" If it's the first time you login, please use the one-time password received in the registration email. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('p',[_vm._v("Verification code required.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('p',[_vm._v("Set new password.")]),_c('p',[_vm._v("This will be the password you sign in with from now on.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('p',[_vm._v("Verify your account.")]),_c('p',[_vm._v("Please verify your account with the verification code sent to you by SMS.")])])])}]

export { render, staticRenderFns }