<template>
  <div>
    <div class="row small-inner">
      <div class="col s6">
        <h2>External information</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a
          :class="{ disabled: routeVersion() || mainArtist.readonly }"
          @click="$emit('edit', $options.name)"
        >
          <template v-if="externalSources.length > 0">
            <i class="fas fa-pencil-alt"></i>
            Edit
          </template>
          <template v-else>
            <i class="far fa-plus-square"></i>
            Add
          </template>
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <ul>
          <li v-for="(source, index) in externalSources" :key="index">
            <a :href="source.url | toHref" target="_blank" :title="source.source">
              {{ source.source | toTitleCase }}
            </a>
          </li>
        </ul>
        <span v-if="externalSources.length === 0" class="none">None</span>
      </div>
    </div>
  </div>
</template>

<script>
import AggregateMixin from '../../../common/aggregateMixin';

export default {
  name: 'ExternalSources',
  components: {},
  mixins: [AggregateMixin],
  props: {
    mainArtist: { type: Object },
  },
  computed: {
    externalSources() {
      return this.mainArtist.basic_info.other_external_sources;
    },
  },
  methods: {},
};
</script>
