<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Documents</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>
    <template v-if="documentsToShow()">
      <div v-for="document in documents" :key="document.id" class="row card">
        <div class="col s3">
          <span class="text--bold">{{ getDocumentType(document.type) }}</span>
          ({{ document.created | formatDate }})
        </div>
        <div class="col s9">
          <a @click="onDownloadContract(document)">
            <i class="far fa-file-alt" />
            {{ getFileName(document.url) }}
          </a>
        </div>
      </div>
    </template>
    <div v-else class="card">
      <span class="none">No uploaded documents</span>
    </div>
  </div>
</template>

<script>
import DownloadLink from '../file/downloadLink';
import extractRemoteFileName from '../../../common/extractRemoteFileName';
import RemoteFileService from '../../../services/remoteFileService';

export default {
  name: 'DocumentsInformation',
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    documentTypes() {
      return this.$store.state.appdata.referenceData.documentTypes;
    },
  },
  methods: {
    getDocumentType(code) {
      const type = this.documentTypes.find((dt) => dt.code === code);
      return type ? type.name : code;
    },
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    documentsToShow() {
      return this.documents.length > 0 && this.documents.filter((doc) => doc.id !== '').length > 0;
    },
    async onDownloadContract(document) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(document.url);
      DownloadLink.direct(fileData, extractRemoteFileName(document.url));
    },
  },
};
</script>
