var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',[_c('div',[_c('table',[_vm._m(0),_c('tbody',_vm._l((Object.entries(_vm.fields)),function(ref){
var key = ref[0];
var field = ref[1];
return _c('tr',{key:key,staticClass:"row"},[_c('td',{staticClass:"thin-column"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(field.enabled),expression:"field.enabled"}],staticClass:"filled-in",attrs:{"id":key,"type":"checkbox"},domProps:{"checked":Array.isArray(field.enabled)?_vm._i(field.enabled,null)>-1:(field.enabled)},on:{"change":function($event){var $$a=field.enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(field, "enabled", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(field, "enabled", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(field, "enabled", $$c)}}}}),_c('label',{attrs:{"for":key}})]),(field.enabled)?_c('td',[(field.showLabelTitle)?_c('label',[_vm._v(_vm._s(_vm.capitalise(field.title))+" ")]):_vm._e(),_c(field.type,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(field.validationRule && field.validationRule(_vm.fields, field)),expression:"field.validationRule && field.validationRule(fields, field)"}],tag:"component",attrs:{"id":(key + "-value"),"name":field.title,"value":field.getValue ? field.getValue(field) : field.value},on:{"input":function (event) { return field.valueEventHandler(field, event); }}},'component',field.props,false)),(field.mandatory)?_c('span',{staticClass:"mandatory"},[_vm._v("(mandatory)")]):_vm._e(),_c('label',{attrs:{"for":(key + "-value")}})],1):_c('td',[_vm._v("Check the box to modify "+_vm._s(field.title))])])}),0)]),_c('div',{staticClass:"push--top float-right"},[_c('action-buttons',{attrs:{"disable-submit":!(
              Object.values(_vm.fields).some(function (f) { return f.enabled; }) &&
              Object.values(_vm.fields)
                .filter(function (f) { return f.enabled; })
                .every(function (f) { return f.canSave(f); })
            )},on:{"save":function($event){_vm.showModal = true},"cancel":function($event){return _vm.$emit('cancel')}}})],1),_c('ChangeTracksModal',{attrs:{"title":"basic info","items":Object.values(_vm.fields)
            .filter(function (f) { return f.enabled; })
            .map(function (f) { return ({
              prop: f.title,
              value: f.valueToText(f),
            }); }),"show":_vm.showModal,"count":_vm.count},on:{"cancel":function($event){_vm.showModal = false},"save":_vm.onSave}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"row"},[_c('td',{staticClass:"thin-column text--bold"},[_vm._v("Modify")]),_c('td',{staticClass:"text--bold"},[_vm._v("Attribute")])])}]

export { render, staticRenderFns }