<template>
  <div v-if="transfer">
    <div class="row">
      <div class="col s12">
        Transfer made by {{ transfer.createdBy }},
        {{ transfer.createdAt | dateParse | dateFormat('YYYY-MM-DD HH:mm') }}
        <p>
          From:
          <router-link :to="getLink(transfer.from)">{{ transfer.from.name }}</router-link>
          ({{ transfer.from.type }})
        </p>
        <p>
          To:
          <router-link :to="getLink(transfer.to)">{{ transfer.to.name }}</router-link>
          ({{ transfer.to.type }})
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <h3>Note</h3>
        <p>{{ transfer.note }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/services/apolloRequest';
import gql from 'graphql-tag';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      transfer: null,
    };
  },
  async created() {
    const { transfer } = await query({
      query: gql`
        query {
          transfer(id: "${this.id}") {
            type
            note
            from {
              id
              name
              type
            }
            to {
              id
              name
              type
            }
            createdBy
            createdAt
          }
        }
      `,
    });
    this.transfer = transfer;
  },
  methods: {
    getLink(node) {
      if (node.type === 'performer') {
        return `/performers/${node.id}/information`;
      }
      return `/associates/${node.id}/information`;
    },
  },
};
</script>
