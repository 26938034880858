<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2>Create Mediarc Request</h2>
      </div>
    </div>
    <form class="card">
      <div v-if="loading">
        <spinner />
      </div>
      <template v-else>
        <div class="row">
          <div class="col s2">
            <select-date v-model="fromDate" name="from_date" label="From date" />
          </div>
          <div class="col s2">
            <select-date
              v-model="toDate"
              name="to_date"
              label="To date"
              :rule="{ same_or_after: fromDate }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <button class="btn" type="submit" :disabled="!validForm" @click.prevent="createFile">
              Create
            </button>
          </div>
        </div>
      </template>
    </form>

    <div class="row">
      <div class="col s12">
        <h2>Mediarc Requests</h2>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col s12">
          <table>
            <thead class="cursor-pointer">
              <th v-for="column in columns" :key="column.name" class="disabled-head">
                {{ column.name }}
              </th>
            </thead>
            <tbody>
              <tr v-for="file in files" :key="file.filename" class="row">
                <td>
                  <span>
                    {{ file.from }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ file.to }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ file.created | formatTimestamp }}
                  </span>
                </td>
                <td>
                  <span>
                    <a @click="onDownloadFile(file.filename)">
                      <i class="fas fa-download" />
                      Download {{ file.filename }}
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import moment from 'moment';
import SelectDate from '../../ui/select/select-date';
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';
import MediarcService from '../../../services/mediarcService';
import Spinner from '../../spinner';

export default {
  name: 'ViewMediarcRequest',
  components: {
    SelectDate,
    Spinner,
  },
  beforeRouteLeave(_to, _from, next) {
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = null;
    next();
  },
  data() {
    return {
      loading: false,
      actualFileCount: 0,
      expectedFileCount: 0,
      timeoutHandle: null,
      fromDate: '',
      toDate: '',
      files: [],
      columns: [{ name: 'From' }, { name: 'To' }, { name: 'Created' }, { name: 'Download' }],
    };
  },
  computed: {
    validForm() {
      return this.fromDate && this.toDate && moment(this.toDate).isSameOrAfter(this.fromDate);
    },
  },
  async created() {
    await this.fetchFiles();
  },
  methods: {
    resetForm() {
      this.toDate = '';
      this.fromDate = '';
    },
    async createFile() {
      const processId = uuid.v4();
      this.loading = true;
      try {
        this.$store.dispatch('process/addProcess', {
          name: `Mediarc file request - ${this.fromDate} - ${this.toDate}`,
          id: processId,
        });
        await MediarcService.requestFile({
          from: this.fromDate,
          to: this.toDate,
          process_id: processId,
        });
        this.expectedFileCount = this.actualFileCount + 1;
        this.setFetchFilesTimeOut();
        this.resetForm();
      } catch (error) {
        error.title = 'Mediarc file could not be requested';
        this.$addStarError(error);
        this.$store.dispatch('process/removeProcess', processId);
      }
      this.loading = false;
    },
    async fetchFiles() {
      this.loading = true;
      try {
        const files = await RemoteFileService.listDocumentRepository('mediarc');
        this.actualFileCount = files.length;
        this.files = files
          .map(({ filename, lastModified }) => {
            const [, from, to] = filename.split('_');
            return {
              from,
              to,
              created: lastModified,
              filename,
            };
          })
          .sort((a, b) => +b.created - +a.created);

        if (this.expectedFileCount > 0 && this.expectedFileCount > this.actualFileCount) {
          this.setFetchFilesTimeOut();
        }
      } catch (error) {
        error.title = 'Files could not be fetched';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    setFetchFilesTimeOut() {
      if (this.timeoutHandle === null) {
        this.timeoutHandle = setTimeout(() => {
          this.timeoutHandle = null;
          this.fetchFiles();
        }, 20000);
      }
    },
    async onDownloadFile(fileName) {
      try {
        const file = `mediarc/${fileName}`;
        const fileData = await RemoteFileService.downloadEndOfYearStatementFile(file);
        DownloadLink.direct(fileData, file);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
  },
};
</script>
