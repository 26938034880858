<template>
  <div>
    <div class="row small-inner">
      <div class="col s6">
        <h2>Notes</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a
          v-if="!editingNote"
          :class="{ disabled: routeVersion() || readonly }"
          @click="editingNote = true"
        >
          <i class="fas fa-pen-square"></i>
          Write a new note
        </a>
        <action-buttons
          v-else
          submit-label="Post"
          :disable-submit="newNoteText.length === 0"
          @save="saveNew"
          @cancel="cancel"
        />
      </div>
    </div>
    <template v-if="editingNote">
      <div class="row card card--half">
        <div class="col s12">
          <textarea cols="30" rows="10" @input="updateNewNoteText" @keyup="autoHeight" />
        </div>
        <file-upload-simple-field
          class="col s12"
          name="note-attachment"
          :blue-link="true"
          label="Add attachment"
          @addFile="addAttachment"
          @removeFile="removeAttachment"
        />
      </div>
    </template>

    <div v-if="notes.length === 0 && !editingNote" class="row">
      <div class="col s12">
        <span class="none">No notes yet.</span>
      </div>
    </div>

    <div v-else class="row flush--bottom">
      <div class="col s12">
        <div v-for="(note, index) in notes" :key="note.timestamp">
          <div
            v-if="index < 3 || showAll"
            class="note card card--half"
            :class="{ 'note--pinned': note.pinned }"
          >
            <div v-if="!note.deleted" class="note__action">
              <context-menu
                :disabled="routeVersion() || readonly"
                :options="[note.pinned ? 'Unpin' : 'Pin', 'Delete']"
                @pin="pinOrUnpinExisting(note)"
                @unpin="pinOrUnpinExisting(note)"
                @delete="deleteExisting(note)"
              />
            </div>
            <template v-if="note.deleted">
              <div class="note__content">
                You deleted one note.
                <a @click="undoDelete(note)">
                  <i class="fas fa-undo-alt" />
                  Undo
                </a>
              </div>
            </template>
            <template v-else>
              <div class="note__content" v-html="converter.makeHtml(note.noteText)" />
              <div class="note__attachment">
                <div v-for="(a, index2) in note.attachments" :key="index2">
                  <a class="word-break-all" @click="onDownloadFile(a)">
                    <i class="far fa-file-alt" />
                    {{ getFileName(a) }}
                  </a>
                </div>
              </div>
              <div class="note__info">
                <div class="col s6 note__meta">
                  {{ note.timestamp | formatDateTime }}
                </div>
                <div class="col s6 note__meta text--right">
                  {{ note.user }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="notes.length > 3" class="row note__more">
          <a class="col s12" @click="showAll = !showAll">
            {{ showAll ? 'Hide' : 'Show more notes' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import showdown from 'showdown';
import { textareaAutoheight } from '../../../domain/common';
import { saveNote, queryNotes, storeNoteAttachments } from '../../../services/notesService';
import ActionButtons from '../button/action-buttons';
import ContextMenu from '../context-menu';
import DownloadLink from '../file/downloadLink';
import FileUploadSimpleField from '../file/file-upload-simple-field';
import RemoteFileService from '../../../services/remoteFileService';
import AggregateMixin from '../../../common/aggregateMixin';
import extractRemoteFileName from '../../../common/extractRemoteFileName';

/*
  The notes are stored in DynamoDB. The notes service communicates directly
  with Dynamo through a API-gateway set up in AWS. Attachments are stored
  separately in the docrepo on S3, just as other attachments. Note that there
  is support for multiple attachments all the way from the service down to Dynamo,
  it's only the GUI that lack this support.
 */

export default {
  name: 'Notes',
  components: {
    ActionButtons,
    ContextMenu,
    FileUploadSimpleField,
  },
  mixins: [AggregateMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: [],
      editingNote: false,
      newNoteText: '',
      attachments: [],
      converter: new showdown.Converter({ simpleLineBreaks: true }),
      showAll: false,
      optionsVisible: false,
      deletedNotes: [],
    };
  },
  computed: {
    numberOfPinned() {
      return _.countBy(this.notes, 'pinned').true;
    },
  },
  created() {
    this.refresh();
  },
  methods: {
    updateNewNoteText(e) {
      this.newNoteText = e.target.value;
    },
    addAttachment(file) {
      this.attachments.push(file);
    },
    removeAttachment() {
      this.attachments.length = 0;
    },
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    async onDownloadFile(url) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
        DownloadLink.direct(fileData, url);
      } catch (e) {
        console.log("Couldn't download", e);
      }
    },
    async refresh() {
      try {
        this.notes = await queryNotes(this.id);
      } catch (e) {
        console.log("Couldn't refresh", e);
      }
      this.notes = this.notes.concat(this.deletedNotes);
      this.notes = _.orderBy(this.notes, ['pinned', 'timestamp'], ['desc', 'desc']);
      this.resetInteractionState();
    },
    async saveNew() {
      try {
        const attachments = await storeNoteAttachments(this.attachments, this.id);
        await saveNote({
          entityId: this.id,
          noteText: this.newNoteText,
          attachments,
        });
      } catch (e) {
        console.log("Couldn't save:\n", e);
      }
      this.refresh();
    },
    async deleteExisting(note) {
      note.pinned = false;
      note.deleted = true;
      this.addToDeletedNotes(note);
      try {
        await saveNote(note);
        setTimeout(() => {
          this.removeFromDeletedNotes(note.timestamp);
        }, 16000);
      } catch (e) {
        console.log("Couldn't delete:\n", e);
      }
      this.refresh();
    },
    async undoDelete(note) {
      try {
        this.removeFromDeletedNotes(note.timestamp);
        note.deleted = false;
        await saveNote(note);
      } catch (e) {
        note.deleted = true;
        this.addToDeletedNotes(note);
        console.log("Couldn't undo", e);
      }
      this.refresh();
    },
    addToDeletedNotes(note) {
      this.deletedNotes.push(Object.assign({}, note));
    },
    removeFromDeletedNotes(timestamp) {
      this.deletedNotes = this.deletedNotes.filter((note) => note.timestamp !== timestamp);
    },
    async pinOrUnpinExisting(note) {
      if (this.numberOfPinned > 2 && !note.pinned) {
        return;
      }
      note.pinned = !note.pinned;
      try {
        await saveNote(note);
      } catch (e) {
        console.log("Couldn't pin:\n", e);
      }
      this.refresh();
    },
    resetInteractionState() {
      this.editingNote = false;
      this.optionsVisible = false;
      this.newNoteText = '';
      this.attachments.length = 0;
    },
    cancel() {
      this.resetInteractionState();
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>

<style lang="scss">
.note {
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-areas:
    'content content kebab'
    'content content .'
    'attachment attachment attachment'
    'info info info';
  grid-template-columns: 1fr 1fr 24px;
  grid-template-rows: 24px 1fr auto 30px;

  &__action {
    grid-area: kebab;

    i {
      font-size: 14px;
    }
    a {
      color: var(--black);
    }
  }

  &__content {
    grid-area: content;
    margin-bottom: var(--spacing-double);

    p {
      margin: 0;
    }
  }

  &__attachment {
    grid-area: attachment;
    margin-bottom: var(--spacing);
  }

  &__info {
    grid-area: info;
    border-top: 1px solid #ccc;
    padding-top: var(--spacing);
  }

  &__meta {
    color: #95989a;
  }

  &__more {
    text-align: center;
    margin-bottom: 10px;
  }

  &--pinned {
    border: 2px solid #07b100;
    border-radius: 2px;
  }
}
</style>
