var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-sdeg-file"},[_c('div',{staticClass:"row small-inner"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v(" Upload "),(_vm.displaySource === 'played-recordings')?_c('span',[_vm._v("played recordings")]):_c('span',[_vm._v("claims")]),_vm._v(" file ")])])]),_c('div',{staticClass:"card"},[_c('form',{staticClass:"row",attrs:{"data-vv-scope":_vm.scope}},[_c('div',{staticClass:"file-upload"},[_c('div',{staticClass:"col s2"},[_c('select-society',{attrs:{"label":"Society","scope":_vm.scope,"value":_vm.uploadFile.society,"extended-result":true},on:{"input":function (evt) {
                _vm.uploadFile.society = evt.localCode.code;
              }}})],1),_c('div',{staticClass:"col s2"},[_c('validated-text-input',{attrs:{"name":"uploadFile.name","label":"Name","placeholder":_vm.displaySource === 'played-recordings'
                ? 'E.g. Played recordings file from PPL'
                : 'Claims from PPL',"value":_vm.uploadFile.name,"scope":_vm.scope},on:{"input":function (value) {
                _vm.uploadFile.name = value;
              }}})],1),_c('div',{staticClass:"col s2"},[_c('validated-text-input',{attrs:{"name":"uploadFile.from","label":"Performance year from","placeholder":"YYYY","value":_vm.uploadFile.from,"rule":{ year: true, min: 4 },"maxlength":4,"scope":_vm.scope},on:{"input":function (value) {
                _vm.uploadFile.from = value;
              }}})],1),_c('div',{staticClass:"col s2"},[_c('validated-text-input',{attrs:{"name":"uploadFile.to","label":"Performance year to","placeholder":"YYYY","value":_vm.uploadFile.to,"rule":!!_vm.uploadFile.from
                ? { min_value: _vm.uploadFile.from, year: true, min: 4 }
                : { year: true, min: 4 },"maxlength":4,"scope":_vm.scope},on:{"input":function (value) {
                _vm.uploadFile.to = value;
              }}})],1),_c('div',{staticClass:"col s2"},[_c('select-date',{attrs:{"name":"deadline","label":"Deadline date","scope":_vm.scope},model:{value:(_vm.deadline),callback:function ($$v) {_vm.deadline=$$v},expression:"deadline"}})],1),_c('div',{staticClass:"col s2"},[_c('span',[_vm._v(" File to upload "+_vm._s(_vm.displaySource === 'played-recordings' ? '(xml UTF-8)' : '(xml UTF-8, xls)')+" ")]),_c('div',{staticClass:"file-input nowrap",attrs:{"action":"","method":"POST"}},[_c('input',{attrs:{"id":"file","type":"file","accept":_vm.displaySource === 'played-recordings' ? '.xml' : '.xml,.xls,.xlsx'},on:{"change":_vm.addFile}}),_c('span',{ref:"fileUploadName"},[_c('i',{staticClass:"far fa-file-alt"}),_vm._v(" Drag your files here or click in this area. ")])])])])]),(_vm.displaySource === 'played-recordings')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col offset-s10 s2 text--right"},[_c('input',{staticClass:"filled-in",attrs:{"id":"fakeIds","name":"fakeIds","type":"checkbox"},domProps:{"checked":false},on:{"change":function($event){return _vm.updateSaveRec($event.target.checked)}}}),_c('label',{attrs:{"for":"fakeIds"}},[_vm._v("Contains Fake LocalCodes")])])]):_vm._e(),_c('div',{staticClass:"row flush"},[_c('div',{staticClass:"col offset-s8 s3 margin--top"},[(_vm.isUploadingFile && !_vm.error)?_c('ProgressBar',{attrs:{"label":"Uploading","total":100,"processed":_vm.uploadFile.uploadProgress,"show-percent":true}}):_vm._e()],1),_c('div',{staticClass:"col s1 text--right"},[_c('button',{staticClass:"btn secondary abs-top",attrs:{"type":"submit","disabled":!_vm.validForm || _vm.isUploadingFile},on:{"click":function($event){$event.preventDefault();return _vm.uploadSdegFile.apply(null, arguments)}}},[_vm._v(" Upload ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }