<template>
  <form>
    <div class="row card">
      <div v-if="payment">
        <div class="row">
          <div class="col s12">
            <p>
              Total amount:
              {{ (payment.payableAmount + payment.payableVat) | formatAmountCent('SEK') }}
            </p>
            <p>Total number of nodes: {{ approved.totalCount }}</p>
            <p>Total number of payees: {{ approved.payeeCount }}</p>
            <p>
              <i>
                Created by {{ payment.createdBy }}
                {{ payment.createdAt | formatDateTime }}
              </i>
            </p>
          </div>
        </div>
        <div v-if="filesToDisplay && filesToDisplay.length > 0" class="row">
          <div class="col s12">
            <h3>Payment file attachments</h3>
            <ul>
              <li v-for="(file, idx) in filesToDisplay" :key="idx">
                {{ file.name }}
                <span>({{ getFileType(file) }})</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <simple-spinner />
        </div>
      </div>
    </div>
    <div v-if="payment && payment.files && payment.files.length > 0" class="row">
      <div class="col s4 offset-s8">
        <verify-with-password
          v-if="verificationStatus !== 'success'"
          :message="verifyWithPasswordMessage"
          :verification-status="verificationStatus"
          :number-of-attempts="numberOfAttempts"
          submit-label="Confirm"
          @onSubmitPassword="onSubmitPassword"
          @onSubmitMfaCode="onSubmitMfaCode"
          @onBypassAuth="onBypassAuth"
        />
      </div>
    </div>
  </form>
</template>

<script>
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import VerifyWithPassword from '../ui/input/verify-with-password';
import SimpleSpinner from '../ui/simple-spinner';
import AuthenicationMixin from '../../common/authenictaionMixin';
import { fileType, paymentNodeState } from '../../domain/paymentDomain';
import { mutate } from '../../services/apolloRequest';
import { paymentCommand } from '../../store/modules/payment/store';

export default {
  name: 'ProgressBankFile',
  components: {
    VerifyWithPassword,
    SimpleSpinner,
  },
  mixins: [AuthenicationMixin],
  props: {
    payment: Object,
  },
  data() {
    return {
      verifyWithPasswordMessage:
        ' I hereby confirm that I have uploaded a file\n' +
        '          to the bank and are aware of the booking and\n' +
        '          payment transactions that will be generated\n' +
        '          when confirming this payment.',
      createFilesBtnClicked: false,
    };
  },
  computed: {
    paymentId() {
      return this.$route.params.paymentId;
    },
    showProgressBar() {
      return !!this.payment.processId;
    },
    approved() {
      return this.payment.stats.find((s) => s.state === paymentNodeState.Approved);
    },
    filesToDisplay() {
      // do not show bank files at this point
      return this.payment.files.filter((file) => file.type !== fileType.indexOf('Bank'));
    },
  },
  methods: {
    async downloadBankfile(key) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(key);
        DownloadLink.direct(fileData, key);
      } catch (e) {
        console.log("Couldn't download", e);
      }
    },
    // this method is triggered by the VerifyWithPassword mixin after a successful verification
    async doWork() {
      await mutate(
        paymentCommand({
          stream_id: this.payment.id,
          type: 'payment/confirm',
          payload: {},
        }),
      );
      this.$emit('confirmed');
    },
    getFileType(file) {
      return fileType[file.type] || 'Unknown';
    },
  },
};
</script>
