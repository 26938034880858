<template>
  <div>
    <div v-if="loading" class="result__spinner">
      <spinner />
    </div>
    <div class="result">
      <div class="row wrapper">
        <div class="col s8">
          <h1>SDEG: {{ header.plr_match_data.line_count }} claims from {{ basicInfoName }}</h1>
          <h2>{{ header.report_name }}</h2>
        </div>
      </div>
      <div v-if="rejectionInfo" class="row wrapper">
        <sdeg-deadline
          class="col s3 offset-s4"
          :deadline="rejectionInfo.deadline"
          :finished="rejectionInfo.finished_by !== undefined"
          @update="updateDeadline"
        />
        <div class="col s2 float-right">
          <input
            id="finished-process"
            name="finished-process"
            type="checkbox"
            value="!!rejectionInfo.finished_by"
            :checked="!!rejectionInfo.finished_by"
            class="filled-in"
            @change="onFinished(rejectionInfo)"
          />
          <label for="finished-process">Finished process</label>
        </div>

        <div class="col s3 float-right">
          <strong>Rejections</strong>
          <div v-if="rejectionInfo.rejectionFiles && rejectionInfo.rejectionFiles.length > 0">
            <ul>
              <li v-for="(rejectionFile, index) in rejectionInfo.rejectionFiles" :key="index">
                <div>
                  <a class="word-break-all" @click="onDownloadFile(rejectionFile.key)">
                    <i class="far fa-file-alt" />
                    Link to file
                  </a>
                  By {{ rejectionFile.created_by }} {{ rejectionFile.created_date | formatDate }}
                </div>
                <div>
                  <input
                    :id="`file-${index}`"
                    :name="`file-${index}`"
                    type="checkbox"
                    value="!!rejectionFile.sent_by"
                    :checked="!!rejectionFile.sent_by"
                    class="filled-in"
                    @change="onSent(rejectionFile, index)"
                  />
                  <label :for="`file-${index}`">Rejections have been sent to society</label>
                  <div v-if="rejectionFile.sent_by">
                    By {{ rejectionFile.sent_by }} {{ rejectionFile.sent_date | formatDate }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="none">No rejection files created</div>
        </div>
      </div>

      <div class="row wrapper">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link
              class="nowrap"
              :to="{
                path: `/societies/${id}/sdeg/claims/${reportId}/to_check`,
                query: { worklist_id: worklistId },
              }"
            >
              To Check ({{ header.plr_match_data.to_check || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="{
                path: `/societies/${id}/sdeg/claims/${reportId}/accepted`,
                query: { worklist_id: worklistId },
              }"
            >
              Accepted ({{ header.plr_match_data.accepted || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="{
                path: `/societies/${id}/sdeg/claims/${reportId}/rejected`,
                query: { worklist_id: worklistId },
              }"
            >
              Rejected ({{ header.plr_match_data.rejected || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="{
                path: `/societies/${id}/sdeg/claims/${reportId}/applied`,
                query: { worklist_id: worklistId },
              }"
            >
              Applied ({{ header.plr_match_data.applied || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="{
                path: `/societies/${id}/sdeg/claims/${reportId}/sent_rejections`,
                query: { worklist_id: worklistId },
              }"
            >
              Sent Rejections ({{ header.plr_match_data.rejected_sent || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="{ path: `/societies/${id}/sdeg/claims/${reportId}/unknown` }"
            >
              Not matched
              <span v-if="header.report_state === 'MF'">
                <i
                  v-if="(header.plr_match_data.not_matched || 0) > 0"
                  class="fa fa-exclamation-circle text--red"
                />
                <i
                  v-if="(header.plr_match_data.not_matched || 0) === 0"
                  class="fa fa-check-circle text--green"
                />
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <template v-if="!loading">
        <keep-alive>
          <router-view @updated="loadHeader" />
        </keep-alive>
      </template>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapGetters } from 'vuex';
import Spinner from '../../../../spinner';
import ViewSocietyMixin from '../../view-society-mixin';
import DownloadLink from '../../../../ui/file/downloadLink';
import MatchingService from '../../../../../services/matchingService';
import RemoteFileService from '../../../../../services/remoteFileService';
import AuthenticationService from '../../../../../services/authenticationService';
import SdegDeadline from '@/components/sdeg/sdeg-deadline.vue';

export default {
  name: 'ViewSdegPlaylistLines',
  components: {
    SdegDeadline,
    Spinner,
  },
  mixins: [ViewSocietyMixin],
  props: {
    processType: String,
  },
  data() {
    return {
      loading: true,
      reportId: this.$router.currentRoute.params.reportId,
      worklistId: this.$router.currentRoute.query.worklist_id,
      rejectionInfo: {},
      header: {
        plr_match_data: {},
      },
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'basicInfoName']),
  },
  watch: {
    $route(to, from) {
      if (to.query.worklist_id !== from.query.worklist_id) {
        this.worklistId = this.$router.currentRoute.query.worklist_id;
        this.loadHeader();
      }
    },
  },
  async created() {
    await this.loadSociety();
    await this.loadHeader();
    this.loading = false;
  },
  methods: {
    async onSent(rejectionFile, index) {
      if (rejectionFile.sent_by === undefined) {
        rejectionFile.sent_by = AuthenticationService.getUserName();
        rejectionFile.sent_date = new Date();
      } else {
        rejectionFile.sent_by = undefined;
        rejectionFile.sent_date = undefined;
      }
      this.$set(this.rejectionInfo.rejectionFiles, index, rejectionFile);
      await MatchingService.updateReportHeader(this.reportId, { info: this.rejectionInfo });
    },
    async onFinished(rejectionInfo) {
      if (rejectionInfo.finished_by === undefined) {
        rejectionInfo.finished_by = AuthenticationService.getUserName();
        rejectionInfo.finished_date = new Date();
      } else {
        rejectionInfo.finished_by = undefined;
        rejectionInfo.finished_date = undefined;
      }
      await MatchingService.updateReportHeader(this.reportId, { info: this.rejectionInfo });
    },
    async onDownloadFile(url) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
        DownloadLink.direct(fileData, `SAMI-${url.replace(/\//g, '-')}`);
      } catch (e) {
        console.log("Couldn't download", e);
      }
    },
    async loadSociety() {
      this.aggregateId = this.$router.currentRoute.params.id;
      this.version = this.$router.currentRoute.params.version;
      try {
        await this.fetchData(this.aggregateId, this.version);
      } catch (error) {
        this.handleError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.aggregateId },
        });
      }
    },
    async loadHeader() {
      try {
        this.header = await MatchingService.getReportHeader('PLR', this.reportId, this.worklistId);
        if (this.header.info) {
          this.rejectionInfo = _.cloneDeep(this.header.info);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    async updateDeadline(newDeadline) {
      this.rejectionInfo.deadline = newDeadline;
      await MatchingService.updateReportHeader(this.reportId, { info: this.rejectionInfo });
    },
  },
};
</script>
