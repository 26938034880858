<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Tracks</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit"></i>
          Edit
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col s3">
        <span>Bulk</span>
        <select-input
          name="bulk"
          :value="bulkTrackAction"
          :items="bulkTrackActions"
          :disabled="bulkSelectedTracks.length === 0"
          item-key="key"
          item-value="value"
          @input="onTrackBulkAction"
        />
      </div>
    </div>

    <div v-if="bulkTrackAction && bulkSelectedTracks.length > 0" class="row card">
      <div
        :is="bulkTrackAction"
        :count="bulkSelectedTracks.length"
        :tracks="bulkSelectedTracks"
        @cancel="bulkTrackAction = null"
        @save="saveBulkAction"
      ></div>
    </div>

    <div class="row card">
      <div class="col s12">
        <table>
          <sortable-head
            :columns="columns"
            :bulk-enabled="true"
            :marked-for-bulk="bulkSelectedTracks.length === localTracks.length"
            :default-sort-should-override="true"
            @sort="onSort"
            @markAll="onMarkAllTracks"
          />
          <tbody>
            <tr v-for="(track, index) in localTracks" :key="index" class="row">
              <td>
                <input
                  :id="`track-cbx-${index}`"
                  v-model="bulkTrackSelection[index]"
                  class="filled-in"
                  type="checkbox"
                  name="mark"
                />
                <label :for="`track-cbx-${index}`" />
              </td>
              <td>
                <router-link :to="'/recordings/' + track.recording.id">
                  {{
                    track.track_title
                      ? track.track_title
                      : track.recordingAggregate.basic_info.name
                  }}{{ track.recordingAggregate.lineup_locked ? ' 🔒' : '' }}
                </router-link>
              </td>
              <td>
                <span v-if="track.recordingAggregate.basic_info.version_title">
                  {{ track.recordingAggregate.basic_info.version_title }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="track.recordingAggregate.basic_info.duration_sec">
                  {{ track.recordingAggregate.basic_info.duration_sec | toMinutes }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <router-link
                  :to="'/mainartists/' + track.recordingAggregate.basic_info.main_artist.id"
                  class="fs-13"
                >
                  {{ track.recordingAggregate.basic_info.main_artist.name }}
                </router-link>
              </td>
              <td>
                <span v-if="track.recording.side_no">
                  {{ track.recording.side_no }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="track.recording.track_no">
                  {{ track.recording.track_no }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="vrdb2IdOfTrack(track)">
                  {{ vrdb2IdOfTrack(track) }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <span v-if="track.recordingAggregate.basic_info.isrc">
                  {{ track.recordingAggregate.basic_info.isrc }}
                </span>
                <span v-else class="none">None</span>
              </td>
              <td class="status">
                <span v-if="track.recordingAggregate.distribution_state.status">
                  <status-icon :status="track.recordingAggregate.distribution_state.status" />
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                <context-menu :options="['Copy lineup']" @copyLineup="openCopyLineupModal(track)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <CopyLineupModal
      v-if="showCopyLineupModal"
      :destinations="copyDestinations"
      :source="copySource"
      @cancel="showCopyLineupModal = false"
      @copy="copyLineup"
    ></CopyLineupModal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PnrService from '../../../services/pnrService.js';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import clone from './../../../common/clone';
import SortableHead from '../../ui/table/sortable-head';
import StatusIcon from '../../ui/status-icon';
import ContextMenu from '../../ui/context-menu';
import CopyLineupModal from '../../ui/recording/copy-lineup-modal';
import SelectInput from '../../ui/select/select-input';
import ChangeTracksLineupComplete from '../edit/bulk/change-tracks-lineup-complete';
import ChangeTracksBasicInfo from '../edit/bulk/change-tracks-basic-info';

export default {
  name: 'Tracks',
  components: {
    SortableHead,
    StatusIcon,
    ContextMenu,
    CopyLineupModal,
    SelectInput,
    ChangeTracksLineupComplete,
    ChangeTracksBasicInfo,
  },
  data() {
    return {
      localTracks: [],
      showCopyLineupModal: false,
      copySource: {},
      copyDestinations: [],
      bulkTrackSelection: [],
      bulkTrackAction: null,
    };
  },
  computed: {
    ...mapGetters('album', ['tracks']),
    columns() {
      return [
        {
          name: 'Recording',
          ascending: true,
          sortParam: 'name',
          active: false,
        },
        {
          name: 'Version',
          ascending: true,
          active: false,
        },
        {
          name: 'Duration',
          ascending: true,
          sortParam: 'duration_sec',
          active: false,
        },
        {
          name: 'Main Artist',
          ascending: true,
          sortParam: ['main_artist', 'name'],
          active: false,
        },
        {
          name: 'Side No.',
          ascending: true,
          sortParam: ['recording', 'side_no'],
          active: true,
        },
        {
          name: 'Track No.',
          ascending: true,
          sortParam: ['recording', 'track_no'],
          active: true,
        },
        {
          name: 'VRDB ID',
          ascending: true,
          sortParam: 'vrdb2_id',
          active: false,
        },
        {
          name: 'ISRC',
          ascending: true,
          sortParam: 'isrc',
          active: false,
        },
        {
          name: 'Status',
          ascending: true,
          sortParam: 'status',
          active: false,
        },
      ];
    },
    bulkTrackActions() {
      return [
        { key: 'ChangeTracksBasicInfo', value: 'Change basic info attributes' },
        { key: 'ChangeTracksLineupComplete', value: 'Change status to Complete/Incomplete lineup' },
      ];
    },
    bulkSelectedTracks() {
      return this.localTracks.filter((track, index) => this.bulkTrackSelection[index]);
    },
  },
  mounted() {
    this.localTracks = clone(this.tracks);
    this.$nextTick(() => this.defaultSort());
  },
  methods: {
    getIcon(status) {
      return {
        success: 'check',
        warning: 'priority_high',
        error: 'clear',
      }[status];
    },
    vrdb2IdOfTrack(track) {
      if (track.recordingAggregate.basic_info.vrdb2_id) {
        return track.recordingAggregate.basic_info.vrdb2_id;
      } else {
        return track.recordingAggregate.basic_info.local_codes.find(
          (lc) =>
            lc.code.toUpperCase() === 'VRDB2_ASSOCIATED' ||
            lc.code.toUpperCase() === 'VRDB2_PLAYLIST_ID',
        )?.value;
      }
    },
    defaultSort() {
      const sorted = this.localTracks.sort((a, b) => {
        if (a.recording.side_no !== b.recording.side_no) {
          return Number(a.recording.side_no) - Number(b.recording.side_no);
        }
        return Number(a.recording.track_no) - Number(b.recording.track_no);
      });
      this.localTracks = sorted;
    },
    openCopyLineupModal(track) {
      const trackToLineupModel = (t) => ({
        id: t.recordingAggregate.id,
        name: t.recordingAggregate.basic_info.name,
        lineup_locked: t.recordingAggregate.lineup_locked,
        version_title: t.recordingAggregate.basic_info.version_title,
        main_artist: {
          name: t.recordingAggregate.basic_info.main_artist.name,
        },
        recording: t.recording,
      });

      this.copySource = trackToLineupModel(track);
      this.copyDestinations = this.localTracks
        .filter((t) => t.recording.id !== track.recording.id)
        .map(trackToLineupModel);
      this.showCopyLineupModal = true;
    },
    copyLineup(destinationIds) {
      this.$emit('copyLineup', {
        sourceId: this.copySource.recording.id,
        destinationIds,
      });
    },
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      let sorted = [];
      if (Array.isArray(column.sortParam)) {
        sorted = _.orderBy(
          this.localTracks,
          [
            function sortValue(recording) {
              return recording[column.sortParam[0]][column.sortParam[1]];
            },
          ],
          [sortOrder],
        );
      } else {
        const sortParam = column.sortParam;
        sorted = _.orderBy(
          this.localTracks,
          [
            function sortValue(recording) {
              return recording[sortParam];
            },
          ],
          [sortOrder],
        );
      }
      this.localTracks = sorted;
    },
    onMarkAllTracks(markAll) {
      this.bulkTrackSelection = new Array(this.localTracks.length).fill(markAll);
    },
    onTrackBulkAction(event) {
      this.bulkTrackAction = event;
    },
    async saveBulkAction(type, payload, options) {
      this.bulkTrackAction = null;
      const baseCommand = {
        type,
        process_id: uuidv4(),
        timestamp: new Date().toISOString(),
        ...options,
        payload,
      };

      const commands = this.bulkSelectedTracks.map((track) => ({
        stream_id: track.recording.id,
        ...baseCommand,
      }));

      await PnrService.postCommands(
        { errorTitle: 'Could not update recordings' },
        commands,
        false,
        true,
      );

      this.localTracks = this.localTracks.map((track, idx) => {
        if (this.bulkTrackSelection[idx]) {
          return {
            ...track,
            ...Object.values(payload).reduce((acc, p) => ({ ...acc, ...p }), {}),
          };
        }

        return track;
      });

      this.bulkTrackSelection = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  vertical-align: middle;
}
</style>
