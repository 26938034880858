<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>
    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <thead>
            <th v-if="processType === 'VRDB2'">Vrdb Id</th>
            <th v-if="processType === 'SDEG'">Public Id</th>
            <th>Recording</th>
            <th>Version</th>
            <th>Main Artist</th>
            <th>Recording Year</th>
            <th>Recorded in Country</th>
          </thead>
          <tbody>
            <template v-for="(recordingLine, recordingLineIndex) in reportLines">
              <tr ref="line" :key="recordingLineIndex" class="cursor-pointer">
                <td class="fs-14">
                  {{ recordingLine.incoming.external_id }}
                </td>
                <td class="fs-14">
                  {{ recordingLine.incoming.title }}
                </td>
                <td class="fs-14">
                  {{ recordingLine.incoming.title_version }}
                </td>
                <td>
                  {{ recordingLine.incoming.main_artist }}
                </td>
                <td>
                  {{ recordingLine.incoming.recording_year }}
                </td>
                <td>
                  {{ recordingLine.incoming.recording_country | formatCountry }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <pagination
          v-if="numberOfPages > 0"
          class="result__pagination"
          :number-of-pages="numberOfPages"
          :selected-page="pagination.currentPage"
          :number-of-hits="totalCount"
          :hits-per-page="pagination.hitsPerPage"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ViewPlaylistMixin from './view-playlist-mixin';
import Pagination from '../../../pagination';
import PaginationAndSortMixin from '../../../../common/paginationAndSortMixin';
import { playlistLineupStates } from '../../../../domain/matchingDomain';

export default {
  name: 'ViewPlaylistUnknown',
  components: {
    Pagination,
  },
  mixins: [PaginationAndSortMixin, ViewPlaylistMixin],
  data() {
    return {
      lineupStateMask: playlistLineupStates.NOT_MATCHED,
    };
  },
  activated() {
    this.sortTerms.order = 'asc';
    const expandedId = this.viewPlaylistToCheck.expandedId;
    this.loadPaginated().then(() => {
      if (expandedId) {
        this.toggleExpanded(expandedId);
      }
    });
  },
  deactivated() {
    this.updateViewPlaylistToCheckExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
};
</script>
<style lang="scss" scoped>
.flex {
  &__options {
    justify-content: space-between;
  }
  &__item {
    flex: 1;
    align-items: center;
  }
  &__text {
    margin-right: var(--spacing);
  }
  &__modifier {
    & + & {
      margin-left: var(--spacing);
    }
    div {
      width: 180px !important;
    }
  }
}
</style>
