<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <ComponentSpinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s2 float-right">
          <filter-input :filter-query="filterTerms.currText" @input="onFilterText($event)" />
        </div>
        <div class="col s2 float-right">
          <select-date
            label="To"
            name="end-filter"
            :rule="!!filterTerms.startDate ? { after: filterTerms.startDate } : {}"
            :value="filterTerms.endDate"
            @input="onEndDateFilter"
          />
        </div>
        <div class="col s2 float-right">
          <select-date
            label="Time period from"
            name="start-filter"
            :value="filterTerms.startDate"
            @input="onStartDateFilter"
          />
        </div>
      </div>

      <div class="row card">
        <div class="row">
          <table class="table__border">
            <sortable-head
              :columns="columns"
              :bulk-enabled="false"
              :more-options="false"
              :default-sort-column-index="0"
              :default-sort-should-override="true"
              @sort="clickSort"
            />
            <tbody>
              <template v-if="mutableRows.length > 0">
                <tr v-for="(row, idx) in mutableRows" :key="idx">
                  <td>
                    <router-link
                      :to="{
                        name: paymentDetailsRouterName,
                        params: { id: ledgerId, paymentId: row.paymentId },
                      }"
                    >
                      {{ row.paymentDate | dateParse | dateFormat('YYYY-MM-DD') }}
                    </router-link>
                  </td>
                  <td class="amount-row">
                    {{ row.total | formatAmountCent }}
                  </td>
                  <td>
                    {{ row.refunded | yesno }}
                  </td>
                  <td>
                    <span v-if="row.refunded">
                      {{ row.refundDate | dateParse | dateFormat('YYYY-MM-DD') }}
                    </span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    {{ row.refundedBy }}
                  </td>
                  <td>
                    <div>
                      <a v-if="row.invoiceKey" @click="onDownloadFile(row.invoiceKey)">
                        <i class="far fa-file-alt"></i>
                        Invoice
                      </a>
                    </div>
                    <div>
                      <a v-if="row.creditInvoiceKey" @click="onDownloadFile(row.creditInvoiceKey)">
                        <i class="far fa-file-alt"></i>
                        Credit Invoice
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="none" colspan="12">No more payment history found.</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <Pagination
          topic="payment-history"
          :number-of-pages="numPages"
          :number-of-hits="pagination.total"
          :hits-per-page="pagination.limit"
          :selected-page="curPage"
          :show-hits-per-page="true"
          :loading="loading"
          @selectPage="onSelectPage"
          @updateHitsPerPage="onUpdateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AGGREGATE_TYPES } from '../../../domain/common';
import DownloadLink from '../../ui/file/downloadLink';
import FilterInput from '../../ui/input/filter-input';
import LedgerHelper from './ledgerHelper';
import RemoteFileService from '../../../services/remoteFileService';
import SelectDate from '../../ui/select/select-date';
import ComponentSpinner from '../../component-spinner';
import SortableHead from '../../ui/table/sortable-head';
import Pagination from '../../pagination';

export default {
  name: 'ViewPaymentHistory',
  components: {
    FilterInput,
    SelectDate,
    ComponentSpinner,
    SortableHead,
    Pagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      ledgerId: this.$router.currentRoute.params.id,
      mutableRows: [],
      sourceRows: [],
      columns: [
        {
          name: 'Payment Date',
          ascending: true,
          sortParam: 'paymentDate',
          active: true,
        },
        {
          name: 'Net Amount',
          active: false,
          amountColumn: true,
          styleClass: 'amount-row',
        },
        {
          name: 'Refunded',
          active: false,
        },
        {
          name: 'Refund Date',
          active: false,
        },
        {
          name: 'Refunded By',
          active: false,
        },
        {
          name: 'Invoice',
          active: false,
        },
      ],
      sortColumn: 'paymentDate',
      sortOrder: 'asc',
      filterTerms: {
        startDate: null,
        endDate: null,
        currText: '',
        prevText: '',
      },
      pagination: {
        limit: 25,
        offset: 0,
        total: 0,
      },
    };
  },
  computed: {
    numPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    curPage() {
      return Math.floor(this.pagination.offset / this.pagination.limit) + 1;
    },
    paymentDetailsRouterName() {
      let name;
      if (this.type === AGGREGATE_TYPES.PERFORMER) {
        name = 'viewPerformerPaymentDetails';
      } else if (this.type === AGGREGATE_TYPES.SOCIETY) {
        name = 'viewSocietyPaymentDetails';
      } else {
        name = 'viewAssociatePaymentDetails';
      }
      return name;
    },
  },
  async created() {
    this.fetchData();
    this.handleQueryParams();
  },
  methods: {
    onSelectPage(pageNo) {
      this.pagination.offset = this.pagination.limit * (pageNo - 1);
      this.fetchData();
    },
    onUpdateHitsPerPage(numHits) {
      this.pagination.limit = Number(numHits);
      this.pagination.offset = 0;
      this.fetchData();
    },
    async onDownloadFile(url) {
      const pdfFile = `${url.split('.').slice(0, -1).join('.')}.pdf`;
      try {
        const fileData = await RemoteFileService.downloadInvoice(pdfFile);
        DownloadLink.direct(fileData, pdfFile);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
    async fetchData() {
      this.loading = true;
      this.ledgerId = this.$router.currentRoute.params.id;
      try {
        const result = await LedgerHelper.getPaymentHistory(
          this.ledgerId,
          this.pagination,
          this.filterTerms,
        );
        this.mutableRows = result.items;
        this.sourceRows = result.items;
        this.pagination.total = result.totalCount;
      } catch (error) {
        error.title = 'Error fetching payment history';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    handleQueryParams() {
      if (this.$route.query.sortColumn) {
        this.sort(this.$route.query.sortColumn, this.$route.query.sortOrder);
      } else {
        this.sort(this.sortColumn, this.sortOrder);
      }
      this.markSorting();

      if (this.$route.query.startDate) {
        this.onStartDateFilter(this.$route.query.startDate);
      }
      if (this.$route.query.endDate) {
        this.onEndDateFilter(this.$route.query.endDate);
      }
    },
    markSorting() {
      this.columns.forEach((column) => {
        if (column.sortParam === this.sortColumn) {
          column.active = true;
          column.ascending = this.sortOrder === 'asc';
        } else {
          column.active = false;
          column.ascending = true;
        }
      });
    },
    setQueryParams() {
      const queryParams = {};
      if (this.sortColumn) {
        queryParams.sortColumn = this.sortColumn;
        queryParams.sortOrder = this.sortOrder;
      }
      if (this.filterTerms.startDate) {
        queryParams.startDate = this.filterTerms.startDate;
      }
      if (this.filterTerms.endDate) {
        queryParams.endDate = this.filterTerms.endDate;
      }
      this.updateRoute(queryParams);
    },
    onStartDateFilter(date) {
      this.filterTerms.startDate = date;
      this.doFilter();
    },
    onEndDateFilter(date) {
      this.filterTerms.endDate = date;
      this.doFilter();
    },
    async doFilter() {
      this.setQueryParams();
      this.refresh();
    },
    onFilterText(text) {
      this.filterTerms.prevText = this.filterTerms.currText;
      this.filterTerms.currText = text;
      this.filterText(this.filterTerms.currText, this.filterTerms.prevText);
    },
    filterText(current, previous) {
      const narrowed = current.length > previous;
      if (narrowed) {
        this.mutableRows = LedgerHelper.filterHistory(this.mutableRows, current);
      } else {
        this.mutableRows = LedgerHelper.filterHistory(this.sourceRows, current);
      }
    },
    clickSort(sort) {
      this.sort(sort.sortParam, sort.ascending ? 'asc' : 'desc');
    },
    sort(sortColumn, sortOrder) {
      this.sortColumn = sortColumn;
      this.sortOrder = sortOrder;
      this.mutableRows = LedgerHelper.sortList(this.mutableRows, this.sortColumn, this.sortOrder);
      this.setQueryParams();
    },
    updateRoute(queryParams) {
      let name;
      if (this.type === AGGREGATE_TYPES.PERFORMER) {
        name = 'viewPerformerPaymentHistory';
      } else if (this.type === AGGREGATE_TYPES.SOCIETY) {
        name = 'viewSocietyPaymentHistory';
      } else {
        name = 'viewAssociatePaymentHistory';
      }

      this.$router.replace({
        name,
        query: queryParams,
      });
    },
  },
};
</script>
