<template>
  <span v-if="mandates.length > 0" style="white-space: nowrap">
    <span v-for="(mandate, index) in activeMandates(mandates)" :key="index">
      {{ mandate.society_code | formatSociety }}
      <i
        v-if="mandate.mandate_type"
        style="float: none"
        class="fas"
        :class="mandate.mandate_type | iconByMandateTypeRegionalOrWorldWide"
      />
      <i
        v-if="mandate.mandate_type === 'R+' || mandate.mandate_type === 'WW-'"
        style="float: none"
        class="fas"
        :class="mandate.mandate_type | iconByMandateTypePlusOrMinus"
      />
      <span v-if="index + 1 !== mandateCount">,</span>
    </span>
  </span>
  <span v-else class="none">None</span>
</template>

<script>
import { getActiveMandates } from 'star-common';

export default {
  name: 'MandateList',
  props: {
    mandates: {
      type: Array,
      required: true,
    },
    filterDuplicates: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mandateCount: 0,
    };
  },
  methods: {
    activeMandates(mandates) {
      if (this.filterDuplicates && mandates.length > 1) {
        const mappedActiveMandates = getActiveMandates(mandates).map(
          (mandate) => `${mandate.society_code}:${mandate.mandate_type}:${mandate.end_date}`,
        );
        const filteredMandates = Array.from(new Set(mappedActiveMandates)).map((item) => {
          const split = item.split(':');
          return {
            society_code: split[0],
            mandate_type: split[1],
            end_date: split[2],
          };
        });
        this.mandateCount = filteredMandates.length;
        return filteredMandates;
      }
      const activeMandates = getActiveMandates(mandates);
      this.mandateCount = activeMandates.length;
      return activeMandates;
    },
  },
};
</script>
