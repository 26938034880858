<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Documents</h2>
      </div>
      <div class="text--right col s6">
        <action-buttons @save="onSave" @cancel="onCancel" />
      </div>
    </div>

    <template v-if="documentsToShow()">
      <div v-for="(document, index) in documents" :key="document.id" class="row card">
        <div class="col s3">
          <span class="text--bold">{{ getDocumentType(document.type) }}</span>
          ({{ document.created | formatDate }})
        </div>
        <div class="col s8">
          <a @click="onDownloadDocument(document)">
            <i class="far fa-file-alt" />
            {{ getFileName(document.url) }}
          </a>
        </div>
        <div class="col s1">
          <a class="float-right" @click="onRemoveExistingDocument(index)">
            <i class="far fa-trash-alt" />
            Delete
          </a>
        </div>
      </div>
    </template>

    <div v-for="(document, index) in newDocuments" :key="'new' + document.id" class="row card">
      <div class="col s6">
        <select-input
          label="Type of document"
          :name="`document_${index}`"
          :value="document.type"
          :items="documentTypes"
          item-key="code"
          item-value="name"
          @input="onUpdateDocumentType($event, index)"
        />
      </div>
      <div class="col s6">
        <file-upload-simple-field
          :name="`documentRef_${index}`"
          :file-handle="document.fileHandle"
          @addFile="onAddDocumentFile($event, index)"
          @removeFile="onRemoveDocument(index)"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s12 edit-link">
        <a :class="{ disabled: !lastHasEntry }" @click="onAddDocument">
          <i class="far fa-plus-square" />
          Add document
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtons from '../button/action-buttons';
import { createDocument } from '../../../domain/common';
import DownloadLink from '../file/downloadLink';
import extractRemoteFileName from '../../../common/extractRemoteFileName';
import FileUploadSimpleField from '../file/file-upload-simple-field';
import RemoteFileService from '../../../services/remoteFileService';
import SelectInput from '../select/select-input';

export default {
  name: 'EditDocumentsInformation',
  components: {
    ActionButtons,
    FileUploadSimpleField,
    SelectInput,
  },
  inject: ['$validator'],
  props: {
    aggregateType: {
      type: String,
      required: false,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    updateDocuments: {
      type: Function,
    },
  },
  data() {
    return {
      selectedDocumentType: null,
      newDocuments: [],
    };
  },
  computed: {
    lastHasEntry() {
      return this.newDocuments.every(
        (doc) => doc.fileHandle && doc.type != null && doc.type !== '',
      );
    },
    documentTypes() {
      return this.$store.state.appdata.referenceData.documentTypes.filter(
        (dt) => !dt.filter || dt.filter.includes(this.aggregateType),
      );
    },
  },
  created() {
    if (this.documents.length === 0) {
      this.onAddDocument();
    }
  },
  methods: {
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    getDocumentType(code) {
      const type = this.documentTypes.find((dt) => dt.code === code);
      return type ? type.name : code;
    },
    documentsToShow() {
      return this.documents.length > 0 && this.documents.filter((doc) => doc.id !== '').length > 0;
    },
    onUpdateDocumentType(type, index) {
      this.newDocuments[index].type = type;
    },
    onAddDocument() {
      this.newDocuments.push(createDocument());
    },
    onAddDocumentFile(file, index) {
      this.newDocuments[index].fileHandle = file;
    },
    onRemoveDocument(index) {
      this.newDocuments.splice(index, 1);
    },
    onRemoveExistingDocument(index) {
      const docs = this.documents.slice(0);
      docs.splice(index, 1);
      this.updateDocuments(docs);
    },
    async onDownloadDocument(document) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(
        extractRemoteFileName(document.url),
      );
      DownloadLink.direct(fileData, document.url);
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.$emit('saved', this.newDocuments);
    },
  },
};
</script>
