<template>
  <div>
    <table>
      <thead>
        <tr>
          <th class="thin-column">#</th>
          <th>Title</th>
          <th>Main Artist</th>
          <th>Playing Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(recording, index) in payload" :key="recording.star_id">
          <td>{{ index + 1 }}</td>
          <td>
            <router-link :to="'/recordings/' + recording.star_id">
              {{ recording.title }}
              {{ recording.version_title ? '(' + recording.version_title + ')' : '' }}
            </router-link>
          </td>
          <td>{{ recording.main_artist }}</td>
          <td>{{ recording.playing_time_sec | toMinutes }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'AirtimeAllTimeWidget',
  mixins: [WidgetMixin],
};
</script>

<style lang="scss" scoped>
tr:hover {
  background: inherit;
}
td:before {
  content: none;
}
</style>
