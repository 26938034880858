var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row wrapper"},[_vm._m(0),_c('div',{staticClass:"col s6 text--right push--top edit-link"},[_c('a',{on:{"click":function($event){return _vm.handleCreateNew()}}},[_c('i',{staticClass:"far fa-plus-square"}),_vm._v(" New Reserve Release ")])])]),(_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s6"},[_c('spinner')],1)]):_c('div',{staticClass:"row card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('table',[_c('thead',_vm._l((_vm.columns),function(column){return _c('th',{key:column.id,class:[column.styleClass]},[_vm._v(" "+_vm._s(column.name)+" ")])}),0),_c('tbody',[(_vm.numberOfReserves === 0)?_c('tr',[_c('td',{staticClass:"none",attrs:{"colspan":"5"}},[_vm._v("No reserve releases")])]):_vm._l((_vm.reserves),function(r,i){return _c('tr',{key:r.id,class:{ 'divide-after': i === _vm.indexOfLastOpen }},[_c('td',[_c('router-link',{attrs:{"to":{
                    path: '/reserves',
                    query: { id: r.id.toString() },
                  }}},[_vm._v(" "+_vm._s(r.releaseYear)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(r.createdDate)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(r.releaseDate)))]),_c('td',[_vm._v(_vm._s(r.createdBy))]),_c('td',[_vm._v(_vm._s(r.confirmedBy))]),_c('td',{class:{
                  'text--green': _vm.releaseIsOpen(r),
                  'text--yellow': _vm.releaseIsWorking(r),
                  'text--red': _vm.releaseHasError(r),
                }},[_vm._v(" "+_vm._s(_vm.getProcessReadableState(r))+" ")]),_c('td',{staticClass:"amount-row"},[_vm._v(" "+_vm._s(_vm._f("formatAmountThousandSeparator")(r.totalReservedAmount,'SEK'))+" ")]),_c('td',[(!_vm.releaseIsConfirmed(r) && !_vm.releaseIsFinished(r))?_c('options',{attrs:{"options":['Delete']},on:{"delete":function($event){return _vm.handleDelete(r.id)}}}):_vm._e()],1)])})],2)])])]),(_vm.numberOfPages > 0)?_c('pagination',{attrs:{"number-of-pages":_vm.numberOfPages,"number-of-hits":_vm.numberOfReserves,"selected-page":Math.floor(_vm.paging.offset / _vm.paging.limit) + 1,"hits-per-page":_vm.paging.limit},on:{"selectPage":_vm.selectPage,"updateHitsPerPage":_vm.updateHitsPerPage}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col s6"},[_c('h1',[_vm._v("Reserves")])])}]

export { render, staticRenderFns }