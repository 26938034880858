<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 xl9 recording-wrapper">
        <h1>Usage for {{ basicInfoName }}</h1>
        <h2>
          <span v-if="basicInfoVersionTitle">({{ basicInfoVersionTitle }})</span>
          <span v-if="!basicInfoMainArtist">(main artist missing)</span>
          <span v-else>
            <span v-if="basicInfoMainArtist.id">
              by
              <router-link :to="'/mainartists/' + basicInfoMainArtist.id">
                {{ basicInfoMainArtist.name }}
              </router-link>
            </span>
            <span v-else>by {{ basicInfoMainArtist.name }}</span>
          </span>
        </h2>
      </div>
    </div>

    <div class="row card">
      <table>
        <thead>
          <tr>
            <th style="width: 50px"></th>
            <th>Performance Year / Channel Group / Channel</th>
            <th v-if="groupExpanded" class="thin-column">VRDB</th>
            <th class="thin-column">Playing Time</th>
            <th class="thin-column">Times Played</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="airtimeByYear.length === 0">
            <td colspan="5" class="none">No usage information found for this recording</td>
          </tr>
          <template v-for="year in airtimeByYear">
            <tr :key="year.year" class="year" @click="year.expanded = !year.expanded">
              <td class="compare__arrow">
                <i :class="`fas fa-caret-${year.expanded ? 'up' : 'down'}`" />
              </td>
              <td>{{ year.year }}</td>
              <td v-if="groupExpanded" />
              <td>{{ year.totalTime | toMinutes }}</td>
              <td class="text--right">{{ year.totalCount | formatNumber }}</td>
            </tr>

            <template v-if="year.expanded">
              <template v-for="group in year.groups">
                <tr
                  :key="`${year.year}-${group.group}-tr`"
                  class="group"
                  @click="group.expanded = !group.expanded"
                >
                  <td class="compare__arrow">
                    <i :class="`fas fa-folder${group.expanded ? '-open' : ''}`" />
                  </td>
                  <td>&nbsp;{{ group.group }}</td>
                  <td v-if="groupExpanded" />
                  <td>{{ group.totalTime | toMinutes }}</td>
                  <td class="text--right">{{ group.totalCount | formatNumber }}</td>
                </tr>

                <template v-if="group.expanded">
                  <tr
                    v-for="channel in group.channels"
                    :key="`${year.year}-${group.group}-${channel.channel_id}-tr`"
                    class="channel"
                  >
                    <td></td>
                    <td>&nbsp;&nbsp;{{ channel.channel_name }}</td>
                    <td>{{ channel.vrdb_playlist ? 'Yes' : 'No' }}</td>
                    <td>{{ channel.playing_time_sec | toMinutes }}</td>
                    <td class="text--right">{{ channel.playing_count | formatNumber }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash';
import { mapGetters } from 'vuex';
import RecordingMixin from './recording-mixin';
import DistributionService from '../../../services/distributionService';

export default {
  name: 'ViewUsageInformation',
  mixins: [RecordingMixin],
  data() {
    return {
      airtimeByYear: [],
    };
  },
  computed: {
    ...mapGetters('recording', [
      'basicInfoName',
      'id',
      'basicInfoVersionTitle',
      'basicInfoMainArtist',
    ]),
    groupExpanded() {
      for (const year of this.airtimeByYear) {
        if (year.expanded) {
          for (const group of year.groups) {
            if (group.expanded) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  async created() {
    await this.fetchData();
    await this.fetchAirtimeSummary();
  },
  methods: {
    async fetchAirtimeSummary() {
      this.loading = true;
      try {
        const summary = await DistributionService.recordingAirtimeSummery(this.id);
        this.airtimeByYear = this.mapSummaryByYear(summary);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    mapSummaryByYear(summary) {
      return Object.entries(groupBy(summary, 'performance_year')).map(([year, channelsByYear]) => {
        let totalTimeByYear = 0;
        let totalCountByYear = 0;

        const groups = Object.entries(groupBy(channelsByYear, 'channel_group')).map(
          ([group, channels]) => {
            const { totalTime, totalCount } = channels.reduce(
              (acc, item) => ({
                totalTime: acc.totalTime + item.playing_time_sec,
                totalCount: acc.totalCount + item.playing_count,
              }),
              { totalTime: 0, totalCount: 0 },
            );

            totalTimeByYear += totalTime;
            totalCountByYear += totalCount;

            return {
              expanded: false,
              group,
              channels,
              totalTime,
              totalCount,
            };
          },
        );

        return {
          expanded: false,
          year,
          totalTime: totalTimeByYear,
          totalCount: totalCountByYear,
          groups,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.recording-wrapper {
  padding-right: 24px;
}

.group {
  background: #fafafa;
  border-top-color: #e3e3e3;
}

.channel {
  background: #f2f2f2;
  border-top-color: #d7d7d7;
}

.group,
.channel {
  & td:before {
    content: none;
  }
}
</style>
