<template>
  <form class="card">
    <div class="row">
      <div class="col s12">
        <calculate-selector :loading="loading" @calculate="onCalculate" />
      </div>
    </div>
    <template v-if="loading">
      <spinner />
    </template>
  </form>
</template>

<script>
import CalculateSelector from './calculate-selector';
import Spinner from '../../spinner';
import CommandService from '@/services/commandService';
import PaymentService from '@/services/paymentService';
import * as uuid from 'uuid';
import { STATE } from '@/store/modules/process/store';

export default {
  name: 'ProgressDistributionClosureCreate',
  components: {
    CalculateSelector,
    Spinner,
  },
  inject: ['$validator'],
  data() {
    return {
      processId: null,
      streamId: null,
      loading: true,
    };
  },
  created() {
    this.checkIfActiveExists();
  },
  methods: {
    async onCalculate(year) {
      try {
        this.loading = true;
        this.streamId = uuid.v4();
        const result = await CommandService.handleCommand('payment', {
          type: 'closure/create',
          stream_id: this.streamId,
          payload: { year },
        });
        this.$store.dispatch('process/addProcess', {
          name: 'Distribution closure',
          id: result.processId,
          route: `/distribution-closures/${result.processId}`,
          routeAvailability: [STATE.RUNNING, STATE.FINISHED],
        });
        await this.$router.push({
          name: 'distributionClosures',
          query: {
            id: this.streamId,
          },
        });
      } catch (e) {
        this.$addStarError(e);
      }
    },
    async checkIfActiveExists() {
      const { distributionClosureId } = await PaymentService.getActiveDistributionClosure();
      if (distributionClosureId !== null) {
        this.$router.push({
          name: 'distributionClosures',
          query: {
            id: distributionClosureId,
          },
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
