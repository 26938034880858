<template>
  <div>
    <entity-badge entity="performer" />
    <div class="search-result__everything__data">
      <div>
        <span class="search-result__everything__data__name">
          <router-link :to="'/performers/' + typedResult.value.id">
            {{ typedResult.value.first_name }} {{ typedResult.value.last_name }}
          </router-link>
        </span>
      </div>
      <div v-if="typedResult.value.pseudo_names && typedResult.value.pseudo_names.length > 0">
        <span class="label">Also known as:</span>
        <span>{{ typedResult.value.pseudo_names | join }}</span>
      </div>
      <div
        v-if="typedResult.value.profile"
        :class="{
          'search-result__everything__data__profile': true,
          'search-result__everything__data__profile--full': showFullProfile,
        }"
        @click="showFullProfile = true"
      >
        {{ typedResult.value.profile }}
      </div>
      <div
        v-if="
          typedResult.value.main_instrument ||
          (typedResult.value.secondary_instruments &&
            typedResult.value.secondary_instruments.length > 0)
        "
      >
        <span class="label">Instruments:</span>
        <span>
          {{
            [typedResult.value.main_instrument, ...typedResult.value.secondary_instruments]
              | formatInstrumentList
          }}
        </span>
      </div>
      <div v-if="typedResult.value.groups">
        <span class="label">Member of the following group/s:</span>
        <span>{{ typedResult.value.groups | join }}</span>
      </div>
      <div v-if="typedResult.value.date_of_birth">
        <span class="label">Born:</span>
        <span>{{ typedResult.value.date_of_birth | formatDate }}.</span>
      </div>
      <div v-if="typedResult.value.date_of_death">
        <span class="label">Died:</span>
        <span>{{ typedResult.value.date_of_death | formatDate }}.</span>
      </div>
      <div v-if="typedResult.value.nationality">
        <span class="label">Nationality:</span>
        <span>{{ typedResult.value.nationality | formatCountry }}.</span>
      </div>
      <div v-if="typedResult.value.country_of_residence">
        <span class="label">Lives in:</span>
        <span v-if="typedResult.value.city">{{ typedResult.value.city }},</span>
        <span>{{ typedResult.value.country_of_residence | formatCountry }}.</span>
      </div>
      <div v-if="typedResult.value.societies">
        <span class="label">Is a member of:</span>
        <span>{{ typedResult.value.societies }}</span>
      </div>
      <div v-if="typedResult.value.collective">
        <span class="label">Collective: Yes</span>
      </div>
      <div v-if="typedResult.value.tags" class="search-result__tags chips__wrapper">
        <template v-for="(tag, index) in typedResult.value.tags">
          <tag :key="index" :tag="tag" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../tags/tag';
import EntityBadge from '../ui/entity-badge';

export default {
  name: 'EverythingResultPerformer',
  components: { Tag, EntityBadge },
  props: {
    typedResult: Object,
  },
  data() {
    return {
      showFullProfile: false,
    };
  },
};
</script>
