<template>
  <div>
    <ComponentSpinner v-if="loading" />
    <div v-else>
      <table>
        <thead>
          <th>Date Created</th>
          <th>Name</th>
          <th>Payment Date</th>
          <th>Society</th>
          <th>Created By</th>
          <th>Status</th>
          <th class="text--right">Amount</th>
        </thead>
        <tbody>
          <tr v-for="p in payments" :key="p.id" :class="{ 'payment-removed': isRemoved(p) }">
            <td>
              {{ p.createdAt | dateParse | dateFormat('YYYY-MM-DD') }}
            </td>
            <td>
              <router-link
                v-if="paymentIsOpen(p) || paymentIsConfirmed(p)"
                :to="{
                  path: `/payments/${p.id}`,
                }"
              >
                {{ p.name }}
              </router-link>
              <span v-else>{{ p.name }}</span>
            </td>
            <template v-if="p.occupied && getPaymentProcess(p)">
              <td colspan="3">
                <process-progress-bar :id="p.processId" @finished="processFinished" />
              </td>
            </template>
            <template v-else>
              <td>{{ p.paymentDate | dateParse | dateFormat('YYYY-MM-DD') }}</td>
              <td>{{ p.societyId | formatSocietyV2(societies) }}</td>
              <td>{{ p.createdBy || 'you' }}</td>
            </template>
            <td
              :class="{
                'text--green': paymentIsOpen(p),
                'text--yellow': p.occupied,
                'text--red': paymentHasError(p),
              }"
            >
              {{ getPaymentReadableState(p) }}
            </td>
            <td class="amount-row">
              {{ p.payableAmount + p.payableVat || 0 | formatAmountCent('SEK') }}
            </td>
            <td>
              <options
                v-if="!(paymentIsConfirmed(p) || paymentIsRemoved(p) || paymentHasError(p))"
                :options="['Delete']"
                @delete="handleDelete(p.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="numberOfPayments > 0"
        :number-of-pages="numberOfPages"
        :number-of-hits="numberOfPayments"
        :selected-page="Math.floor(offset / limit) + 1"
        :hits-per-page="limit"
        @selectPage="selectPage"
        @updateHitsPerPage="updateHitsPerPage"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { paymentState } from '@/domain/paymentDomain';
import Pagination from '../pagination';
import { STATE } from '@/store/modules/process/store';
import { mutate, query } from '@/services/apolloRequest';
import Options from '../ui/context-menu.vue';
import { paymentCommand } from '@/store/modules/payment/store';
import ProcessProgressBar from '../process/process-progress-bar.vue';
import ComponentSpinner from '../component-spinner.vue';

export default {
  components: {
    Pagination,
    Options,
    ProcessProgressBar,
    ComponentSpinner,
  },
  props: {
    societyId: String,
    defaultLimit: Number,
  },
  data() {
    return {
      payments: [],
      offset: 0,
      limit: 25,
      numberOfPayments: 0,
      deleting: [],
      loading: false,
    };
  },
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
    numberOfPages() {
      return Math.ceil(this.numberOfPayments / this.limit);
    },
  },
  created() {
    this.fetchPayments();
    this.limit = this.defaultLimit || this.limit;
  },
  methods: {
    async fetchPayments() {
      this.loading = true;
      try {
        const res = await query(
          {
            query: gql`
              query Payments($query: PaymentsQuery) {
                payments(query: $query) {
                  totalCount
                  payments {
                    id
                    name
                    state
                    createdAt
                    paymentDate
                    societyId
                    createdBy
                    payableAmount
                    payableVat
                    processId
                    occupied
                    isManual
                  }
                }
              }
            `,
            variables: {
              query: {
                societyId: this.societyId,
                limit: this.limit,
                offset: this.offset,
              },
            },
          },
          { pickProp: 'payments' },
        );
        this.payments = res.payments;
        this.numberOfPayments = res.totalCount;
      } catch (err) {
        this.$addStarError(err);
      } finally {
        this.loading = false;
      }
    },
    isWorking(payment) {
      return payment.occupied;
    },
    isRemoved(payment) {
      return this.deleting.includes(payment.id);
    },
    setParameters(query = this.$route.query) {
      this.limit = Number.parseInt(query.limit, 10) || 25;
      this.offset = Number.parseInt(query.offset, 10) || 0;
    },
    selectPage(page) {
      this.offset = Math.max(0, (page - 1) * this.limit);
      this.fetchPayments();
    },
    updateHitsPerPage(nbr) {
      this.limit = Number(nbr);
      this.offset = 0;
      this.fetchPayments();
    },
    paymentIsOpen(payment) {
      const openStates = [
        paymentState.Created,
        paymentState.Working,
        paymentState.Ready,
        paymentState.UserApproved,
        paymentState.SystemApproved,
        paymentState.ConfirmReady,
      ];
      return openStates.includes(payment.state);
    },
    paymentIsConfirmed(payment) {
      return payment.state === paymentState.Confirmed;
    },
    paymentIsRemoved(payment) {
      return payment.state === paymentState.Removed;
    },
    paymentHasError(payment) {
      return payment.state === paymentState.Failed;
    },
    getPaymentReadableState(payment) {
      return paymentState[payment.state];
    },
    async handleDelete(id) {
      this.deleting.push(id);
      await mutate(
        paymentCommand({
          stream_id: id,
          type: 'payment/remove',
          payload: {},
        }),
      );
      this.deleting = this.deleting.filter((did) => did !== id);
      await this.fetchPayments();
    },
    processFinished() {
      this.fetchPayments();
    },
    isFinished(processId) {
      const process = this.getProcess(processId);
      return process?.state === STATE.FINISHED;
    },
    async getPaymentProcess(p) {
      const process = this.getProcess(p.processId);
      if (!process && p.occupied && p.processId) {
        await this.$store.dispatch('process/addProcess', {
          id: p.processId,
          name: `Payment ${p.name}`,
        });
      }
      return this.getProcess(p.processId);
    },
    getProcess(id) {
      return this.$store.getters['process/process'](id);
    },
  },
};
</script>
