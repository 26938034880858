<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Extra Information and Meta Data</h2>
      </div>
      <div class="col s6 edit-link text--right">
        <a @click="onEdit">
          <i class="far fa-edit"></i>
          EDIT
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <label class="col s3">STAR code</label>
        <div class="col s9">
          <span v-if="metaInfo.code">{{ metaInfo.code }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">IPD society code</label>
        <div class="col s9">
          <span v-if="metaInfo.IPD_society_code">{{ metaInfo.IPD_society_code }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">IPD society name</label>
        <div class="col s9">
          <span v-if="metaInfo.IPD_society_name">{{ metaInfo.IPD_society_name }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">VRDB code</label>
        <div class="col s9">
          <span v-if="metaInfo.vrdb_code">{{ metaInfo.vrdb_code }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Country</label>
        <div class="col s9">
          <span v-if="metaInfo.country">
            {{ metaInfo.country | formatCountry }} ({{ metaInfo.country }})
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Create mandate</label>
        <div class="col s9">
          <span v-if="metaInfo.create_mandate">{{ metaInfo.create_mandate | yesno }}</span>
          <span v-else class="none">No</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Internal order</label>
        <div class="col s9">
          <span v-if="metaInfo.order">{{ metaInfo.order }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">DDEX Party ID</label>
        <div class="col s9">
          <span v-if="metaInfo.ddex_party_id">
            {{ metaInfo.ddex_party_id | formatDdexPartyId }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Statement file type</label>
        <div class="col s9">
          <span v-if="metaInfo.statement_file_type">
            {{ metaInfo.statement_file_type | formatStatementFileType }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MetaInfo',
  computed: {
    ...mapGetters('society', ['metaInfo']),
  },
  methods: {
    onEdit() {
      this.$emit('edit', this.$options.name);
    },
  },
};
</script>
