<template>
  <div>
    <table>
      <thead class="redist-table-head cursor-pointer">
        <tr>
          <th width="40px" />
          <sort-col
            width="25%"
            :active="sortFn === sortByMainArtist"
            :sort-asc="sortAsc"
            @click="setSortFn(sortByMainArtist)"
          >
            Main Artist
          </sort-col>
          <sort-col
            :active="sortFn === sortByTitle"
            :sort-asc="sortAsc"
            :colspan="2"
            @click="setSortFn(sortByTitle)"
          >
            Title
          </sort-col>
          <sort-col
            :active="sortFn === sortByAbsAmount"
            :sort-asc="sortAsc"
            class="text--right"
            width="15%"
            @click="setSortFn(sortByAbsAmount)"
          >
            Abs. Amount
          </sort-col>
          <sort-col
            :active="sortFn === sortByAmount"
            :sort-asc="sortAsc"
            class="text--right"
            width="15%"
            @click="setSortFn(sortByAmount)"
          >
            Amount
          </sort-col>
          <th width="60px">
            <span>State</span>
          </th>
          <th width="20px"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, idx) in filteredList">
          <tr :key="idx" :class="{ 'juris-changed': row.juris_changed }">
            <td class="thin-column dropdown-button__arrow" @click="toggleExpand(row)">
              <i class="fas" :class="row.expanded ? 'fa-caret-up' : 'fa-caret-down'" />
            </td>
            <td>{{ row.main_artist }}</td>
            <td colspan="2">
              <router-link :to="`/recordings/${row.recording_id}`">
                {{ row.title }}
              </router-link>
              <span v-if="row.juris_changed">*</span>
            </td>
            <td class="text--right">
              {{ row.abs_amount | formatAmountThousandSeparator('SEK') }}
            </td>
            <td class="text--right">
              {{ row.amount | formatAmountThousandSeparator('SEK') }}
            </td>
            <td class="text--center">
              <simple-spinner v-if="row.loading" />
              <button
                v-else
                :title="getBlockTitle(row)"
                class="block-btn"
                :class="row.blocked_by ? 'blocked' : 'ok'"
                @click="toggleBlock(row)"
              >
                <i class="fa" :class="getBlockBtnClass(row)" />
                <span hidden>Block</span>
              </button>
            </td>
            <td class="text--center">
              <context-menu :options="['Remove']" @remove="removeRow(row)" />
            </td>
          </tr>
          <template v-if="row.expanded">
            <template v-if="row.details.length > 0">
              <tr :key="`${idx}-expand`" class="details-header">
                <col />
                <td><strong>Name</strong></td>
                <td><strong>Role</strong></td>
                <td width="15%" class="text--right">
                  <strong>Distributed</strong>
                </td>
                <td class="text--right">
                  <strong>To Redistribute</strong>
                </td>
                <td colspan="2" class="text--right">
                  <strong>Total</strong>
                </td>
              </tr>
              <tr v-for="(detail, ridx) of row.details" :key="`${idx}-${ridx}`" class="details">
                <col />
                <td>
                  <router-link :to="`/performers/${detail.performer_id}`">
                    {{ detail.name }}
                  </router-link>
                </td>
                <td>{{ getRole(detail.role_code) }}</td>
                <td class="text--right">
                  {{ detail.dist_amount | formatAmountThousandSeparator('SEK') }}
                </td>
                <td class="text--right">
                  {{ detail.redist_amount | formatAmountThousandSeparator('SEK') }}
                </td>
                <td colspan="3" class="text--right">
                  {{ detail.tot_amount | formatAmountThousandSeparator('SEK') }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr :key="`${idx}-expand`">
                <td colspan="6">
                  <component-spinner />
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
    <div v-if="jurisChangeExists" class="juris-changed">
      <p>
        * The recording's jurisdiction has changed and does no longer cover the redistributed area
      </p>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import SortCol from '../../ui/table/sort-col';
import ComponentSpinner from '../../component-spinner';
import SimpleSpinner from '../../ui/simple-spinner';
import ContextMenu from '../../ui/context-menu.vue';

export default {
  name: 'RedistributionTable',
  components: {
    SortCol,
    ComponentSpinner,
    SimpleSpinner,
    ContextMenu,
  },
  props: {
    redistribution: Array,
    textFilter: String,
  },
  data() {
    const noSort = () => 0;
    return {
      noSort,
      sortByMainArtist: (a, b) => a.main_artist.localeCompare(b.main_artist),
      sortByTitle: (a, b) => a.title.localeCompare(b.title),
      sortByAbsAmount: (a, b) => (Number(a.abs_amount) > Number(b.abs_amount) ? 1 : -1),
      sortByAmount: (a, b) => (Number(a.amount) > Number(b.amount) ? 1 : -1),
      sortFn: noSort,
      sortAsc: true,
    };
  },
  computed: {
    filteredList() {
      let list = this.redistribution.slice();
      if (this.textFilter) {
        list = list.filter((row) => {
          const filterValues = [row.recording_id, row.main_artist, row.title, ...row.performers];
          return filterValues.some((val) =>
            val.toLowerCase().includes(this.textFilter.toLowerCase()),
          );
        });
      }
      list = list.sort(this.sortFn);
      if (this.sortAsc === false) {
        list = list.reverse();
      }
      return list;
    },
    rolesRecording() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
    jurisChangeExists() {
      return this.redistribution.some((row) => row.juris_changed);
    },
  },
  methods: {
    toggleExpand(row) {
      if (row.expanded === true) {
        this.$emit('contract', row);
      } else {
        this.$emit('expand', row);
      }
    },
    getRole(code) {
      const rc = this.rolesRecording.find((rr) => rr.code === code);
      if (!rc) {
        return '-';
      }
      return rc.name;
    },
    setSortFn(fn) {
      if (fn === this.sortFn) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortFn = fn;
        this.sortAsc = true;
      }
    },
    hasDetails(row) {
      return row.details && row.details.length > 0;
    },
    toggleBlock(row) {
      if (row.blocked_by) {
        this.$emit('unblock', row);
      } else {
        this.$emit('block', row);
      }
    },
    getBlockBtnClass(row) {
      if (row.blocked_by === 'system') {
        return 'fa-ban';
      }
      if (row.blocked_by) {
        return 'fa-hand-paper';
      }
      return 'fa-check';
    },
    getBlockTitle(row) {
      const dateStr = moment(row.block_date).format('YYYY-MM-DD HH:mm');
      return row.blocked_by ? `Blocked by ${row.blocked_by} on ${dateStr}` : 'Block';
    },
    removeRow(row) {
      this.$store.dispatch('distribution/removeRedistRow', row.recording_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  background: #fafafa;
  & td:before {
    content: none;
  }
}
.block-btn {
  display: inline-block;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  &.blocked,
  &:hover {
    opacity: 1;
  }
  &.ok:hover .fa:before {
    content: '\f256';
  }
  &.blocked:hover .fa:before {
    content: '\f00c';
  }
}
.simple-spinner {
  width: 15px;
}
.juris-changed {
  color: var(--red--light);
}
</style>
