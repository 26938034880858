var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row card report__headers"},[_c('DataTable',{attrs:{"columns":_vm.columns,"items":_vm.jobs,"sort":_vm.sort,"item-key":"id","expand-key":"payload","local-sort":""},on:{"update:sort":function($event){_vm.sort=$event}},scopedSlots:_vm._u([{key:"item:state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"chip",class:'report__headers-chip--' + _vm.getStateColor(item.state)},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item:date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.date_created))+" ")]}},{key:"item:date_changed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.date_changed))+" ")]}},{key:"item:payload",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(item.payload))])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row wrapper"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("Jobs")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row small-inner"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v("Job status")])])])}]

export { render, staticRenderFns }