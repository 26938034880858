<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 xl9 recording-wrapper">
        <h1>Remuneration for {{ basicInfoName }}</h1>
        <h2>
          <span v-if="basicInfoVersionTitle">({{ basicInfoVersionTitle }})</span>
          <span v-if="!basicInfoMainArtist">(main artist missing)</span>
          <span v-else>
            <span v-if="basicInfoMainArtist.id">
              by
              <router-link :to="'/mainartists/' + basicInfoMainArtist.id">
                {{ basicInfoMainArtist.name }}
              </router-link>
            </span>
            <span v-else>by {{ basicInfoMainArtist.name }}</span>
          </span>
        </h2>
      </div>
    </div>

    <div class="row card">
      <table>
        <thead>
          <tr>
            <th class="thin-column" style="width: 40px"></th>
            <th colspan="3">Performer</th>
            <th class="amount-row" style="width: 15%">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="items.length === 0">
            <td colspan="5" class="none">No performer remuneration recording</td>
          </tr>
          <template v-for="(item, idx) in items">
            <tr
              :key="`${item.performer_id}-tr1`"
              @click="
                idx === expandedIndex
                  ? (expandedIndex = undefined)
                  : fetchDetails(idx, item.performer_id)
              "
            >
              <td class="compare__arrow">
                <i :class="`fas fa-caret-${idx === expandedIndex ? 'up' : 'down'}`" />
              </td>
              <td colspan="3">
                <router-link :to="`/performers/${item.performer_id}`">
                  {{ item.performer_name }}
                </router-link>
              </td>
              <td class="amount-row">{{ item.amount | formatAmountThousandSeparator('SEK') }}</td>
            </tr>

            <template v-if="idx === expandedIndex">
              <tr :key="`${item.performer_id}-tr2`" class="text--bold">
                <col />
                <td>Type</td>
                <td>Role</td>
                <td>Right</td>
              </tr>

              <template v-for="(detail, didx) of details">
                <tr :key="`${idx}-${didx}-detail`" class="details">
                  <col />
                  <td>{{ detail.type | formatDistributionType }}</td>
                  <td>{{ detail.role_code | formatRole }}</td>
                  <td>{{ detail.right | formatRight }}</td>
                  <td class="amount-row">
                    {{ detail.amount | formatAmountThousandSeparator('SEK') }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RecordingMixin from './recording-mixin';
import DistributionService from '../../../services/distributionService';

export default {
  name: 'ViewRecordingRemuneration',
  mixins: [RecordingMixin],
  data() {
    return {
      items: [],
      details: [],
      expandedIndex: undefined,
    };
  },
  computed: {
    ...mapGetters('recording', [
      'basicInfoName',
      'id',
      'basicInfoVersionTitle',
      'basicInfoMainArtist',
    ]),
  },
  async created() {
    await this.fetchData();
    await this.fetchRemuneration();
  },
  methods: {
    async fetchRemuneration() {
      this.loading = true;
      try {
        this.items = await DistributionService.recordingPerformerRemuneration(this.id);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchDetails(index, performerId) {
      this.details = [];
      this.expandedIndex = index;
      try {
        this.details = await DistributionService.recordingPerformerRemunerationDetails(
          this.id,
          performerId,
        );
      } catch (error) {
        this.expandedIndex = undefined;
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  background: #fafafa;
  & td:before {
    content: none;
  }
}
</style>
