<template>
  <span :class="{ 'signed-number': true, negative: Math.sign(input) === -1, positive: input > 0 }">
    <template v-if="Math.sign(input) === +1">+</template>
    {{ input | formatAmountCent }} SEK
  </span>
</template>

<script>
export default {
  name: 'SignedNumber',
  props: {
    input: {
      type: Number,
      required: true,
    },
  },
};
</script>
