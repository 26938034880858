var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('entity-badge',{attrs:{"entity":"performer"}}),_c('div',{staticClass:"search-result__everything__data"},[_c('div',[_c('span',{staticClass:"search-result__everything__data__name"},[_c('router-link',{attrs:{"to":'/performers/' + _vm.typedResult.value.id}},[_vm._v(" "+_vm._s(_vm.typedResult.value.first_name)+" "+_vm._s(_vm.typedResult.value.last_name)+" ")])],1)]),(_vm.typedResult.value.pseudo_names && _vm.typedResult.value.pseudo_names.length > 0)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Also known as:")]),_c('span',[_vm._v(_vm._s(_vm._f("join")(_vm.typedResult.value.pseudo_names)))])]):_vm._e(),(_vm.typedResult.value.profile)?_c('div',{class:{
        'search-result__everything__data__profile': true,
        'search-result__everything__data__profile--full': _vm.showFullProfile,
      },on:{"click":function($event){_vm.showFullProfile = true}}},[_vm._v(" "+_vm._s(_vm.typedResult.value.profile)+" ")]):_vm._e(),(
        _vm.typedResult.value.main_instrument ||
        (_vm.typedResult.value.secondary_instruments &&
          _vm.typedResult.value.secondary_instruments.length > 0)
      )?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Instruments:")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatInstrumentList")([_vm.typedResult.value.main_instrument ].concat( _vm.typedResult.value.secondary_instruments)))+" ")])]):_vm._e(),(_vm.typedResult.value.groups)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Member of the following group/s:")]),_c('span',[_vm._v(_vm._s(_vm._f("join")(_vm.typedResult.value.groups)))])]):_vm._e(),(_vm.typedResult.value.date_of_birth)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Born:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.typedResult.value.date_of_birth))+".")])]):_vm._e(),(_vm.typedResult.value.date_of_death)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Died:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.typedResult.value.date_of_death))+".")])]):_vm._e(),(_vm.typedResult.value.nationality)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Nationality:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatCountry")(_vm.typedResult.value.nationality))+".")])]):_vm._e(),(_vm.typedResult.value.country_of_residence)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Lives in:")]),(_vm.typedResult.value.city)?_c('span',[_vm._v(_vm._s(_vm.typedResult.value.city)+",")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("formatCountry")(_vm.typedResult.value.country_of_residence))+".")])]):_vm._e(),(_vm.typedResult.value.societies)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Is a member of:")]),_c('span',[_vm._v(_vm._s(_vm.typedResult.value.societies))])]):_vm._e(),(_vm.typedResult.value.collective)?_c('div',[_c('span',{staticClass:"label"},[_vm._v("Collective: Yes")])]):_vm._e(),(_vm.typedResult.value.tags)?_c('div',{staticClass:"search-result__tags chips__wrapper"},[_vm._l((_vm.typedResult.value.tags),function(tag,index){return [_c('tag',{key:index,attrs:{"tag":tag}})]})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }