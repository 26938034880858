import { render, staticRenderFns } from "./version.vue?vue&type=template&id=062943b3&scoped=true&"
import script from "./version.vue?vue&type=script&lang=js&"
export * from "./version.vue?vue&type=script&lang=js&"
import style0 from "./version.vue?vue&type=style&index=0&id=062943b3&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062943b3",
  null
  
)

export default component.exports