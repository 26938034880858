import * as uuid from 'uuid';
import { mutate } from './apolloRequest';

import gql from '../domain/commandGql';
import prepareForWire from './wirePrepper';
import AuthenticationService from '@/services/authenticationService';

function prepareCommand(command, processId) {
  return prepareForWire({
    ...command,
    process_id: command.processId || processId,
    user: command.user || AuthenticationService.getUserName(),
    payload: command.payload || {},
  });
}

export default {
  handleCommands: (commands, prepareCommands, transformErrors) => {
    if (prepareCommands === undefined || !!prepareCommands) {
      commands = commands.map((cmd) => prepareCommand(cmd, processId));
    }

    const processId = uuid.v4();
    return mutate(
      {
        mutation: gql.handleCommands,
        variables: {
          commands: commands,
          target: 'pnr',
          transformErrors: transformErrors === undefined || !!transformErrors ? 'true' : 'false',
        },
      },
      { pickProp: 'handleCommands' },
    );
  },
  handleCommand: (target, command) => {
    const processId = uuid.v4();
    return mutate(
      {
        mutation: gql.handleCommand,
        variables: {
          command: prepareCommand(command, processId),
          target,
        },
      },
      { pickProp: 'handleCommand' },
    );
  },
};
