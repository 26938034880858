<template>
  <div class="compare__search">
    <div class="compare__nested-heading">
      <strong>Possible candidates</strong>
    </div>
    <div v-if="candidates.length === 0" class="none push--bottom">
      No candidates, try searching for one...
    </div>
    <ul v-else>
      <li
        v-for="candidate in candidates"
        :key="candidate.id"
        class="row"
        :class="{ selected: isSelected(candidate) }"
        @click="selectedChanged(candidate)"
      >
        <div v-if="!isAlbum" class="col s7">
          <span>{{ candidate.name }}</span>
          <span v-if="candidate.version_title">({{ candidate.version_title }})</span>
          <span class="candidate-artist-separator text--italic">by</span>
          {{ candidate.main_artist }}
        </div>
        <div v-else class="col s7">
          <span>{{ candidate.name }}</span>
          <span class="candidate-artist-separator text--italic">by</span>
          {{ candidate.main_artist }}
        </div>
        <div class="col s1">
          <span v-if="candidate.samiLineupMember">
            <i title="Sami WW/WW-" class="fas fa-user"></i>
          </span>
        </div>
        <status-icon v-if="candidate.status" class="col s1" :status="candidate.status" />
        <div v-if="!isAlbum" class="col s3">
          {{ candidate.recording_date | year }}
          <router-link class="float-right" :to="'/recordings/' + candidate.id">
            <i class="fas fa-arrow-right"></i>
          </router-link>
        </div>
        <div v-else class="col s3">
          <router-link class="float-right" :to="'/albums/' + candidate.id">
            <i class="fas fa-arrow-right"></i>
          </router-link>
        </div>
      </li>
    </ul>
    <div class="row compare__search__id">
      <div class="col s12">
        <span for="">Search {{ isAlbum ? 'album' : 'recording' }}:</span>
        <searchable-input
          :show-label="false"
          name="otherRecording"
          :placeholder="isAlbum ? 'E.g. Du & jag döden' : 'E.g. Stoppa mig Juni (Lilla Ego)'"
          :clear-after-select="true"
          :css-props="{ whiteSpace: 'normal' }"
          :searcher="search"
          :use-template="isAlbum ? 'album' : 'recording'"
          @input="add"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SearchableInput from '../ui/input/searchable-input';
import SearchHelper from '../search/searchHelper';
import StatusIcon from '../ui/status-icon';

export default {
  name: 'CandidateSelection',
  components: {
    SearchableInput,
    StatusIcon,
  },
  props: {
    isAlbum: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    ...mapGetters('matching', ['candidates', 'currentCandidate']),
  },
  created() {
    const topCandidate = this.candidates[0];
    if (topCandidate && this.currentCandidate && this.currentCandidate.id === topCandidate.id) {
      this.selected = topCandidate;
    }
  },
  methods: {
    ...mapMutations('matching', ['updateCandidates']),
    add(result) {
      if (!result.id) {
        return;
      }
      const candidate = {
        id: result.id,
        name: result.name,
        label: '',
        ...(this.isAlbum
          ? {
              main_artist: result.main_artist.name,
            }
          : {
              main_artist: result.main_artist.name,
              recording_date: result.recording_date,
              status: result.distribution_state.status,
            }),
      };
      this.updateCandidates([...this.candidates, candidate]);
      this.selectedChanged(candidate);
    },
    search(terms) {
      return this.isAlbum
        ? SearchHelper.quickSearchAlbum(terms)
        : SearchHelper.quickSearchRecording(terms);
    },
    selectedChanged(candidate) {
      if (this.isSelected(candidate)) {
        return;
      }

      let id;
      if (candidate) {
        this.selected = candidate;
        id = candidate.id;
      }
      this.$emit('candidateChanged', id);
    },
    isSelected(candidate) {
      return this.selected.id === candidate.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.compare__search {
  font-size: 13px;
  ul {
    border: 1px solid #e8e8e8;
    max-height: 510px;
    margin-bottom: var(--spacing-triple);
    overflow-y: auto;
  }
  li {
    padding: var(--spacing);
    margin-bottom: 0;
    &:first-child {
      margin-top: -1px;
    }
    &:last-child {
      margin-bottom: -1px;
    }
    &.selected {
      position: relative;
      font-weight: bold;
      border-left: 3px solid var(--blue--water);
      margin-left: -1px;
      cursor: default;
    }
    &:hover:not(.selected) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .candidate-artist-separator {
    color: var(--grey--vlight);
  }
}
</style>
