var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',[_vm._l((_vm.reportLines),function(line,index){return [_c('tr',{key:(index + "-tr1"),ref:"line",refInFor:true,class:{ 'cursor-pointer': _vm.expandEnabled },on:{"click":function($event){_vm.expandEnabled && _vm.toggleExpanded(line.incoming.group_id)}}},[(_vm.expandEnabled)?_c('td',{staticClass:"compare__arrow"},[_c('i',{class:("fas fa-caret-" + (_vm.isExpanded(line.incoming.group_id) ? 'up' : 'down'))})]):_vm._e(),(_vm.selectable)?_c('td',{staticClass:"compare__checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.checked),expression:"line.checked"}],ref:"mark",refInFor:true,staticClass:"filled-in",attrs:{"id":index,"type":"checkbox","name":"mark","disabled":line.completed},domProps:{"checked":Array.isArray(line.checked)?_vm._i(line.checked,null)>-1:(line.checked)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=line.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(line, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(line, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(line, "checked", $$c)}}}}),_c('label',{attrs:{"for":index},on:{"click":function($event){$event.stopPropagation();}}})]):_vm._e(),_c('td',{staticClass:"fs-14",class:{
          'text--orange': line.completed && ['I', 'R'].includes(line.incoming.match_state),
          'text--green': line.completed && !['I', 'R'].includes(line.incoming.match_state),
        }},[_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}}),(line.completed)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e()]),(!_vm.isExpanded(line.incoming.group_id))?[_c('td',[_vm._v(" "+_vm._s(line.incoming.main_artist)+" ")]),_c('td',[_vm._v(" "+_vm._s(line.incoming.sr_code)+" ")])]:_vm._e(),(!_vm.isExpanded(line.incoming.group_id) && !_vm.album)?[_c('td',[_vm._v(" "+_vm._s(line.incoming.recording_date)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCountry")(line.incoming.recorded_in_country))+" ")]),_c('td',[_vm._v(" "+_vm._s(line.incoming.label)+" ")]),(line.incoming.duration_sec > 0)?_c('td',[_vm._v(" "+_vm._s(_vm._f("toMinutes")(line.incoming.duration_sec))+" ")]):_vm._e()]:_vm._e(),(!_vm.isExpanded(line.incoming.group_id) && _vm.album)?[_c('td',[_vm._v(" "+_vm._s(line.incoming.label)+" ")]),_c('td',[_vm._v(" "+_vm._s(line.incoming.release_date)+" ")])]:_c('td',{attrs:{"colspan":"1000"}})],2),(_vm.isExpanded(line.incoming.group_id) && !_vm.album)?[_c('tr',{key:(index + "-tr2"),staticClass:"prevent-hover-bg no-borders"},[_c('td',{attrs:{"colspan":"1000"}},[(_vm.loading)?_c('div',{staticClass:"compare__loading"},[_c('spinner')],1):_c('div',{staticClass:"compare__nested"},[_c('div',{staticClass:"compare__nested-main compare__main-divide"},[_c('span',{staticClass:"compare__nested-heading compare__nested-heading--incoming"},[_c('strong',[_vm._v("Mediarc recording:")]),_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}})]),_c('span',{staticClass:"compare__nested-heading compare__nested-heading--candidate"},[_c('strong',[_vm._v("Star suggestion:")]),_c('a',{attrs:{"href":("#/recordings/" + (_vm.getCandidateValue('id')))}},[_vm._v(" "+_vm._s(_vm.getCandidateValue('basic_info.name'))+" ")])]),_c('ul',{staticClass:"compare__nested-list"},[_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.alternate_titles',
                    value: _vm.candidateAllTitles,
                    displayFilter: 'prettyPrintArray',
                  },"incoming":{
                    key: 'title',
                    value: line.incoming.title,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.version_title',
                    value: _vm.getCandidateValue('basic_info.version_title'),
                  },"incoming":{
                    key: 'basic_info.version_title',
                    value: false,
                  },"show-left-side":false,"forced-status":"ok"}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.main_artist.name',
                    value: _vm.getCandidateValue('basic_info.main_artist.name'),
                  },"incoming":{
                    key: 'main_artist',
                    value: line.incoming.main_artist,
                  },"original-value":_vm.getCandidateValue('basic_info.main_artist'),"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.duration_sec',
                    value: _vm.getCandidateValue('basic_info.duration_sec'),
                    displayFilter: 'toMinutes',
                  },"incoming":{
                    key: 'duration_sec',
                    value: line.incoming.duration_sec,
                    displayFilter: 'toMinutes',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'albums',
                    value: _vm.getCandidateValue('albums'),
                    displayFilter: 'prettyPrintAlbumArray',
                  },"incoming":{
                    key: 'album',
                    value: line.incoming.album,
                    displayFilter: 'prettyPrintAlbumObject',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'album_local_code',
                    value: _vm.getCandidateValue('albums')
                      .flatMap(function (album) { return album.local_codes; })
                      .filter(function (lc) { return lc.code === 'SR'; }),
                    displayFilter: 'prettyPrintCodeValueObject',
                  },"incoming":{
                    key: 'album_local_code',
                    value: line.incoming.album && [
                      ("SR:" + (line.incoming.album.local_codes.find(function (lc) { return lc.code === 'SR'; }).value)) ],
                    displayFilter: 'prettyPrintArray',
                  },"forced-status":"idle","allow-action":false}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.recording_date',
                    value: _vm.getCandidateValue('basic_info.recording_date'),
                    displayFilter: 'formatRecordingDate',
                  },"incoming":{
                    key: 'recording_date',
                    value: line.incoming.recording_date,
                  },"comparator":'yearsMatch',"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.recorded_in_country',
                    value: _vm.getCandidateValue('basic_info.recorded_in_country'),
                    displayFilter: 'formatCountry',
                  },"incoming":{
                    key: 'recording_country',
                    value: line.incoming.recorded_in_country,
                    displayFilter: 'formatCountry',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.produced_in_country',
                    value: _vm.getCandidateValue('basic_info.produced_in_country'),
                    displayFilter: 'formatCountry',
                  },"incoming":{
                    key: 'produced_in_country',
                    value: line.incoming.produced_in_country,
                    displayFilter: 'formatCountry',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.label',
                    value: _vm.getCandidateValue('basic_info.label'),
                    displayFilter: 'toTitleCase',
                  },"incoming":{
                    key: 'label',
                    value: line.incoming.label,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.genre',
                    value: _vm.getCandidateValue('basic_info.genre'),
                    displayFilter: 'formatGenre',
                  },"incoming":{
                    key: 'genre',
                    value: line.incoming.genre,
                    displayFilter: 'formatGenre',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.composer',
                    value: _vm.getCandidateValue('basic_info.composer'),
                    displayFilter: 'prettyPrintNames',
                  },"incoming":{
                    key: 'composer',
                    value: line.incoming.composer,
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.writer',
                    value: _vm.getCandidateValue('basic_info.writer'),
                  },"incoming":{
                    key: 'writer',
                    value: line.incoming.writer,
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.arranger',
                    value: _vm.getCandidateValue('basic_info.arranger'),
                  },"incoming":{
                    key: 'arranger',
                    value: line.incoming.arranger,
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.local_codes_label',
                    value: _vm.getCandidateValue('basic_info.local_codes'),
                    displayFilter: 'prettyPrintCodeValueObject',
                  },"incoming":{
                    key: 'basic_info.local_codes_label',
                    value: [("SR:" + (line.incoming.sr_code))],
                    displayFilter: 'prettyPrintArray',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}})],1)]),(_vm.allowAction && !line.completed)?_c('CompareButtonsMediarc',{attrs:{"candidate-changed":_vm.candidateChanged,"handle-new-btn":_vm.handleNewBtn,"handle-save-btn":_vm.handleSaveBtn,"handle-unidentify-btn":_vm.handleUnidentifyBtn,"is-album":_vm.album,"line":line}}):_vm._e()],1)])])]:_vm._e(),(_vm.isExpanded(line.incoming.group_id) && _vm.album)?[_c('tr',{key:(index + "-tr2"),staticClass:"prevent-hover-bg no-borders"},[_c('td',{attrs:{"colspan":"1000"}},[(_vm.loading)?_c('div',{staticClass:"compare__loading"},[_c('spinner')],1):_c('div',{staticClass:"compare__nested"},[_c('div',{staticClass:"compare__nested-main compare__main-divide"},[_c('span',{staticClass:"compare__nested-heading compare__nested-heading--incoming"},[_c('strong',[_vm._v("Mediarc album:")]),_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}})]),_c('span',{staticClass:"compare__nested-heading compare__nested-heading--candidate"},[_c('strong',[_vm._v("Star suggestion:")]),_c('a',{attrs:{"href":("#/albums/" + (_vm.getCandidateValue('id')))}},[_vm._v(" "+_vm._s(_vm.getCandidateValue('basic_info.name'))+" ")])]),_c('ul',{staticClass:"compare__nested-list"},[_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.name',
                    value: _vm.getCandidateValue('basic_info.name'),
                  },"incoming":{
                    key: 'name',
                    value: line.incoming.title,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.main_artist.name',
                    value: _vm.getCandidateValue('basic_info.main_artist.name'),
                  },"incoming":{
                    key: 'main_artist',
                    value: line.incoming.main_artist,
                  },"original-value":_vm.getCandidateValue('basic_info.main_artist'),"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.label',
                    value: _vm.getCandidateValue('basic_info.label'),
                    displayFilter: 'toTitleCase',
                  },"incoming":{
                    key: 'label',
                    value: line.incoming.label,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.release_date',
                    value: _vm.getCandidateValue('basic_info.release_date'),
                  },"incoming":{
                    key: 'basic_info.release_date',
                    value: line.incoming.release_date,
                  },"comparator":'yearsMatch',"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.no_tracks',
                    value: _vm.getCandidateValue('tracks').length,
                  },"incoming":{
                    key: 'no_tracks',
                    value: line.incoming.number_of_tracks,
                  },"allow-action":false}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.catalog_number',
                    value: _vm.getCandidateValue('basic_info.catalog_number'),
                  },"incoming":{
                    key: 'catalog_number',
                    value: line.incoming.catalog_number,
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.local_codes_label',
                    value: _vm.getCandidateValue('basic_info.local_codes'),
                    displayFilter: 'prettyPrintCodeValueObject',
                  },"incoming":{
                    key: 'basic_info.local_codes_label',
                    value: [("SR:" + (line.incoming.sr_code))],
                    displayFilter: 'prettyPrintArray',
                  },"allow-action":_vm.allowAction && !line.completed,"auto-action":true},on:{"valueUpdated":_vm.candidateValueUpdated}})],1)]),(_vm.allowAction && !line.completed)?_c('CompareButtonsMediarc',{attrs:{"candidate-changed":_vm.candidateChanged,"handle-new-btn":_vm.handleNewBtn,"handle-save-btn":_vm.handleSaveBtn,"handle-unidentify-btn":_vm.handleUnidentifyBtn,"is-album":_vm.album,"line":line}}):_vm._e()],1)])])]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }