<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col s5">
          <div class="row">
            <h1>
              Payment history
              {{ paymentDetails.paymentDate | dateFormat('YYYY-MM-DD') }}
            </h1>
          </div>

          <div class="row">
            <label class="col s8">Original amount</label>
            <div class="col s4 text--right nowrap">
              {{ (paymentDetails.net - totalTax - totalFee) | formatAmountCent('SEK') }}
            </div>
          </div>
          <div class="row">
            <label class="col s8">Administrative Fee</label>
            <div class="col s4 text--right nowrap">
              {{ totalFee | formatAmountCent('SEK') }}
            </div>
          </div>
          <div class="row">
            <label class="col s8">Gross amount</label>
            <div class="col s4 text--right nowrap">
              {{ (paymentDetails.net - totalTax) | formatAmountCent('SEK') }}
            </div>
          </div>
          <div class="row">
            <div class="row">
              <div class="col s7 offset-s1">National Remunerations</div>
              <div class="col s4 text--right nowrap">
                {{ totalDomesticNetAmount | formatAmountCent('SEK') }}
              </div>
            </div>
            <div class="row">
              <div class="col s7 offset-s1">International Remunerations</div>
              <div class="col s4 text--right nowrap">
                {{ totalInternationalNetAmount | formatAmountCent('SEK') }}
              </div>
            </div>
          </div>
          <div class="row">
            <label class="col s8">VAT</label>
            <div class="col s4 text--right nowrap">
              {{ paymentDetails.vat | formatAmountCent('SEK') }}
            </div>
          </div>
          <div class="row">
            <label class="col s8">Deducted Tax</label>
            <div class="col s4 text--right nowrap">
              {{ totalTax | formatAmountCent('SEK') }}
            </div>
          </div>
          <div class="row">
            <div class="col s8 text--bold positive">
              <h2>Total Amount</h2>
            </div>
            <div class="col s4 text--right nowrap">
              {{ paymentDetails.total | formatAmountCent('SEK') }}
            </div>
          </div>
        </div>
        <div class="col s4 offset-s3">
          <div class="row">
            <div class="col s12">
              <div>
                <a
                  v-if="paymentDetails.invoiceKey"
                  @click="onDownloadFile(paymentDetails.invoiceKey)"
                >
                  <i class="far fa-file-alt"></i>
                  Download invoice
                </a>
              </div>
              <div>
                <a
                  v-if="paymentDetails.creditInvoiceKey"
                  @click="onDownloadFile(paymentDetails.creditInvoiceKey)"
                >
                  <i class="far fa-file-alt"></i>
                  Download Credit invoice
                </a>
              </div>
            </div>
          </div>
          <div>
            <h2>Refund</h2>
            <template v-if="paymentDetails.refunded">
              <div class="row">
                <div class="col s6"><strong>Refunded</strong></div>
                <div class="col s6">
                  {{ paymentDetails.refundedAmount | formatAmountCent('SEK') }}
                </div>
              </div>
              <div class="row">
                <div class="col s6"><strong>Reason</strong></div>
                <div class="col s6">{{ RefundReasonTypes[paymentDetails.refundReason] }}</div>
              </div>
              <div class="row">
                <div class="col s6"><strong>Transaction date</strong></div>
                <div class="col s6">
                  {{ paymentDetails.refundDate | dateFormat('YYYY-MM-DD') }}
                </div>
              </div>
              <div class="row">
                <div class="col s6"><strong>Created by</strong></div>
                <div class="col s6">
                  {{ paymentDetails.refundedBy }}
                  <span v-if="paymentDetails.refundedAt">
                    {{ paymentDetails.refundedAt | dateParse | dateFormat('YYYY-MM-DD') }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col s6"><strong>Note</strong></div>
                <div class="col s6">{{ paymentDetails.refundNote }}</div>
              </div>
            </template>
            <template v-else-if="paymentDetails.refundable">
              <div class="row">
                <div class="col s12">
                  <span class="text--bold">
                    Refund {{ (paymentDetails.net + paymentDetails.vat) | formatAmountCent('SEK') }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <select-generic
                    v-model="refund.reason"
                    name="refund-reason"
                    label="Reason"
                    :rule="{ required: true }"
                    :data="RefundReasonTypes"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <select-date
                    v-model="refund.refundDate"
                    name="refund-date"
                    label="Transaction date"
                    :rule="{ required: true }"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <label class="label">Note</label>
                  <input v-model="refund.note" type="text" autocomplete="off" name="note" />
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <action-buttons submit-label="Refund" :show-abort="false" @save="onRefund()" />
                </div>
              </div>
            </template>
            <template v-else-if="paymentDetails.refundable === false">
              <div class="row">
                <div class="col s12">This payment can not be refunded.</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="tabs row wrapper">
        <div class="tabs__wrapper">
          <router-link
            :to="{ name: nodeType === 'associate' ? 'associateOverview' : 'performerOverview' }"
            exact
          >
            Details
          </router-link>
          <router-link
            :to="{
              name:
                nodeType === 'associate' ? 'associateTrackBreakdown' : 'performerTrackBreakdown',
            }"
          >
            Track Breakdown
          </router-link>
        </div>
      </div>
      <div class="row card">
        <router-view
          :spec-ids="specIds"
          :node-id="ledgerId"
          :is-migrated-payment="specIds.length === 0"
          :payment-id="paymentDetails.paymentId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RefundReasonTypes } from '../../../domain/paymentDomain';
import ActionButtons from '../../ui/button/action-buttons';
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';
import SelectDate from '../../ui/select/select-date';
import SelectGeneric from '../../ui/select/select-generic';
import Spinner from '../../spinner';
import { mutate, query } from '../../../services/apolloRequest';
import { paymentCommand } from '../../../store/modules/payment/store';
import gql from 'graphql-tag';
import * as uuid from 'uuid';

export default {
  name: 'ViewPaymentDetails',
  components: {
    ActionButtons,
    SelectDate,
    SelectGeneric,
    Spinner,
  },
  inject: ['$validator'],
  data() {
    return {
      RefundReasonTypes,
      loading: false,
      ledgerId: this.$router.currentRoute.params.id,
      paymentId: this.$router.currentRoute.params.paymentId,
      refund: {
        reason: null,
        refundDate: null,
        note: null,
      },
      paymentDetails: {
        paymentDate: null,
        net: 0,
        tax: 0,
        fee: 0,
        vat: 0,
        invoiceKey: null,
        creditInvoiceKey: null,
        refunded: false,
        refundDate: null,
        refundReason: null,
        refundNote: null,
        refundable: false,
        refundedAt: null,
        refundedBy: null,
        txId: null,
        migrated: false,
      },
      remunerations: [],
      specs: [],
      specificationColumns: [
        {
          name:
            this.$router.currentRoute.name === 'viewAssociatePaymentDetails'
              ? 'Collects/inherits from'
              : 'Name',
        },
        { name: 'Amount', styleClass: 'amount-row' },
        { name: 'VAT', styleClass: 'amount-row' },
      ],
    };
  },
  computed: {
    nodeType() {
      return this.$route.meta?.nodeType;
    },
    totalDomesticNetAmount() {
      return this.domesticRemunerations.reduce((sum, r) => sum + r.net, 0);
    },
    totalInternationalNetAmount() {
      return this.internationalRemunerations.reduce((sum, r) => sum + r.net, 0);
    },
    domesticRemunerations() {
      return this.remunerations.filter((r) => r.territory === 'SE');
    },
    internationalRemunerations() {
      return this.remunerations.filter((r) => r.territory !== 'SE');
    },
    specIds() {
      return this.paymentDetails.entries.flatMap((e) => e.specIds);
    },
    totalFee() {
      return this.remunerations.reduce((sum, r) => sum + r.fee, 0);
    },
    totalTax() {
      return this.remunerations.reduce((sum, r) => sum + r.tax, 0);
    },
  },
  async created() {
    await this.fetchData();
    await this.fetchSummary();
  },
  methods: {
    async onDownloadFile(url) {
      try {
        const pdfFile = `${url.split('.').slice(0, -1).join('.')}.pdf`;
        const fileData = await RemoteFileService.downloadInvoice(pdfFile);
        DownloadLink.direct(fileData, pdfFile);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
    async fetchPayment() {
      const payQuery = gql`
        query {
          payment(id: "${this.paymentId}") {
            name
            paymentDate
          }
        }
      `;
      const { payment } = await query({ query: payQuery });
      return payment;
    },
    async fetchRefund() {
      const refundQuery = gql`
        query {
          refunds(query: { paymentId: "${this.paymentId}" }) {
            id
            date
            reason
            note
            nodes(id: "${this.ledgerId}") {
              amount
            }
          }
        }
      `;
      const { refunds } = await query({ query: refundQuery });
      return refunds[0] || {};
    },
    async fetchData() {
      this.loading = true;
      try {
        const paymentQuery = gql`
          query {
            paymentHistory(request: { nodeId: "${this.ledgerId}", paymentId: "${this.paymentId}" }) {
              items {
                paymentId
                paymentDate
                vat
                net
                total
                refunded
                refundDate
                refundedAmount
                refundedBy
                refundedAt
                refundReason
                refundNote
                isSoi
                invoiceKey
                invoiceId
                creditInvoiceKey
                creditInvoiceId
                type
                entries(entryType: 1) {
                  accountId
                  specIds
                }
              }
            }
          }
        `;
        const { paymentHistory } = await query({ query: paymentQuery });
        const [payment] = paymentHistory.items;
        this.paymentDetails = {
          paymentDate: new Date(payment.paymentDate),
          net: payment.net,
          tax: 0,
          fee: 0,
          vat: payment.vat,
          total: payment.total,
          invoiceKey: payment.invoiceKey,
          creditInvoiceKey: payment.creditInvoiceKey,
          refunded: !!payment.refunded,
          refundedAmount: payment.refundedAmount,
          refundDate: payment.refundDate && new Date(payment.refundDate),
          refundReason: payment.refundReason,
          refundNote: payment.refundNote,
          refundable: payment.isSoi,
          refundedAt: payment.refundedAt,
          refundedBy: payment.refundedBy,
          migrated: payment.migrated,
          entries: payment.entries,
        };
      } catch (error) {
        error.title = 'Error fetching payment details';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    async fetchSummary() {
      try {
        const specIds = this.paymentDetails.entries.flatMap((e) => e.specIds);
        if (specIds.length === 0) {
          return;
        }
        const { specTerritorySummary } = await query({
          query: gql`
            query SpecTerritorySummary($specIds: [String]!) {
              specTerritorySummary(specIds: $specIds) {
                territory
                net
                fee
                tax
              }
            }
          `,
          variables: {
            specIds,
          },
        });
        this.remunerations = specTerritorySummary;
      } catch (err) {
        console.error(err);
        this.$addStarError(`Could not fetch remunerations`);
      }
    },
    async onRefund() {
      try {
        this.loading = true;
        const refundId = uuid.v5(`refund-${this.ledgerId}`, this.paymentId);
        await mutate(
          paymentCommand({
            stream_id: refundId,
            type: 'refund/create',
            payload: {
              paymentId: this.paymentId,
              nodeId: this.ledgerId,
              date: this.refund.refundDate,
              reason: this.refund.reason,
              note: this.refund.note,
            },
          }),
        );
        await this.fetchData();
      } catch (error) {
        error.title = 'Error when refunding';
        this.$addStarError(error);
      }
      setTimeout(this.fetchData, 1000);
    },
  },
};
</script>
