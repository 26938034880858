<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Jobs</h1>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Job status</h2>
      </div>
    </div>

    <div class="row card report__headers">
      <DataTable
        :columns="columns"
        :items="jobs"
        :sort.sync="sort"
        item-key="id"
        expand-key="payload"
        local-sort
      >
        <template #item:state="{ item }">
          <span class="chip" :class="'report__headers-chip--' + getStateColor(item.state)">
            {{ item.state }}
          </span>
        </template>
        <template #item:date_created="{ item }">
          {{ item.date_created | formatDateTime }}
        </template>
        <template #item:date_changed="{ item }">
          {{ item.date_changed | formatDateTime }}
        </template>
        <template #item:payload="{ item }">
          <pre>{{ item.payload }}</pre>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import JobService from '../../services/jobService';
import DataTable from '../ui/table/DataTable';

export default {
  name: 'ViewJobs',
  components: {
    DataTable,
  },
  data() {
    return {
      jobs: [],
      columns: [
        { key: 'type', text: 'Type' },
        { key: 'service', text: 'Service' },
        { key: 'state', text: 'State' },
        { key: 'date_created', text: 'Created', sortable: true },
        { key: 'date_changed', text: 'Updated', sortable: true },
        { key: 'user', text: 'User' },
      ],
      sort: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    getStateColor(state) {
      switch (state) {
        case 'PENDING':
        case 'EXECUTING':
          return 'wait';
        case 'FAILED':
          return 'fail';
        default:
          return 'ok';
      }
    },
    async fetchData() {
      try {
        const response = await JobService.listJobs();
        this.jobs = response;
        this.sort = { order_by: 'date_created', direction: 'descending' };
      } catch (err) {
        this.$addStarError(err);
      }
    },
  },
};
</script>
