<template>
  <div>
    <div class="row text--right edit-link">
      <div class="col s12">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <label class="col s3">Profile</label>
        <div class="col s9">
          <span v-if="profile">{{ profile }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Alternate names</label>
        <div class="col s9">
          <span v-for="(title, index) in alternateNames" :key="index">
            {{ title + (alternateNames.length - 1 > index ? ', ' : '') }}
          </span>
          <span v-if="alternateNames.length === 0" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Years active</label>
        <div class="col s9">
          <span v-for="(active, index) in yearsActive" :key="index">
            {{ active.from }} -
            <span v-if="active.to">{{ active.to }}</span>
            <span v-else>present</span>
            <span v-if="index + 1 !== yearsActive.length">,</span>
          </span>
          <span v-if="yearsActive.length === 0" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Origin</label>
        <div class="col s9">
          <span>{{ originCountry | formatCountry }} {{ originCity }}</span>
          <span v-if="!originCountry && !originCity" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Genre</label>
        <div class="col s9">
          <span v-if="genre">{{ genre | formatGenre }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <view-local-codes :local-codes="data.local_codes" />
    </div>
  </div>
</template>

<script>
import ViewLocalCodes from '../../ui/view-local-codes';

export default {
  name: 'BasicInformation',
  components: { ViewLocalCodes },
  props: {
    data: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    profile() {
      return this.data.profile_description;
    },
    alternateNames() {
      return this.data.alternate_names;
    },
    yearsActive() {
      return this.data.active_period;
    },
    originCountry() {
      return this.data.origin_country;
    },
    originCity() {
      return this.data.origin_city;
    },
    genre() {
      return this.data.genre;
    },
    sourceInfo() {
      return this.data.source_info;
    },
  },
  methods: {},
};
</script>
