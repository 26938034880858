/**
 * The Authenication Service provides authentication related functionality.
 */
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import * as Config from './configService';
import { query } from './apolloRequest';
import gql from '../domain/userGql';

const userPool = new CognitoUserPool(Config.getConfigValue(Config.PROPS.AUTHENTICATION));
let cognitoUser;
let activeDaemon = false;

function refreshSession(refreshToken) {
  return userPool.getCurrentUser().refreshSession(refreshToken, (err) => {
    if (err) {
      console.log('Auth Token refresh failed', err);
      return false;
    }

    console.log('Auth Token refresh success');
    return true;
  });
}

function refreshDaemon() {
  if (!activeDaemon) {
    console.log('Refresh daemon activated');
    setInterval(() => {
      userPool.getCurrentUser().getSession((err, session) => {
        if (err) {
          console.log('Refresh daemon failure', err);
        } else {
          refreshSession(session.getRefreshToken());
        }
      });
    }, 15 * 60 * 1000);
    activeDaemon = true;
  }
}

function createAuthCallback(resolve, reject) {
  return {
    onSuccess: (result) => {
      refreshDaemon();
      resolve({ status: 'success', result });
    },
    newPasswordRequired: (userAttributes) => {
      resolve({ status: 'newPasswordRequired', result: userAttributes });
    },
    mfaRequired: (codeDeliveryDetails) => {
      resolve({ status: 'mfaCodeRequired', result: codeDeliveryDetails });
    },
    onFailure: (err) => {
      reject(err);
    },
  };
}

export const GROUPS = {
  DEVELOPER: 'Developers',
  ADMIN: 'Admin',
  TESTER: 'Tester',
};

export default {
  login: (Username, Password) => {
    const authenticationData = {
      Username,
      Password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, createAuthCallback(resolve, reject));
    });
  },
  resetPasswordSendVerficiationCode: (username) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword(createAuthCallback(resolve, reject));
    });
  },
  verifyAndSetNewPassword: (verificationCode, newPassword) =>
    new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(
        verificationCode,
        newPassword,
        createAuthCallback(resolve, reject),
      );
    }),

  setNewPassword: (userAttributes, newPassword) =>
    new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        undefined,
        createAuthCallback(resolve, reject),
      );
    }),

  verifyMfaCode: (mfaCode) =>
    new Promise((resolve, reject) => {
      cognitoUser.sendMFACode(mfaCode, createAuthCallback(resolve, reject));
    }),

  reAuthenticate: (password) => {
    const username = userPool.getCurrentUser().username;
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const userData = {
      Username: username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, createAuthCallback(resolve, reject));
    });
  },

  logout: () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
  },

  hasValidSession: () =>
    new Promise((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      if (!currentUser) {
        resolve(false);
        return;
      }

      currentUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
        refreshDaemon();
        if (!session.isValid()) {
          resolve(refreshSession(session.getRefreshToken()));
          return;
        }
        resolve(true);
      });
    }),

  getAuthToken: () => {
    const currentUser = userPool.getCurrentUser();
    if (!currentUser) {
      return '';
    }

    return currentUser.getSession((err, session) => {
      if (err) {
        return '';
      }
      return session.getIdToken().getJwtToken();
    });
  },

  getUser: () => {
    const currentUser = userPool.getCurrentUser();
    if (!currentUser) {
      return {};
    }

    return currentUser.getSession((err, session) => {
      if (err) {
        return {};
      }

      return session.getIdToken().payload;
    });
  },

  getUserName: () => {
    const currentUser = userPool.getCurrentUser();
    if (!currentUser) {
      return '';
    }

    return currentUser.username;
  },

  listUsers: async () =>
    query({ query: gql.listUsers }, { pickProp: 'allUsers', cachePolicy: 'cache-first' }),
};
