<template>
  <div class="cmp">
    <div class="row">
      <div class="col s2 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div class="col s12 l7">
        <div class="row">
          <div class="col s12">
            <h1>Create Album</h1>
          </div>
        </div>

        <create-basic-info />
        <create-tracks />
        <div>
          <create-tags
            scope="tags-album"
            entity-type="album"
            path="basic_info"
            add-method="addToBasicInfoTags"
            remove-method="removeFromBasicInfoTags"
          />
        </div>

        <create-menu entity-type="album" @create="createAlbumAndNavigateToAlbumView" />
      </div>
    </div>
  </div>
</template>

<script>
import AlbumService from '../../../services/albumService';
import EntityTag from '../../ui/entity-tag';
import clone from '../../../common/clone';
import CreateBasicInfo from './create-basic-info';
import CreateMenu from '../../ui/create-menu';
import CreateTags from '../../tags/create-tags';
import CreateTracks from './create-tracks';
import store from '../../../store/index';

export default {
  name: 'CreateAlbum',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    EntityTag,
    CreateBasicInfo,
    CreateMenu,
    CreateTags,
    CreateTracks,
  },
  data() {
    return {
      storeNamespace: 'album',
      error: false,
    };
  },
  created() {
    return store.dispatch(`${this.storeNamespace}/createAggregate`);
  },
  methods: {
    createAlbumAndNavigateToAlbumView() {
      this.error = false;
      return AlbumService.createAlbum(clone(store.getters[`${this.storeNamespace}/aggregate`]))
        .then((id) =>
          this.$router.push({
            name: 'albumView',
            params: { id },
          }),
        )
        .catch((error) => {
          error.title = 'Ooops!';
          this.$addStarError(error);
        });
    },
  },
};
</script>
