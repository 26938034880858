<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Refund</h1>
      </div>
    </div>
    <div v-if="refund" class="row card">
      <div class="col s12">
        <div class="data">
          <dl>
            <dt>Confirmed by</dt>
            <dd>{{ refund.confirmedBy }}</dd>
            <dt>Confirmed at</dt>
            <dd>
              <span v-if="refund.confirmedAt">
                {{ refund.confirmedAt | dateParse | dateFormat('YYYY-MM-DD') }}
              </span>
              <span>Unknown</span>
            </dd>
            <dt>Reason</dt>
            <dd>{{ refund.reason }}</dd>
            <dt>Note</dt>
            <dd>{{ refund.note }}</dd>
            <dt>Transaction date</dt>
            <dd>{{ refund.date | dateParse | dateFormat('YYYY-MM-DD') }}</dd>
          </dl>
        </div>
        <h2>Refunded nodes</h2>
        <p>The following nodes were affected by the refund:</p>
        <table>
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th class="text--right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="node of refund.nodes" :key="node.id">
              <td>
                <router-link :to="getNodeLink(node)">
                  {{ node.name }}
                </router-link>
              </td>
              <td>{{ node.type }}</td>
              <td class="text--right">{{ node.total | formatAmountCent('SEK') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../services/apolloRequest';
export default {
  props: {
    refundId: String,
  },
  data() {
    return {
      refund: null,
      loading: false,
    };
  },
  created() {
    this.fetchRefund();
  },
  methods: {
    getNodeLink(node) {
      let baseUrl = '/performers';
      if (node.type !== 'performer') {
        baseUrl = '/associates';
      }
      return `${baseUrl}/${node.nodeId}/ledger/payment-details/${this.refund.paymentId}`;
    },
    async fetchRefund() {
      this.loading = true;
      const refunds = await query(
        {
          query: gql`
            query refunds($query: RefundQuery) {
              refunds(query: $query) {
                id
                reason
                note
                confirmedBy
                confirmedAt
                date
                paymentId
                net
                vat
                nodes {
                  nodeId
                  total
                  name
                  type
                }
              }
            }
          `,
          variables: {
            query: {
              refundIds: [this.refundId],
            },
          },
        },
        { pickProp: 'refunds' },
      );
      if (refunds.length === 0) {
        throw new Error(`No refund found with id ${this.refundId}`);
      }
      if (refunds.length > 1) {
        throw new Error(`Expected only one refund with id ${this.refundId}, got ${refunds.length}`);
      }
      this.refund = refunds[0];
      this.loading = false;
    },
  },
};
</script>

<style scoped>
dl {
  margin-bottom: 2rem;
}
dt {
  flex: 1 1 30%;
}
dd {
  flex: 1 1 70%;
}
.data {
  display: inline-block;
  max-width: 50rem;
}
</style>
