<template>
  <tr>
    <td v-if="expandedContent" class="compare__arrow" @click="$emit('expand')">
      <i :class="`fas fa-caret-${expanded ? 'up' : 'down'}`" />
    </td>
    <td v-if="lineIsChanged(line)" class="text--green">
      <i class="fas fa-check-circle" />
    </td>
    <td v-else-if="bulkEnabled" class="compare__checkbox">
      <input
        :id="line[config.lineKey]"
        :checked="selected"
        class="filled-in"
        type="checkbox"
        @change="$emit('select', $event.target.checked)"
      />
      <label :for="line[config.lineKey]">
        <i
          v-if="line.issues && line.issues.length > 0"
          v-tooltip.right-start="issueText()"
          class="fas fa-exclamation-triangle"
        ></i>
      </label>
    </td>
    <td v-for="c of config.columns" :key="c.key" :class="c.key">
      {{ format(line[c.key], c.filter) }}
    </td>
  </tr>
</template>

<script>
import Vue from 'vue';
import config from './config';
import 'floating-vue/dist/style.css';

export default {
  name: 'ReportLine',
  props: {
    line: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    expandedContent: {
      type: Boolean,
      default: true,
    },
    bulkEnabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
  },
  methods: {
    lineIsChanged(line) {
      return line.match_state !== this.state;
    },
    issueText() {
      const html = this.config.buildIssueHtml ? this.config.buildIssueHtml(this.line.issues) : '';
      return {
        content: html,
        html: true,
      };
    },
    format(value, filter) {
      if (!filter) {
        return value;
      }

      const [name, ...params] = Array.isArray(filter) ? filter : [filter];

      return Vue.filter(name)(value, ...params);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.gross_amount {
  white-space: nowrap;
}
.net_amount {
  white-space: nowrap;
}
</style>
