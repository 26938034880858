var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[(_vm.expandedContent)?_c('th',{staticClass:"thin-column"}):_vm._e(),(_vm.bulkEnabled)?_c('th',[_c('input',{ref:"mark",staticClass:"filled-in",attrs:{"id":_vm.checkboxId,"name":"mark","type":"checkbox","disabled":_vm.bulkSuspend},domProps:{"checked":_vm.markedForBulk},on:{"change":_vm.markAll}}),_c('label',{attrs:{"for":_vm.checkboxId}})]):_vm._e(),_vm._l((_vm.columns),function(column,index){return _c('th',{key:(index + "-column"),class:[
        {
          'thin-column': column.thinColumn,
          'disabled-head': !column.sortParam || _vm.disabled,
          'text--grey': _vm.disabled,
          count: column.count,
        },
        column.styleClass ],on:{"click":function($event){!_vm.bulkSuspend && _vm.sortDefault(column)}}},[_vm._v(" "+_vm._s(column.name)+" "),(column.sortParam)?_c('span',[(!column.ascending)?_c('i',{staticClass:"fas fa-caret-down",class:{ 'text--grey': !column.active || _vm.disabled }}):_c('i',{staticClass:"fas fa-caret-up",class:{ 'text--grey': !column.active || _vm.disabled }})]):_vm._e()])}),(_vm.moreOptions)?_c('th',{staticClass:"thin-column"}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }