<template>
  <div>
    <div class="row text--right edit-link">
      <div class="col s12">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>
    <div class="row card">
      <div class="row">
        <label class="col s3">Social Security Number</label>
        <div class="col s9">
          <span v-if="personInfoSocialSecurityNumber" class="e2e-social-security-number">
            {{ personInfoSocialSecurityNumber }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Date of birth</label>
        <div class="col s9">
          <span v-if="personInfoDateOfBirth">{{ personInfoDateOfBirth | formatDate }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Date of death</label>
        <div class="col s9">
          <span v-if="personInfoDateOfDeath">{{ personInfoDateOfDeath | formatDate }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Protected identity</label>
        <div class="col s9">
          <i v-if="personInfoProtectedIdentity" class="fas fa-lock" />
          <span v-else class="none">None</span>
        </div>
      </div>

      <view-local-codes :local-codes="localCodes" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewLocalCodes from '../../ui/view-local-codes';

export default {
  name: 'PersonInformation',
  components: {
    ViewLocalCodes,
  },
  computed: mapGetters('associate', [
    'personInfoSocialSecurityNumber',
    'personInfoDateOfBirth',
    'personInfoDateOfDeath',
    'personInfoProtectedIdentity',
    'type',
    'localCodes',
  ]),
};
</script>
