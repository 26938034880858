<template>
  <div class="errors">
    <div v-if="loading">
      <spinner />
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table class="errors__recordings">
          <thead>
            <th>Recording title</th>
            <th>Main artist</th>
            <th>Airplay time</th>
            <th v-if="isTestEnvironment" class="thin-column"></th>
          </thead>
          <tbody>
            <template v-for="(line, index) in reportLines">
              <tr :key="index">
                <td class="fs-14">
                  {{ line.incoming.title }}
                </td>
                <td>
                  {{ line.incoming.main_artist }}
                </td>
                <td>
                  {{ line.incoming.airplay_time | toMinutes }}
                </td>
                <td v-if="isTestEnvironment">
                  <context-menu
                    :disabled-options="isAlbum ? ['Airtime correction'] : []"
                    :options="['Airtime correction']"
                    @airtimeCorrection="toggleUsageCorrectionModal(line)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
          <div v-if="reportLines.length === 0" class="no-result">0 transferred lines found</div>
        </table>

        <div class="result__pagination">
          <pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>

    <modal
      v-if="showUsageCorrectionModal"
      submit-label="Transfer correction"
      :hide-overflow="false"
      :disable-submit="
        usageCorrection.timeNew === usageCorrection.timeOld ||
        validationErrors.has('duration-sec') ||
        validationErrors.has('detail')
      "
      @close="showUsageCorrectionModal = false"
      @cancel="showUsageCorrectionModal = false"
      @save="toggleUsageCorrectionModal"
    >
      <h1 slot="header">Airtime correction</h1>
      <div slot="body">
        <select-input
          v-model="usageCorrection.selectedDetail"
          name="detail"
          label="Channel / Program / Play date"
          :rule="{ required: true }"
          class="push--bottom"
          :items="usageCorrection.details"
        />
        <duration-input
          v-model="usageCorrection.timeNew"
          name="duration-sec"
          :rule="{ required: true }"
          label="New airtime"
        />
        <p v-if="usageCorrection.timeNew !== usageCorrection.timeOld">
          Airtime to be
          <strong>
            {{ usageCorrection.timeNew - usageCorrection.timeOld >= 0 ? 'added' : 'deducted' }}
          </strong>
          {{ Math.abs(usageCorrection.timeNew - usageCorrection.timeOld) | toMinutes }}
        </p>
        <p v-else>No changes to airtime has been made</p>
      </div>
    </modal>
  </div>
</template>

<script>
import * as moment from 'moment';
import { PROPS, getConfigValue } from '../../services/configService';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import Pagination from '../pagination';
import PaginationAndSortMixin from '../../common/paginationAndSortMixin';
import ViewMusicReportMixin from './view-music-report-mixin';
import ContextMenu from '../ui/context-menu';
import Modal from '../ui/dialog/modal';
import DurationInput from '../ui/input/duration-input';
import SelectInput from '../ui/select/select-input';

export default {
  name: 'ViewMusicReportTransferredLines',
  components: {
    Pagination,
    ContextMenu,
    Modal,
    DurationInput,
    SelectInput,
  },
  mixins: [DebouncedSearchMixin(50), PaginationAndSortMixin, ViewMusicReportMixin],
  inject: ['$validator'],
  data() {
    return {
      reportId: this.$router.currentRoute.params.reportId,
      lineupStateMask: 'T',
      showUsageCorrectionModal: false,
      usageDetails: [],
      usageCorrection: {
        groupId: '',
        timeOld: 0,
        timeNew: 0,
        selectedDetail: 0,
      },
    };
  },
  computed: {
    isTestEnvironment() {
      return getConfigValue(PROPS.ENVIRONMENT) !== 'prod';
    },
  },
  async activated() {
    await this.fetchData();
  },
  async created() {
    await this.loadPaginated();
  },
  methods: {
    async toggleUsageCorrectionModal(line) {
      if (this.showUsageCorrectionModal) {
        this.showUsageCorrectionModal = false;
        const { channel_name, ...details } = this.usageDetails[this.usageCorrection.selectedDetail];

        await this.addUsageCorrection({
          group_id: this.usageCorrection.groupId,
          airplay_time: this.usageCorrection.timeNew - this.usageCorrection.timeOld,
          ...details,
        });
      } else {
        const { group_id, airplay_time } = line.incoming;
        this.usageDetails = await this.getUsageDetails(group_id);
        this.usageCorrection = {
          groupId: group_id,
          timeNew: airplay_time,
          timeOld: airplay_time,
          selectedDetail: 0,
          details: this.usageDetails.map((detail, index) => ({
            code: index,
            name: `${detail.channel_name} / ${detail.program} / ${moment(detail.play_date).format(
              'YYYY-MM-DD HH:mm:ss',
            )}`,
          })),
        };
        this.showUsageCorrectionModal = true;
      }
    },
  },
};
</script>
