<template>
  <div class="ledger-overview">
    <div class="balance">
      <h3>Balance</h3>
      <signed-number :input="balance" />
    </div>
    <div class="active-payments">
      <h3>Active Payments</h3>
      <active-payments :node-id="id" />
    </div>
    <div v-if="latestPayment" class="latest-payment">
      Latest payment:
      <signed-number :input="Number(latestPayment.total)" />
      <div class="latest-payment__details">
        <span>
          Payment day: {{ latestPayment.paymentDate | dateParse | dateFormat('YYYY-MM-DD') }}
        </span>
        <span>
          <router-link :to="`payment-details/${latestPayment.paymentId}`">
            View payment history
          </router-link>
        </span>
        <a v-if="latestPayment.invoiceKey" @click="onDownloadFile(latestPayment.invoiceKey)">
          <i class="far fa-file-alt"></i>
          Download invoice
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadLink from '../../ui/file/downloadLink';
import ActivePayments from '../../payment/active-payments';
import RemoteFileService from '../../../services/remoteFileService';
import SignedNumber from '../../ui/signed-number';

export default {
  name: 'LedgerOverview',
  components: {
    ActivePayments,
    SignedNumber,
  },
  props: {
    balance: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    latestPayments: {
      type: Array,
      default: () => [],
    },
    detailsView: {
      type: String,
      required: true,
    },
    nodeType: {
      type: String,
      required: true,
    },
  },
  computed: {
    latestPayment() {
      return this.latestPayments[0];
    },
  },
  methods: {
    async onDownloadFile(url) {
      try {
        const pdfFile = `${url.split('.').slice(0, -1).join('.')}.pdf`;
        const fileData = await RemoteFileService.downloadInvoice(pdfFile);
        DownloadLink.direct(fileData, pdfFile);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.ledger-overview {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
  gap: 1rem;
}
.balance,
.latest-payment,
.active-payments {
  border: 1px solid var(--grey--light);
  padding: 10px !important;
  font-size: 16px;
  flex: 1 1 auto;

  i {
    font-size: 12px;
  }

  &__manual-payment {
    text-align: right;
    margin-top: 5px;
    font-size: 12px;
  }
}
.latest-payment,
.active-payments {
  flex: 1 1 35%;
}
.balance {
  flex: 1 1 20%;
}
.latest-payment {
  &__details {
    font-size: 12px;
    margin-top: 5px;

    span {
      margin-right: 10px;
    }
  }
}
.earlier-payments {
  i {
    font-size: 12px;
  }
}
</style>
