<template>
  <div>
    <div v-if="loading">
      <Spinner />
    </div>

    <div class="row wrapper">
      <div class="col s12">
        <h1>IPD Download</h1>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Create New Files</h2>
      </div>
    </div>

    <div class="row card">
      <div class="col s4">
        <searchable-tag-input
          name="societies"
          label="Societies"
          placeholder="E.g. PPL"
          :value-list="selectedSocieties"
          :simple-searcher="searchSociety"
          :preview-list="societies"
          :mandatory="true"
          use-template="tag"
          scope="ipd-download"
          @add="updateSelectedSocieties"
          @remove="updateSelectedSocieties"
        />
      </div>
      <div class="col s4">
        <!-- Territory selection is for now limited to Sweden, in the future we might need to enable this -->
        <select-country
          name="territory"
          label="Territory"
          :disabled="true"
          :value="selectedTerritory"
          scope="ipd-download"
          :mandatory="true"
          rule="required"
          @input="updateSelectedTerritory"
        />
      </div>
      <div class="col s4">
        <br />
        <button class="btn primary m2" :disabled="!validForm" @click.prevent="createFiles">
          Create files
        </button>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Cron jobs</h2>
      </div>
    </div>

    <div class="row card">
      <div v-for="job of cronjobs" :key="job.name" class="cronjob row">
        <div class="col s2">{{ job.name }}:</div>
        <div class="col s2">
          {{ job.enabled ? 'ON' : 'OFF' }}
        </div>
      </div>
      <div>
        Both IPD cronjobs need to be ON, if OFF talk to IT-department or activate them
        <router-link to="/cronjobs">here</router-link>
        . Remember to turn them OFF when done.
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Available Files</h2>
      </div>
    </div>

    <div class="row card report__headers">
      <table>
        <SortableHead
          :columns="columns"
          :expanded-content="false"
          :more-options="false"
          :bulk-enabled="false"
        />
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td>
              <span v-if="file.file_id">
                <router-link :to="`/integrations/ipd/download/${file.id}`">
                  {{ file.file_id }}
                </router-link>
              </span>
              <span v-else class="none">None</span>
            </td>
            <td>
              <span>{{ file.society }}</span>
            </td>
            <td>
              <span>{{ file.territory | formatCountry }}</span>
            </td>
            <td style="min-width: 140px">
              <span
                class="chip float-left"
                :class="getIpdDownloadStateClass(file.state)"
                :title="file.error"
              >
                {{ file.state | formatIpdDownloadState }}
                <span v-if="file.state === 'P'">
                  &nbsp;({{ Math.round(file.progress * 100) }}%)
                </span>
              </span>
            </td>
            <template v-if="file.state === 'F' || file.state === 'P'">
              <td class="count count--green">{{ file.active }}</td>
              <td class="count count--gold">{{ file.merged }}</td>
              <td class="count count--orange">{{ file.deleted }}</td>
            </template>
            <template v-else>
              <td class="count none">-</td>
              <td class="count none">-</td>
              <td class="count none">-</td>
            </template>
            <td>
              <span>{{ file.created | formatDate }}</span>
            </td>
            <td>
              <span>{{ file.user }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="result__pagination">
        <Pagination
          v-if="numberOfPages() > 0"
          :number-of-pages="numberOfPages()"
          :selected-page="page"
          :number-of-hits="itemCount"
          :hits-per-page="pageSize"
          @selectPage="selectPage"
          @updateHitsPerPage="selectPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IpdService from '../../services/ipdService';
import PaginationQueryParamMixin from '../../common/paginationQueryParamMixin';
import Pagination from '../pagination';
import SortableHead from '../ui/table/sortable-head';
import Spinner from '../spinner';
import SelectCountry from '../ui/select/select-country';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import { getConfigValue, PROPS } from '../../services/configService';

export default {
  name: 'ViewIpdDownloadFile',
  components: {
    Pagination,
    SortableHead,
    Spinner,
    SearchableTagInput,
    SelectCountry,
  },
  mixins: [PaginationQueryParamMixin],
  beforeRouteLeave(_to, _from, next) {
    clearTimeout(this.autoReloadHandler);
    this.autoReloadHandler = null;

    next();
  },
  data() {
    return {
      selectedSocieties: [],
      selectedTerritory: 'SE',
      files: [],
      autoReloadHandler: null,
      columns: [
        { name: 'File ID', thinColumn: true },
        { name: 'Society' },
        { name: 'Territory' },
        { name: 'Status', thinColumn: true },
        { name: 'Active', thinColumn: true },
        { name: 'Merged', thinColumn: true },
        { name: 'Deleted', thinColumn: true },
        { name: 'Created' },
        { name: 'User' },
      ],
    };
  },
  computed: {
    societies() {
      return this.$store.state.appdata.referenceData.ipdSocieties
        .filter(({ name }) => name !== 'SAMI')
        .map(({ name }) => ({
          name,
          type: 'tag',
          count: 0,
        }));
    },
    validForm() {
      return this.selectedSocieties.length && this.selectedTerritory;
    },
    cronjobs() {
      return this.$store.state.cronjob.cronjobs.filter((job) =>
        /-ipd-(create|download)$/.test(job.name),
      );
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  async created() {
    this.updateQueryParams(this.$route.query);
    await this.fetchData();

    const env = getConfigValue(PROPS.ENVIRONMENT);
    if (env !== 'local') {
      await this.$store.dispatch('cronjob/fetchCronjob', `star-${env}-ipd-create`);
      await this.$store.dispatch('cronjob/fetchCronjob', `star-${env}-ipd-download`);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { total, items } = await IpdService.getDownloadFiles({
          limit: this.pageSize,
          offset: this.getOffset(),
        });

        this.itemCount = total;
        this.files = items;

        const autoReload = this.files.some((file) => !['F', 'T', 'E'].includes(file.state));
        if (autoReload && this.autoReloadHandler === null) {
          this.setAutoReload();
        }
      } catch (err) {
        this.$addStarError(err);
      }

      this.loading = false;
    },
    async createFiles() {
      this.loading = true;

      try {
        const files = await IpdService.createDownloadFiles({
          societies: this.selectedSocieties.map((s) => s.name),
          territory: this.selectedTerritory,
        });

        this.selectedSocieties = [];

        this.itemCount += files.length;
        this.files = [...files, ...this.files].slice(0, this.pageSize);
        this.setAutoReload();
      } catch (err) {
        this.$addStarError(err);
      }

      this.loading = false;
    },
    getIpdDownloadStateClass(state) {
      switch (state) {
        case 'N':
        case 'R':
        case 'QD':
        case 'D':
        case 'QP':
        case 'P':
          return 'report__headers-chip--wait';
        case 'F':
          return 'report__headers-chip--ok';
        case 'T':
        case 'E':
          return 'report__headers-chip--fail';
        default:
          return 'report__headers-chip--remove';
      }
    },
    setAutoReload() {
      this.autoReloadHandler = setTimeout(() => {
        this.autoReloadHandler = null;
        this.fetchData();
      }, 30000);
    },
    searchSociety(terms) {
      return this.societies.filter((society) =>
        society.name.toLowerCase().includes(terms.toLowerCase()),
      );
    },
    updateSelectedSocieties(event) {
      this.selectedSocieties = event.data;
    },
    updateSelectedTerritory(event) {
      this.selectedTerritory = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.cronjob {
  font-size: 18px;
}
</style>
