var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"prevent-hover-bg no-borders"},[_c('td',{attrs:{"colspan":"1000"}},[(_vm.loading)?_c('ComponentSpinner'):_c('div',{staticClass:"compare__nested"},[_c('div',{staticClass:"compare__nested-main compare__main-divide"},[_c('span',{staticClass:"compare__nested-heading compare__nested-heading--incoming"},[_c('strong',[_vm._v("Played "+_vm._s(_vm.config.entityType)+":")]),_c('span',[_vm._v(_vm._s(_vm.line[_vm.config.lineTitleKey]))])]),_c('span',{staticClass:"compare__nested-heading compare__nested-heading--candidate"},[_c('strong',[_vm._v("Star suggestion:")]),_c('a',{attrs:{"href":("#" + (_vm.config.candidateRoute) + (_vm.getCandidateValue('id')))}},[_vm._v(" "+_vm._s(_vm.getCandidateValue('name'))+" ")])]),_c('ul',{staticClass:"compare__nested-list"},_vm._l((_vm.config.compare),function(compare){return _c('CompareGridRow2',{key:((_vm.selectedHit.id) + "-" + (compare.candidate.displayKey)),attrs:{"candidate":{
              key: compare.candidate.displayKey,
              value: _vm.candidateValue(compare.candidate),
              valueKey: compare.candidate.valueKey,
              displayFilter: compare.candidate.displayFilter,
            },"incoming":{
              key: compare.incoming.displayKey,
              value: _vm.line[compare.incoming.valueKey],
              displayFilter: compare.incoming.displayFilter,
            },"enabled":!!_vm.selectedHit.id,"allow-action":compare.allowAction,"inherit-when-empty":compare.inheritWhenEmpty,"comparator":compare.comparator},on:{"valueUpdated":_vm.candidateValueUpdated}})}),1)]),_c('div',{style:({ 'grid-area': 'action' })},[_c('div',{staticClass:"compare__search"},[_c('div',{staticClass:"compare__nested-heading"},[_c('strong',[_vm._v("Possible candidates")])]),(_vm.hits.length === 0)?_c('div',{staticClass:"none push--bottom"},[_vm._v(" No candidates, try searching for one... ")]):_c('ul',_vm._l((_vm.hits),function(candidate,idx){return _c('li',{key:candidate.id,staticClass:"candidate",class:{ selected: _vm.isSelected(idx), match: _vm.isCandidateMatch(candidate.id) },on:{"click":function($event){return _vm.selectCandidate(idx)}}},[_c('div',{staticClass:"candidate_left"},[_c('div',{staticClass:"candidate_title"},[_vm._v(_vm._s(_vm.getCandidateName(candidate)))]),_c('div',{staticClass:"candidate_artist"},[_c('span',{staticClass:"candidate-artist-separator text--italic"},[_vm._v("by")]),_vm._v(" "+_vm._s(candidate.main_artist)+" ")]),_c('div',{staticClass:"candidate_date"},[_vm._v(" "+_vm._s(_vm._f("year")(candidate[_vm.config.candidateDateKey]))+" ")])]),_c('div',{staticClass:"candidate_right"},[_c('div',{staticClass:"candidate_meta"},[_c('div',{staticClass:"candidate_lineup"},[_c('span',[_vm._v(_vm._s(candidate.lineup_length))]),_c('span',[_c('i',{staticClass:"fas fa-user member",class:{ 'member--active': candidate.samiLineupMember },attrs:{"title":"Sami WW/WW-"}})])]),_c('div',{staticClass:"candidate_status"},[_c('status-icon',{attrs:{"status":candidate.status}})],1)]),_c('div',{staticClass:"flex-break"}),_c('div',{staticClass:"candidate_link"},[_c('router-link',{attrs:{"to":_vm.candidateLink,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
return [_c('a',{attrs:{"href":href,"target":_vm.candidateTabName}},[_c('i',{staticClass:"fas fa-arrow-right"})])]}}],null,true)})],1)])])}),0),_c('div',{staticClass:"row compare__search__id"},[_c('div',{staticClass:"col s12"},[_c('span',[_vm._v(_vm._s(_vm.config.searchAndCompare.title))]),_c('searchable-input',{attrs:{"show-label":false,"name":"name","placeholder":'E.g. Stoppa mig Juni (Lilla Ego)',"clear-after-select":true,"css-props":{ whiteSpace: 'normal' },"searcher":_vm.search,"use-template":_vm.config.searchAndCompare.template},on:{"input":_vm.add}})],1)])]),_c('div',{staticClass:"row compare__action-btns"},[_c('div',{staticClass:"col s12"},[_c('compare-buttons-matching2',{attrs:{"line":_vm.line,"dirty_line":_vm.changes > 0,"candidate-id":_vm.selectedHit.id,"is-album":false,"handle-identify-btn":_vm.handleIdentify,"handle-ignore-btn":_vm.ignore,"handle-new":_vm.handleNew,"new-btn-title":_vm.config.entityType === 'recording' ? 'New recording' : 'New Album',"handle-save-btn":_vm.saveCandidate,"revert-match":_vm.revertMatch,"capture-key-press":_vm.captureKeyPress}})],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }