<template>
  <div>
    <div class="title">New {{ extras.title }}</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Revenue</div>
        <div class="widget-table--col sm">Year</div>
        <div class="widget-table--col amount">Amount</div>
      </header>
      <div v-for="revenue in payload.items" :key="revenue.id" class="widget-table--row">
        <div class="widget-table--col">
          <router-link :to="`/distribution/revenues/${revenue.id}`">
            {{ revenue.name }}
          </router-link>
        </div>
        <div class="widget-table--col sm">{{ revenue.year }}</div>
        <div class="widget-table--col amount">
          {{ revenue.amount | formatAmountThousandSeparator('SEK') }}
        </div>
      </div>
    </section>

    <section class="widget-link">
      <router-link :to="extras.url">
        Show {{ extras.title }}
        {{ payload.total_count > 5 ? '(' + (payload.total_count - 5) + ' more)' : '' }}
      </router-link>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'NewRevenuesWidget',
  mixins: [WidgetMixin],
};
</script>
