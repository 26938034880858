<template>
  <div>
    <div class="row text--right edit-link">
      <div class="col s12">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>
    <div class="row card">
      <div class="row">
        <label class="col s3">Alternate names</label>
        <div class="col s9">
          <span v-for="(title, i) in basicInfoAlternateTitles" :key="i">
            {{ title + (basicInfoAlternateTitles.length - 1 > i ? ', ' : '') }}
          </span>
          <span v-if="basicInfoAlternateTitles.length === 0" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Release date</label>
        <div class="col s9">
          <span v-if="basicInfoReleaseDate" class="e2e-album-basic-info-release-date">
            {{ basicInfoReleaseDate }}
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Total length</label>
        <div class="col s9">
          <span v-if="tracks.length === 0" class="none">None</span>
          <span v-else>{{ basicInfoTotalLength | toMinutes }}</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Label</label>
        <div class="col s9">
          <span v-if="basicInfoLabel">{{ basicInfoLabel }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Catalogue number</label>
        <div class="col s9">
          <span v-if="basicInfoCatalogNumber">{{ basicInfoCatalogNumber }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <view-local-codes :local-codes="basicInfoLocalCodes" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import ViewLocalCodes from '../../ui/view-local-codes';

export default {
  name: 'BasicInfo',
  components: { ViewLocalCodes },
  computed: {
    ...mapGetters('album', [
      'basic_info',
      'basicInfoName',
      'basicInfoCatalogNumber',
      'basicInfoMainArtist',
      'basicInfoReleaseDate',
      'basicInfoComposer',
      'basicInfoLabel',
      'basicInfoDescription',
      'basicInfoLocalCodes',
      'tracks',
      'basicInfoAlternateTitles',
    ]),
    basicInfoTotalLength() {
      return _.sumBy(this.tracks, (t) => t.duration_sec);
    },
  },
};
</script>
