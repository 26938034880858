var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'main-nav': true, 'main-nav--active': _vm.navigationActive, 'main-nav--fixed': _vm.fixed },on:{"mouseenter":function($event){_vm.navigationActive = true},"mouseleave":function($event){_vm.navigationActive = false}}},[_c('ul',{staticClass:"main-nav__items"},[_vm._l((_vm.navItems),function(navItem){return [(_vm.hasAccess(navItem))?[(navItem.subMenuIcon)?[_c('li',{key:navItem.id,staticClass:"main-nav__item main-nav__item--list",class:{ 'is-open': navItem.isOpen },style:([
              navItem.activeNested ? { overflow: 'visible', color: '#ffdd35' } : '',
              navItem.isOpen ? '' : { overflow: 'hidden' },
              { 'max-height': navItem.isOpen ? (_vm.elHeight + "px") : '56px' } ]),on:{"click":_vm.setHeight}},[_c('div',{staticClass:"main-nav__item-link"},[_c('span',{staticClass:"main-nav__item-text"},[_c('i',{staticClass:"fas",class:navItem.icon}),_vm._v(" "+_vm._s(navItem.title)+" ")]),_c('div',{staticClass:"main-nav__more",on:{"click":function($event){return _vm.open(navItem)}}},[_c('i',{staticClass:"fas",class:navItem.subMenuIcon})])]),_c('ul',{staticClass:"main-nav__item-list",class:{ 'main-nav__item-list--hide': !_vm.navigationActive && !_vm.fixed },attrs:{"show":_vm.navigationActive && navItem.isOpen}},[_vm._l((navItem.links),function(nestedLink){return [(
                    _vm.hasAccess(nestedLink) &&
                    (!nestedLink.developerOnly || _vm.isDeveloper) &&
                    (!nestedLink.testingOnly || _vm.isTestEnvironment)
                  )?_c('li',{key:nestedLink.id,staticClass:"main-nav__item-list-content",on:{"click":function($event){return _vm.toggleOverflow($event)}}},[_c('router-link',{attrs:{"to":nestedLink.link}},[_vm._v(" "+_vm._s(nestedLink.title)+" ")])],1):_vm._e()]})],2)])]:[(navItem.title === 'My Dashboard')?[_c('li',{key:navItem.id,staticClass:"main-nav__item",style:([navItem.activeNested ? { overflow: 'visible' } : { overflow: 'hidden' }]),on:{"click":function($event){return _vm.toggleOverflow($event, _vm.closeNestedMenus)}}},[_c('div',{staticClass:"main-nav__item-link"},[_c('router-link',{style:([navItem.activeNested ? { color: '#ffdd35' } : null]),attrs:{"to":navItem.link,"exact":""}},[_c('span',{staticClass:"main-nav__item-text"},[_c('i',{staticClass:"fas",class:navItem.icon}),_vm._v(" "+_vm._s(navItem.title)+" ")])])],1)])]:[_c('li',{key:navItem.id,staticClass:"main-nav__item",style:([
                _vm.route == navItem.link || navItem.activeNested
                  ? { overflow: 'visible' }
                  : { overflow: 'hidden' } ]),on:{"click":function($event){return _vm.toggleOverflow($event, _vm.closeNestedMenus)}}},[_c('div',{staticClass:"main-nav__item-link"},[_c('router-link',{attrs:{"to":navItem.link}},[_c('span',{staticClass:"main-nav__item-text"},[_c('i',{staticClass:"fas",class:navItem.icon}),_vm._v(" "+_vm._s(navItem.title)+" ")])])],1)])]]]:_vm._e()]})],2),_c('div',{staticClass:"arrow",on:{"click":_vm.fixSidebar}},[_c('i',{staticClass:"far fa-arrow-alt-circle-right"})])])}
var staticRenderFns = []

export { render, staticRenderFns }