<template>
  <div class="e2e-contact-information">
    <div class="row">
      <div class="col s6">
        <h2>Contact Information</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>
    <div class="row card">
      <div class="row">
        <label class="col s3">Address</label>
        <div v-if="hasAddress" class="col s9">
          <div v-if="contactInfo.address.care_of">c/o {{ contactInfo.address.care_of }}</div>
          <div class="e2e-contact-information-street">
            {{ contactInfo.address.street }}
          </div>
          <div>{{ contactInfo.address.postal_code }} {{ contactInfo.address.city }}</div>
          <div>{{ contactInfo.address.province }}</div>
          <div>{{ contactInfo.address.country | formatCountry }}</div>
        </div>
        <div v-else class="col s9">
          <span class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Website</label>
        <div class="col s9">
          <span v-if="contactInfo.website">
            <a :href="contactInfo.website | toHref" target="_blank">{{ contactInfo.website }}</a>
          </span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Email</label>
        <div class="col s9">
          <span v-for="(email, index) in contactInfo.emails" :key="index">
            <a :href="'mailto:' + email">{{ email }}</a>
            <span v-if="index + 1 !== contactInfo.emails.length">,</span>
          </span>
          <span v-if="contactInfo.emails.length === 0 || contactInfo.emails[0] === ''" class="none">
            None
          </span>
        </div>
      </div>

      <div
        v-if="
          contactInfo.phone_numbers.length === 0 || contactInfo.phone_numbers[0].number === null
        "
        class="row flush--bottom"
      >
        <label class="col s3">Phone number</label>
        <div class="col s9">
          <span class="none">None</span>
        </div>
      </div>
      <div
        v-for="(phoneNumber, idx) in contactInfo.phone_numbers"
        :key="idx"
        class="row"
        :class="idx + 1 === contactInfo.phone_numbers.length ? 'flush--bottom' : ''"
      >
        <label class="col s3">
          {{ phoneNumber.type ? $t(`common.phone.${phoneNumber.type.toLowerCase()}`) : '' }}
        </label>
        <div class="col s9">
          <div>{{ phoneNumber.number | formatPhoneNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isPristine } from '../../../common/typeUtils';

export default {
  name: 'ViewContactInfo',
  props: {
    contactInfo: { type: Object },
  },
  computed: {
    hasAddress() {
      return !isPristine(this.contactInfo.address);
    },
  },
};
</script>
