<template>
  <div class="compare__airtime">
    <span class="compare__nested-heading">
      <strong>Played information</strong>
    </span>
    <ul class="compare__airtime-list">
      <li class="compare__airtime-list-item">
        Air time played
        <span>
          {{ playtime | toMinutes }}
        </span>
      </li>
      <li class="compare__airtime-list-item">
        No. of times
        <span>
          {{ linecount }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CompareAirtime',
  props: {
    playtime: {
      type: Number,
    },
    linecount: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
.compare {
  &__airtime {
    grid-area: airtime;
    padding-left: 16px;
    margin-right: -28px;

    &-list {
      &-item {
        padding-left: 16px;
        display: grid;
        grid-template-columns: 150px 1fr;
        border-bottom: 1px solid #eee;
        align-items: center;
        min-height: 36px;
      }
    }
  }
}
</style>
