var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('spinner'):_vm._e(),_vm._m(0),_vm._m(1),_c('div',{staticClass:"row card"},[(!_vm.initiated)?[_c('div',{staticClass:"col s2"},[_c('select-year',{attrs:{"label":"Expiry Year","years":_vm.years,"empty-select-option":false},on:{"input":function($event){_vm.year = $event}}})],1),_c('div',{staticClass:"col s2"},[_c('br'),_c('button',{staticClass:"btn",attrs:{"disabled":!_vm.year},on:{"click":function($event){return _vm.createFile()}}},[_vm._v("Create file")])])]:_c('div',{staticClass:"col s12"},[_vm._v("File is being created, check back here in a few minutes.")])],2),_vm._m(2),_c('div',{staticClass:"row card"},[_c('div',{staticClass:"col s12"},[_c('DataTable',{attrs:{"columns":_vm.columns,"items":_vm.files},scopedSlots:_vm._u([{key:"item:filename",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('i',{staticClass:"far fa-file-alt"}),_vm._v(" Download "+_vm._s(item.filename)+" ")])]}},{key:"item:year",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata.year)+" ")]}},{key:"item:user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata.user)+" ")]}},{key:"item:lastModified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(item.lastModified))+" ")]}}])})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row wrapper"},[_c('div',{staticClass:"col s6"},[_c('h1',[_vm._v("US Tax Forms")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v("Create Expiring Tax Form File")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h2',[_vm._v("Files")])])])}]

export { render, staticRenderFns }