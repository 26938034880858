<template>
  <div>
    <div v-for="{ list, version } of lists" :key="list.stream_id">
      <div class="meta">
        <div class="version">
          <router-link :to="{ path: `${basePath}/${version}` }">Version {{ version }}</router-link>
        </div>
        <div class="created-at">
          <i>{{ list.createdAt | formatDateTime }}</i>
        </div>
      </div>
      <div class="row card">
        <SamiListSummary :list="list" :version="version" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../services/apolloRequest';
import SamiListSummary from './sami-list-summary.vue';
export default {
  components: {
    SamiListSummary,
  },
  props: {
    entityId: String,
    entityType: String,
  },
  data() {
    return {
      lists: [],
    };
  },
  computed: {
    basePath() {
      if (this.entityType === 'recording') {
        return `/recording/${this.entityId}/information`;
      }
      if (this.entityType === 'album') {
        return `/albums/${this.entityId}/information`;
      }
      return '/';
    },
  },
  created() {
    this.fetchLists();
  },
  methods: {
    async fetchLists() {
      const { samiListsForEntity } = await query({
        query: gql`
          query {
            samiListsForEntity(id: "${this.entityId}") {
              version
              list {
                title
                groupOrArtistName
                createdAt
                comment
                contact {
                  name
                  email
                  phone
                }
                recordings {
                  title
                  number
                  lineup {
                    role
                    instruments
                    performer {
                      id
                      pseudoName
                    }
                    newPerformer {
                      firstName
                      lastName
                      pseudoName
                    }
                  }
                }
              }
            }
          }
        `,
      });
      this.lists = samiListsForEntity;
    },
  },
};
</script>

<style scoped>
.meta {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 1rem;
  color: var(--grey--light);
  padding: 0.3rem 0;
}
.created-at,
.version {
  flex: 1 1 50%;
}
.created-at {
  text-align: right;
}
</style>
