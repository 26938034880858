<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 l9 album-wrapper">
        <h1>{{ basicInfoName }}</h1>
        <h2>
          <span v-if="!basicInfoMainArtist">(main artist missing)</span>
          <span v-else>
            <span v-if="basicInfoMainArtist.id">
              by
              <router-link :to="'/mainartists/' + basicInfoMainArtist.id">
                {{ basicInfoMainArtist.name }}
              </router-link>
            </span>
            <span v-else>by {{ basicInfoMainArtist.name }}</span>
          </span>
        </h2>
        <version
          :version="routeVersion()"
          :readonly="readonly"
          entity-type="album"
          view-name="albumInformation"
        />
        <div>
          <create-basic-info
            v-if="isEditMode('BasicInfo')"
            :edit-mode="true"
            @update="onUpdateBasicInfo"
            @cancel="onCancel"
          />
          <basic-info v-else @edit="onEdit" />
        </div>
        <div>
          <create-tracks
            v-if="isEditMode('Tracks')"
            :edit-mode="true"
            @update="onUpdateTracks"
            @cancel="onCancel"
          />
          <tracks v-else @edit="onEdit" @copyLineup="copyLineup" />
        </div>
        <div>
          <performers
            :selected-performer-id="selectedPerformer ? selectedPerformer.id : null"
            :show-edit-performer="showEditPerformer"
            @patchLineup="patchLineup"
            @openEditPerformer="onOpenEditPerformer"
            @closeEditPerformer="onCloseEditPerformer"
          />
        </div>
      </div>
      <div class="col s12 l3">
        <right-column
          :editable-component="editableComponent"
          :pre-selected-tab="routeVersion() ? 'activity' : 'general'"
          @onEdit="onEdit"
          @onCancel="onCancel"
          @onUpdateBasicInfo="onUpdateBasicInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import CommandService from '../../../services/commandService';
import AlbumService from '../../../services/albumService';
import AggregateMixin from '../../../common/aggregateMixin';
import BasicInfo from './basic-info';
import CreateBasicInfo from './../create/create-basic-info';
import CreateTracks from './../create/create-tracks';
import Performers from './performers';
import Spinner from './../../spinner';
import store from '../../../store/index';
import Tracks from './tracks';
import RightColumn from '../right-column';
import Version from '../../ui/version';

export default {
  name: 'AlbumInformation',
  components: {
    BasicInfo,
    CreateBasicInfo,
    CreateTracks,
    Performers,
    Spinner,
    Tracks,
    RightColumn,
    Version,
  },
  mixins: [AggregateMixin],
  data() {
    return {
      storeNamespace: 'album',
      navigationViews: ['albumView', 'albumPlayedView'],
      loading: true,
      editableComponent: '',
      performers: [],
      selectedPerformer: null,
      showEditPerformer: null,
    };
  },
  computed: mapGetters('album', [
    'id',
    'version',
    'aggregate',
    'basicInfoName',
    'basicInfoMainArtist',
    'readonly',
    'merged_with',
  ]),
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const album = await this.loadAggregate(AlbumService.getAlbum);
        this.dispatchAggregate(album);
      } catch (error) {
        this.dispatchAggregate();
        error.title = 'Could not load main album';
        this.$addStarError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.routeAggregateId() },
        });
      } finally {
        this.loading = false;
      }
    },
    dispatchAggregate(_aggregate) {
      if (!_aggregate) {
        store.dispatch(`${this.storeNamespace}/createAggregate`);
        return;
      }
      const aggregate = Object.freeze(_.cloneDeep(_aggregate));
      store.dispatch(`${this.storeNamespace}/setAlbumAggregate`, aggregate);
      this.savedInitialAggregate = _.cloneDeep(aggregate);
    },
    async onUpdateBasicInfo() {
      try {
        await AlbumService.updateAlbumBasicInfo(this.aggregate);
        await this.fetchData();
        this.editableComponent = '';
      } catch (error) {
        error.title = 'Could not update information';
        throw error;
      }
    },
    async updateTracks() {
      try {
        await AlbumService.updateAlbumTracks(this.aggregate);
        this.fetchData();
      } catch (error) {
        error.title = 'Could not update track information';
        throw error;
      }
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onEdit(ref) {
      this.editableComponent = ref;
    },
    async onUpdateTracks() {
      await this.updateTracks();
      this.editableComponent = '';
    },
    onCancel() {
      this.dispatchAggregate(this.savedInitialAggregate);
      this.editableComponent = '';
    },
    async copyLineup({ sourceId, destinationIds }) {
      const commands = destinationIds.map((destId) => ({
        type: 'recording:copyLineup',
        stream_id: destId,
        payload: { source_id: sourceId },
      }));

      await this.handleCommands(commands);
    },
    async patchLineup(changes) {
      const commands = changes.map((change) => ({
        type: 'recording:patchLineup',
        stream_id: change.id,
        payload: {
          upsert: change.upsert || [],
          deleteIds: change.deleteIds || [],
        },
      }));

      await this.handleCommands(commands, true);
    },
    async handleCommands(commands, fetchDataAtEnd) {
      this.loading = true;
      try {
        await CommandService.handleCommands(commands);
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      } finally {
        if (fetchDataAtEnd) {
          this.fetchData();
        }
        this.loading = false;
      }
    },
    onOpenEditPerformer(performer) {
      this.selectedPerformer = performer;
      this.showEditPerformer = true;
    },
    onCloseEditPerformer() {
      this.showEditPerformer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.album-wrapper {
  padding-right: 24px;
}
</style>
