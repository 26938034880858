import gql from 'graphql-tag';

const getFullRecording = gql`
  query recording($idTerm: String!, $versionTerm: Int) {
    recordingAggregate(id: $idTerm, version: $versionTerm) {
      id
      version
      lineup_locked
      basic_info {
        vrdb2_id
        name
        version_title
        alternate_titles
        vrdb2_title
        vrdb2_rm
        main_artist {
          id
          name
          type
        }
        recorded_in_country
        recording_date
        produced_in_country
        release_date
        genre
        duration_sec
        composer
        writer
        arranger
        label
        isrc
        source_info {
          source
          comment
        }
        local_codes {
          code
          value
        }
        tags
      }
      distribution_state {
        status
        legacy_unknown_performers
      }
      lineup {
        relation {
          id
          name
          pseudo_names
          type
          nationality
          societies {
            society_code
            mandate_type
            start_date
            end_date
          }
          ipn
          collective
        }
        instruments
        role
        pseudo_name
      }
      albums {
        id
        name
        side_no
        track_no
        release_date
        catalog_number
        local_codes {
          code
          value
        }
      }
      vrdb2_state {
        sync_state
        duplicate_of
        validation_error
        updated_at
        is_enqueued
      }
    }
  }
`;

const getLineup = gql`
  query recording($idTerm: String!, $versionTerm: Int) {
    recordingAggregate(id: $idTerm, version: $versionTerm) {
      lineup {
        relation {
          id
          name
          pseudo_names
          type
          nationality
          societies {
            society_code
            mandate_type
            start_date
            end_date
          }
          ipn
          collective
        }
        instruments
        role
        pseudo_name
      }
    }
  }
`;

const compactRecording = `
  recordings {
    id
    duration_sec
    recorded_in_country
    label
    vrdb2_id
    composer
    writer
    arranger
    name
    alternate_titles
    isrc
    recording_date
    version_title
    sami_members
    main_artist {
      id
      name
      type
    }
    lineup {
      id
      name
      role
      instruments
    }
    lineup_locked
    tags
    distribution_state {
      status
      legacy_unknown_performers
    }
  }
`;

const getRecordingsByPerformer = gql`
  query recording(
    $idTerm: String!
    $filterQuery: String
    $mainArtist: String
    $pagination: Pagination
  ) {
    recordingsByLineupPerformer(
      id: $idTerm
      filterQuery: $filterQuery
      mainArtist: $mainArtist
      pagination: $pagination
    ) {
      total
      recordings {
        id
        lineup_locked
        duration_sec
        recorded_in_country
        label
        vrdb2_id
        composer
        writer
        arranger
        name
        alternate_titles
        isrc
        recording_date
        version_title
        sami_members
        main_artist {
          id
          name
          type
        }
        role
        instruments
        tags
        distribution_state {
          status
          legacy_unknown_performers
        }
      }
    }
  }
`;

const getRecordingsByMainArtist = gql`
   query recording($idTerm: String!, $filterQuery: String, $pagination: Pagination) {
    recordingsByMainArtist(id: $idTerm, filterQuery: $filterQuery, pagination: $pagination) {
      total,
      ${compactRecording}
    }
  }
`;

const canDeleteRecording = gql`
  query canDeleteRecording($idTerm: String!) {
    canDeleteRecording(id: $idTerm) {
      canDelete
      reasons
    }
  }
`;

const deleteRecording = gql`
  mutation deleteRecording($idTerm: String, $user: String) {
    handleCommands(
      target: pnr
      commands: [{ stream_id: $idTerm, type: "recording:delete", user: $user, payload: {} }]
    ) {
      streamId
      error
      command {
        stream_id
        process_id
        timestamp
        type
        version
        payload
        user
      }
    }
  }
`;

const updateLineupLocked = gql`
  mutation updateLineupLocked($idTerm: String!, $user: String, $lineupLocked: Boolean) {
    handleCommands(
      target: pnr
      commands: [
        {
          stream_id: $idTerm
          type: "recording:updateLineupLocked"
          user: $user
          payload: { lineup_locked: $lineupLocked }
        }
      ]
    ) {
      streamId
      error
      command {
        stream_id
        process_id
        timestamp
        type
        version
        payload
        user
      }
    }
  }
`;

export default {
  getFullRecording,
  getLineup,
  getRecordingsByPerformer,
  getRecordingsByMainArtist,
  canDeleteRecording,
  deleteRecording,
  updateLineupLocked,
};
