var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.loading)?[_c('div',{staticClass:"col s12"},[_c('spinner')],1)]:[_c('div',{staticClass:"col s12 l9 performer-wrapper"},[_c('h1',[_vm._v(" "+_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName)+" "),(_vm.aggregate.deleted)?_c('span',{staticClass:"text--italic"},[_vm._v(" (deleted by "+_vm._s(_vm.aggregate.deletedBy)+") ")]):_vm._e()]),_c('version',{attrs:{"version":_vm.routeVersion(),"readonly":_vm.aggregate.deleted,"entity-type":"performer","view-name":"performerInformation"}}),_c('StarViewEditPairs',{staticClass:"e2e-view-performer__information",model:{value:(_vm.editableComponent),callback:function ($$v) {_vm.editableComponent=$$v},expression:"editableComponent"}},[_c('StarViewEditPair',{attrs:{"id":"GeneralInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('general-information',{on:{"edit":activator}})]}}])},[_c('edit-general-information',{on:{"cancel":_vm.onCancel,"saved":_vm.onSaved}})],1),_c('StarViewEditPair',{attrs:{"id":"MemberInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('member-information',{on:{"edit":activator}})]}}])},[_c('edit-member-information',{on:{"cancel":_vm.onCancel,"saved":_vm.onSaved}})],1),_c('StarViewEditPair',{attrs:{"id":"MandateInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('mandate-information',{attrs:{"show-all-mandates":_vm.showAllMandates},on:{"edit":activator,"reload":_vm.fetchData,"fetch":function($event){return _vm.fetchMandates()}}})]}}])},[_c('create-mandate-information',{attrs:{"edit-mode":true},on:{"cancel":_vm.onCancel,"saved":_vm.onSaved}})],1),_c('StarViewEditPair',{attrs:{"id":"PaymentInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('payment-information',{attrs:{"private-account":_vm.privateAccount,"company-account":_vm.companyAccount,"foreign-account":_vm.foreignAccount,"has-payment-receiver":_vm.hasPaymentReceiver},on:{"edit":activator}})]}}])},[_c('create-payment-information',{attrs:{"edit-mode":true,"company-account-visible":_vm.companyAccount.account_number && !_vm.companyAccount.account_number.isEmpty,"company-account-disabled":true,"private-account":_vm.privateAccount,"foreign-account":_vm.foreignAccount,"company-account":_vm.companyAccount,"has-payment-receiver":_vm.hasPaymentReceiver},on:{"resetPaymentInfo":_vm.resetPaymentInfo,"addPrivateAccount":function($event){return _vm.commitChange('addPrivateAccount', $event)},"addCompanyAccount":function($event){return _vm.commitChange('addCompanyAccount', $event)},"addForeignAccount":function($event){return _vm.commitChange('addForeignAccount', $event)},"cancel":_vm.onCancel,"save":_vm.updatePaymentInfo}})],1),_c('StarViewEditPair',{attrs:{"id":"Associates","single":""},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
var editIsActive = ref.editIsActive;
return [_c('associates',{attrs:{"enable-row-options":_vm.routeVersion() === null,"namespace":"performer","relation-type":"associates","edit-mode":editIsActive},on:{"edit":activator,"cancel":_vm.onCancel,"remove":_vm.removeAssociate,"save":_vm.saveAssociate}})]}}])}),_c('StarViewEditPair',{attrs:{"id":"ContactInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('contact-information',{on:{"edit":activator}})]}}])},[_c('edit-contact-information',{on:{"cancel":_vm.onCancel,"saved":_vm.onSaved}})],1),(!_vm.dateOfDeath)?_c('StarViewEditPair',{attrs:{"id":"TaxInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('tax-information',{attrs:{"tax-tin":_vm.taxTin,"tax-domicile":_vm.taxDomicile,"tax-vat-number":_vm.taxVatNumber,"statement-of-income":_vm.statementOfIncome},on:{"edit":activator}})]}}],null,false,4039278702)},[_c('edit-tax-information',{attrs:{"entity":_vm.currentPerformer},on:{"cancel":_vm.onCancel,"save":_vm.onUpdateTaxInfo}})],1):_vm._e(),_c('StarViewEditPair',{attrs:{"id":"DocumentsInformation"},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var activator = ref.activator;
return [_c('documents-information',{attrs:{"documents":_vm.documents},on:{"edit":activator}})]}}])},[_c('edit-documents-information',{attrs:{"documents":_vm.documents,"update-documents":_vm.updateDocuments,"aggregate-type":"performer"},on:{"cancel":_vm.onCancel,"saved":function($event){return _vm.onUpdateDocument($event)}}})],1)],1)],1),_c('div',{staticClass:"col s12 l3"},[_c('right-column',{attrs:{"editable-component":_vm.editableComponent,"pre-selected-tab":_vm.routeVersion() ? 'activity' : 'general',"performer-deleted":_vm.aggregate.deleted || _vm.routeVersion() !== null},on:{"update:editableComponent":function($event){_vm.editableComponent=$event},"update:editable-component":function($event){_vm.editableComponent=$event},"cancel":_vm.onCancel,"saved":_vm.onSaved,"deletePerformer":_vm.showDeleteModal}})],1)],(_vm.deleteModalShown)?_c('modal',{attrs:{"submit-label":"Delete","disable-submit":!_vm.canDelete.canDelete,"show-confirm-check-box":_vm.canDelete.canDelete,"confirm-message":"I confirm deletion of performer. This action is not reversible!","action-button-class":"red"},on:{"close":function($event){_vm.deleteModalShown = false},"cancel":function($event){_vm.deleteModalShown = false},"save":function($event){return _vm.onDeletePerformer()}}},[_c('h1',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Delete Performer?")]),(_vm.canDelete.reasons.length > 0)?_c('div',{attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.canDelete.reasons),function(reason){return _c('div',{key:reason,staticClass:"row margin--bottom"},[_c('span',{staticClass:"is-danger float-left"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(reason)+" ")])])}),0):_vm._e()]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }