<template>
  <div>
    <distribution-closure-information class="row" :distribution-closure="distributionClosure" />
    <div class="row">
      <div v-if="working" class="col s4 offset-s9">
        <component-spinner />
      </div>
      <div v-else-if="!approved" class="col float-right">
        <action-buttons
          submit-label="Approve"
          :show-abort="false"
          :disable-submit="+distributionClosure.header.totalAmount === 0"
          @save="approveDistributionClosure"
        />
      </div>
      <div v-else-if="verificationStatus !== 'success'" class="col s4 offset-s8">
        <verify-with-password
          :message="verifyWithPasswordMessage"
          :verification-status="verificationStatus"
          :number-of-attempts="numberOfAttempts"
          :disabled="distributionClosure.rows.length === 0"
          submit-label="Confirm"
          @onSubmitPassword="onSubmitPassword"
          @onSubmitMfaCode="onSubmitMfaCode"
          @onBypassAuth="onBypassAuth"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Back to distribution closures"
            :show-abort="false"
            @save="backToHistory()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATE } from '@/store/modules/process/store';
import VerifyWithPassword from '../../ui/input/verify-with-password';
import AuthenticationMixin from '../../../common/authenictaionMixin';
import ActionButtons from '@/components/ui/button/action-buttons';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import CommandService from '@/services/commandService';
import ComponentSpinner from '../../component-spinner';
import DistributionClosureInformation from '@/components/distribution/distribution-closure/distribution-closure-information';

export default {
  name: 'ProgressDistributionClosureVerify',
  components: {
    DistributionClosureInformation,
    VerifyWithPassword,
    ActionButtons,
    ComponentSpinner,
  },
  mixins: [AuthenticationMixin],
  props: {
    distributionClosure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      working: false,
      approved: this.distributionClosure.header?.state === distributionClosureState.Approved,
      verifyWithPasswordMessage:
        ' I hereby confirm that I want to perform\n' + '          a distribution closure.\n',
    };
  },
  methods: {
    async approveDistributionClosure() {
      this.working = true;
      const stream_id = this.distributionClosure.header.distributionClosureId;
      await CommandService.handleCommand('payment', {
        type: 'closure/approve',
        stream_id,
      });
      this.working = false;
      this.approved = true;
    },
    async doWork() {
      this.working = true;
      const stream_id = this.distributionClosure.header.distributionClosureId;
      const result = await CommandService.handleCommand('payment', {
        type: 'closure/confirm',
        stream_id,
      });
      this.working = false;
      this.$emit('refresh', 3000);
      this.$store.dispatch('process/addProcess', {
        name: 'Distribution closure',
        id: result.processId,
        route: `/distribution-closures/${result.processId}`,
        routeAvailability: [STATE.RUNNING, STATE.FINISHED],
      });
    },
    backToHistory() {
      this.$router.push({
        name: 'distributionClosureHistory',
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
